/* NOTE Designframe 0.98.9.1 Release															*/
/* -------------------------------------------------------------------------------------------- */
/* NOTE Getting Started 																		*/
/* -------------------------------------------------------------------------------------------- */
/* Designframe can be configured using the calculator then implementing output values in the	*/
/* "SECTION CONFIG" sections below.																*/
/* 	* You can edit your Theme, change specific values, and add custom classes in this file.		*/
/* 	* Keeping the default Designframe Preferred Values is strongly recommended.					*/
/* 	* Icon Fonts (e.g. Font Awesome) are implemented independently of Designframe. 				*/
/* 	* Official Tailwind Forms Plug-In REQUIRED 													*/
/* -------------------------------------------------------------------------------------------- */
/* VS CODE USERS: Table of Contents and Sections Anchors are implemented using					*/
/* https://marketplace.visualstudio.com/items?itemName=ExodiusStudios.comment-anchors 			*/
/* with LAYER as a custom anchor tag.															*/
/* -------------------------------------------------------------------------------------------- */

/* -------------------------------------------------------------------------------------------- */
/* SECTION Required Tailwind Directives - DO NOT EDIT 											*/
/* -------------------------------------------------------------------------------------------- */
@tailwind base;
/* Injects Tailwind base styles and any other plugin base styles.								*/
@tailwind components;
/* Injects Tailwind component classes and any plugin component classes 							*/
@tailwind utilities;
/* Injects Tailwind utility classes and any plugin utility classes								*/
/* -------------------------------------------------------------------------------------------- */
/* !SECTION Required Tailwind Directives - DO NOT EDIT											*/
/* -------------------------------------------------------------------------------------------- */

/* ------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------ */
/* CSJS CS JS VARIABLES BEGIN	- SEE JS REPLACMENT FOR VALUE IN CSJS COMMENTS									*/
/* ------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------ */

/* -------------------------------------------------------------------------------------------- */
/* SECTION CONFIG Fonts																			*/
/* -------------------------------------------------------------------------------------------- */
/* LAYER base begin				*/
@layer base { /* Do not edit 	*/
	/* ---------------------------- */

	/* ---------------------------- */
	/* ANCHOR Font Setup 			*/
	/* ---------------------------- */
	/* Add and name your fonts.		*/
	/* Preferred approach is to set a specific font for each weight and style combination for Display, Heading, and Body typography. */

	/* TODO Test/Check Font rendering for italic and weights to verify loading different font, NOT system pseudo-variant. */

	/* CSJS Theme Base Variables - Fonts */

	@font-face {
		font-family: 'displayFontFamily'; /* CSJS Do not change this variable. Always keep "displayFontFamily" */
		/* Add your Display and/or Heading Font - Use additional font-face entries with the same font-family name but different weights and/or styles to preserve tailwind text utilities */
		src: url('../src/assets/fonts/DIN2014-Bold.woff') format('woff'); /* CSJS Use {font_display_src} and {font_display_format} to load from Google Font per User-Specified Single Font with User-Specified Single Weight as URL parameter, independent of the font-weight and font-style below. */
		font-weight: 400; /* CSJS Do not change this variable. Always keep "400" */
		/* This should be set to match the intended behavior to the corresponding tailwind class equivalent to preserve utility function */
		font-style: normal; /* CSJS Do not change this variable. Always keep "normal" */
		/* This should be set to match the intended behavior to the corresponding tailwind class equivalent to preserve utility function */
	}

	@font-face {
		font-family: 'headingFontFamily'; /* CSJS Do not change this variable. Always keep "headingFontFamily" */
		/* Add your Display and/or Heading Font - Use additional font-face entries with the same font-family name but different weights and/or styles to preserve tailwind text utilities */
		src: url('../src/assets/fonts/DIN2014-Bold.woff') format('woff');/* CSJS Use {font_heading_src} and {font_heading_format} to load from Google Font per User-Specified Single Font with User-Specified Single Weight as URL parameter, independent of the font-weight and font-style below. */
		font-weight: 400; /* CSJS Do not change this variable. Always keep "400" */
		/* This should be set to match the intended behavior to the corresponding tailwind class equivalent to preserve utility function */
		font-style: normal; /* CSJS Do not change this variable. Always keep "normal" */
		/* This should be set to match the intended behavior to the corresponding tailwind class equivalent to preserve utility function */
	}

	@font-face {
		font-family: 'bodyFontFamily'; /* CSJS Do not change this variable. Always keep "bodyFontFamily" */
		/* Add your Body Font - Use additional font-face entries with the same font-family name but different weights and/or styles to preserve tailwind text utilities */
		src: url('../src/assets/fonts/NotoSans-Regular.ttf') format('truetype'); /* CSJS Use {font_body_src} and {font_body_format} to load from Google Font per User-Specified Single Font with User-Specified Single Weight as URL parameter, independent of the font-weight and font-style below. */
		font-weight: 400; /* CSJS Do not change this variable. Always keep "400" */
		/* This should be set to match the intended behavior to the corresponding tailwind class equivalent to preserve utility function */
		font-style: normal; /* CSJS Do not change this variable. Always keep "normal" */
		/* This should be set to match the intended behavior to the corresponding tailwind class equivalent to preserve utility function */
	} /* Commonspace - Defines specific font to use for notoSans without any weight/style classes. Body uses a single font family but with specific different styled fonts within the family for different weights and styles */

	@font-face {
		font-family: 'bodyFontFamily'; /* CSJS Do not change this variable. Always keep "bodyFontFamily" */
		/* Add your Body Font - Use additional font-face entries with the same font-family name definition but different weights and/or styles to preserve tailwind text utilities */
		src: url('../src/assets/fonts/NotoSans-Italic.ttf') format('truetype'); /* CSJS Use {font_body_italic_src} and {font_body_italic_format} to load from Google Font per User-Specified Single Font with User-Specified Single Weight as URL parameter, independent of the font-weight and font-style below. */
		font-weight: normal; /* CSJS Do not change this variable. Always keep "normal" */
		/* This should be set to match the intended behavior to the corresponding tailwind class equivalent to preserve utility function */
		font-style: italic; /* CSJS Do not change this variable. Always keep "italic" */
		/* This should be set to match the intended behavior to the corresponding tailwind class equivalent to preserve utility function */
	} /* Commonspace - Defines specific font to use for notoSans with the italic class applied. Body uses a single font family but with specific different styled fonts within the family for different weights and styles */

	@font-face {
		font-family: 'bodyFontFamily'; /* CSJS Do not change this variable. Always keep "bodyFontFamily" */
		/* Add your Body Font - Use additional font-face entries with the same font-family name definition but different weights and/or styles to preserve tailwind text utilities */
		src: url('../src/assets/fonts/NotoSans-SemiBold.ttf') format('truetype'); /* CSJS Use {font_body_bold_src} and {font_body_bold_format} to load from Google Font per User-Specified Single Font with User-Specified Single Weight as URL parameter, independent of the font-weight and font-style below. */
		font-weight: 700; /* CSJS Do not change this variable. Always keep "700" */
		/* This should be set to match the intended behavior to the corresponding tailwind class equivalent to preserve utility function */
		font-style: normal; /* CSJS Do not change this variable. Always keep "normal" */
		/* This should be set to match the intended behavior to the corresponding tailwind class equivalent to preserve utility function */
	} /* Commonspace - Defines specific font to use for notoSans with the bold class applied. Body uses a single font family but with specific different styled fonts within the family for different weights and styles */

	@font-face {
		font-family: 'bodyFontFamily'; /* CSJS Do not change this variable. Always keep "bodyFontFamily" */
		/* Add your Body Font - Use additional font-face entries with the same font-family name definition but different weights and/or styles to preserve tailwind text utilities */
		src: url('../src/assets/fonts/NotoSans-SemiBoldItalic.ttf') format('truetype'); /* CSJS Use {font_body_bold_italic_src} and {font_body_bold_italic_format} to load from Google Font per User-Specified Single Font with User-Specified Single Weight as URL parameter, independent of the font-weight and font-style below. */
		font-weight: 700; /* CSJS Do not change this variable. Always keep "700" */
		/* This should be set to match the intended behavior to the corresponding tailwind class equivalent to preserve utility function */
		font-style: italic; /* CSJS Do not change this variable. Always keep "italic" */
		/* This should be set to match the intended behavior to the corresponding tailwind class equivalent to preserve utility function */
	}

	/* -------------------------------- */
} /* Do not edit 					*/
/* !LAYER base end 					*/
/* -------------------------------------------- */
/* !SECTION CONFIG Fonts						*/
/* -------------------------------------------- */

/* -------------------------------------------- */
/* SECTION CONFIG Quick Setup Variables			*/
/* -------------------------------------------- */
/* LAYER :root begin			*/
:root { /* Do not edit			*/
	/* ---------------------------- */

	/* ---------------------------- */
	/* ANCHOR Font Assignments		*/
	/* ---------------------------- */

	/* ASSIGNMENTS */
	/* Assign your Heading, Body, and Display Fonts from Font Setup to their appropriate uses. */

	/* CSJS Theme Base Variables - Fonts */
	--font-heading: headingFontFamily; 		/* Default font-family for Heading. */ 	/* CSJS Do not change this variable. Always keep "headingFontFamily" */
	--font-heading-alt1: sans-serif; 		/* First fallback for Heading. */ 		/* CSJS Use "serif" or "sans-serif" based on Heading Font SRC */
	--font-heading-alt2: system-ui; 		/* Second fallback for Heading. */ 		/* CSJS Do not change this variable. Always keep "system-ui" */
	--font-body: bodyFontFamily; 			/* Default font-family Body. */ 		/* CSJS Do not change this variable. Always keep "bodyFontFamily" */
	--font-body-alt1: sans-serif; 			/* First fallback for Body. */ 			/* CSJS Use "serif" or "sans-serif" based on Body Font SRC */
	--font-body-alt2: system-ui; 			/* Second fallback for Body. */ 		/* CSJS Do not change this variable. Always keep "system-ui" */
	--font-display: displayFontFamily; 		/* Default font-family for Display. Same value as --font-heading is standard usage. */ 	/* CSJS Do not change this variable. Always keep "displayFontFamily" */
	--font-display-alt1: sans-serif; 		/* First fallback for Display. Same value as --font-heading is standard usage. */ 		/* CSJS Use "serif" or "sans-serif" based on Display Font SRC */
	--font-display-alt2: system-ui; 		/* Second fallback for Display. Same value as --font-heading is standard usage. */ 		/* CSJS Do not change this variable. Always keep "system-ui" */

	/* -------------------------------------------------------------------------------------------- */
	/* ANCHOR Quick Setup Variables																	*/
	/* -------------------------------------------------------------------------------------------- */

	/* CSJS Theme Base Variables - Colors */
	--qs-color-key: 251 3 185;						/* CSJS {color_key} */
	--qs-color-key-rgb: 251, 3, 185; 				/* CSJS {color_key_rgb} */
	--qs-color-key-end: 56 131 255; 				/* CSJS {color_key_end} */
	--qs-color-key-end-rgb: 56, 131, 255; 			/* CSJS {color_key_end_rgb} */
	--qs-color-primary: 12 12 12;					/* CSJS {color_primary} */
	--qs-color-secondary: 136 136 136;				/* CSJS {color_secondary} */
	--qs-color-tertiary: 204 204 204;				/* CSJS {color_tertiary} */
	--qs-color-invert: 255 255 255;					/* CSJS {color_invert} */
	--qs-color-disabled: 204 204 204;				/* CSJS {color_disabled} */
	--qs-color-background: 255 255 255;				/* CSJS {color_background} */
	--qs-color-background-start: 255 230 248;		/* CSJS {color_background_start} */
	--qs-color-background-start-rgb: 255, 230, 248;	/* CSJS {color_background_start_rgb} */
	--qs-color-background-end: 235 243 255;			/* CSJS {color_background_end} */
	--qs-color-background-end-rgb: 235, 243, 255;	/* CSJS {color_background_end_rgb} */
	--qs-color-background-direction: right top;		/* CSJS {color_background_direction}  */

	/* CSJS Theme Alt Variables - Colors */
	--qs-color-key-alt: 250 0 2; 						/* CSJS {color_key} */
	--qs-color-key-alt-rgb: 250, 0, 2; 					/* CSJS {color_key_rgb} */
	--qs-color-key-end-alt: 250 160 2; 					/* CSJS {color_key_end} */
	--qs-color-key-end-alt-rgb: 250, 160, 2; 			/* CSJS {color_key_end_rgb} */
	--qs-color-primary-alt: 12 12 12;					/* CSJS {color_primary_alt} */
	--qs-color-secondary-alt: 136 136 136;				/* CSJS {color_secondary_alt} */
	--qs-color-tertiary-alt: 204 204 204;				/* CSJS {color_tertiary_alt} */
	--qs-color-invert-alt: 255 255 255;					/* CSJS {color_invert_alt} */
	--qs-color-disabled-alt: 204 204 204;				/* CSJS {color_disabled_alt} */
	--qs-color-background-alt: 255 255 255;				/* CSJS {color_background_alt} */
	--qs-color-background-start-alt: 235 243 255;		/* CSJS {color_background_start_alt} */
	--qs-color-background-start-alt-rgb: 235, 243, 255;	/* CSJS {color_background_start_alt_rgb} */
	--qs-color-background-end-alt: 255 230 248;			/* CSJS {color_background_end_alt} */
	--qs-color-background-end-alt-rgb: 255, 230, 248;	/* CSJS {color_background_end_alt_rgb} */
	--qs-color-background-alt-direction: right top;		/* CSJS {color_background_alt_direction} */

	/* CSJS Theme Header Variables - Colors */
	--qs-color-header-background: 255 255 255;		/* CSJS {color_header_background} */
	--qs-color-header-primary: 12 12 12;			/* CSJS {color_header_primary} */
	--qs-color-header-nav-link: 136 136 136;		/* CSJS {color_header_nav_link} */
	--qs-font-header-nav-weight: 700;				/* CSJS {font_header_nav_weight} */

	/* CSJS Theme Footer Variables - Colors */
	--qs-color-footer-background: 255 255 255;		/* CSJS {color_footer_background} */
	--qs-color-footer-primary: 12 12 12;			/* CSJS {color_footer_primary} */
	--qs-color-footer-nav-link: 136 136 136;		/* CSJS {color_footer_nav_link} */
	--qs-font-footer-nav-weight: 700;				/* CSJS {font_footer_nav_weight} */

	/* -------------------------------------------------------------------------------------------- */
	/* ANCHOR Commonspace Specific Variables														*/
	/* -------------------------------------------------------------------------------------------- */

	/* CSJS Main Theme Image/Video Variables */
	--cs-theme-bg-image: url('../src/assets/images/nghtmre-bg-image.png');		/* CSJS Default is "none" {theme_background_image} */
	--cs-theme-bg-image-position: bottom; 				/* CSJS Default is center {theme_background_image_position} */
	--cs-theme-bg-video-position: center; 				/* CSJS Default is center {theme_background_video_position} */

	/* CSJS Alt Theme Image/Video Variables */
	--cs-theme-alt-bg-image: none;	/* CSJS Default is "none" {theme_alt_background_image} */
	--cs-theme-alt-bg-image-position: center;			/* CSJS Default is center {theme_alt_background_image_position} */
	--cs-theme-alt-bg-video-position: center; 			/* CSJS Default is center {theme_alt_background_video_position} */

	/* CSJS Space Home Hero Image/Video Variables */
	--cs-space-banner-hero-bg-image: url('../src/assets/images/fam-home-header.jpg');	/* CSJS  {space_hero_background_image} */
	--cs-space-banner-hero-bg-image-position: center; 	/* CSJS Default is center {space_hero_background_image_position} */
	--cs-space-banner-hero-bg-video-position: center; 	/* CSJS Default is center {space_hero_background_video_position} */
	--cs-space-banner-hero-fg-content-justify: center; 	/* CSJS Default is center */
	--cs-space-banner-hero-fg-content-align: center; 	/* CSJS Default is center */

	/* CSJS Space Section Banner URLs */
	--cs-space-banner-updates-image: url('../src/assets/images/fam-updates-header.jpg'); 		/* CSJS {space_banner_updates} */
	--cs-space-banner-benefits-image: url('../src/assets/images/fam-benefits-header.jpg'); 		/* CSJS {space_banner_benefits} */
	--cs-space-banner-members-image: url('../src/assets/images/fam-members-header.jpg'); 		/* CSJS {space_banner_members} */
	--cs-space-banner-access-image: url('../src/assets/images/fam-access-header.jpg'); 			/* CSJS {space_banner_access} */
	--cs-space-banner-channels-image: url('../src/assets/images/fam-channels-header.jpg'); 		/* CSJS {space_banner_channels} */
	--cs-space-banner-info-image: url('../src/assets/images/fam-info-header.jpg'); 				/* CSJS {space_banner_information} */


	/* ---------------------------- */
} /* Do not edit				*/
/* !LAYER :root end	 			*/
/* -------------------------------------------- */
/* !SECTION CONFIG Theming Setup Variables		*/
/* -------------------------------------------- */

/* NOTE CSS STATIC BELOW THIS LINE */

/* ------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------ */
/* CSJS CS JS VARIABLES END - ALL CODE BELOW IS STATIC (NO JS VARIABLES BELOW)									*/
/* ------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------ */

/* -------------------------------------------- */
/* SECTION CONFIG Extended Variables			*/
/* -------------------------------------------- */
/* LAYER :root begin			*/
:root { /* Do not edit			*/
	/* ---------------------------- */

	/* ---------------------------- */
	/* SECTION Screens				*/
	/* ---------------------------- */

	/* ---------------------------- */
	/* ANCHOR Screen Breakpoints	*/
	/* ---------------------------- */
	/* NOTE If you edit these values from the Preferred Value, you must also edit the @media breakpoints (search for "@media") in this file AND the values in lines 9 to 15 in df-preset.js due to CSS not supporting variables in media queries. See tailwind-setup.txt for more information. */

	--screen-sm: 640px; 		/* Preferred Value and Tailwind Default is 640px */
	--screen-md: 768px; 		/* Preferred Value and Tailwind Default is 768px */
	--screen-lg: 960px; 		/* Preferred Value is 960px. Use 1024px to set to Tailwind Default. */
	--screen-xl: 1440px; 		/* Preferred Value is 1440px. Use 1280px to set to Tailwind Default */
	--screen-2xl: 1600px; 		/* Preferred Value is 1600px. Use 1536px to set to Tailwind Default */

	/* ---------------------------- */
	/* !SECTION Screens				*/
	/* ---------------------------- */

	/* ---------------------------- */
	/* SECTION Layout				*/
	/* ---------------------------- */

	/* ---------------------------- */
	/* ANCHOR Units & Grid			*/
	/* ---------------------------- */
	/* Settings here configure the base unit and the overall grid for each screen. */

	/* LAYOUT - BASE UNIT */
	/* Specify the grid point base unit size (px/rem) for your layout. */

	--layout-unit: 4px; 		/* Must be an even number between 2px and 16px. Preferred value is 4px or 0.25rem.  */

	/* LAYOUT - GRID SYSTEM	*/
	/* Specify the column count for each screen. */

	--layout-cols-base: 6;			/* Mobile-first default count which applies below the screen-sm breakpoint. Preferred value is 6 */
	--layout-cols-sm: 6; 		/* Preferred value is 6 */
	--layout-cols-md: 6; 		/* Preferred value is 6 */
	--layout-cols-lg: 12; 		/* Preferred value is 12 */
	--layout-cols-xl: 12; 		/* Preferred value is 12 */
	--layout-cols-2xl: 12; 		/* Preferred value is 12 */

	/* ---------------------------- */
	/* ANCHOR Adaptive Behavior		*/
	/* ---------------------------- */
	/* This defines the default adaptive behavior. Keeping the default Preferred Values is strongly recommended. */

	/* MINIMUM GRID COLUMNS */
	/* This is the minimum column count for which a controlling parent element can be for each screen. */

	--layout-cols-base-min: 2;		/* Preferred value is 2 OR one-third of layout-cols rounded to an integer > 1.  */
	--layout-cols-sm-min: 1;	/* Preferred value is 1 OR one-fourth of layout-cols-sm rounded to an integer ≥ 1.  */
	--layout-cols-md-min: 1;	/* Preferred value is 1 OR one-fourth of layout-cols-md rounded to an integer ≥ 1.  */
	--layout-cols-lg-min: 1;	/* Preferred value is 1 OR one-eighth of layout-cols-lg rounded to an integer ≥ 1.  */
	--layout-cols-xl-min: 1;	/* Preferred value is 1 OR one-eighth of layout-cols-xl rounded to an integer ≥ 1.  */
	--layout-cols-2xl-min: 1;	/* Preferred value is 1 OR one-eighth of layout-cols-2xl rounded to an integer ≥ 1.  */

	/* ADAPTIVE GRID COLUMNS */
	/* The following are column counts relative to your layout-cols above. Generally there are only 2 sets based on your chosen column counts per breakpoint, however if an unusual variation is used, it can be adapted here. Round up to next integer when dividing. */

	--layout-cols-base-third: 2;
	--layout-cols-base-half: 3;
	--layout-cols-base-twothird: 4;
	--layout-cols-sm-third: 2;
	--layout-cols-sm-half: 3;
	--layout-cols-sm-twothird: 4;
	--layout-cols-md-third: 2;
	--layout-cols-md-half: 3;
	--layout-cols-md-twothird: 4;
	--layout-cols-lg-sixth: 2;
	--layout-cols-lg-quarter: 3;
	--layout-cols-lg-third: 4;
	--layout-cols-lg-fivetwelfth: 5;
	--layout-cols-lg-half: 6;
	--layout-cols-lg-seventwelfth: 7;
	--layout-cols-lg-twothird: 8;
	--layout-cols-lg-threefourth: 9;
	--layout-cols-lg-fivesixth: 10;
	--layout-cols-lg-eleventwelfth: 11;
	--layout-cols-xl-sixth: 2;
	--layout-cols-xl-quarter: 3;
	--layout-cols-xl-third: 4;
	--layout-cols-xl-fivetwelfth: 5;
	--layout-cols-xl-half: 6;
	--layout-cols-xl-seventwelfth: 7;
	--layout-cols-xl-twothird: 8;
	--layout-cols-xl-threefourth: 9;
	--layout-cols-xl-fivesixth: 10;
	--layout-cols-xl-eleventwelfth: 11;
	--layout-cols-2xl-sixth: 2;
	--layout-cols-2xl-quarter: 3;
	--layout-cols-2xl-third: 4;
	--layout-cols-2xl-fivetwelfth: 5;
	--layout-cols-2xl-half: 6;
	--layout-cols-2xl-seventwelfth: 7;
	--layout-cols-2xl-twothird: 8;
	--layout-cols-2xl-threefourth: 9;
	--layout-cols-2xl-fivesixth: 10;
	--layout-cols-2xl-eleventwelfth: 11;

	/* FLEX BLOCK MINIMUM */
	/* For container blocks with a Flex Parent only. */

	--layout-basis-min: 33.333333%; /* This is the minimum width on mobile which a controlling parent element can be. Preferred value is 33.333333%. */

	/* ---------------------------- */
	/* ANCHOR Gutters				*/
	/* ---------------------------- */
	/* Specify your standard gutter spacing for each screen. */

	--spacing-gutter-base: 24px;
	--spacing-gutter-sm: 24px;
	--spacing-gutter-md: 24px;
	--spacing-gutter-lg: 24px;
	--spacing-gutter-xl: 32px;
	--spacing-gutter-2xl: 32px;

	/* ---------------------------- */
	/* ANCHOR Rows & Columns		*/
	/* ---------------------------- */
	/* Settings here define specific measurements in px or rem. Keeping the default Preferred Values or Designframe-calculated values is strongly recommended. */

	/* COLUMNS */
	/* Specify the calculated col width for each screen. This sets some min-widths and is available using any width utility like w-col, min-w-col-sm, etc. */

	--layout-col-width-base: 32px; /* This is the base mobile-first default size which applies below the screen-sm breakpoint  */
	--layout-col-width-sm: 76px;
	--layout-col-width-md: 96px;
	--layout-col-width-lg: 52px;
	--layout-col-width-xl: 84px;
	--layout-col-width-2xl: 96px;

	/* ROWS */
	/* Specify the calculated row height for each screen. This sets some min-heights and is available using any height utility like auto-rows-sm, h-row, min-h-row-sm, etc. */

	--layout-row-height-base: 48px; /* This is the base mobile-first default size which applies below the screen-sm breakpoint  */
	--layout-row-height-sm: 72px;
	--layout-row-height-md: 48px;
	--layout-row-height-lg: 72px;
	--layout-row-height-xl: 72px;
	--layout-row-height-2xl: 72px;

	/* ---------------------------- */
	/* ANCHOR Shoulders & Offsets	*/
	/* ---------------------------- */

	/* STACKING */
	/* Specify your offsets when stacking with overlap. */
	--offset-layout-unit: 4px;

	--offset-element-min: 6px;
	--offset-element-base: 12px;
	--offset-element-sm: 16px;
	--offset-element-md: 24px;
	--offset-element-lg: 60px;
	--offset-element-xl: 96px;
	--offset-element-2xl: 128px;
	--offset-element-3xl: 168px;
	--offset-element-4xl: 200px;

	--offset-li-base: 24px;

	/* SHOULDERS */
	/* Specify your standard shoulder space for each screen */

	--spacing-shoulder-base: 24px;
	--spacing-shoulder-sm: 32px;
	--spacing-shoulder-md: 36px;
	--spacing-shoulder-lg: 36px;
	--spacing-shoulder-xl: 40px;
	--spacing-shoulder-2xl: 48px;

	/* HEADER */
	/* Add your header height offset spacing here */

	/* CSNOTE CS SPECIFIC Header Offset Measurements */
	--offset-header-base: 48px;
	--offset-header-sm: 64px;
	--offset-header-md: 64px;
	--offset-header-lg: 64px;
	--offset-header-xl: 64px;
	--offset-header-2xl: 72px;

	/* FOOTER */
	/* Add your footer height offset spacing here */

	--offset-footer-base: 56px;
	--offset-footer-sm: 56px;
	--offset-footer-md: 72px;
	--offset-footer-lg: 72px;
	--offset-footer-xl: 72px;
	--offset-footer-2xl: 72px;

	/* SPECIAL OFFSETS */
	/* Add special or custom offset spacing here. */

	--offset-section-nav: 104px;

	/* ---------------------------- */
	/* !SECTION Layout				*/
	/* ---------------------------- */

	/* ---------------------------- */
	/* SECTION Spacing & Sizing		*/
	/* ---------------------------- */

	/* ---------------------------- */
	/* ANCHOR Padding				*/
	/* ---------------------------- */
	/* Default and adaptive settings here define specific measurements in px or rem. Keeping the default Preferred Values or Designframe-calculated values is strongly recommended. */

	/* HEADER */
	/* Specify your standard section padding for each screen. Preferred value matches half gutter spacing. */

	--pad-header-base: 12px;
	--pad-header-sm: 12px;
	--pad-header-md: 12px;
	--pad-header-lg: 12px;
	--pad-header-xl: 16px;
	--pad-header-2xl: 16px;

	/* SECTIONS	*/
	/* Specify your standard section padding for each screen. Preferred value matches 2 times gutter spacing. */

	--pad-section-base: 48px;
	--pad-section-sm: 48px;
	--pad-section-md: 48px;
	--pad-section-lg: 48px;
	--pad-section-xl: 64px;
	--pad-section-2xl: 64px;

	/* ARTICLES	*/
	/* Specify your standard article (card) padding for each screen. Preferred value matches text-p3-size to 1.5x text-p3-size OR 0.5 to 0.667 times gutter spacing rounded. */

	--pad-article-base: 16px;
	--pad-article-sm: 16px;
	--pad-article-md: 16px;
	--pad-article-lg: 16px;
	--pad-article-xl: 24px;
	--pad-article-2xl: 24px;

	/* ELEMENTS	*/
	/* Specify your standard element padding for each screen. Preferred value matches text-p3-size to 1.5x text-p3-size OR 0.5 to 0.667 times gutter spacing rounded. */

	--pad-element-base: 16px;
	--pad-element-sm: 16px;
	--pad-element-md: 16px;
	--pad-element-lg: 16px;
	--pad-element-xl: 24px;
	--pad-element-2xl: 24px;

	/* BASE	*/
	/* Specify your base padding for each screen. Preferred value matches your layout-unit. */

	--pad-base-base: var(--layout-unit);
	--pad-base-sm: var(--layout-unit);
	--pad-base-md: var(--layout-unit);
	--pad-base-lg: var(--layout-unit);
	--pad-base-xl: var(--layout-unit);
	--pad-base-2xl: var(--layout-unit);

	/* CHIPS */
	/* Specify your padding for Chips (visual tags/pills). */

	--pad-x-chip: calc(2 * var(--layout-unit)); /* Height is derived from text-chip-lh */


	/* ---------------------------- */
	/* ANCHOR Gaps					*/
	/* ---------------------------- */
	/* Default & adaptive settings here define specific measurements in px or rem. Keeping the default Preferred Values or Designframe-calculated values is strongly recommended. */

	/* SECTIONS	*/
	/* Specify your standard section child (items within a section) gap spacing for each screen. Preferred value matches gutter spacing. */

	--gap-section-base: var(--spacing-gutter-base);
	--gap-section-sm: var(--spacing-gutter-sm);
	--gap-section-md: var(--spacing-gutter-md);
	--gap-section-lg: var(--spacing-gutter-lg);
	--gap-section-xl: var(--spacing-gutter-xl);
	--gap-section-2xl: var(--spacing-gutter-2xl);

	/* BLOCK */
	/* Specify your standard block child item spacing for each screen. Preferred value matches your spacing-gutter. */

	--spacing-block-base: var(--spacing-gutter-base);
	--spacing-block-sm: var(--spacing-gutter-sm);
	--spacing-block-md: var(--spacing-gutter-md);
	--spacing-block-lg: var(--spacing-gutter-lg);
	--spacing-block-xl: var(--spacing-gutter-xl);
	--spacing-block-2xl: var(--spacing-gutter-2xl);

	/* CARD */
	/* Specify your standard card child item spacing for each screen */
	/* Preferred value matches your pad-article */

	--spacing-card-base: var(--pad-article-base);
	--spacing-card-sm: var(--pad-article-sm);
	--spacing-card-md: var(--pad-article-md);
	--spacing-card-lg: var(--pad-article-lg);
	--spacing-card-xl: var(--pad-article-xl);
	--spacing-card-2xl: var(--pad-article-2xl);

	/* ARTICLES	*/
	/* Specify your standard article child gap spacing for each screen. Preferred value matches pad-article. */

	--gap-article-base: var(--pad-article-base);
	--gap-article-sm: var(--pad-article-sm);
	--gap-article-md: var(--pad-article-md);
	--gap-article-lg: var(--pad-article-lg);
	--gap-article-xl: var(--pad-article-xl);
	--gap-article-2xl: var(--pad-article-2xl);

	/* STACKS */
	/* Specify your standard stack (grouped elements) gap spacing for each screen. Preferred value matches pad-element. */

	--gap-stack-base: var(--pad-element-base);
	--gap-stack-sm: var(--pad-element-sm);
	--gap-stack-md: var(--pad-element-md);
	--gap-stack-lg: var(--pad-element-lg);
	--gap-stack-xl: var(--pad-element-xl);
	--gap-stack-2xl: var(--pad-element-2xl);

	/* FORMS */
	/* Specify your standard stack (grouped elements) gap spacing for each screen. Preferred value matches gap-element. */

	--gap-form-base: 12px;
	--gap-form-sm: 12px;
	--gap-form-md: 12px;
	--gap-form-lg: 12px;
	--gap-form-xl: 16px;
	--gap-form-2xl: 16px;

	/* HGROUP */
	/* Specify your standard HGROUP gap spacing (spacing between H and P elements in an HGROUP element) here. */

	--gap-hgroup-display: 16px; 	/* Preferred value matches text-p3-size */
	--gap-hgroup-heading: 8px; 		/* Preferred value matches 0.5 of text-p3-size rounded */
	--gap-hgroup-title: 4px; 		/* Preferred value matches 0.25 of text-p3-size rounded */

	/* ELEMENTS	*/
	/* Specify your standard element child (items within an element) gap spacing for each screen. Preferred value matches half pad-element. */

	--gap-element-base: 12px;
	--gap-element-sm: 12px;
	--gap-element-md: 12px;
	--gap-element-lg: 12px;
	--gap-element-xl: 16px;
	--gap-element-2xl: 16px;

	/* SUB-ELEMENT */
	/* Specify your standard sub spacing for each screen. This is also applied to adaptive stacks. Preferred value matches half of pad-element. */

	--spacing-sub-base: 8px;
	--spacing-sub-sm: 8px;
	--spacing-sub-md: 8px;
	--spacing-sub-lg: 8px;
	--spacing-sub-xl: 12px;
	--spacing-sub-2xl: 12px;

	/* ---------------------------- */
	/* ANCHOR Sizing				*/
	/* ---------------------------- */

	/* ELEMENT */
	/* Specify your standard element sizing. */

	--element-base-unit: 40px; 		/* Preferred value matches 2.5 times text-base-size */
	--element-min-size: 24px; 		/* Preferred value matches text-base-lh */
	--element-sub-size: 16px; 		/* Preferred value matches text-base-size */
	--element-base-size: 40px; 		/* Preferred value matches --element-base-size */

	/* C Scale  */
	--element-c-sm: 52px; 		/* Preferred value matches --element-base-size */
	--element-c-md: 84px; 		/* Preferred value matches --element-base-size */
	--element-c-lg: 200px; 		/* Preferred value matches --element-base-size */
	--element-c-xl: 316px; 		/* Preferred value matches --element-base-size */
	--element-c-2xl: 432px; 	/* Preferred value matches --element-base-size */
	--element-c-3xl: 548px; 	/* Preferred value matches --element-base-size */
	--element-c-4xl: 664px; 	/* Preferred value matches --element-base-size */

	/* R Scale */
	--element-r-sm: 48px; 		/* Preferred value matches --element-base-size */
	--element-r-md: 72px; 		/* Preferred value matches --element-base-size */
	--element-r-lg: 176px; 		/* Preferred value matches --element-base-size */
	--element-r-xl: 280px; 		/* Preferred value matches --element-base-size */
	--element-r-2xl: 384px; 	/* Preferred value matches --element-base-size */
	--element-r-3xl: 488px; 	/* Preferred value matches --element-base-size */
	--element-r-4xl: 592px; 	/* Preferred value matches --element-base-size */

	--element-max-h-base: 408px;	/* Preferred value matches screen 6row. */
	--element-max-h-sm: 360px; 		/* Preferred value matches screen 4row. */
	--element-max-h-md: 552px; 		/* Preferred value matches screen 8row. */
	--element-max-h-lg: 552px; 		/* Preferred value matches screen 6row. */
	--element-max-h-xl: 592px; 		/* Preferred value matches screen 6row. */
	--element-max-h-2xl: 592px; 	/* Preferred value matches screen 6row. */

	/* SPECIAL ELEMENTS */
	/* Specify special instance element sizing here. */

	--element-nav-min-height: 24px;  				/* Preferred value matches 1.5 of text-base-size */
	--element-nav-max-height: var(--text-nav-lh);	/* Preferred value matches --text-nav-lh */

	/* ---------------------------- */
	/* !SECTION Spacing & Sizing	*/
	/* ---------------------------- */

	/* ---------------------------- */
	/* SECTION Rounding & Borders	*/
	/* ---------------------------- */

	/* ---------------------------- */
	/* ANCHOR Radius				*/
	/* ---------------------------- */
	/* Default & adaptive settings here define specific measurements in px or rem. Keeping the default Preferred Values or Designframe-calculated values is strongly recommended. */

	/* GENERAL */
	/* Specify your general corner rounding here, if any. If none, set to 0px. */

	--rounded-min: 4px;
	--rounded-corner: 8px;

	/* ARTICLES	*/
	/* Specify your standard article (card) rounding for each screen. Preferred value matches pad-article-*. */

	--rounded-article-base: var(--pad-article-base);
	--rounded-article-sm: var(--pad-article-sm);
	--rounded-article-md: var(--pad-article-md);
	--rounded-article-lg: var(--pad-article-lg);
	--rounded-article-xl: var(--pad-article-xl);
	--rounded-article-2xl: var(--pad-article-2xl);

	/* SPECIAL ELEMENTS */
	/* Specify your various element rounding, if any. If none, set to 0px. */

	--rounded-field: 20px;
	--rounded-input: 9999px;
	--rounded-chip: var(--layout-unit);

	/* BASE UNIT */

	/* Base Rounding should be set 0px for no base rounding or 1x or 2x of --layout-unit, based on your style. */
	--rounded-base: 8px;

	/* ---------------------------- */
	/* ANCHOR Borders				*/
	/* ---------------------------- */
	/* For base border & elements, 1px is recommended. */

	/* BASE	*/
	/* Base Border isn't currently auto-applied. */

	--border-base: 1px; 				/* Utility Only. Not currently active in any classes */

	/* ELEMENTS	*/
	/* Set specific element default borders. */

	--border-button: 1px;
	--border-form: 1px;

	/* ---------------------------- */
	/* !SECTION Rounding & Borders	*/
	/* ---------------------------- */

	/* ---------------------------- */
	/* SECTION Type Sizing			*/
	/* ---------------------------- */

	/* ---------------------------- */
	/* ANCHOR Sizing				*/
	/* ---------------------------- */

	/* BASE SIZING */
	/* Specify base type size and line height for text. Base should be used as P3, and is equivalent to 1rem. Rem or px can be used for sizing, depending on your implementation and preferences. */

	--text-base-size: 16px; 	/* Must be an even number that is a multiple of Base Unit. Preferred value is 16px / default 1rem. */
	--text-base-lh: 24px; 		/* Must be 1 or more full Base Units larger than text-base-size. Preferred value is 1.5 of text-base-size. */

	/* DISPLAY SIZING */
	/* Default type size and line height for Display text. */

	--text-d1-size: 96px; 		/* Preferred value matches 6 times text-base-size */
	--text-d1-lh: 1; 			/* Preferred value is 1 */
	--text-d2-size: 72px; 		/* Preferred value matches 4.5 times text-base-size */
	--text-d2-lh: 1; 			/* Preferred value is 1 */
	--text-d3-size: 60px; 		/* Preferred value matches 3.75 times text-base-size */
	--text-d3-lh: 1; 			/* Preferred value is 1 */

	/* HEADING SIZING */
	/* Default type size and line height for Heading text. */

	--text-h1-size: 48px;		/* Preferred value matches 3 of text-base-size */
	--text-h1-lh: 1;			/* Preferred value is 1 */
	--text-h2-size: 36px;		/* Preferred value matches 2.25 of text-base-size */
	--text-h2-lh: 40px; 		/* Preferred value matches 2.5 times text-base-size */
	--text-h3-size: 24px; 		/* Preferred value matches 1.5 of text-base-size */
	--text-h3-lh: 32px; 		/* Preferred value matches 2 of text-base-size */
	--text-h4-size: 20px; 		/* Preferred value matches 1.25 of text-base-size */
	--text-h4-lh: 28px; 		/* Preferred value matches 1.75 of text-base-size */
	--text-h5-size: 18px; 		/* Preferred value matches 1.125 of text-base-size */
	--text-h5-lh: 28px; 		/* Preferred value matches 1.75 of text-base-size */
	--text-h6-size: 16px; 		/* Preferred value matches text-base-size */
	--text-h6-lh: 24px; 		/* Preferred value matches 1.5 of text-base-size */
	--text-heb-size: 14px; 		/* Preferred value matches 0.875 of text-base-size */
	--text-heb-lh: 20px; 		/* Preferred value matches 1.25 of text-base-size */

	/* BODY SIZING */
	/* Default type size and line height for Body text. */
	--text-p1-size: 20px; 		/* Preferred value matches 1.25 of text-base-size */
	--text-p1-lh: 28px; 		/* Preferred value matches 1.75 of text-base-size */
	--text-p2-size: 18px; 		/* Preferred value matches 1.125 of text-base-size */
	--text-p2-lh: 28px; 		/* Preferred value matches 1.75 of text-base-size */
	--text-p3-size: 16px; 		/* Preferred value matches text-base-size */
	--text-p3-lh: 24px;  		/* Preferred value matches 1.5 of text-base-size */
	--text-p4-size: 14px; 		/* Preferred value matches 0.875 of text-base-size */
	--text-p4-lh: 20px; 		/* Preferred value matches 1.25 of text-base-size */
	--text-p5-size: 12px; 		/* Preferred value matches 0.75 of text-base-size */
	--text-p5-lh: 16px; 		/* Preferred value matches text-base-size */

	/* ICON SIZING */
	/* Default type size and line height for text-based Icons. */
	--text-i1-size: 72px; 		/* Preferred value matches text-d2-size */
	--text-i1-lh: 1; 			/* Preferred value is 1 */
	--text-i2-size: 48px; 		/* Preferred value matches text-d2-size */
	--text-i2-lh: 1; 			/* Preferred value is 1 */
	--text-i3-size: 36px; 		/* Preferred value matches 2.25 of text-base-size */
	--text-i3-lh: 1; 			/* Preferred value is 1 */
	--text-i4-size: 24px; 		/* Preferred value matches 1.5 of text-base-size */
	--text-i4-lh: 1; 			/* Preferred value is 1 */
	--text-i5-size: 16px; 		/* Preferred value matches text-base-size */
	--text-i5-lh: 1; 			/* Preferred value is 1 */
	--text-i6-size: 12px; 		/* Preferred value matches 0.75 of text-base-size */
	--text-i6-lh: 1; 			/* Preferred value is 1 */

	/* ELEMENT SIZING */
	/* Specify default type size and line height for general elements text applications. */

	--text-nav-size: 20px; 					/* Preferred value matches text-p1-size */
	--text-nav-lh: 40px;					/* Preferred value matches 2.5 times text-base-size */
	--text-button-size: 18px; 				/* Preferred value matches text-p2-size */
	--text-button-lh: var(--text-nav-lh); 	/* Preferred value matches text-nav-lh */
	--text-chip-size: 12px; 				/* Preferred value matches text-p5-size */
	--text-chip-lh: 24px; 					/* Preferred value matches 2 times text-chip-size */

	/* ADAPTIVE SIZING */
	/* Specify default type size adaptive behavior for smaller (base, sm, md) screens. */

	--text-d1a-size: var(--text-d2-size); 	/* Preferred value matches text-d2-size */
	--text-d1a-lh: var(--text-d2-lh); 		/* Preferred value matches text-d2-lh */
	--text-d2a-size: var(--text-d3-size); 	/* Preferred value matches text-d3-size */
	--text-d2a-lh: var(--text-d3-lh); 		/* Preferred value matches text-d3-lh */
	--text-d3a-size: var(--text-h1-size); 	/* Preferred value matches text-h1-size */
	--text-d3a-lh: var(--text-h1-lh); 		/* Preferred value matches text-h1-lh */
	--text-h1a-size: var(--text-h2-lh); 	/* Preferred value matches text-h2-lh */
	--text-h1a-lh: 1; 						/* Preferred value is 1 */
	--text-i1a-size: var(--text-d3-size); 	/* Preferred value matches text-d3-size */
	--text-i1a-lh: var(--text-d3-lh); 		/* Preferred value matches text-d3-lh */
	--text-nava-size: var(--text-p2-size); 	/* Preferred value matches text-p2-size */
	--text-nava-lh: var(--text-nav-lh); 	/* Preferred value matches text-nav-lh */

	/* ---------------------------- */
	/* !SECTION Typography			*/
	/* ---------------------------- */


	/* ---------------------------- */
	/* SECTION Effects				*/
	/* ---------------------------- */

	/* ---------------------------- */
	/* ANCHOR Shadows				*/
	/* ---------------------------- */
	/* Utility extensions. Shadows should be entered as RGB values with alpha. */

	--shadow-light: 0px 0px 4px 0px rgba(0, 0, 0, 0.125);
	--shadow-base: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
	--shadow-heavy: 0px 4px 8px 2px rgba(0, 0, 0, 0.25);
	--drop-shadow-light: 0px 0px 4px rgba(0, 0, 0, 0.125);
	--drop-shadow-base: 0px 2px 4px rgba(0, 0, 0, 0.25);
	--drop-shadow-heavy: 0px 4px 8px rgba(0, 0, 0, 0.25);

	/* ---------------------------- */
	/* ANCHOR Blur					*/
	/* ---------------------------- */
	/* Utility extensions. */

	--blur-light: 24px;
	--blur-base: 48px;
	--blur-heavy: 64px;
	--blur-max: 128px;

	/* ---------------------------- */
	/* ANCHOR Shader Alpha Values	*/
	/* ---------------------------- */
	/* Shaders can be applied as an extension for any Designframe Color such as key, primary, etc. Use by appending -shader, -shader-light, and -shader-heavy to those colors, for example bg-key-shader. */

	--shader-light: 0.15;
	--shader-base: 0.60;
	--shader-heavy: 0.85;

	/* ---------------------------- */
	/* !SECTION Effects				*/
	/* ---------------------------- */

	/* ------------------------------------	*/
	/* SECTION Interactions & Animation		*/
	/* ------------------------------------ */

	/* ---------------------------- */
	/* ANCHOR Transitions			*/
	/* ---------------------------- */

	/* GLOBAL TRANSITIONS */
	/* This sets the configurable transition-set utility which is applied to all default transitions. */

	--transition-property-base: all;
	--transition-timing-base: cubic-bezier(0.4, 0, 0.2, 1);
	--transition-duration-base: 300ms; /* Set this as your base transition timing. 250ms to 300ms is recommended */

	/* ---------------------------- */
	/* ANCHOR Animations			*/
	/* ---------------------------- */

	/* GLOWS */
	/* Under development. */

	/* ------------------------------------	*/
	/* !SECTION Interactions & Animation	*/
	/* ------------------------------------ */

	/* -------------------------------------------- */
	/* SECTION COLORS & THEMING VALUES				*/
	/* -------------------------------------------------------------------------------------------- */
	/* Theming Colors																				*/
	/* -------------------------------------------------------------------------------------------- */
	/* Theme Colors are used as defaults and for all Designframe Themes. Colors should be entered	*/
	/* as RGB values with no alpha for Tailwind Opacity Modifiers to work. Designframe Color Themes	*/
	/* are independent of Tailwind's Dark Mode support. The use of the word "theme" here applies to */
	/* color palettes, not the Tailwind theme configuration. 										*/
	/* -------------------------------------------------------------------------------------------- */
	/* Theme Sets																					*/
	/* -------------------------------------------------------------------------------------------- */
	/* THEME is your main color set and is used to determine the logical INVERT variations.			*/
	/* THEME TRANSPARENT is same as THEME but with transparent background defaults for layering.	*/
	/* THEME INVERT is should be defined as the invert/dark mode equivalent of THEME.				*/
	/* THEME INVERT TRANSPARENT is same as THEME INVERT but with transparent background defaults.	*/
	/* -------------------------------------------------------------------------------------------- */
	/* THEME KEY is THEME-based but with Key Color(s) as backgrounds with colors adjusted.			*/
	/* THEME KEY GRADIENT is THEME KEY ALT-based but with Key Gradient as backgrounds.				*/
	/* -------------------------------------------------------------------------------------------- */
	/* THEME ALT is an optional alternative color palette. 											*/
	/* THEME ALT TRANSPARENT is same as THEME ALT but with transparent background defaults.			*/
	/* THEME ALT INVERT is should be defined as the invert/dark mode equivalent of THEME ALT.		*/
	/* THEME ALT INVERT TRANSPARENT is same as THEME ALT INVERT but with transparent backgrounds.	*/
	/* -------------------------------------------------------------------------------------------- */
	/* THEME KEY ALT is THEME ALT-based but with Key Alt as backgrounds with colors adjusted.		*/
	/* THEME KEY ALT GRADIENT is THEME KEY ALT-based but with Key Alt Gradient as backgrounds.		*/
	/* -------------------------------------------------------------------------------------------- */

	/* ---------------------------- */
	/* SECTION THEME				*/
	/* ---------------------------- */

	/* ---------------------------- */
	/* ANCHOR Key Colors			*/
	/* ---------------------------- */
	/* This sets the THEME standout color(s). Key is intended to be your brand's key/main color which is not black or white, and can be set as a color pair if you'd like to use a gradient as your key color. */

	--color-key: var(--qs-color-key); 					/* If key color is intended to be a gradient, then enter starting color here. */
	--color-key-rgb: var(--qs-color-key-rgb); 			/* Same as color-key but in comma-separated rgba format, excluding a. */
	--color-key-end: var(--qs-color-key-end); 			/* If key color is intended to be a gradient, then enter end color here. If not, then enter same as color-key. */
	--color-key-end-rgb: var(--qs-color-key-end-rgb); 	/* Same as color-key-end but in comma-separated rgba format, excluding a. */

	/* ---------------------------- */
	/* ANCHOR Standard Colors		*/
	/* ---------------------------- */
	/* This sets the THEME standard colors.	*/

	--color-primary: var(--qs-color-primary);							/* Primary is intended to be black or the darkest monotone color used for standard text and similar applications. */
	--color-secondary: var(--qs-color-secondary);						/* Secondary is meant to be a mid-to-dark gray or similar monotone color for help text and other secondary importance applications. */
	--color-tertiary: var(--qs-color-tertiary);						/* Tertiary is intended as a mid-to-light (but still legible) gray or similar monotone color for least important text and color applications. */
	--color-background: var(--qs-color-background);					/* Background is meant to be a while or the lightest monotone color used for backgrounds to contrast with the Primary and Key Colors. */
	--color-background-start: var(--qs-color-background-start);			/* OPTIONAL - Enter Gradient Background Start Color. Enter same value as color-key if there is no gradient option for background. */
	--color-background-start-rgb: var(--qs-color-background-start-rgb);	/* OPTIONAL - Same as color-background-start but in comma-separated rgba format, excluding a. */
	--color-background-end: var(--qs-color-background-end); 	/* OPTIONAL - Enter Gradient Background End Color. Enter same value as color-background-start if there is no gradient option for background. */
	--color-background-end-rgb: var(--qs-color-background-end-rgb);	/* OPTIONAL - Same as color-background-end but in comma-separated rgba format, excluding a. */
	--color-background-direction: var(--qs-color-background-direction);	/* NEEDED IF using color-background-start and -end to define direction of gradient. Use CSS format such as top, right top, right bottom, etc. */
	--color-disabled: var(--qs-color-disabled);						/* Disabled is intended as a mid-to-light (but still legible) gray or similar monotone color for disabled element, text, and color applications. */

	/* ---------------------------- */
	/* ANCHOR Invert Colors			*/
	/* ---------------------------- */
	/* This sets the THEME INVERT and THEME KEY color palette variations, in combination with the colors above. Additionally, color-invert is used by DEFAULT and THEME elements. */

	--color-invert: var(--qs-color-invert); 					/* Usually matches color-background and is the opposite/invert of color-primary. */
	--color-key-invert: 255 255 255; 					/* Usually matches color-background, unless the actual opposite/invert of color-key is desired. */
	--color-key-invert-end: 255 255 255; 				/* Usually matches color-background, unless the actual opposite/invert of color-key-end is desired. */
	--color-key-gradient-invert: 255 255 255; 		/* This color is used to create a pseudo-transparancy for hover states on gradient elements such as buttons. Preferred value is the same as --color-background */
	--color-key-gradient-invert-opacity: 0.9; 		/* This is the opacity for the color used to create a pseudo-transparancy for hover states on general gradient elements such as buttons. Preferred value is 1 if your body/sections have a solid color background, or 0.9 for gradient backgrounds.  */
	--color-key-gradient-active-opacity: 0.75; 		/* This is the opacity for the color used to create a pseudo-transparancy for active/hover states on gradient elements such as buttons. Preferred value is 1 if your body/sections have a solid color background, or 0.75 for gradient backgrounds.  */

	/* TODO Area for default hover theme behavior */

	/* ---------------------------- */
	/* !SECTION THEME (Default)		*/
	/* ---------------------------- */

	/* ---------------------------- */
	/* SECTION THEME ALT			*/
	/* ---------------------------- */

	/* ---------------------------- */
	/* ANCHOR Key Alt Colors		*/
	/* ---------------------------- */
	/* This sets the THEME ALT standout color(s). THEME ALT is intended to be an optional alternative theme for special/specific usage. */

	--color-key-alt: var(--qs-color-key-alt); 					/* OPTIONAL - Enter Alternate Color or Alternate Start Color. Enter same value as color-key if there is no alternate. */
	--color-key-alt-rgb: var(--qs-color-key-alt-rgb); 			/* Same as color-key-alt but in comma-separated rgba format, excluding a. */
	--color-key-end-alt: var(--qs-color-key-end-alt); 			/* OPTIONAL - Enter Alternate End Color if using Alternate Start Color. Enter same value as color-key-end if there is no alternate. */
	--color-key-end-alt-rgb: var(--qs-color-key-end-alt-rgb); 	/* Same as color-key-end-alt but in comma-separated rgba format, excluding a. */

	/* ---------------------------- */
	/* ANCHOR Standard Alt Colors	*/
	/* ---------------------------- */
	/* This sets the THEME ALT standard color(s). Enter the same values as THEME if you do not want to use THEME ALT. */

	--color-primary-alt: var(--qs-color-primary-alt);							/* OPTIONAL - Enter same value as primary if there is no alternate. */
	--color-secondary-alt: var(--qs-color-secondary-alt);						/* OPTIONAL - Enter same value as secondary if there is no alternate. */
	--color-tertiary-alt: var(--qs-color-tertiary-alt);						/* OPTIONAL - Enter same value as tertiary if there is no alternate. */
	--color-background-alt: var(--qs-color-background-alt);					/* OPTIONAL - Enter same value as background-default if there is no alternate. */
	--color-background-start-alt: var(--qs-color-background-start-alt);			/* OPTIONAL - Enter Gradient Background Start Color. Enter same value as color-key if there is no gradient option for background. */
	--color-background-start-alt-rgb: var(--qs-color-background-start-alt-rgb);	/* OPTIONAL - Same as color-background-start but in comma-separated rgba format, excluding a. */
	--color-background-end-alt: var(--qs-color-background-end-alt); 	/* OPTIONAL - Enter Gradient Background End Color. Enter same value as color-background-start if there is no gradient option for background. */
	--color-background-end-alt-rgb: var(--qs-color-background-end-alt-rgb);	/* OPTIONAL - Same as color-background-end but in comma-separated rgba format, excluding a. */
	--color-background-alt-direction: var(--qs-color-background-alt-direction);	/* NEEDED IF using color-background-alt-start and -alt-end to define direction of gradient. */
	--color-disabled: var(--qs-color-disabled-alt);						/* Disabled is intended as a mid-to-light (but still legible) gray or similar monotone color for disabled element, text, and color applications. */

	/* TODO Add in BG Gradient start/end */

	/* ---------------------------- */
	/* ANCHOR Invert Alt Colors		*/
	/* ---------------------------- */
	/* This sets the THEME ALT INVERT color palette variation, in combination with the ALT colors above. Additionally, color-invert-alt is used by THEME ALT elements. Enter the same values as THEME if you do not want to use THEME ALT. */

	--color-invert-alt: var(--qs-color-invert-alt); 	/* OPTIONAL - Enter same value as primary if there is no alternate. */
	--color-key-alt-invert: 250 0 2; 					/* OPTIONAL - Enter Key Invert Color if using Key Theme. Enter same value as color-key if there is not using Key Theme. */
	--color-key-alt-invert-end: 250 160 2; 				/* OPTIONAL - Enter Key Invert End Color if using Key Invert Start Color. Enter same value as color-key-end if there is not using Key Theme. */
	--color-key-alt-gradient-invert: 255 255 255; 	/* OPTIONAL - This color is used to create a pseudo-transparancy for hover states on gradient elements such as buttons. Preferred value is the same as --color-background-alt */
	--color-key-alt-gradient-invert-opacity: 0.9; 	/* OPTIONAL - This is the opacity for the color used to create a pseudo-transparancy for hover states on gradient elements such as buttons. Preferred value is 1 if your body/sections have a solid color background, or 0.9 for gradient backgrounds.  */
	--color-key-alt-gradient-active-opacity: 0.75; 		/* This is the opacity for the color used to create a pseudo-transparancy for active/hover states on gradient elements such as buttons. Preferred value is 1 if your body/sections have a solid color background, or 0.8 for gradient backgrounds.  */

	/* ---------------------------- */
	/* !SECTION THEME ALT			*/
	/* ---------------------------- */

	/* ---------------------------- */
	/* SECTION ALL THEMES COLORS	*/
	/* ---------------------------- */

	/* ---------------------------- */
	/* ANCHOR Alert Colors			*/
	/* ---------------------------- */
	/* These are default alert colors used across all themes. */

	--color-alert-notify: 96 165 250;					/* Blue color preferred 0 181 255 */
	--color-alert-notify-heading: 29 79 216;
	--color-alert-notify-text: 37 99 235;
	--color-alert-notify-background: 219 234 254;


	--color-alert-warning: 250 204 21;					/* Yellow color preferred 255 242 0 */
	--color-alert-warning-heading: 161 98 7;
	--color-alert-warning-text: 202 138 4;
	--color-alert-warning-background: 254 249 195;

	--color-alert-error: 248 113 113;					/* Red color preferred 255 1 48 */
	--color-alert-error-heading: 185 28 28;
	--color-alert-error-text: 220 38 38;
	--color-alert-error-background: 254 226	226;

	--color-alert-success: 74 222 128;					/* Green color preferred 1 255 48 */
	--color-alert-success-heading: 21 128 61;
	--color-alert-success-text: 22 163 74;
	--color-alert-success-background: 220 252 231;

	/* ---------------------------- */
	/* ANCHOR HEADER COLORS			*/
	/* ---------------------------- */
	/* These set optional Header Colors and Weight which can be applied as an override to any/all themes. */

	--color-header-background: var(--qs-color-header-background);
	--color-header-primary:  var(--qs-color-header-primary);
	--color-header-nav-link:  var(--qs-color-header-nav-link);
	--font-header-nav-weight: var(--qs-font-header-nav-weight);

	--color-header-background-alt: var(--qs-color-header-background);
	--color-header-primary-alt:  var(--qs-color-header-primary);
	--color-header-nav-link-alt:  var(--qs-color-header-nav-link);
	--font-header-nav-weight-alt: var(--qs-font-header-nav-weight);

	/* ---------------------------- */
	/* ANCHOR FOOTER COLORS			*/
	/* ---------------------------- */
	/* These set optional Footer Colors and Weight which can be applied as an override to any/all themes. */

	--color-footer-background: var(--qs-color-footer-background);
	--color-footer-primary:  var(--qs-color-footer-primary);
	--color-footer-nav-link:  var(--qs-color-footer-nav-link);
	--font-footer-nav-weight: var(--qs-font-footer-nav-weight);

	--color-footer-background-alt: var(--qs-color-footer-background);
	--color-footer-primary-alt:  var(--qs-color-footer-primary);
	--color-footer-nav-link-alt:  var(--qs-color-footer-nav-link);
	--font-footer-nav-weight-alt: var(--qs-font-footer-nav-weight);

	/* ---------------------------- */
	/* !SECTION ALL THEMES COLORS	*/
	/* ---------------------------- */

	/* -------------------------------------------- */
	/* !SECTION COLORS & THEMING VALUES				*/
	/* -------------------------------------------- */

	/* ---------------------------- */
} /* Do not edit				*/
/* !LAYER :root end	 			*/
/* -------------------------------------------- */
/* !SECTION Extended Variables\					*/
/* -------------------------------------------- */

/* TODO Re-factor rounding utility, integrate animations, use variable to toggle default shadows. */

/* -------------------------------------------------------------------------------------------- */
/* SECTION HTML Element Applications															*/
/* -------------------------------------------------------------------------------------------- */
/* NOTE These default applications provide secondary fallbacks for plain HTML elements and are	*/
/* overridden in the "CONFIG Theming" section of this file. This section should not be edited.	*/
/* -------------------------------------------------------------------------------------------- */
/* LAYER base begin				*/
@layer base { /* Do not edit 	*/
	/* ---------------------------- */

	/* ---------------------------- */
	/* ANCHOR  Backgrounds			*/
	/* ---------------------------- */
	/* Sets the default colors for base sections. */

	body, header, main, footer, .card, .card-base {
		@apply bg-default;
	}

	/* ---------------------------- */
	/* ANCHOR  Typography			*/
	/* ---------------------------- */
	/* Applies the default styles for basic type elements. */

	body {
		@apply font-body text-p3 text-primary antialiased;
	}
	.card, .card-base {
		@apply text-primary;
	}
	h1, h2, h3, h4, h5, h6 {
		@apply font-heading text-key;
	}
	h1 {
		@apply text-h1;
	}
	h2 {
		@apply text-h2;
	}
	h3 {
		@apply text-h3;
	}
	h4 {
		@apply text-h4;
	}
	h5 {
		@apply text-h5;
	}
	h6 {
		@apply text-h6;
	}
	nav a {
		@apply text-nav;
	}

	a i {
		@apply text-key;
	}
	button i {
		@apply text-ie;
	}

	input label {
		@apply text-p2 text-key;
	}
	::placeholder {
		@apply text-p3 text-secondary;
	}
	ul {
		@apply list-disc list-outside space-y-base-unit pl-offset-li-base;
	}
	ol {
		@apply list-decimal list-outside space-y-base-unit pl-offset-li-base;
	}
	p.title {
		@apply font-bold config-text-key;
	}
	i {
		line-height: inherit;
	}
	.text-help {
		@apply text-p4 italic text-secondary;
	}

	/* ---------------------------- */
	/* ANCHOR  Nav Links			*/
	/* ---------------------------- */
	/* Sets the default colors for header nav elements. */

	nav a, nav a:link, nav a:visited, nav a:focus {
		@apply text-secondary;
	}
	nav a:hover {
		@apply text-key;
	}
	nav a:active {
		@apply text-secondary;
	}

	/* ---------------------------- */
	/* ANCHOR  Mobile Menu			*/
	/* ---------------------------- */
	/* Sets the default colors and structure for mobile menu elements. */

	#header-nav-menu {
		@apply bg-default;
	}
& #header-nav-menu {
	nav a {
		@apply px-0 w-full text-center bg-key;
	}
	nav a.nav-gradient {
		@apply gradient-key;
	}
	nav a p {
		@apply px-element w-full text-nav text-secondary bg-default;
	}
	nav a:hover p {
		@apply text-invert bg-opacity-0;
	}
}

/* ---------------------------- */
/* ANCHOR  Links & Interactions	*/
/* ---------------------------- */
/* Sets the default colors and behavior for basic interactions. */

button {
	@apply text-key;
}
a {
	@apply text-key cursor-pointer;
}
a:hover {
	@apply text-key-end;
}
:focus-visible {
	outline-color: rgb(var(--color-key));
}

/* ---------------------------- */
/* ANCHOR  Other Elements		*/
/* ---------------------------- */
/* Sets the default colors and behavior for other HTML elements. */

hr {
	@apply gradient-key my-article-base w-full col-span-full border-0 h-px; /* CSNOTE DF specific gradient vs key-color */
}

/* -------------------------------- */
} /* Do not edit 					*/
/* !LAYER base end 					*/
/* -------------------------------------------------------------------------------------------- */
/* !SECTION HTML Element Applications				 											*/
/* -------------------------------------------------------------------------------------------- */

/* -------------------------------------------------------------------------------------------- */
/* SECTION DF Core Layout Utilities																*/
/* -------------------------------------------------------------------------------------------- */
/* LAYER components begin 			*/
@layer components { /* Do not edit	*/
	/* -------------------------------- */

	/* ANCHOR Layout Utilities */
	/* HEADER & FOOTER NAVIGATION - Edit the CSS below to set default positioning. */
	.header-flex {
		@apply flex items-center justify-between;
	}
	.header-flex > nav {
		@apply flex items-center justify-between w-full;
	}
	.header-grid {
		@apply grid;
	}
	.header-grid > nav {
		@apply col-span-full;
	}
	.footer-base {
		@apply px-0 py-0;
	}
	.footer-flex {
		@apply flex items-center justify-between;
	}
	.footer-grid {
		@apply grid;
	}
	.section-flex, .flex-base {
		@apply flex flex-wrap;
	}
	.section-grid, .grid-base {
		@apply grid;
	}
	.centerframe-flex {
		@apply flex flex-wrap mx-auto max-w-breakpoint;
	}
	.centerframe-grid {
		@apply grid mx-auto max-w-breakpoint;
	}

	.p-safeview {
		padding-top: env(safe-area-inset-top);
		padding-bottom: env(safe-area-inset-bottom);
		padding-left: env(safe-area-inset-left);
		padding-right: env(safe-area-inset-right);
	}
	.px-safeview {
		padding-left: env(safe-area-inset-left);
		padding-right: env(safe-area-inset-right);
	}
	.py-safeview {
		padding-top: env(safe-area-inset-top);
		padding-bottom: env(safe-area-inset-bottom);
	}
	.pt-safeview {
		padding-top: env(safe-area-inset-top);
	}
	.pb-safeview {
		padding-bottom: env(safe-area-inset-bottom);
	}
	.pl-safeview {
		padding-left: env(safe-area-inset-left);
	}
	.pr-safeview {
		padding-right: env(safe-area-inset-right);
	}
	.m-safeview {
		margin-top: env(safe-area-inset-top);
		margin-bottom: env(safe-area-inset-bottom);
		margin-left: env(safe-area-inset-left);
		margin-right: env(safe-area-inset-right);
	}
	.mx-safeview {
		margin-left: env(safe-area-inset-left);
		margin-right: env(safe-area-inset-right);
	}
	.my-safeview {
		margin-top: env(safe-area-inset-top);
		margin-bottom: env(safe-area-inset-bottom);
	}
	.mt-safeview {
		margin-top: env(safe-area-inset-top);
	}
	.mb-safeview {
		margin-bottom: env(safe-area-inset-bottom);
	}
	.ml-safeview {
		margin-left: env(safe-area-inset-left);
	}
	.mr-safeview {
		margin-right: env(safe-area-inset-right);
	}
	.stack-col-base {
		@apply flex flex-col;
	}
	.stack-col-min {
		@apply flex flex-col gap-stack-min;
	}
	.stack-col-sm {
		@apply flex flex-col gap-stack-sm;
	}
	.stack-row-base {
		@apply flex flex-row flex-nowrap;
	}
	.stack-row-base-wrap {
		@apply flex flex-row flex-wrap;
	}
	.stack-row-min {
		@apply flex flex-row gap-x-stack-min;
	}
	.datarow, .formrow {
		@apply flex flex-row w-full justify-between items-center
	}
	.datarow > *, .formrow > * {
		@apply w-full;
	}
	.datarow.title, .formrow.title {
		@apply items-baseline;
	}
	.data-item {
		@apply w-full flex items-center;
	}
	.data-item.row {
		@apply flex-row;
	}
	.data-item.col {
		@apply flex-col;
	}


	.spacer-element-min {
		@apply w-element-min h-element-min;
	}
	.spacer-element-min-row {
		@apply w-full h-element-min;
	}
	.spacer-element {
		@apply w-element-base h-element-base;
	}
	.spacer-element-row {
		@apply w-full h-element-base;
	}
	.spacer-element-col {
		@apply h-full w-element-base;
	}
	.stack-title-row {
		@apply flex flex-row gap-element-base;
	}
	.stack-title-row > p:first-child {
		@apply font-body config-text-key;
	}
	.stack-title-row > p:last-child {
		@apply italic;
	}
	.stack-row.split, .stack-row-wrap.split, .stack-row-base.split {
		@apply justify-between;
	}
	.stack-button-info {
		@apply flex flex-col gap-y-element-base;
	}
	.stack-button-info .stack-title-row {
		@apply justify-center;
	}
	.stack-row.split > div:first-child, .stack-row-wrap.split > div:first-child {
		@apply text-left;
	}
	.stack-row.split > div:last-child, .stack-row-wrap.split > div:last-child {
		@apply text-right;
	}
	.stack-avatar-min {
		@apply isolate flex -space-x-offset-element-min;
	}
	.stack-avatar {
		@apply isolate flex -space-x-offset-element-base;
	}
	.stack-avatar-sm {
		@apply isolate flex -space-x-offset-element-sm;
	}
	.stack-avatar.md,
	.stack-avatar-md {
		@apply isolate flex -space-x-offset-element-md;
	}
	.stack-avatar-lg {
		@apply isolate flex -space-x-offset-element-lg;
	}
	.stack-avatar-xl {
		@apply isolate flex -space-x-offset-element-xl;
	}
	.stack-buttons {
		@apply flex gap-y-element-base w-full;
	}
	.stack-buttons .button {
		@apply w-full;
	}
	.stack-buttons.col {
		@apply flex-col;
	}
	.stack-buttons.row {
		@apply flex-row;
	}
	.stack-buttons.wrap {
		@apply flex-wrap;
	}
	.block-element {
		@apply text-center leading-element-base h-element-base w-element-base;
	}

	.z-to-t :nth-child(1) { @apply z-10; }
	.z-to-t :nth-child(2) { @apply z-20; }
	.z-to-t :nth-child(3) { @apply z-30; }
	.z-to-t :nth-child(4) { @apply z-40; }
	.z-to-t :nth-child(5) { @apply z-50; }
	.z-to-t :nth-child(6) { @apply z-60; }
	.z-to-t :nth-child(7) { @apply z-70; }
	.z-to-t :nth-child(8) { @apply z-80; }
	.z-to-t :nth-child(9) { @apply z-90; }
	.z-to-t :nth-child(10) { @apply z-100; }
	.z-to-t :nth-child(11) { @apply z-110; }
	.z-to-t :nth-child(12) { @apply z-120; }
	.z-to-t :nth-child(13) { @apply z-130; }
	.z-to-t :nth-child(14) { @apply z-140; }
	.z-to-t :nth-child(15) { @apply z-150; }
	.z-to-t :nth-child(16) { @apply z-160; }
	.z-to-t :nth-child(17) { @apply z-170; }
	.z-to-t :nth-child(18) { @apply z-180; }
	.z-to-t :nth-child(19) { @apply z-190; }
	.z-to-t :nth-child(20) { @apply z-200; }
	.z-to-t :nth-child(21) { @apply z-210; }
	.z-to-t :nth-child(22) { @apply z-220; }
	.z-to-t :nth-child(23) { @apply z-230; }
	.z-to-t :nth-child(24) { @apply z-240; }

	.z-to-b :nth-child(1) { @apply z-240; }
	.z-to-b :nth-child(2) { @apply z-230; }
	.z-to-b :nth-child(3) { @apply z-220; }
	.z-to-b :nth-child(4) { @apply z-210; }
	.z-to-b :nth-child(5) { @apply z-200; }
	.z-to-b :nth-child(6) { @apply z-190; }
	.z-to-b :nth-child(7) { @apply z-180; }
	.z-to-b :nth-child(8) { @apply z-170; }
	.z-to-b :nth-child(9) { @apply z-160; }
	.z-to-b :nth-child(10) { @apply z-150; }
	.z-to-b :nth-child(11) { @apply z-140; }
	.z-to-b :nth-child(12) { @apply z-130; }
	.z-to-b :nth-child(13) { @apply z-120; }
	.z-to-b :nth-child(14) { @apply z-110; }
	.z-to-b :nth-child(15) { @apply z-100; }
	.z-to-b :nth-child(16) { @apply z-90; }
	.z-to-b :nth-child(17) { @apply z-80; }
	.z-to-b :nth-child(18) { @apply z-70; }
	.z-to-b :nth-child(19) { @apply z-60; }
	.z-to-b :nth-child(20) { @apply z-50; }
	.z-to-b :nth-child(21) { @apply z-40; }
	.z-to-b :nth-child(22) { @apply z-30; }
	.z-to-b :nth-child(23) { @apply z-20; }
	.z-to-b :nth-child(24) { @apply z-10; }

	/* -------------------------------- */
} /* Do not edit					*/
/* !LAYER components end			*/
/* -------------------------------------------------------------------------------------------- */
/* !SECTION DF Core Layout Utilities					 										*/
/* -------------------------------------------------------------------------------------------- */

/* -------------------------------------------------------------------------------------------- */
/* SECTION DF Core Visual Utilities																*/
/* -------------------------------------------------------------------------------------------- */
/* LAYER components begin 			*/
@layer components { /* Do not edit	*/
	/* ---------------------------------*/

	/* ---------------------------- */
	/* ANCHOR  Color Utilities		*/
	/* ---------------------------- */

	.invert-color {
		@apply text-primary;
	}

	.transparent {
		@apply !ring-transparent !border-transparent !bg-transparent !shadow-none;
	}
	.disabled {
		@apply grayscale-0 !cursor-default !ring-0;
	}
	a.disabled {
		@apply !border-disabled !text-disabled !ring-disabled opacity-100;
	}
	a.disabled:hover {
		@apply !bg-transparent;
	}
	.disabled p, .disabled h2, .disabled h3, .disabled h4, .disabled h5, .disabled h6, .disabled i {
		@apply  !text-disabled !bg-none;
	}
	.disabled .button, .disabled .button::before, .disabled .button:hover::before {
		@apply !bg-disabled !bg-none !cursor-default;
	}
	.button.disabled {
		@apply !bg-disabled !text-invert;
	}
	.button.disabled p {
		@apply !text-invert;
	}

	.button.secondary.disabled .button.secondary.disabled:hover {
		@apply !bg-disabled !text-invert;
	}
	.button.secondary.disabled::before {
		@apply !bg-disabled !bg-none;
	}
	.button.secondary.disabled:hover span {
		@apply !bg-invert;
	}

	.button.secondary.disabled p {
		@apply !text-secondary;
	}
	.ghost {
		@apply grayscale-0 opacity-50 bg-none bg-disabled blur-xs;
	}

	/* -------------------------------- */
	/* ANCHOR  Font Awesome Specific	*/
	/* -------------------------------- */

	i.fa-circle-xmark {
		@apply rounded-full bg-background;
	}

	/* ---------------------------- */
	/* ANCHOR  Gradient Utilities	*/
	/* ---------------------------- */
	/* Edit the XX in bg-gradient-to-XX to change the linear-gradient direction using top, bottom, left, and right combinations. For example, bg-gradient-to-r sets the direction to the right, and bg-gradient-to-tr sets the direction to the top right. You can always use the Tailwind bg-gradient-* utility normally with your gradient-key-start and gradient-key-end and alt colors. */

	.gradient-key { /* Do not edit */
		@apply /* EDIT FROM HERE */bg-gradient-to-r/* TO HERE */ from-key to-key-end;
	}/* Do not edit */
	.gradient-key-opacity-invert { /* Do not edit */
		background: linear-gradient(to right, rgba(var(--color-key-rgb), calc(1 - var(--color-key-gradient-invert-opacity))), rgba(var(--color-key-end-rgb), calc(1 - var(--color-key-gradient-invert-opacity)))) ; /* Gradient background Opacity Variant */
	}/* Do not edit */
	.gradient-key-transparent { /* Do not edit */
		background: linear-gradient(to right, rgba(var(--color-key-rgb), 0), rgba(var(--color-key-end-rgb), 0)) ; /* Gradient background Opacity Variant */
	}/* Do not edit */
	.gradient-key-invert { /* Do not edit */
		@apply /* EDIT FROM HERE */bg-gradient-to-tr/* TO HERE */ from-key-invert to-key-end-invert;
	}/* Do not edit */
	.gradient-text-key { /* Do not edit */
		@apply /* EDIT FROM HERE */bg-gradient-to-r/* TO HERE */ from-key to-key-end text-transparent bg-clip-text;
	}/* Do not edit */
	.gradient-text-key-invert { /* Do not edit */
		@apply /* EDIT FROM HERE */bg-gradient-to-tr/* TO HERE */ from-key-invert to-key-end-invert text-transparent bg-clip-text;
	}/* Do not edit */

	.gradient-key-alt { /* Do not edit */
		@apply /* EDIT FROM HERE */bg-gradient-to-tr/* TO HERE */ from-key-alt to-key-end-alt;
	}/* Do not edit */
	.gradient-key-alt-invert { /* Do not edit */
		@apply /* EDIT FROM HERE */bg-gradient-to-tr/* TO HERE */ from-key-alt-invert to-key-end-alt-invert;
	}/* Do not edit */
	.gradient-text-key-alt { /* Do not edit */
		@apply /* EDIT FROM HERE */bg-gradient-to-tr/* TO HERE */ from-key-alt to-key-end-alt text-transparent bg-clip-text;
	}/* Do not edit */
	.gradient-text-key-alt-invert { /* Do not edit */
		@apply /* EDIT FROM HERE */bg-gradient-to-tr/* TO HERE */ from-key-alt-invert to-key-end-alt-invert text-transparent bg-clip-text;
	}/* Do not edit */

	.gradient-bg { /* Do not edit */
		@apply /* EDIT FROM HERE */bg-gradient-to-tr/* TO HERE */ from-background-start to-background-end;
		background: linear-gradient(to var(--color-background-direction), rgba(var(--color-background-start-rgb), 1), rgba(var(--color-background-end-rgb), 1)) ;

	}/* Do not edit */


	.shader-gradient-key-light { /* Do not edit */
		background: linear-gradient(
				to top right,
				rgba(var(--color-key-rgb), var(--shader-light)),
				rgba(var(--color-key-end-rgb), var(--shader-light))
		);
	}/* Do not edit */

	.shader-gradient-key { /* Do not edit */
		background: linear-gradient(
				to top right,
				rgba(var(--color-key-rgb), var(--shader-base)),
				rgba(var(--color-key-end-rgb), var(--shader-base))
		);
	}/* Do not edit */

	.shader-gradient-key-heavy { /* Do not edit */
		background: linear-gradient(
				to top right,
				rgba(var(--color-key-rgb), var(--shader-heavy)),
				rgba(var(--color-key-end-rgb), var(--shader-heavy))
		);
	}/* Do not edit */

	/* ---------------------------- */
	/* ANCHOR  Effects Utilities	*/
	/* ---------------------------- */

	/* TODO Convert to TW Extension and confirm no conflict with -inner prefix. */

	/* SHADOWS */
	.shadow-inner-light {
		box-shadow: inset 0 0 4px 0 rgba(0, 0, 0, 0.125);
	}
	.shadow-inner-light-x {
		box-shadow: inset 4px 0 4px -4px rgba(0, 0, 0, 0.125),
		inset -4px 0 4px -4px rgba(0, 0, 0, 0.125);
	}
	.shadow-inner-light-y {
		box-shadow: inset 0 4px 4px -4px rgba(0, 0, 0, 0.125),
		inset 0 -4px 4px -4px rgba(0, 0, 0, 0.125);
	}
	.shadow-inner-light-l {
		box-shadow: inset 4px 0 4px -4px rgba(0, 0, 0, 0.125)
	}
	.shadow-inner-light-r {
		box-shadow: inset -4px 0 4px -4px rgba(0, 0, 0, 0.125);
	}
	.shadow-inner-light-t {
		box-shadow: inset 0 4px 4px -4px rgba(0, 0, 0, 0.125);
	}
	.shadow-inner-light-b {
		box-shadow: inset 0 -4px 4px -4px rgba(0, 0, 0, 0.125);
	}
	.shadow-outer-light {
		box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.125);
	}
	.shadow-outer-light-x {
		box-shadow: 4px 0 4px -4px rgba(0, 0, 0, 0.125),
		-4px 0 4px -4px rgba(0, 0, 0, 0.125);
	}
	.shadow-outer-light-y {
		box-shadow: 0 4px 4px -4px rgba(0, 0, 0, 0.125),
		0 -4px 4px -4px rgba(0, 0, 0, 0.125);
	}
	.shadow-outer-light-l {
		box-shadow: -4px 0 4px -4px rgba(0, 0, 0, 0.125)
	}
	.shadow-outer-light-r {
		box-shadow: 4px 0 4px -4px rgba(0, 0, 0, 0.125);
	}
	.shadow-outer-light-t {
		box-shadow: 0 -4px 4px -4px rgba(0, 0, 0, 0.125);
	}
	.shadow-outer-light-b {
		box-shadow: 0 4px 4px -4px rgba(0, 0, 0, 0.125);
	}

	/* ---------------------------- */
	/* ANCHOR  Shader Utilities		*/
	/* ---------------------------- */

	/* SHADERS */
	.shader-linear-primary-up {
		@apply bg-gradient-to-t from-primary-shader;
	}
	.shader-linear-primary-up-33 {
		@apply bg-gradient-to-t from-primary-shader to-33;
	}
	.shader-linear-primary-up-66 {
		@apply bg-gradient-to-t from-primary-shader to-66;
	}
	.shader-linear-primary-down {
		@apply bg-gradient-to-b from-primary-shader;
	}
	.shader-linear-primary-down-33 {
		@apply bg-gradient-to-b from-primary-shader to-33;
	}
	.shader-linear-primary-down-66 {
		@apply bg-gradient-to-b from-primary-shader to-66;
	}
	.shader-linear-primary-right {
		@apply bg-gradient-to-r from-primary-shader;
	}
	.shader-linear-primary-right-33 {
		@apply bg-gradient-to-r from-primary-shader to-33;
	}
	.shader-linear-primary-right-66 {
		@apply bg-gradient-to-r from-primary-shader to-66;
	}
	.shader-linear-primary-left {
		@apply bg-gradient-to-l from-primary-shader;
	}
	.shader-linear-primary-left-33 {
		@apply bg-gradient-to-l from-primary-shader to-33;
	}
	.shader-linear-primary-left-66 {
		@apply bg-gradient-to-l from-primary-shader to-66;
	}
	.shader-linear-primary-upleft {
		@apply bg-gradient-to-tl from-primary-shader;
	}
	.shader-linear-primary-upleft-33 {
		@apply bg-gradient-to-tl from-primary-shader to-33;
	}
	.shader-linear-primary-upleft-66 {
		@apply bg-gradient-to-tl from-primary-shader to-66;
	}
	.shader-linear-primary-downleft {
		@apply bg-gradient-to-bl from-primary-shader;
	}
	.shader-linear-primary-downleft-33 {
		@apply bg-gradient-to-bl from-primary-shader to-33;
	}
	.shader-linear-primary-downleft-66 {
		@apply bg-gradient-to-bl from-primary-shader to-66;
	}
	.shader-linear-primary-upright {
		@apply bg-gradient-to-tr from-primary-shader;
	}
	.shader-linear-primary-upright-33 {
		@apply bg-gradient-to-tr from-primary-shader to-33;
	}
	.shader-linear-primary-upright-66 {
		@apply bg-gradient-to-tr from-primary-shader to-66;
	}
	.shader-linear-primary-downleft {
		@apply bg-gradient-to-bl from-primary-shader;
	}
	.shader-linear-primary-downleft-33 {
		@apply bg-gradient-to-bl from-primary-shader to-33;
	}
	.shader-linear-primary-downleft-66 {
		@apply bg-gradient-to-bl from-primary-shader to-66;
	}

	/* ---------------------------- */
	/* ANCHOR Transition Utilities	*/
	/* ---------------------------- */

	.focus-set { /* TODO Convert to Variables Basis */
		@apply focus:ring-2 focus:outline-none focus:ring-key;
	}
	.transition-set {
		@apply transition-base ease-base duration-base;
	}

	/* ---------------------------- */
	/* ANCHOR  Media Utilities		*/
	/* ---------------------------- */

	.bg-cover-center {
		@apply bg-center bg-no-repeat bg-cover;
	}
	.bg-cover-top {
		@apply bg-bottom bg-no-repeat bg-cover;
	}
	.bg-cover-bottom {
		@apply bg-bottom bg-no-repeat bg-cover;
	}
	.bg-cover-left {
		@apply bg-left bg-no-repeat bg-cover;
	}
	.bg-cover-right {
		@apply bg-right bg-no-repeat bg-cover;
	}
	.bg-cover-left-top {
		@apply bg-left-top bg-no-repeat bg-cover;
	}
	.bg-cover-left-bottom {
		@apply bg-left-bottom bg-no-repeat bg-cover;
	}
	.bg-cover-right-top {
		@apply bg-right-top bg-no-repeat bg-cover;
	}
	.bg-cover-right-bottom {
		@apply bg-right-bottom bg-no-repeat bg-cover;
	}

	/* ---------------------------- */
	/* ANCHOR Animation Utilities 	*/
	/* ---------------------------- */

	/* GLOWS */
	.gradient-glow-key {
		cursor: pointer;
		position: relative;
		display: inline-block;
		z-index: 0;
		overflow: visible;
	}
	.gradient-glow-key::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: linear-gradient(to right, rgb(var(--color-key)), rgb(var(--color-key-end))); /* Gradient background */
		filter: blur(16px);
		z-index: 0;
		opacity: 0;
	}
	.gradient-glow-key > * {
		position: relative;
		z-index: 2; /* Ensure glow is behind content */
	}
	.gradient-glow-key:hover::before {
		@apply animate-glow;
	}

	.gradient-glow-key-cycle-pair {
		cursor: pointer;
		position: relative;
		display: inline-block;
		z-index: 0;
		overflow: visible;
	}
	.gradient-glow-key-cycle-pair::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: linear-gradient(to right, rgb(var(--color-key)), rgb(var(--color-key-end)), rgb(var(--color-key))); /* Gradient background */
		filter: blur(16px);
		z-index: 0;
		opacity: 0;
	}
	.gradient-glow-key-cycle-pair > * {
		position: relative;
		z-index: 2; /* Ensure glow is behind content */
	}
	.gradient-glow-key-cycle-pair:hover::before {
		@apply animate-glow-cycle;
	}

	.gradient-glow-key-cycle-pair-reverse {
		cursor: pointer;
		position: relative;
		display: inline-block;
		z-index: 0;
		overflow: visible;
	}
	.gradient-glow-key-cycle-pair-reverse::after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: linear-gradient(to right, rgb(var(--color-key-end)), rgb(var(--color-key)), rgb(var(--color-key-end))); /* Gradient background */
		filter: blur(16px);
		z-index: 0;
		opacity: 0;
	}
	.gradient-glow-key-cycle-pair-reverse > * {
		position: relative;
		z-index: 2; /* Ensure it is behind the content */
	}
	.gradient-glow-key-cycle-pair-reverse:hover::after {
		@apply animate-glow-cycle-reverse;
	}

	/* TODO Convert Rounding to auto-utility classes. */

	/* ---------------------------- */
	/* ANCHOR Badging Utilities 	*/
	/* ---------------------------- */
	/* CSNOTE Badge Position is CS Specific */
	.avatar-min:has(i), .avatar-gradient-min:has(i), .avatar:has(i), .avatar-gradient:has(i), .avatar-sm:has(i), .avatar-gradient-sm:has(i), .avatar-md:has(i), .avatar-gradient-md:has(i), .avatar-lg:has(i), .avatar-gradient-lg:has(i), .avatar-xl:has(i), .avatar-gradient-xl:has(i) {
		@apply relative;
	}
	.avatar-min i, .avatar-gradient-min i, .avatar-gradient i, .avatar-sm i, .avatar-gradient-sm i, .avatar-md i, .avatar-gradient-md i, .avatar-lg i, .avatar-gradient-lg i, .avatar-xl i, .avatar-gradient-xl i {
		@apply config-text-key;
	}
	.avatar-min i, .avatar-gradient-min i {
		display: block;
		position: absolute;
		bottom: calc(var(--border-button) * -1);
		right: calc(var(--layout-unit) / -2);
		font-size: var(--text-i6-size);
		line-height: var(--text-i6-lh);
		height: var(--text-i6-size);
		width: var(--text-i6-size);
	}
	.avatar-gradient i {
		display: block;
		position: absolute;
		bottom: calc(var(--border-button) * -1);
		right: calc(var(--layout-unit) * -1);
		font-size: var(--text-i5-size);
		line-height: var(--text-i5-lh);
		height: var(--text-i5-size);
		width: var(--text-i5-size);
	}
	.avatar-sm i, .avatar-gradient-sm i {
		display: block;
		position: absolute;
		bottom: calc(var(--border-button) * -1);
		right: calc(var(--layout-unit) * -1);
		font-size: var(--text-i4-size);
		line-height: var(--text-i4-lh);
		height: var(--text-i4-size);
		width: var(--text-i4-size);
	}
	.avatar-md i, .avatar-gradient-md i {
		display: block;
		position: absolute;
		bottom: calc(var(--border-button) * -1);
		right: calc(var(--layout-unit) * -1);
		font-size: var(--text-i3-size);
		line-height: var(--text-i3-lh);
		height: var(--text-i3-size);
		width: var(--text-i3-size);
	}
	.avatar-lg i, .avatar-gradient-lg i {
		display: block;
		position: absolute;
		bottom: calc(var(--border-button) * -1);
		right: 0;
		font-size: var(--text-i2-size);
		line-height: var(--text-i2-lh);
		height: var(--text-i2-size);
		width: var(--text-i2-size);
	}
	.avatar-xl i, .avatar-gradient-xl i {
		display: block;
		position: absolute;
		bottom: calc(var(--border-button) * -1);
		right: 0;
		font-size: var(--text-i1-size);
		line-height: var(--text-i1-lh);
		height: var(--text-i1-size);
		width: var(--text-i1-size);
	}

	/* ---------------------------- */
	/* ANCHOR Other Utilities 		*/
	/* ---------------------------- */
	.hr-inline {
		display: flex;
		align-items: flex-start;
		text-align: left;
	}
	.hr-inline::after {
		content: '';
		flex-grow: 1;
		height: 1px;
		background: rgb(var(--color-key));
		min-width: var(--spacing-gutter-base);
		margin: auto;
		margin-left: var(--gap-element-xl);
	}
	.hr-inline.gradient::after {
		content: '';
		background: linear-gradient(to right, rgba(var(--color-key-rgb), 1), rgba(var(--color-key-end-rgb), 1));
	}


	/* -------------------------------- */
} /* Do not edit					*/
/* !LAYER components end			*/
/* -------------------------------------------------------------------------------------------- */
/* !SECTION DF Core Visual Utilities															*/
/* -------------------------------------------------------------------------------------------- */

/* -------------------------------------------------------------------------------------------- */
/* SECTION DF Components Structure 			 													*/
/* -------------------------------------------------------------------------------------------- */
/* LAYER components begin 			*/
@layer components { /* Do not edit	*/
	/* ---------------------------------*/
	div.screen-modal,
	section.screen-modal {
		@apply h-dvh w-screen fixed inset-0 z-top overflow-y-scroll
		duration-base backdrop-blur-heavy blur-none scale-100 opacity-100 rounded-none motion-reduce:transition-none;
	}
	div.screen-modal.hide,
	section.screen-modal.hide {
		@apply invisible opacity-0 backdrop-blur-none blur-max scale-0 rounded-[var(--screen-xl)] lg:rounded-[var(--screen-2xl)] duration-base-2x motion-reduce:transition-none;
	}
	div.screen-modal .modal-block,
	section.screen-modal .modal-block {
		@apply flex-grow-0 min-h-card max-h-full overflow-scroll
		duration-base-3x scale-100 opacity-100 motion-reduce:transition-none;
	}
	div.screen-modal.hide .modal-block,
	section.screen-modal.hide .modal-block {
		@apply invisible scale-50 opacity-0 duration-base motion-reduce:transition-none;
	}

	.frame-modal {
		@apply justify-center content-center h-full;
	}

	.lock-body
	{
		@apply h-svh overflow-y-hidden fixed w-full;
	}
	div.screen-modal.theme-transparent,
	section.screen-modal.theme-transparent {
		@apply shader-gradient-key-light;
	}

	#header-nav-menu-open.hide, #header-nav-menu-close.hide, #header-nav-menu.hide, #mobile-footer-menu-open.hide, #mobile-footer-menu-close.hide, #mobile-footer-menu.hide  {
		@apply hidden;
	}
	/* ---------------------------- */
	/* ANCHOR Buttons & Forms		*/
	/* ---------------------------- */

	a.button-primary, a.button-secondary {
		@apply text-button text-center px-element-base inline-block min-w-min ring-button ring-inset;
	}

	button.primary, button.secondary {
		@apply text-button text-center px-element-base inline-block min-w-min ring-button ring-inset;
	}

	a.button-primary-gradient, button.primary-gradient, a.button-secondary-gradient, button.secondary-gradient {
		@apply p-border-button text-center relative inline-flex items-center justify-center cursor-pointer;
	}

	a.button-primary-gradient span, button.primary-gradient span, a.button-secondary-gradient span, button.secondary-gradient span {
		@apply w-full px-element-base;
	}
	a.button-primary-gradient p, button.primary-gradient p,
	a.button-secondary-gradient p, button.secondary-gradient p {
		@apply text-button-size leading-gradient-button text-center;
	}
	a.button-primary-gradient p i, button.primary-gradient p i, a.button-secondary-gradient p i, button.secondary-gradient p i {
		@apply text-button-size leading-gradient-button text-center;
	}
	a.button-primary-gradient.sm p, button.primary-gradient.sm p, a.button-secondary-gradient.sm p, button.secondary-gradient.sm p,
	a.button-primary-gradient.sm p i, button.primary-gradient.sm p i, a.button-secondary-gradient.sm p i, button.secondary-gradient.sm p i {
		@apply text-p4-size leading-p3 text-center;
	}
	a.button-primary-gradient.md p, button.primary-gradient.md p, a.button-secondary-gradient.md p, button.secondary-gradient.md p,
	a.button-primary-gradient.md p i, button.primary-gradient.md p i, a.button-secondary-gradient.md p i, button.secondary-gradient.md p i {
		@apply text-p3-size leading-p1 text-center;
	}
	a.button-primary-gradient-alt, button.primary-gradient-alt, a.button-secondary-gradient-alt, button.secondary-gradient-alt {
		@apply text-center inline-flex items-center justify-center relative;
		z-index: 0;
	}

	a.button-icon-round-gradient-alt, button.icon-round-gradient-alt {
		@apply p-border-button text-center inline-flex items-center justify-center w-element-base h-element-base relative;
		z-index: 0;
	}
	a.button-primary-gradient-alt span, button.primary-gradient-alt span, a.button-secondary-gradient-alt span, button.secondary-gradient-alt span {
		@apply w-full px-element-base ring-button ring-inset relative;
		z-index: 1;
	}
	a.button-icon-round-gradient-alt span, button.icon-round-gradient-alt span {
		@apply w-full h-full ring-button ring-inset ring-transparent relative;
		z-index: 1;
	}
	a.button-primary-gradient-alt p, button.primary-gradient-alt p, a.button-secondary-gradient-alt p, button.secondary-gradient-alt p {
		@apply text-button relative;
		z-index: 1;
	}
	a.button-icon-round-gradient-alt p, button.icon-round-gradient-alt p {
		@apply text-button relative;
		z-index: 1;
	}




	a.button-primary-gradient-alt::before, button.primary-gradient-alt::before {
		@apply w-full h-full top-0 left-0 absolute;
		content: '';
		z-index: 0;
	}
	a.button-secondary-gradient-alt::before, button.secondary-gradient-alt::before {
		@apply w-full h-full top-0 left-0 absolute;
		content: '';
		z-index: 0;
		opacity: calc(1 - var(--color-key-gradient-invert-opacity));
	}

	a.button-icon-round-gradient-alt::before, button.icon-round-gradient-alt::before {
		@apply w-full h-full top-0 left-0 absolute;
		content: '';
		z-index: 0;
		opacity: calc(1 - var(--color-key-gradient-invert-opacity));
	}
	button.primary.squared, button.secondary.squared, a.button-primary.squared, a.button-secondary.squared,
	a.button-primary-gradient.squared, button.primary-gradient.squared, a.button-secondary-gradient.squared, button.secondary-gradient.squared {
		@apply rounded-base;
	}
	button.primary.squared span, button.secondary.squared span, a.button-primary.squared span, a.button-secondary.squared span,
	a.button-primary-gradient.squared span, button.primary-gradient.squared span, a.button-secondary-gradient.squared span, button.secondary-gradient.squared span {
		@apply rounded-base-inset;
	}

	a.button-primary i,
	a.button-primary-gradient i, button.primary-gradient i,
	a.button-secondary-gradient i, button.secondary-gradient i {
		@apply pr-base-unit;
	}



	a.button-primary i.i-r, button.primary i.i-r, a.buttton-secondary i.i-r, button.secondary i.i-r,
	a.button-primary-gradient i.i-r, button.primary-gradient i.i-r,
	a.button-secondary-gradient i.i-r, button.secondary-gradient i.i-r {
		@apply pr-0 pl-base-unit;
	}

	a.button-icon-round, button.icon-round {
		@apply text-ie text-center w-element-base h-element-base bg-transparent;
	}
	a.button-icon-round:hover, button.icon-round:hover {
		@apply ring-button ring-inset;
	}
	a.button-icon-round-gradient, button.icon-round-gradient,
	.button-icon.square.gradient {
		@apply p-border-button text-center relative inline-flex items-center justify-center w-element-base h-element-base;
	}
	a.button-icon-round-gradient p, button.icon-round-gradient p,
	.button-icon.square.gradient p {
		@apply text-ie-size leading-gradient-button;
	}
	a.button-icon-round-gradient span, button.icon-round-gradient span,
	.button-icon.square.gradient span {
		@apply w-full;
	}

	a.chip-primary-gradient, a.chip-secondary-gradient {
		@apply p-border-button;
	}
	a.chip-primary-gradient p, a.chip-secondary-gradient p {
		@apply px-chip text-chip font-bold;
	}
	a.chip-primary-gradient span, a.chip-secondary-gradient span {
		@apply w-full block;
	}

	button.filter-dropdown, button.filter-dropdown-gradient {
		@apply flex items-center;
	}
	button.filter-dropdown p, button.filter-dropdown-gradient p {
		@apply mx-auto flex-grow text-left whitespace-nowrap text-button-size lg:text-p1;
	}
	button.filter-dropdown i, button.filter-dropdown-gradient i {
		@apply pl-sub-base text-button-size lg:text-p1;
	}

	button.icon-dropdown {
		@apply px-element-base ring-button ring-inset flex justify-between items-center w-full min-w-min h-element-base;
	}
	button.icon-dropdown p {
		@apply text-button-size lg:text-p3 mx-auto flex-grow text-center line-clamp-1;
	}
	button.icon-dropdown i,
	button.icon-dropdown-gradient i,
	button.icon-dropdown-gradient-alt i,
	button.icon-dropdown-gradient-alt.secondary i {
		@apply min-w-element-sub;
	}

	button.icon-dropdown-gradient, button.icon-dropdown-gradient-alt {
		@apply p-border-button text-center relative inline-flex w-full;
	}
	.button-dropdown-gradient-alt.secondary {
		@apply p-border-button text-center relative inline-flex w-full ring-0;
	}
	.button-dropdown-gradient-alt.secondary i {
		@apply pr-0 min-w-element-sub;
	}
	.button-dropdown-gradient-alt.secondary.text-left span > i:first-child {
		@apply hidden;
	}
	.button-dropdown-gradient-alt.secondary.text-left > span > p {
		text-align: left;
		width: 100%;
	}
	button.icon-dropdown-gradient span, button.icon-dropdown-gradient-alt span,
	.button-dropdown-gradient-alt.secondary span {
		@apply w-full h-full px-element-base flex flex-grow flex-row items-center justify-center;
	}
	button.icon-dropdown-gradient p, button.icon-dropdown-gradient-alt p,
	.button-dropdown-gradient-alt.secondary p {
		@apply text-button-size mx-auto line-clamp-1;
	}
	button.icon-dropdown-gradient i, button.icon-dropdown-gradient-alt i,
	.button-dropdown-gradient-alt.secondary i {
		@apply leading-gradient-button;
	}

	a.button-icon-base-gradient {
		@apply h-element-base text-h4;
	}

	.dropdown-menu {
		@apply z-10 mt-sub-base divide-y divide-secondary/20 ring-1 ring-opacity-5 focus:outline-none;
	}
	.dropdown-menu div.title-block {
		@apply px-element-base py-sub-base;
	}
	.dropdown-menu div {
		@apply py-base-base;
	}
	.dropdown-menu p.title {
		@apply text-p4;
	}
	.dropdown-menu button, .dropdown-menu a {
		@apply block px-element-base py-sub-base text-left w-full text-p4;
	}
	.dropdown-menu-gradient {
		@apply z-10 mt-sub-base focus:outline-none;
	}
	div.dropdown-menu-gradient {
		@apply divide-y divide-secondary/20;
	}
	.dropdown-menu-gradient div.title-block {
		@apply px-element-base py-sub-base;
	}
	.dropdown-menu-gradient div {
		@apply py-base-base;
	}
	.dropdown-menu-gradient p.title {
		@apply text-p4;
	}
	.dropdown-menu-gradient button, .dropdown-menu-gradient a, .dropdown-menu-gradient li {
		@apply block px-element-base py-sub-base text-left w-full text-p4 cursor-pointer;
	}
	ul.dropdown-menu-gradient {
		@apply p-0 absolute mx-element-base mt-0 rounded-t-none;
		width: calc(100% - 32px);
	}

	ul.dropdown-menu-gradient li:last-child {
		@apply rounded-b-corner;
	}
	ul.dropdown-menu-gradient li {
		@apply list-none border-t-0 text-center relative;
	}

	ul.dropdown-menu-gradient.hide {
		@apply hidden;
	}

	.dropdown-menu-set {
		@apply w-full relative;
	}
	.form-input-dropdown {
		@apply ring-form ring-inset border-0 p-0 rounded-full flex flex-row items-center;
	}
	.form-input-dropdown span {
		@apply flex flex-row justify-between;
	}
	.form-input-dropdown.gradient span p,
	.form-input-dropdown.gradient span i {
		@apply gradient-text-key;
	}
	.form-input-dropdown.air {
		@apply flex flex-col ring-0;
	}
	.form-input-dropdown.air span {
		@apply flex flex-row items-center;
	}
	.form-input-dropdown p {
		@apply whitespace-nowrap;
	}
	.form-input,
	.form-input-file {
		@apply ring-form ring-inset border-0 p-0 rounded-full flex flex-row items-center;
	}
	.form-input > span,
	.form-input-file > span {
		@apply w-full;
	}
	.form-input-combo {
		@apply form-input ring-form ring-inset border-0 p-0 rounded-full flex flex-row items-center;
	}
	.form-input-combo button p, .form-input-combo a p {
		@apply whitespace-nowrap;
	}
	.form-input.gradient > span,
	.form-input-file.gradient > span {
		@apply flex flex-row items-center;
		position: relative;
		z-index: 1;
	}
	.form-input label,
	.form-input-file label {
		@apply text-p2 w-2/5 pl-element-base;
	}

	.form-input.unlabeled label,
	.form-input-file.unlabeled label {
		@apply sr-only;
	}
	.form-input-combo label {
		@apply sr-only;
	}
	.form-textarea {
		@apply flex form-input ring-form ring-inset rounded-field; /* NOTE Flex is REQUIRED to remove the mystery bottom spacing below textarea elements that is present in all browsers as of 2024. */
	}
	.form-textarea textarea {
		@apply w-full;
	}
	.form-textarea.air > label {
		@apply w-full pl-0 text-p3 pb-base-unit;
	}
	.form-textarea.air textarea {
		@apply border-transparent border-x-0 border-t-0;
	}
	.form-input input, .form-input-file input, .form-input-combo input, .form-input-dropdown span {
		@apply flex-auto border-0 py-0 px-sub-xl text-p2 min-w-element-base h-element-minxx m-base-unit w-full;
	}

	.form-input-file input[type=file]::file-selector-button {
		@apply bg-transparent border-0 py-0 pl-0 pr-sub-base gradient-text-key rounded-full text-p2;
	}

	.form-input.air,
	.form-input-file.air {
		@apply flex flex-col ring-0;
	}
	.form-input.air > label,
	.form-input-file.air > label {
		@apply w-full pl-0 text-p4 sm:text-p3 pb-base-unit;
	}
	.form-input.air > input,
	.form-input-file.air > input {
		@apply w-full m-0;
	}
	.form-input-media {
		@apply bg-cover bg-center ring-button ring-key rounded-base relative;
	}
	.form-input-media > video {
		width: 100%;
		height: 100%;
		position: absolute;
		object-fit: cover;
		object-position: center;
	}
	.form-input-media a {
		@apply block content-center text-center rounded-base min-h-element-base min-w-element-base;
	}

	.form-input-media a:hover {
		@apply bg-invert-shader;
	}

	.form-input-media a i {
		@apply text-i5 config-transition-set text-key;
	}
	.form-input-media.sm, .form-input-media.sm a {
		@apply h-elementc-sm;
	}
	.form-input-media.md, .form-input-media.md a {
		@apply h-elementc-md;
	}
	.form-input-media.lg, .form-input-media.lg a {
		@apply h-elementc-lg;
	}

	.form-input-media.air {
		@apply rounded-base bg-invert-shader ring-transparent shadow-light;
	}
	.form-input-media.air > video {
		@apply rounded-base;
	}

	.form-input-media.air a:hover {
		@apply bg-invert/50 ring-button ring-key;
	}
	.form-input-media.air.corner-i a:hover {
		@apply ring-transparent;
	}
	.form-input-media.square, .form-input-media.square a {
		@apply aspect-square;
	}
	.form-input-media.video, .form-input-media.video a {
		@apply aspect-video;
	}

	.form-input-media.circle, .form-input-media.circle a {
		@apply aspect-square rounded-full;
	}
	.form-input-media.circle > video {
		@apply rounded-full;
	}

	.form-input-media.corner-i, .form-input-media.corner-i a {
		position: relative;
	}

	.form-input-media.corner-i a i, .form-input-media.sm.corner-i a i {
		display: block;
		position: absolute;
		top: calc( (var(--text-i5-size) / 4) * -1);
		right: calc( (var(--text-i5-size) / 4) * -1);
		font-size: var(--text-i5-size);
		line-height: var(--text-i5-lh);
		height: var(--text-i5-size);
		width: var(--text-i5-size);
		transition: none;
	}

	.form-input-media.md.corner-i a i {
		display: block;
		position: absolute;
		top: calc( var(--layout-unit) * -1);
		right: calc( var(--layout-unit) * -1);
		font-size: var(--text-i4-size);
		line-height: var(--text-i4-lh);
		height: var(--text-i4-size);
		width: var(--text-i4-size);
		transition: none;
	}

	.form-input-media.lg.corner-i a i {
		display: block;
		position: absolute;
		top: calc( var(--layout-unit) * -1);
		right: calc( var(--layout-unit) * -1);
		font-size: var(--text-i3-size);
		line-height: var(--text-i3-lh);
		height: var(--text-i3-size);
		width: var(--text-i3-size);
		transition: none;
	}

	.form-input-media.corner-i a:hover i {
		@apply shadow-light;
	}

	.form-input-media.nohover a:hover {
		@apply cursor-default bg-invert/0 ring-transparent;
	}

	.form-input-checkbox {
		@apply flex items-center;
	}
	.form-input-checkbox.row {
		@apply flex-row w-full gap-x-base-unit;
	}
	.form-input-checkbox .checkbox {
		@apply flex aspect-square items-center min-h-element-sub min-w-element-sub;
	}
	.form-input-checkbox .checkbox input {
		@apply aspect-square border-key text-key config-focus-set w-full h-full;
	}
	.form-input-checkbox.sm {
		@apply gap-x-base-unit;
	}
	.form-input-checkbox.md {
		@apply gap-x-sub-base;
	}
	.form-input-checkbox.lg {
		@apply gap-x-element-base;
	}
	.form-input-checkbox.sm .checkbox {
		@apply w-element-sub min-w-element-sub h-element-sub;
	}
	.form-input-checkbox.md .checkbox {
		@apply w-element-subx min-w-element-subx h-element-subx;
	}
	.form-input-checkbox.lg .checkbox {
		@apply w-element-min min-w-element-min h-element-min;
	}

	.form-input-checkbox.rounded .checkbox {
		@apply rounded-min;
	}
	.form-input-checkbox.rounded .checkbox input {
		@apply rounded-min-inset;
	}
	.form-input-checkbox.circle .checkbox {
		@apply rounded-full;
	}
	.form-input-checkbox.circle .checkbox input {
		@apply rounded-full;
	}

	.form-input-checkbox.gradient .checkbox {
		@apply gradient-key p-border-button;
	}
	.form-input-checkbox.gradient .checkbox input {
		@apply border-0;
	}
	.form-input-checkbox.gradient .checkbox input:checked {
		@apply bg-transparent;
	}
	.form-input-checkbox.sm div p {
		@apply text-p4-size leading-p3;
	}
	.form-input-checkbox.md div p {
		@apply text-p3-size leading-p3;
	}
	.form-input-checkbox.lg div p {
		@apply text-p2-size leading-p3;
	}

	.input-checkbox-toggle {
		@apply hidden;
	}
	.input-checkbox-toggle-label:hover {
		@apply cursor-pointer gradient-text-key;
	}


	.form-radio-set label {
		@apply ml-sub-base;
	}
	.form-radio-option {
		@apply flex items-center cursor-pointer;
	}

	.form-radio-set.simple legend {
		@apply text-primary mb-title;
	}
	.form-radio-set.simple > p {
		@apply text-secondary;
	}
	.form-radio-set.simple .form-stack-radio-options {
		@apply flex flex-col gap-y-form-sm mt-element-base;
	}

	.form-radio-set.card-stack .form-stack-radio-options label {
		@apply rounded-corner bg-key shadow-light m-0;
	}
	.form-radio-set.card-stack.gradient.secondary .form-stack-radio-options label {
		@apply gradient-key p-border-button shadow-light;
	}
	.form-radio-set.card-stack.gradient.secondary .form-stack-radio-options label > div {
		@apply rounded-corner-inset bg-invert bg-opacity-gradient-invert config-transition-set flex flex-col items-center justify-center p-element-base w-full h-full;
	}

	.form-radio-set.card-stack.gradient.secondary .form-stack-radio-options label:hover > div,
	.form-radio-set.card-stack.gradient.secondary .form-stack-radio-options label:has(input[type="radio"]:checked) > div {
		@apply bg-transparent;
	}
	.form-radio-set.card-stack.gradient.secondary .form-stack-radio-options label:hover > div p,
	.form-radio-set.card-stack.gradient.secondary .form-stack-radio-options label:has(input[type="radio"]:checked) > div p,
	.form-radio-set.card-stack.gradient.secondary .form-stack-radio-options label:hover > div i,
	.form-radio-set.card-stack.gradient.secondary .form-stack-radio-options label:has(input[type="radio"]:checked) > div i {
		@apply text-invert;
	}

	.form-radio-set.card-block {
		@apply rounded-corner bg-key shadow-light;
	}
	.form-radio-set.card-block.gradient {
		@apply rounded-corner gradient-key shadow-light;
	}
	.form-radio-set.card-block.gradient.secondary {
		@apply p-border-button;
	}
	.form-radio-set.card-block.gradient.secondary .form-stack-radio-options {
		@apply flex flex-col gap-y-px;
	}
	.form-radio-set.card-block.gradient.secondary.row .form-stack-radio-options {
		@apply flex flex-row gap-x-px;
	}
	.form-radio-set.card-block.gradient.secondary .form-stack-radio-options label {
		@apply bg-opacity-gradient-invert;
	}
	.form-radio-set.card-block.gradient.secondary .form-stack-radio-options label span:first-child p {
		@apply font-bold gradient-text-key;
	}
	.form-radio-set.card-block.gradient.secondary .form-stack-radio-options label:hover span:first-child p {
		@apply text-invert;
	}
	.form-radio-set.card-block .form-stack-radio-options {
		@apply relative;
	}
	.form-radio-set.card-block .form-stack-radio-options > .form-radio-option:first-child {
		@apply rounded-t-corner-inset;
	}
	.form-radio-set.card-block.row .form-stack-radio-options > .form-radio-option:first-child {
		@apply rounded-l-corner-inset rounded-tr-none;
	}
	.form-radio-set.card-block .form-stack-radio-options > .form-radio-option:last-child {
		@apply rounded-b-corner-inset;
	}
	.form-radio-set.card-block.row .form-stack-radio-options > .form-radio-option:last-child {
		@apply rounded-r-corner-inset rounded-bl-none;
	}
	.form-radio-set.card-block .form-radio-option {
		@apply bg-invert config-transition-set;
	}
	.form-radio-set.card-block .form-radio-option:hover {
		@apply bg-transparent;
	}
	.form-radio-set.card-block .form-radio-option:hover label, .form-radio-set.card-block .form-radio-option:hover p {
		@apply text-invert config-transition-set;
	}
	.form-radio-set.card-block .form-radio-option {
		@apply relative flex cursor-pointer flex-row w-full p-element-base focus:outline-none gap-x-form-sm;
	}
	.form-radio-set.card-block .form-radio-option span {
		@apply flex items-center text-p4;
	}
	.form-radio-set.card-block label {
		@apply ml-0;
	}
	.form-radio-set.card-block input {
		@apply mr-sub-base;
	}
	.form-radio-set.card-block label:has(input[type="radio"]:checked) {
		@apply bg-transparent;
	}
	.form-radio-set.card-block label:has(input[type="radio"]:checked) p {
		@apply text-invert;
	}
	.form-radio-set.card-block.secondary label:has(input[type="radio"]:checked) :first-child p {
		@apply text-invert;
	}

	.set-input-hidden input {
		@apply hidden;
	}

	/* ---------------------------- */
	/* ANCHOR Alerts				*/
	/* ---------------------------- */


	/* Alert Structure - All */

	.alert {
		@apply py-sub-base flex flex-row gap-x-article-base;
	}

	.alert a {
		@apply text-p4-size leading-h6;
	}
	.alert .button {
		@apply h-element-min text-heb-size leading-h6 whitespace-nowrap px-sub-2xl py-0;
	}
	.alert .content > hgroup, .alert .content > div {
		@apply gap-heading;
	}
	.alert .content p {
		@apply text-p4-size leading-h6;
	}
	.form-input .alert {
		@apply self-start;
	}

	/* Alert Structure - Banner */

	.alert.banner {
		@apply shadow-outer-light-y w-full;
	}
	.alert.banner .content {
		@apply items-center justify-center w-full flex flex-row flex-wrap gap-x-article-base gap-y-half-unit;
	}
	.alert.banner .content > hgroup, .alert.banner .content > div {
		@apply flex flex-row flex-wrap;
	}
	.alert.banner .action a i {
		@apply w-element-min text-center;
	}
	.alert.banner .action {
		@apply items-center min-w-element-min max-w-fit text-p3-size leading-h6;
	}

	/* Alert Structure - Callout */

	.alert.callout {
		@apply px-element-base rounded-field my-element-base justify-center;
	}
	.card .alert.callout .content h6, .card .alert.callout .content p {
		@apply leading-p5;
	}
	.alert.callout .content > hgroup, .alert.callout .content > div {
		@apply flex flex-row flex-wrap;
	}
	.alert.callout .action a i {
		@apply pl-half-unit;
	}
	.alert.callout .content h6 i, .alert.callout .content p i {
		@apply pr-half-unit;
	}

	/* Alert Structure - Box */
	.alert.box {
		@apply p-element-base rounded-corner my-element-base shadow-light w-full;
	}
	.alert.box .action a i {
		@apply pl-half-unit;
	}
	.alert.box .content {
		@apply flex flex-col gap-y-article-base w-full;
	}
	.alert.box .content ul li {
		@apply text-p4;
	}

	/* Alert Theme - Default */
	.alert div .button {
		@apply text-key bg-invert ring-key hover:bg-key hover:text-invert;
	}
	.alert .action a i {
		@apply text-secondary;
	}
	.alert .action a:hover i {
		@apply gradient-text-key;
	}
	.alert {
		@apply bg-background;
	}

	/* Alert Theme - Key */
	.alert.key {
		@apply bg-key text-invert;
	}
	.alert.key h6 {
		@apply text-invert;
	}
	.alert.key a {
		@apply text-invert hover:text-key-end;
	}
	.alert.key .button {
		@apply bg-key ring-invert text-invert hover:text-key hover:bg-invert;
	}
	.alert.key .action a i {
		@apply text-invert opacity-gradient-active;
	}
	.alert.key .action a:hover i {
		@apply opacity-100;
	}

	/* Alert Theme - Key Gradient */
	.alert.key-gradient {
		@apply gradient-key text-invert;
	}
	.alert.key-gradient h6 {
		@apply text-invert;
	}
	.alert.key-gradient a {
		@apply text-invert hover:text-key-end;
	}
	.alert.key-gradient .button {
		@apply text-invert ring-invert bg-transparent hover:text-key hover:bg-invert hover:bg-opacity-gradient-active hover:ring-key;
	}
	.alert.key-gradient .action a i {
		@apply text-invert opacity-gradient-active;
	}
	.alert.key-gradient .action a:hover i {
		@apply opacity-100;
	}

	/* Alert Theme - Notify */
	.alert.notify {
		@apply bg-alert-notify-bg text-alert-notify-p;
	}
	.alert.notify h6 {
		@apply text-alert-notify-h;
	}
	.alert.notify a {
		@apply text-alert-notify hover:text-alert-notify-h;
	}
	.alert.notify .button {
		@apply text-alert-notify-h ring-alert-notify-h bg-transparent hover:text-alert-notify-bg hover:bg-alert-notify hover:ring-alert-notify;
	}
	.alert.notify .action a i {
		@apply text-alert-notify;
	}
	.alert.notify .action a:hover i {
		@apply text-alert-notify-h;
	}

	/* Alert Theme - Warning */
	.alert.warning {
		@apply bg-alert-warning-bg text-alert-warning-p;
	}
	.alert.warning h6 {
		@apply text-alert-warning-h;
	}
	.alert.warning a {
		@apply text-alert-warning hover:text-alert-warning-h;
	}
	.alert.warning .button {
		@apply text-alert-warning-h ring-alert-warning-h bg-transparent hover:text-alert-warning-bg hover:bg-alert-warning hover:ring-alert-warning;
	}
	.alert.warning .action a i {
		@apply text-alert-warning;
	}
	.alert.warning .action a:hover i {
		@apply text-alert-warning-h;
	}

	/* Alert Theme - Error */
	.alert.error {
		@apply bg-alert-error-bg text-alert-error-p;
	}
	.alert.error h6 {
		@apply text-alert-error-h;
	}
	.alert.error a {
		@apply text-alert-error hover:text-alert-error-h;
	}
	.alert.error .button {
		@apply text-alert-error-h ring-alert-error-h bg-transparent hover:text-alert-error-bg hover:bg-alert-error hover:ring-alert-error;
	}
	.alert.error .action a i {
		@apply text-alert-error;
	}
	.alert.error .action a:hover i {
		@apply text-alert-error-h;
	}


	/* Alert Theme - Success */
	.alert.success {
		@apply bg-alert-success-bg text-alert-success-p;
	}
	.alert.success h6 {
		@apply text-alert-success-h;
	}
	.alert.success a {
		@apply text-alert-error hover:text-alert-error-h;
	}
	.alert.success .button {
		@apply text-alert-success-h ring-alert-success-h bg-transparent hover:text-alert-success-bg hover:bg-alert-success hover:ring-alert-success;
	}
	.alert.success .action a i {
		@apply text-alert-success;
	}
	.alert.success .action a:hover i {
		@apply text-alert-success-h;
	}


	/* ---------------------------- */
	/* ANCHOR Special Blocks		*/
	/* ---------------------------- */

	.block-callout {
		@apply w-full text-center;
	}
	.block-callout hgroup p {
		@apply text-p3 italic;
	}

	/* ---------------------------- */
	/* ANCHOR Other Elements		*/
	/* ---------------------------- */

	.toggle-menu, .toggle-menu-gradient {
		@apply text-ie;
	}

	/* CHIPS */
	.chip, .chip-gradient {
		@apply text-chip px-chip font-bold;
	}

	/* -------------------------------- */
} /* Do not edit					*/
/* !LAYER components end			*/
/* -------------------------------------------------------------------------------------------- */
/* !SECTION DF Components Structure 			 												*/
/* -------------------------------------------------------------------------------------------- */

/* -------------------------------------------------------------------------------------------- */
/* SECTION DF Components Radius & Transitions													*/
/* -------------------------------------------------------------------------------------------- */
/* LAYER components begin 			*/
@layer components { /* Do not edit	*/
	/* ---------------------------------*/

	/* ---------------------------- */
	/* ANCHOR Links					*/
	/* ---------------------------- */

	nav a, nav a:link, nav a:visited, main p a, main p a:link, main p a:visited, footer p a, footer p a:link, footer p a:visited {
		@apply config-transition-set;
	}
	.toggle-menu, .toggle-menu-gradient {
		@apply config-transition-set;
	}

	#header-nav-menu nav a, #header-nav-menu nav a p {
		@apply config-transition-set;
	}


	/* ---------------------------- */
	/* ANCHOR Avatars				*/
	/* ---------------------------- */

	/* TODO Create all avatar variations */

	/* Avatar New Combo */

	.avatar {
		@apply w-element-base h-element-base aspect-square rounded-full p-border-button bg-key border-0 shadow-light hover:brightness-110;
	}

	.avatar img {
		@apply aspect-square rounded-full w-full h-full object-cover object-center;
	}
	.avatar.gradient {
		@apply gradient-key;
	}
	.avatar.min {
		@apply w-element-min h-element-min;
	}
	.avatar.sm {
		@apply w-elementc-sm h-elementc-sm;
	}
	.avatar.md {
		@apply w-elementc-md h-elementc-md;
	}
	.avatar.lg {
		@apply w-elementc-lg h-elementc-lg;
	}
	.avatar.xl {
		@apply w-elementc-xl h-elementc-xl;
	}
	.avatar.square, .avatar.square img {
		@apply rounded-base;
	}
	.avatar.square-rounded {
		@apply rounded-corner;
	}
	.avatar.square-rounded img {
		@apply rounded-corner-inset;
	}
	.avatar.circle, .avatar.circle img {
		@apply rounded-full;
	}
	.avatar.hexagon, .avatar.hexagon img {
		border-radius: 0;
		clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);
	}

	.avatar.hexagon-rounded, .avatar.hexagon-rounded img {
		border-radius: 0;
		clip-path: polygon( 1.336% 54.545%,1.336% 54.545%,0.942% 53.683%,0.636% 52.79%,0.417% 51.873%,0.286% 50.94%,0.242% 50%,0.286% 49.06%,0.417% 48.127%,0.636% 47.21%,0.942% 46.317%,1.336% 45.455%,22.133% 5.339%,22.133% 5.339%,22.607% 4.529%,23.148% 3.787%,23.752% 3.117%,24.412% 2.524%,25.121% 2.012%,25.874% 1.584%,26.665% 1.244%,27.488% 0.997%,28.336% 0.845%,29.203% 0.794%,70.797% 0.794%,70.797% 0.794%,71.664% 0.845%,72.512% 0.997%,73.335% 1.244%,74.126% 1.584%,74.879% 2.012%,75.588% 2.524%,76.248% 3.117%,76.852% 3.787%,77.393% 4.529%,77.867% 5.339%,98.664% 45.455%,98.664% 45.455%,99.058% 46.317%,99.364% 47.21%,99.583% 48.127%,99.714% 49.06%,99.758% 50%,99.714% 50.94%,99.583% 51.873%,99.364% 52.79%,99.058% 53.683%,98.664% 54.545%,77.867% 94.661%,77.867% 94.661%,77.393% 95.471%,76.852% 96.213%,76.248% 96.883%,75.588% 97.476%,74.879% 97.988%,74.126% 98.416%,73.335% 98.756%,72.512% 99.003%,71.664% 99.155%,70.797% 99.206%,29.203% 99.206%,29.203% 99.206%,28.336% 99.155%,27.488% 99.003%,26.665% 98.756%,25.874% 98.416%,25.121% 97.988%,24.412% 97.476%,23.752% 96.883%,23.148% 96.213%,22.607% 95.471%,22.133% 94.661%,1.336% 54.545%);
	}

	/* CSNOTE CS Specific */
	.avatar.cs-avatar-item, .avatar.cs-avatar-item img {
		border-radius: 0;
		clip-path: polygon(
				25% 10%, 26% 8%, 28% 6%, 30% 5%,
				70% 5%, 72% 6%, 74% 8%, 75% 10%,
				98% 46%, 99% 49%, 99% 51%, 98% 54%,
				75% 90%, 74% 92%, 72% 94%, 70% 95%,
				30% 95%, 28% 94%, 26% 92%, 25% 90%,
				2% 54%, 1% 51%, 1% 49%, 2% 46%);
	}
	.avatar.cs-avatar-membership {
		@apply rounded-corner;
	}
	.avatar.cs-avatar-membership img {
		@apply rounded-corner-inset;
	}

	/* Sizing & Base Effects */

	.avatar-min, .avatar-gradient-min, .avatar-square-min, .avatar-square-gradient-min,
	.avatar-gradient, .avatar-square, .avatar-square-gradient,
	.avatar-sm, .avatar-gradient-sm, .avatar-square-sm, .avatar-square-gradient-sm,
	.avatar-md, .avatar-gradient-md, .avatar-square-md, .avatar-square-gradient-md,
	.avatar-lg, .avatar-gradient-lg, .avatar-square-lg, .avatar-square-gradient-lg,
	.avatar-xl, .avatar-gradient-xl, .avatar-square-xl, .avatar-square-gradient-xl {
		@apply relative inline-block  aspect-square;
	}

	.avatar-min img, .avatar-gradient-min img, .avatar-square-min img, .avatar-square-gradient-min img,
	.avatar-gradient img, .avatar-square img, .avatar-square-gradient img,
	.avatar-sm img, .avatar-gradient-sm img, .avatar-square-sm img, .avatar-square-gradient-sm img,
	.avatar-md img, .avatar-gradient-md img, .avatar-square-md img, .avatar-square-gradient-md img,
	.avatar-lg img, .avatar-gradient-lg img, .avatar-square-lg img, .avatar-square-gradient-lg img,
	.avatar-xl img, .avatar-gradient-xl img, .avatar-square-xl img, .avatar-square-gradient-xl img {
		@apply object-cover object-center w-full h-full aspect-square;
	}

	a.avatar-min:hover img, a.avatar-gradient-min:hover img, a.avatar-square-min:hover img, a.avatar-square-gradient-min:hover img,
	a.avatar:hover img, a.avatar-gradient:hover img, a.avatar-square:hover img, a.avatar-square-gradient:hover img,
	a.avatar-sm:hover img, a.avatar-gradient-sm:hover img, a.avatar-square-sm:hover img, a.avatar-square-gradient-sm:hover img,
	a.avatar-md:hover img, a.avatar-gradient-md:hover img, a.avatar-square-md:hover img, a.avatar-square-gradient-md:hover img,
	a.avatar-lg:hover img, a.avatar-gradient-lg:hover img, a.avatar-square-lg:hover img, a.avatar-square-gradient-lg:hover img,
	a.avatar-xl:hover img, a.avatar-gradient-xl:hover img, a.avatar-square-xl:hover img, a.avatar-square-gradient-xl:hover img {
		@apply brightness-110; /* CSNOTE CS Specific */
	}

	.avatar-min, .avatar-gradient-min, .avatar-square-min, .avatar-square-gradient-min {
		@apply w-element-min h-element-min;
	}
	.avatar-gradient, .avatar-square, .avatar-square-gradient {
		@apply w-element-base h-element-base;
	}
	.avatar-sm, .avatar-gradient-sm, .avatar-square-sm, .avatar-square-gradient-sm {
		@apply w-elementc-sm h-elementc-sm;
	}
	.avatar-md, .avatar-gradient-md, .avatar-square-md, .avatar-square-gradient-md {
		@apply w-elementc-md h-elementc-md;
	}
	.avatar-lg, .avatar-gradient-lg, .avatar-square-lg, .avatar-square-gradient-lg {
		@apply w-elementc-lg h-elementc-lg;
	}
	.avatar-xl, .avatar-gradient-xl, .avatar-square-xl, .avatar-square-gradient-xl {
		@apply w-elementc-xl h-elementc-xl;
	}
	/* NOTE DF v2 - potential to apply sizing (base, sm, md, etc as "unit size" vs "unit-size", possibly could do same with gradients (mix-ins vs combo class */

	.avatar-min, .avatar-square-min,
	.avatar-square,
	.avatar-sm, .avatar-square-sm,
	.avatar-md, .avatar-square-md,
	.avatar-lg, .avatar-square-lg,
	.avatar-xl, .avatar-square-xl {
		@apply ring-button ring-key;
	}

	.avatar-min, .avatar-sm, .avatar-md, .avatar-lg, .avatar-xl,
	.avatar-gradient-min, .avatar-gradient, .avatar-gradient-sm, .avatar-gradient-md, .avatar-gradient-lg, .avatar-gradient-xl {
		@apply rounded-full;

	}

	.avatar-min img, .avatar-sm img, .avatar-md img, .avatar-lg img, .avatar-xl img,
	.avatar-gradient-min img, .avatar-gradient img, .avatar-gradient-sm img, .avatar-gradient-md img, .avatar-gradient-lg img, .avatar-gradient-xl img {
		@apply rounded-full;
	}

	.avatar-square-min, .avatar-square, .avatar-square-sm, .avatar-square-md, .avatar-square-lg, .avatar-square-xl,
	.avatar-square-gradient-min, .avatar-square-gradient, .avatar-square-gradient-sm, .avatar-square-gradient-md, .avatar-square-gradient-lg, .avatar-square-gradient-xl {
		@apply rounded-base;
	}

	.avatar-square-min img, .avatar-square img, .avatar-square-sm img, .avatar-square-md img, .avatar-square-lg img, .avatar-square-xl img,
	.avatar-square-gradient-min img, .avatar-square-gradient img, .avatar-square-gradient-sm img, .avatar-square-gradient-md img, .avatar-square-gradient-lg img, .avatar-square-gradient-xl img {
		@apply rounded-base;
	}

	.avatar-gradient-min, .avatar-gradient, .avatar-gradient-sm, .avatar-gradient-md, .avatar-gradient-lg, .avatar-gradient-xl,
	.avatar-square-gradient-min, .avatar-square-gradient, .avatar-square-gradient-sm, .avatar-square-gradient-md, .avatar-square-gradient-lg, .avatar-square-gradient-xl {
		@apply gradient-key p-border-button;
	}

	/* ---------------------------- */
	/* ANCHOR Buttons & Forms		*/
	/* ---------------------------- */


	button.primary, button.secondary, a.button-primary, a.button-secondary, a.button-primary-gradient, button.primary-gradient, a.button-secondary-gradient, button.secondary-gradient, a.button-primary-gradient-alt, button.primary-gradient-alt, a.button-secondary-gradient-alt, button.secondary-gradient-alt, button.icon-dropdown, button.icon-dropdown-gradient, button.icon-dropdown-gradient-alt,
	.button-dropdown-gradient-alt.secondary {
		@apply rounded-full config-focus-set config-transition-set;
	}

	a.button-icon-round-gradient-alt, button.icon-round-gradient-alt {
		@apply rounded-full config-focus-set config-transition-set;
	}

	a.button-primary-gradient span, button.primary-gradient span, a.button-secondary-gradient span, button.secondary-gradient span, a.button-primary-gradient-alt span, button.primary-gradient-alt span, a.button-secondary-gradient-alt span, button.secondary-gradient-alt span, button.icon-dropdown-gradient span, button.icon-dropdown-gradient-alt span, .button-dropdown-gradient-alt.secondary span {
		@apply rounded-full config-transition-set;
	}

	a.button-icon-round-gradient-alt span, button.icon-round-gradient-alt span {
		@apply rounded-full config-transition-set;
	}



	a.button-primary-gradient p, button.primary-gradient p, a.button-primary-gradient p i, button.primary-gradient p i,
	a.button-secondary-gradient p, button.secondary-gradient p, a.button-secondary-gradient p i, button.secondary-gradient p i,
	a.button-primary-gradient-alt p, button.primary-gradient-alt p, a.button-secondary-gradient-alt p, button.secondary-gradient-alt p, button.icon-dropdown-gradient p, button.icon-dropdown-gradient-alt p, button.icon-dropdown-gradient i, button.icon-dropdown-gradient-alt i, a.chip-secondary-gradient p,
	.button-dropdown-gradient-alt.secondary p, .button-dropdown-gradient-alt.secondary i {
		@apply config-transition-set;
	}
	a.button-icon-round-gradient-alt i, button.icon-round-gradient-alt i  {
		@apply config-transition-set;
	}



	a.button-primary-gradient-alt::before, button.primary-gradient-alt::before, a.button-secondary-gradient-alt::before, button.secondary-gradient-alt::before {
		@apply rounded-full config-transition-set;
	}

	a.button-icon-round-gradient-alt::before, button.icon-round-gradient-alt::before {
		@apply rounded-full config-transition-set;
	}

	a.button-icon-round, button.icon-round, a.button-icon-round-gradient, button.icon-round-gradient {
		@apply rounded-full config-focus-set;
	}
	.button-icon.square.gradient {
		@apply rounded-base config-focus-set;
	}
	a.button-icon-round-gradient span, button.icon-round-gradient span, .form-input, .form-input-combo {
		@apply rounded-full;
	}
	.button-icon.square.gradient span {
		@apply rounded-base config-focus-set;
	}
	a.chip-primary-gradient, a.chip-secondary-gradient {
		@apply rounded-chip config-focus-set config-transition-set;
	}
	a.chip-primary-gradient span, a.chip-secondary-gradient span {
		@apply rounded-chip-inset config-transition-set;
	}

	button.filter-dropdown, button.filter-dropdown-gradient {
		@apply config-focus-set config-transition-set;
	}


	.dropdown-menu, .dropdown-menu-gradient {
		@apply rounded-corner shadow-base;
	}

	nav.pagination {
		@apply isolate inline-flex -space-x-px rounded-md;
	}
	nav.pagination a, nav.pagination span.pagination-ellipsis {
		@apply relative inline-flex items-center justify-center  p-base-unit text-p3 font-bold text-secondary ring-1 ring-inset ring-secondary/20 hover:ring-key hover:bg-key hover:text-invert hover:z-40 focus:z-20 focus:outline-offset-0 min-w-elementxx-base min-h-element-base;
	}
	nav.pagination a:active {
		@apply bg-key text-invert z-50;
	}
	nav.pagination a.pagination-extra {
		@apply hidden lg:inline-flex;
	}
	nav.pagination a.pagination-next {
		@apply rounded-r-corner;
	}
	nav.pagination a.pagination-previous {
		@apply rounded-l-corner;
	}

	nav.pagination-gradient {
		@apply isolate inline-flex -space-x-px rounded-corner;
	}
	nav.pagination-gradient a, nav.pagination-gradient span.pagination-ellipsis {
		@apply relative inline-flex items-center justify-center  p-base-unit text-p3 font-bold gradient-text-key ring-1 ring-inset ring-transparent focus:z-20 focus:outline-offset-0 min-w-element-base sm:min-w-elementxx-base min-h-element-base rounded-corner config-focus-set config-transition-set shadow-none;
	}
	nav.pagination-gradient a::before, nav.pagination-gradient span.pagination-ellipsis::before {
		@apply w-full h-full top-0 left-0 absolute bg-gradient-to-r from-key to-key-end rounded-corner config-transition-set;
		content: '';
		z-index: 0;
		opacity: 0;
	}
	nav.pagination-gradient a:hover, nav.pagination-gradient span.pagination-ellipsis:hover {
		@apply z-50 shadow-light;
	}
	nav.pagination-gradient a:hover::before, nav.pagination-gradient span.pagination-ellipsis:hover::before {
		z-index: 30;
		opacity: calc(1 - var(--color-key-gradient-invert-opacity));
	}
	nav.pagination-gradient a:active {
		@apply bg-key text-invert bg-clip-border z-40;
	}
	nav.pagination-gradient a.pagination-extra {
		@apply hidden lg:inline-flex;
	}
	nav.pagination-gradient a.pagination-next {
		@apply rounded-r-corner;
	}
	nav.pagination-gradient a.pagination-previous {
		@apply rounded-l-corner;
	}


	.button-gradient-air {
		@apply relative inline-flex items-center justify-center  p-base-unit gradient-text-key ring-1 ring-inset ring-transparent min-w-element-base h-element-base rounded-corner config-focus-set config-transition-set shadow-none;
	}
	a.button-icon-square-gradient-air {
		@apply relative inline-flex items-center justify-center  p-base-unit text-p3 font-bold gradient-text-key ring-1 ring-inset ring-transparent w-element-base h-element-base rounded-corner config-focus-set config-transition-set shadow-none;
	}
	.button-gradient-air::before, a.button-icon-square-gradient-air::before {
		@apply w-full h-full top-0 left-0 absolute bg-gradient-to-r from-key to-key-end rounded-corner config-transition-set;
		content: '';
		z-index: 0;
		opacity: 0;
	}
	.button-gradient-air:hover, a.button-icon-square-gradient-air:hover {
		@apply gradient-text-key shadow-light;
	}
	a.button-icon-square-gradient-air:active {
		@apply bg-key text-invert bg-clip-border;
	}

	.button-gradient-air:hover::before, a.button-icon-square-gradient-air:hover::before {
		opacity: calc(1 - var(--color-key-gradient-invert-opacity));
	}

	a.button-icon-base-gradient {
		@apply gradient-text-key hover:opacity-90; active:gradient-text-key;
	}
	.form-input input, .form-input-file input, .form-input-dropdown span {
		@apply rounded-l-none rounded-r-full;
	}
	.form-input-file input {
		@apply rounded-full;
	}
	.form-input.unlabeled input {
		@apply rounded-l-full;
	}
	.form-input-dropdown.air span,
	.form-input.air input {
		@apply rounded-l-none rounded-r-none;
	}
	.form-input-combo input {
		@apply rounded-l-full rounded-r-none;
	}
	.form-input input:focus, .form-input-file input:focus, .form-input-combo input:focus, .form-textarea textarea:focus {
		@apply ring-transparent;
	}

	/* ---------------------------- */
	/* ANCHOR Other Elements		*/
	/* ---------------------------- */
	.icon-link i {
		@apply config-text-key mr-half-unit;
	}
	.chip, .chip-gradient {
		@apply rounded-chip config-focus-set config-transition-set;
	}

	/* -------------------------------- */
} /* Do not edit					*/
/* !LAYER components end			*/
/* -------------------------------------------------------------------------------------------- */
/* !SECTION  DF Components Radius & Transitions													*/
/* -------------------------------------------------------------------------------------------- */

/* -------------------------------------------------------------------------------------------- */
/* SECTION DF Components Visuals (Base Color Applications)										*/
/* -------------------------------------------------------------------------------------------- */
/* LAYER components begin 			*/
@layer components { /* Do not edit	*/
	/* ---------------------------------*/

	/* ---------------------------- */
	/* ANCHOR Containers			*/
	/* ---------------------------- */

	.card-transparent, .card-base-transparent {
		@apply bg-transparent;
	}

	/* ---------------------------- */
	/* ANCHOR Buttons & Forms		*/
	/* ---------------------------- */

	/* SECTION COMBO BUTTON CLASSES ----------------------------------------- */

	/* Base */

	.button {
		@apply relative
		flex justify-center items-center
		h-element-base rounded-full
		p-border-button
		text-button text-center
		ring-button ring-inset
		config-focus-set config-transition-set;
		z-index:1;
	}
	/* TODO Need to resolve standard i position in span vs in p element to ensure vertical spacing alignment. */

	.button > span {
		@apply flex items-center justify-center px-element-base
		w-full h-full rounded-full
		config-transition-set;
	}
	.button i {
		@apply
		text-button
		config-transition-set;
	}
	.button > span > i:first-child {
		@apply
		pr-base-unit;
	}
	.button > span > i:last-child {
		@apply
		pl-base-unit;
	}

	/* TODO REMOVE THIS OVERRIDE AFTER OLD BUTTONS ARE CONVERTED */
	button.button.secondary { @apply px-border-button; }

	.form-input.gradient,
	.form-input-file.gradient { @apply p-border-button relative; }

	.form-input.gradient > span,
	.form-input-file.gradient > span {
		@apply rounded-full;
	}

	.form-input.gradient::before,
	.form-input-file.gradient::before,
	.button.gradient::before {
		@apply absolute top-0 left-0
		w-full h-full rounded-full
		config-transition-set;
		content: '';
		z-index: 0;
	}
	.form-input.gradient,
	.form-input-file.gradient,
	.button.gradient {
		@apply
		ring-0;
	}
	.form-input.gradient > span,
	.form-input-file.gradient > span,
	.button.gradient > span {
		@apply relative;
		z-index: 1;
	}
	.button.gradient > span > p {
		@apply
		config-transition-set;
	}

	/* Colors */

	.button,
	.button.secondary:hover, .button.secondary.active {
		@apply
		text-invert
		bg-key
		ring-key;
	}
	.button p, .button i {
		@apply
		text-invert
		whitespace-nowrap;
	}
	.button:hover, .button.active,
	.button.secondary {
		@apply
		text-key
		bg-transparent;
	}
	.button:hover p, .button:hover i {
		@apply
		text-key;
	}

	.form-input.gradient::before,
	.form-input-file.gradient::before,
	.button.gradient::before {
		@apply bg-gradient-to-r from-key to-key-end;
	}
	.form-input.gradient,
	.form-input-file.gradient,
	.button.gradient {
		@apply
		bg-transparent;
	}

	.button.gradient > span,
	.button.gradient.secondary:hover > span,
	.button.gradient.secondary.active > span {
		@apply
		bg-key-gradient-invert
		bg-opacity-0;
	}

	.form-input.gradient > span,
	.form-input-file.gradient > span,
	.button.gradient:hover > span, .button.gradient.active > span,
	.button.gradient.secondary > span {
		@apply
		bg-key-gradient-invert
		bg-opacity-gradient-invert;
	}
	.button.gradient > span p, .button.gradient > span i,
	.button.gradient.secondary:hover > span p, .button.gradient.secondary:hover > span i {
		@apply
		text-invert;
	}
	.button.gradient:hover > span p, .button.gradient:hover > span i,
	.button.gradient.secondary > span p, .button.gradient.secondary > span i {
		@apply
		gradient-text-key;
	}

	/* Sizing */
	.button.sm {
		@apply h-element-min text-p4-size leading-p3 text-center;
	}
	.button.md {
		@apply h-element-minxx text-p3-size leading-h3 text-center;
	}

	/* Shaping */
	.button.rounded-corners, .button.rounded-corners::before {
		@apply rounded-corner;
	}
	.button.rounded-corners span {
		@apply rounded-corner-inset;
	}
	.button.rounded-corners-base, .button.rounded-corners-base::before {
		@apply rounded-base;
	}
	.button.rounded-corners-base span {
		@apply rounded-base-inset;
	}

	/* Spacing */
	.button.dropdown > span,
	.button.distribute > span {
		@apply justify-between;
	}
	.button.dropdown > span > i:first-child {
		@apply w-element-base pr-0 text-left;
	}
	.button.dropdown > span > i:last-child {
		@apply w-element-base pl-0 text-right;
	}
	.button.centered.dropdown > span > p {
		@apply w-full pl-element-base-unit;
	}


	/* !SECTION COMBO BUTTON CLASSES ----------------------------------------- */



	button.primary, button.secondary, a.button-primary, a.button-secondary {
		@apply ring-key;
	}
	button.primary, a.button-primary {
		@apply text-invert bg-key;
	}

	button.primary:hover, a.button-primary:hover {
		@apply text-key bg-key/5;
	}
	button.secondary, a.button-secondary {
		@apply text-key bg-transparent;
	}
	button.secondary:hover, a.button-secondary:hover {
		@apply text-invert bg-key/95;
	}




	a.button-primary-gradient, button.primary-gradient, a.button-secondary-gradient, button.secondary-gradient, button.icon-dropdown-gradient, button.icon-dropdown-gradient-alt,
	.button-dropdown-gradient-alt.secondary,
	a.chip-primary-gradient, a.chip-secondary-gradient {
		@apply bg-gradient-to-r from-key to-key-end;
	}

	a.button-primary-gradient span, button.primary-gradient span, a.button-icon-round-gradient > span, button.icon-round-gradient > span, a.chip-primary-gradient span,
	.button-icon.square.gradient > span {
		@apply bg-key-gradient-invert bg-opacity-0;
	}

	a.button-primary-gradient:hover span, button.primary-gradient:hover span,
	a.button-icon-round-gradient:hover span, button.icon-round-gradient:hover span,
	button.icon-dropdown-gradient-alt:hover span,
	.button-dropdown-gradient-alt.secondary:hover span,
	a.chip-primary-gradient:hover span,
	.button-icon.square.gradient:hover span {
		@apply bg-opacity-gradient-invert;
	}
	button.icon-dropdown-gradient,
	button.icon-dropdown-gradient-alt {
		@apply ring-0;
	}
	.button-dropdown-gradient-alt.secondary span,
	.icon-dropdown-gradient-alt.secondary span,
	.button-dropdown-gradient-alt.secondary span {
		@apply bg-opacity-gradient-invert;
	}
	.icon-dropdown-gradient-alt.secondary span:hover, .icon-dropdown-gradient-alt.secondary.active span {
		@apply bg-invert opacity-95;
	}
	a.button-primary-gradient p, button.primary-gradient p, a.button-primary-gradient p i, button.primary-gradient p i,
	a.button-primary-gradient-alt p, button.primary-gradient-alt p,
	a.button-secondary-gradient-alt p, button.secondary-gradient-alt p,
	a.button-secondary-gradient:hover p, button.secondary-gradient:hover p, a.button-secondary-gradient:hover p i, button.secondary-gradient:hover p i,
	button.icon-dropdown-gradient p, button.icon-dropdown-gradient i,
	button.icon-dropdown-gradient.secondary:hover p, button.icon-dropdown:hover p, button.icon-dropdown-gradient.secondary:hover i,
	a.chip-primary-gradient p, a.chip-secondary-gradient:hover p,
	a.button-icon-round-gradient-alt p, button.icon-round-gradient-alt p {
		@apply text-invert;
	}
	a.button-primary-gradient:hover p, button.primary-gradient:hover p,
	a.button-primary-gradient:hover p i, button.primary-gradient:hover p i,
	a.button-secondary-gradient p, button.secondary-gradient p, a.button-secondary-gradient p i, button.secondary-gradient p i,
	a.button-icon-round-gradient p, button.icon-round-gradient p,
	a.button-icon-round-gradient p i, button.icon-round-gradient p i,
	button.icon-dropdown-gradient:hover p, button.icon-dropdown-gradient:hover i,
	button.icon-dropdown-gradient.secondary p, button.icon-dropdown-gradient-alt p,
	button.icon-dropdown-gradient.secondary i, button.icon-dropdown-gradient-alt i,
	.button-dropdown-gradient-alt.secondary p, .button-dropdown-gradient-alt.secondary i,
	a.chip-primary-gradient:hover p, a.chip-secondary-gradient p,
	.button-icon.square.gradient p, .button-icon.square.gradient p i {
		@apply gradient-text-key;
	}

	nav a.button-icon-round-gradient p i, button.icon-round-gradient p i,
	nav a.button-icon-round-gradient p, button.icon-round-gradient p  {
		@apply text-secondary bg-none;
	}

	a.button-secondary-gradient span, button.secondary-gradient span,
	button.icon-dropdown-gradient:hover span, button.icon-dropdown-gradient.secondary span,
	a.chip-secondary-gradient span {
		@apply bg-key-gradient-invert bg-opacity-gradient-invert;
	}
	a.button-secondary-gradient:hover span, button.secondary-gradient:hover span, button.icon-dropdown-gradient.secondary:hover span, a.chip-secondary-gradient:hover span {
		@apply bg-opacity-0;
	}

	a.button-primary-gradient-alt, button.primary-gradient-alt,
	a.button-secondary-gradient-alt, button.secondary-gradient-alt,
	a.button-icon-round-gradient-alt, button.icon-round-gradient-alt {
		@apply bg-transparent;
	}

	a.button-primary-gradient-alt span, button.primary-gradient-alt span {
		@apply ring-transparent;
	}

	a.button-primary-gradient-alt span:hover, button.primary-gradient-alt span:hover {
		@apply ring-key;
	}


	a.button-primary-gradient-alt::before, button.primary-gradient-alt::before {
		@apply bg-gradient-to-r from-key to-key-end;
		opacity: 1;
	}

	a.button-primary-gradient-alt:hover::before, button.primary-gradient-alt:hover::before {
		opacity: calc(1 - var(--color-key-gradient-invert-opacity));
	}
	a.button-secondary-gradient-alt span, button.secondary-gradient-alt span {
		@apply ring-key;
	}
	a.button-secondary-gradient-alt span:hover, button.secondary-gradient-alt span:hover {
		@apply ring-key;
	}
	a.button-secondary-gradient-alt::before, button.secondary-gradient-alt::before {
		@apply bg-gradient-to-r from-key to-key-end;
		opacity: calc(1 - var(--color-key-gradient-invert-opacity));
	}

	a.button-icon-round-gradient-alt::before, button.icon-round-gradient-alt::before {
		@apply bg-gradient-to-r from-key to-key-end;
		opacity:0;
	}
	a.button-secondary-gradient-alt:hover::before, button.secondary-gradient-alt:hover::before {
		opacity: 1;
	}
	a.button-icon-round-gradient-alt:hover::before, button.icon-round-gradient-alt:hover::before {
		@apply ring-key;
		opacity: calc(1 - var(--color-key-gradient-invert-opacity));
	}
	a.button-icon-round-gradient-alt:hover span, button.icon-round-gradient-alt:hover span {
		@apply ring-key;
	}

	a.button-icon-round, button.icon-round, button.icon-dropdown p {
		@apply text-key;
	}
	a.button-icon-round:hover, button.icon-round:hover {
		@apply ring-key;
	}

	a.button-icon-round-gradient, button.icon-round-gradient,
	.button-icon.square.gradient {
		@apply bg-gradient-to-r from-key/0 to-key-end/0;
	}
	a.button-icon-round-gradient:hover, button.icon-round-gradient:hover,
	.button-icon.square.gradient:hover {
		@apply bg-gradient-to-r from-key to-key-end;
	}

	button.filter-dropdown {
		@apply text-key;
	}
	button.filter-dropdown:hover {
		@apply text-key-end;
	}
	button.filter-dropdown-gradient {
		@apply gradient-text-key;
	}
	button.filter-dropdown-gradient:hover {
		@apply opacity-gradient-active;
	}

	button.icon-dropdown {
		@apply text-key ring-key;
	}
	button.icon-dropdown:hover {
		@apply bg-key text-invert;
	}
	.button-dropdown-gradient-alt span,
	button.icon-dropdown-gradient-alt span {
		@apply bg-key-gradient-invert bg-opacity-100;
	}
	.dropdown-menu, .dropdown-menu-gradient {
		@apply bg-default ring-secondary;
	}
	.dropdown-menu p.title, .dropdown-menu-gradient p.title {
		@apply text-secondary;
	}
	.dropdown-menu button, .dropdown-menu a, .dropdown-menu-gradient button, .dropdown-menu-gradient a, .dropdown-menu-gradient li {
		@apply text-primary;
	}
	.dropdown-menu button:hover, .dropdown-menu a:hover {
		@apply text-invert bg-key;
	}
	.dropdown-menu-gradient button:hover, .dropdown-menu-gradient a:hover, .dropdown-menu-gradient li:hover {
		@apply text-invert gradient-key;
		/* Use bg-key instead of gradient-key for solid color background */
	}

	.form-input label,
	.form-input-file label {
		@apply text-key;
	}
	.form-input-dropdown.air span,
	.form-input.air input, .form-textarea.air textarea {
		@apply bg-invert-shader;
	}
	.form-input-dropdown.air span:hover,
	.form-input.air input:hover, .form-input.air input:focus, .form-textarea.air textarea:hover, .form-textarea.air textarea:focus {
		@apply border-b border-x-0 border-t-0 border-key;
	}
	.form-input, .form-input-combo, .form-textarea {
		@apply ring-key bg-transparent;
	}
	.form-input input, .form-input-file input, .form-input-combo input, .form-textarea textarea {
		@apply bg-transparent;
	}
	.form-input-dropdown span:hover,
	.form-input input:hover, .form-input input:focus,
	.form-input-file input:hover, .form-input-file input:focus,
	.form-input-combo input:hover, .form-input-combo input:focus, .form-textarea textarea:hover, .form-textarea textarea:focus {
		@apply bg-invert-shader;
	}

	/* ---------------------------- */
	/* ANCHOR Alerts				*/
	/* ---------------------------- */

	.block-callout {
		@apply text-invert gradient-key;
	}
	.block-callout hgroup h3 {
		@apply text-invert;
	}

	/* ---------------------------- */
	/* ANCHOR Other Elements		*/
	/* ---------------------------- */

	.chip {
		@apply ring-key bg-key text-invert ring-button ring-inset;
	}
	.chip:hover {
		@apply text-key bg-key/5;
	}
	.chip.secondary {
		@apply ring-key bg-transparent text-key ring-button ring-inset;
	}
	.chip.secondary:hover {
		@apply bg-key text-invert;
	}
	.chip-gradient {
		@apply gradient-key text-invert bg-clip-border;
	}
	.chip-gradient:hover {
		@apply text-invert;
	}

	.toggle-menu i, .toggle-menu:link i, .toggle-menu:visited i, .toggle-menu:focus i  {
		@apply text-secondary;
	}

	.toggle-menu:hover i {
		@apply config-hlink-base;
	}

	.toggle-menu:active i {
		@apply config-hlink-active;
	}

	.toggle-menu-gradient i, .toggle-menu-gradient:link i, .toggle-menu-gradient:visited i, .toggle-menu-gradient:focus i  {
		@apply text-secondary;
	}

	.toggle-menu-gradient:hover i {
		@apply config-hlink-base;
	}

	.toggle-menu-gradient:active i {
		@apply config-hlink-active;
	}

	/* -------------------------------- */
} /* Do not edit					*/
/* !LAYER components end			*/
/* -------------------------------------------------------------------------------------------- */
/* !SECTION DF Components Visuals (Base Color Applications)										*/
/* -------------------------------------------------------------------------------------------- */

/* -------------------------------------------------------------------------------------------- */
/* SECTION DF Theming Variants																	*/
/* -------------------------------------------------------------------------------------------- */
/* LAYER components begin 			*/
@layer components { /* Do not edit	*/
	/* ---------------------------------*/

	/* IMPORTANT Only change the CSS below if you changed the default screen values or if you want to change the theme color logic. */

	/* -------------------------------------------------------------------- */
	/* SECTION Global														*/
	/* -------------------------------------------------------------------- */

	/* ---------------------------- */
	/* ANCHOR Text					*/
	/* ---------------------------- */

	h1, h2 ,h3 ,h4 ,h5, h6 {
		@apply config-text-key;
	}

	h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
		@apply config-hlink-base;
	}
	h1 a:link, h2 a:link, h3 a:link, h4 a:link, h5 a:link, h6 a:link, h1 a:visited, h2 a:visited, h3 a:visited, h4 a:visited, h5 a:visited, h6 a:visited, h1 a:focus, h2 a:focus, h3 a:focus, h4 a:focus, h5 a:focus, h6 a:focus {
		@apply config-hlink-base;
	}
	h1 a:hover, h2 a:hover, h3 a:hover, h4 a:hover, h5 a:hover, h6 a:hover {
		@apply config-hlink-hover;
	}
	h1 a:active, h2 a:active, h3 a:active, h4 a:active, h5 a:active, h6 a:active {
		@apply config-hlink-active;
	}

	p a {
		@apply config-plink-base;
	}
	p a:link, p a:visited, p a:focus {
		@apply config-plink-base;
	}
	p a:hover {
		@apply config-plink-hover;
	}
	p a:active {
		@apply config-plink-active;
	}

	/* -------------------------------------------------------------------- */
	/* !SECTION Global														*/
	/* -------------------------------------------------------------------- */


	/* -------------------------------------------------------------------- */
	/* SECTION Theme & Theme Transparent									*/
	/* -------------------------------------------------------------------- */

	/* Currently, no theme/theme-transparent overrides of DF Core/Component Visuals styles are needed here. */

	/* -------------------------------------------------------------------- */
	/* !SECTION Theme & Theme Transparent									*/
	/* -------------------------------------------------------------------- */


	/* -------------------------------------------------------------------- */
	/* SECTION Theme Invert & Theme Invert Transparent						*/
	/* -------------------------------------------------------------------- */

& .theme-invert, .theme-invert-transparent {

	/* ---------------------------- */
	/* ANCHOR Buttons & Forms		*/
	/* ---------------------------- */
	a.button-primary-gradient span, button.primary-gradient span, a.button-icon-round-gradient span, button.icon-round-gradient span,
	.button-icon.square.gradient span {
		@apply bg-primary bg-opacity-0;
	}
	a.button-primary-gradient:hover span, button.primary-gradient:hover span, a.button-icon-round-gradient:hover span, button.icon-round-gradient:hover span, button.icon-dropdown-gradient-alt:hover span,
	.button-dropdown-gradient-alt.secondary:hover span,
	.button-icon.square.gradient:hover span {
		@apply bg-primary bg-opacity-gradient-invert;
	}
	a.button-primary-gradient p, button.primary-gradient p, a.button-primary-gradient p i, button.primary-gradient p i,
	a.button-primary-gradient-alt p, button.primary-gradient-alt p, a.button-secondary-gradient-alt p, button.secondary-gradient-alt p,
	a.button-secondary-gradient:hover p, button.secondary-gradient:hover p, a.button-secondary-gradient:hover p i, button.secondary-gradient:hover p i,
	button.icon-dropdown-gradient.secondary:hover p, button.icon-dropdown:hover p, button.icon-dropdown-gradient.secondary:hover i, a.chip-secondary-gradient:hover p {
		@apply text-invert;
	}
	a.button-primary-gradient:hover p, button.primary-gradient:hover p, a.button-primary-gradient:hover p i, button.primary-gradient:hover p i,
	a.button-secondary-gradient p, button.secondary-gradient p, a.button-secondary-gradient p i, button.secondary-gradient p i,
	a.button-icon-round-gradient p, button.icon-round-gradient p, button.icon-dropdown-gradient.secondary p, button.icon-dropdown-gradient-alt p, button.icon-dropdown-gradient.secondary i, button.icon-dropdown-gradient-alt i,
	.button-dropdown-gradient-alt.secondary p, .button-dropdown-gradient-alt.secondary i,
	a.chip-secondary-gradient p,
	.button-icon.square.gradient p {
		@apply gradient-text-key;
	}
	a.button-secondary-gradient span, button.secondary-gradient span, button.icon-dropdown-gradient.secondary span, a.chip-secondary-gradient span {
		@apply bg-primary bg-opacity-gradient-invert;
	}
	a.button-secondary-gradient:hover span, button.secondary-gradient:hover span, button.icon-dropdown-gradient.secondary:hover span, a.chip-secondary-gradient:hover span {
		@apply bg-opacity-0;
	}
	button.icon-dropdown-gradient-alt span {
		@apply bg-primary bg-opacity-100;
	}
	.form-input-dropdown span:hover,
	.form-input input:hover, .form-input input:focus,
	.form-input-file input:hover, .form-input-file input:focus,
	.form-input-combo input:hover, .form-input-combo input:focus {
		@apply gradient-key-opacity-invert;
	}

}
/* -------------------------------------------------------------------- */
/* !SECTION Theme Invert & Theme Invert Transparent						*/
/* -------------------------------------------------------------------- */

/* BOOKMARK -CURRENT- */

/* -------------------------------------------------------------------- */
/* SECTION Theme Key & Theme Key Gradient								*/
/* -------------------------------------------------------------------- */

/* TODO Components Key Variants */

/* -------------------------------------------------------------------- */
/* !SECTION Theme Key & Theme Key Gradient								*/
/* -------------------------------------------------------------------- */

/* -------------------------------------------------------------------- */
/* SECTION Theme Alt & Theme Alt Transparent							*/
/* -------------------------------------------------------------------- */

/* TODO Components Alt Variants */

/* -------------------------------------------------------------------- */
/* !SECTION Theme Alt & Theme Alt Transparent							*/
/* -------------------------------------------------------------------- */

/* -------------------------------------------------------------------- */
/* SECTION Theme Alt Invert & Theme Alt Invert Transparent				*/
/* -------------------------------------------------------------------- */

/* TODO Components Alt Invert Variants */

/* -------------------------------------------------------------------- */
/* !SECTION Theme Alt Invert & Theme Alt Invert Transparent				*/
/* -------------------------------------------------------------------- */

/* -------------------------------------------------------------------- */
/* SECTION Theme Key Alt & Theme Key Alt Gradient						*/
/* -------------------------------------------------------------------- */

/* TODO Components Key Alt Variants */

/* -------------------------------------------------------------------- */
/* !SECTION Theme Key & Theme Key Gradient								*/
/* -------------------------------------------------------------------- */

/* -------------------------------- */
} /* Do not edit					*/
/* !LAYER components end			*/
/* -------------------------------------------------------------------------------------------- */
/* !SECTION DF Theming Variants																	*/
/* -------------------------------------------------------------------------------------------- */

/* -------------------------------------------------------------------------------------------- */
/* SECTION DF Adaptives (Screen Media Queries)													*/
/* -------------------------------------------------------------------------------------------- */
/* LAYER components begin 			*/
@layer components { /* Do not edit 	*/
	/* -------------------------------- */

	/* --------------------------------------------------------------------	*/
	/* SECTION UTILITIES - ALL SCREENS										*/
	/* DESIGNFRAME ADAPTIVE - Do not edit section 							*/
	/* --------------------------------------------------------------------	*/
	.mobile-visible {
		@apply visible sm:invisible:
	}
	.mobile-invisible {
		@apply invisible sm:visible:
	}
	.mobile-none-block {
		@apply hidden sm:block;
	}
	.mobile-none-flex {
		@apply hidden sm:flex;
	}
	.mobile-only-block {
		@apply block sm:hidden;
	}
	.mobile-1 {
		@apply order-1 sm:order-none;
	}
	.mobile-2 {
		@apply order-2 sm:order-none;
	}
	.mobile-3 {
		@apply order-3 sm:order-none;
	}
	.mobile-4 {
		@apply order-4 sm:order-none;
	}
	.mobile-5 {
		@apply order-5 sm:order-none;
	}
	.mobile-6 {
		@apply order-6 sm:order-none;
	}
	.mobile-7 {
		@apply order-7 sm:order-none;
	}
	.mobile-8 {
		@apply order-8 sm:order-none;
	}
	.mobile-9 {
		@apply order-9 sm:order-none;
	}
	.mobile-10 {
		@apply order-10 sm:order-none;
	}
	.mobile-11 {
		@apply order-11 sm:order-none;
	}
	.mobile-12 {
		@apply order-12 sm:order-none;
	}

	/* --------------------------------------------------------------------	*/
	/* /DESIGNFRAME ADAPTIVE - Do not edit section 							*/
	/* !SECTION UTILITIES - ALL SCREENS										*/
	/* --------------------------------------------------------------------	*/

	/* TODO Re-Factor Adaptives to use TW Screen Prefixes */

	/* --------------------------------------------------------------------	*/
	/* SECTION BASE (<640px & undefined) Screens 										*/
	/* DESIGNFRAME ADAPTIVE - Do not edit section 							*/
	/* --------------------------------------------------------------------	*/

	header {
		@apply px-shoulder-base py-header-base;
	}
	.header-base {
		@apply px-0 py-0;
	}
	.header-grid {
		@apply grid-cols-base gap-x-gutter-base;
	}
	.header-flex nav {
		@apply gap-x-gutter-base;
	}
	footer {
		@apply px-shoulder-base py-section-base;
	}
	.footer-base {
		@apply px-0 py-0;
	}
	.footer-grid {
		@apply grid-cols-base gap-gutter-base;
	}
	.footer-flex nav {
		@apply gap-gutter-base;
	}
	.block-logo {
		@apply h-element-base items-center;
	}
	section {
		@apply px-shoulder-base py-section-base;
	}
	.section-grid, .centerframe-grid, .grid-base {
		@apply grid-cols-base gap-gutter-base;
	}
	.grid-cols {
		@apply grid-cols-base;
	}
	.section-flex, .centerframe-flex, .flex-base {
		@apply gap-gutter-base;
	}
	.mt-gutter {
		@apply mt-gutter-base;
	}
	.mb-gutter {
		@apply mb-gutter-base;
	}
	.ml-gutter {
		@apply ml-gutter-base;
	}
	.mr-gutter {
		@apply mr-gutter-base;
	}
	.pt-gutter {
		@apply pt-gutter-base;
	}
	.pb-gutter {
		@apply pb-gutter-base;
	}
	.pl-gutter {
		@apply pl-gutter-base;
	}
	.pr-gutter {
		@apply pr-gutter-base;
	}
	.mt-article {
		@apply mt-article-base;
	}
	.mb-article {
		@apply mb-article-base;
	}
	.ml-article {
		@apply ml-article-base;
	}
	.mr-article {
		@apply mr-article-base;
	}
	.gap-form {
		@apply gap-form-base;
	}
	.gap-gutter {
		@apply gap-gutter-base;
	}
	.gap-gutterx {
		@apply gap-gutterx-base;
	}
	.gap-x-gutterx {
		@apply gap-x-gutterx-base;
	}
	.gap-y-gutterx {
		@apply gap-y-gutterx-base;
	}
	.gap-gutterxx {
		@apply gap-gutterxx-base;
	}
	.gap-x-gutterxx {
		@apply gap-x-gutterxx-base;
	}
	.gap-y-gutterxx {
		@apply gap-y-gutterxx-base;
	}
	.gap-stack {
		@apply gap-stack-base;
	}
	.gap-y-stack {
		@apply gap-y-stack-base;
	}
	.gap-x-stack {
		@apply gap-x-stack-base;
	}
	.space-y-card {
		@apply space-y-card-base;
	}
	.space-x-card {
		@apply space-x-card-base;
	}
	.section-grid.gap-2gutter, .centerframe-grid.gap-2gutter, .grid-base.gap-2gutter, .section-flex.gap-2gutter, .centerframe-flex.gap-2gutter, .flex-base.gap-2gutter {
		@apply gap-2gutter-base;
	}
	.gap-2gutter {
		@apply gap-2gutter-base;
	}
	.gap-x-gutter {
		@apply gap-x-gutter-base;
	}
	.gap-y-gutter {
		@apply gap-y-gutter-base;
	}
	.gap-x-2gutter {
		@apply gap-x-2gutter-base;
	}
	.gap-y-2gutter {
		@apply gap-y-2gutter-base;
	}
	.section-grid .block-xs, .centerframe-grid .block-xs, .grid-base .block-xs {
		@apply col-base-xs;
	}
	.section-grid .block-sm, .centerframe-grid .block-sm, .grid-base .block-sm {
		@apply col-base-sm;
	}
	.section-grid .block-smx, .centerframe-grid .block-smx, .grid-base .block-smx {
		@apply col-base-smx;
	}
	.section-grid .block-md, .centerframe-grid .block-md, .grid-base .block-md {
		@apply col-base-md;
	}
	.section-grid .block-mdx, .centerframe-grid .block-mdx, .grid-base .block-mdx {
		@apply col-base-mdx;
	}
	.section-grid .block-lg, .centerframe-grid .block-lg, .grid-base .block-lg {
		@apply col-base-lg;
	}
	.section-grid .block-lgx, .centerframe-grid .block-lgx, .grid-base .block-lgx {
		@apply col-base-lgx;
	}
	.section-grid .block-xl, .centerframe-grid .block-xl, .grid-base .block-xl {
		@apply col-base-xl;
	}
	.section-grid .block-xlx, .centerframe-grid .block-xlx, .grid-base .block-xlx {
		@apply col-base-xlx;
	}
	.section-grid .block-2xl, .centerframe-grid .block-2xl, .grid-base .block-2xl {
		@apply col-base-2xl;
	}
	.section-grid .block-2xlx, .centerframe-grid .block-2xlx, .grid-base .block-2xlx {
		@apply col-base-2xlx;
	}
	.section-grid .block-full, .centerframe-grid .block-full, .grid-base .block-full {
		@apply col-span-full;
	}
	.section-flex .block-xs, .centerframe-flex .block-xs, .flex-base .block-xs {
		@apply shrink grow basis-base-xs;
	}
	.section-flex .block-sm, .centerframe-flex .block-sm, .flex-base .block-sm {
		@apply shrink grow basis-base-sm;
	}
	.section-flex .block-smx, .centerframe-flex .block-smx, .flex-base .block-smx {
		@apply shrink grow basis-base-smx;
	}
	.section-flex .block-md, .centerframe-flex .block-md, .flex-base .block-md {
		@apply shrink grow basis-base-md;
	}
	.section-flex .block-mdx, .centerframe-flex .block-mdx, .flex-base .block-mdx {
		@apply shrink grow basis-base-mdx;
	}
	.section-flex .block-lg, .centerframe-flex .block-lg, .flex-base .block-lg {
		@apply shrink grow basis-base-lg;
	}
	.section-flex .block-lgx, .centerframe-flex .block-lgx, .flex-base .block-lgx {
		@apply shrink grow basis-base-lgx;
	}
	.section-flex .block-xl, .centerframe-flex .block-xl, .flex-base .block-xl {
		@apply shrink grow basis-base-xl;
	}
	.section-flex .block-xlx, .centerframe-flex .block-xlx, .flex-base .block-xlx {
		@apply shrink grow basis-base-xlx;
	}
	.section-flex .block-2xl, .centerframe-flex .block-2xl, .flex-base .block-2xl {
		@apply shrink grow basis-base-2xl;
	}
	.section-flex .block-2xlx, .centerframe-flex .block-2xlx, .flex-base .block-2xlx {
		@apply shrink grow basis-base-2xlx;
	}
	.section-flex .block-full, .centerframe-flex .block-full {
		@apply shrink grow basis-full;
	}
	.rounded-card {
		@apply rounded-article-base;
	}
	.rounded-t-card {
		@apply rounded-t-article-base;
	}
	.rounded-b-card {
		@apply rounded-b-article-base;
	}
	.rounded-l-card {
		@apply rounded-l-article-base;
	}
	.rounded-r-card {
		@apply rounded-r-article-base;
	}
	.card {
		@apply rounded-article-base p-article-base;
	}
	.card-base {
		@apply rounded-article-base;
	}
	.block-base, .block-xs, .block-sm, .block-smx, .block-md, .block-mdx, .block-lg, .block-lgx, .block-xl, .block-xlx, .block-2xl, .block-2xlx, .block-full {
		@apply space-y-block-base;
	}
	.block-base.card, .block-xs.card, .block-sm.card, .block-smx.card, .block-md.card, .block-mdx.card, .block-lg.card, .block-lgx.card, .block-xl.card, .block-xlx.card, .block-2xl.card, .block-2xlx.card, .block-full.card {
		@apply space-y-card-base;
	}
	.block-base.card-base, .block-xs.card-base, .block-sm.card-base, .block-smx.card-base, .block-md.card-base, .block-mdx.card-base, .block-lg.card-base, .block-lgx.card-base, .block-xl.card-base, .block-xlx.card-base, .block-2xl.card-base, .block-2xlx.card-base, .block-full.card-base {
		@apply space-y-0;
	}
	.stack-y, .block-base.stack-y, .block-xs.stack-y, .block-sm.stack-y, .block-smx.stack-y, .block-md.stack-y, .block-mdx.stack-y, .block-lg.stack-y, .block-lgx.stack-y, .block-xl.stack-y, .block-xlx.stack-y, .block-2xl.stack-y, .block-2xlx.stack-y, .block-full.stack {
		@apply space-y-card-base;
	}
	.stack-x, .block-base.stack-x, .block-xs.stack-x, .block-sm.stack-x, .block-smx.stack-x, .block-md.stack-x, .block-mdx.stack-x, .block-lg.stack-x, .block-lgx.stack-x, .block-xl.stack-x, .block-xlx.stack-x, .block-2xl.stack-x, .block-2xlx.stack-x, .block-full.stack {
		@apply space-x-card-base;
	}
	.datarow, .formrow {
		@apply gap-x-stack-base gap-y-sub-base;
	}
	.card .datarow, .card-base .datarow,
	.card .formrow, .card-base .formrow {
		@apply gap-x-card-base gap-y-sub-base;
	}
	.stack-row {
		@apply flex flex-row flex-nowrap gap-x-stack-base;
	}
	.stack-row-wrap {
		@apply flex flex-row gap-x-stack-base gap-y-stack-base flex-wrap;
	}
	.stack-col {
		@apply flex flex-col gap-stack-base;
	}
	.stack-form-col {
		@apply flex flex-col gap-form-base;
	}
	.card .stack-row, .card-base .stack-row {
		@apply flex flex-row gap-x-card-base;
	}
	.card .stack-row-wrap, .card-base .stack-row-wrap {
		@apply flex flex-row gap-x-card-base gap-y-stack-base flex-wrap;
	}
	.card .stack-col, .card-base .stack-col {
		@apply flex flex-col gap-y-card-base;
	}
	.pr-col {
		@apply pr-col-base;
	}
	.pl-col {
		@apply pl-col-base;
	}
	.p-header-safeview {
		padding-top: max(var(--pad-header-base), env(safe-area-inset-top));
		padding-left: max(var(--spacing-shoulder-base), env(safe-area-inset-left));
		padding-right: max(var(--spacing-shoulder-base), env(safe-area-inset-right));
		padding-bottom: var(--pad-header-base);
	}
	.p-section-safeview {
		padding-top: max(var(--pad-section-base), env(safe-area-inset-top));
		padding-left: max(var(--spacing-shoulder-base), env(safe-area-inset-left));
		padding-right: max(var(--spacing-shoulder-base), env(safe-area-inset-right));
		padding-bottom: max(var(--pad-section-base), env(safe-area-inset-bottom));
	}
	.pt-section-safeview {
		padding-top: max(var(--pad-section-base), env(safe-area-inset-top));
	}
	.pb-section-safeview {
		padding-bottom: max(var(--pad-section-base), env(safe-area-inset-bottom));
	}
	.pl-section-safeview {
		padding-left: max(var(--spacing-shoulder-base), env(safe-area-inset-left));
	}
	.pr-section-safeview {
		padding-right: max(var(--spacing-shoulder-base), env(safe-area-inset-right));
	}
	.px-section-safeview, .px-section-safeview-base {
		padding-left: max(var(--spacing-shoulder-base), env(safe-area-inset-left));
		padding-right: max(var(--spacing-shoulder-base), env(safe-area-inset-right));
	}
	.py-section-safeview {
		padding-top: max(var(--pad-section-base), env(safe-area-inset-top));
		padding-bottom: max(var(--pad-section-base), env(safe-area-inset-bottom));
	}
	.p-footer-safeview {
		padding-top: var(--pad-section-base);
		padding-left: max(var(--spacing-shoulder-base), env(safe-area-inset-left));
		padding-right: max(var(--spacing-shoulder-base), env(safe-area-inset-right));
		padding-bottom: max(var(--pad-section-base), env(safe-area-inset-bottom));
	}
	.m-header-safeview {
		margin-top: max(var(--pad-header-base), env(safe-area-inset-top));
		margin-left: max(var(--spacing-shoulder-base), env(safe-area-inset-left));
		margin-right: max(var(--spacing-shoulder-base), env(safe-area-inset-right));
		margin-bottom: var(--pad-header-base);
	}
	.m-section-safeview {
		margin-top: max(var(--pad-section-base), env(safe-area-inset-top));
		margin-left: max(var(--spacing-shoulder-base), env(safe-area-inset-left));
		margin-right: max(var(--spacing-shoulder-base), env(safe-area-inset-right));
		margin-bottom: max(var(--pad-section-base), env(safe-area-inset-bottom));
	}
	.ml-section-safeview {
		margin-left: max(var(--spacing-shoulder-base), env(safe-area-inset-left));
	}
	.mr-section-safeview {
		margin-right: max(var(--spacing-shoulder-base), env(safe-area-inset-right));
	}
	.mt-section-safeview {
		margin-top: max(var(--pad-section-base), env(safe-area-inset-top));
	}
	.mb-section-safeview {
		margin-bottom: max(var(--pad-section-base), env(safe-area-inset-bottom));
	}
	.mx-section-safeview {
		margin-left: max(var(--spacing-shoulder-base), env(safe-area-inset-left));
		margin-right: max(var(--spacing-shoulder-base), env(safe-area-inset-right));
	}
	.my-section-safeview {
		margin-top: max(var(--pad-section-base), env(safe-area-inset-top));
		margin-bottom: max(var(--pad-section-base), env(safe-area-inset-bottom));
	}
	.m-footer-safeview {
		margin-top: var(--pad-section-base);
		margin-left: max(var(--spacing-shoulder-base), env(safe-area-inset-left));
		margin-right: max(var(--spacing-shoulder-base), env(safe-area-inset-right));
		margin-bottom: max(var(--pad-section-base), env(safe-area-inset-bottom));
	}
	.p-header {
		@apply px-shoulder-base py-header-base;
	}
	.px-header {
		@apply px-shoulder-base;
	}
	.py-header {
		@apply py-header-base;
	}
	.p-shoulder {
		@apply p-shoulder-base;
	}
	.pl-shoulder {
		@apply pl-shoulder-base;
	}
	.pr-shoulder {
		@apply pr-shoulder-base;
	}
	.p-section {
		@apply p-section-base;
	}
	.px-section {
		@apply px-section-base;
	}
	.py-section {
		@apply py-section-base;
	}
	.pt-section {
		@apply pt-section-base;
	}
	.pb-section {
		@apply pb-section-base;
	}
	.pl-section {
		@apply pl-section-base;
	}
	.pr-section {
		@apply pr-section-base;
	}
	.p-article {
		@apply p-article-base;
	}
	.px-article {
		@apply px-article-base;
	}
	.py-article {
		@apply py-article-base;
	}
	.p-block {
		@apply p-block-base;
	}
	.px-block {
		@apply px-block-base;
	}
	.py-block {
		@apply py-block-base;
	}
	.p-card {
		@apply p-card-base;
	}
	.px-card {
		@apply px-card-base;
	}
	.py-card {
		@apply py-card-base;
	}
	.p-element {
		@apply p-element-base;
	}
	.px-element {
		@apply px-element-base;
	}
	.py-element {
		@apply py-element-base;
	}
	.pl-element {
		@apply pl-element-base;
	}
	.pr-element {
		@apply pr-element-base;
	}
	.pt-element {
		@apply pt-element-base;
	}
	.pb-element {
		@apply pb-element-base;
	}
	.p-sub {
		@apply p-sub-base;
	}
	.pt-sub {
		@apply pt-sub-base;
	}
	.pb-sub {
		@apply pb-sub-base;
	}
	.pl-sub {
		@apply pl-sub-base;
	}
	.pr-sub {
		@apply pr-sub-base;
	}
	.px-sub {
		@apply px-sub-base;
	}
	.py-sub {
		@apply py-sub-base;
	}
	.p-base {
		@apply p-base-base;
	}
	.px-base {
		@apply px-base-base;
	}
	.py-base {
		@apply py-base-base;
	}
	.pl-half-gutter {
		@apply pl-half-gutter-base;
	}
	.pr-half-gutter {
		@apply pr-half-gutter-base;
	}
	.ml-half-gutter {
		@apply ml-half-gutter-base;
	}
	.mr-half-gutter {
		@apply mr-half-gutter-base;
	}
	.h-1row {
		@apply h-1row-base;
	}
	.h-2row {
		@apply h-2row-base;
	}
	.h-3row {
		@apply h-3row-base;
	}
	.h-4row {
		@apply h-4row-base;
	}
	.h-5row {
		@apply h-5row-base;
	}
	.h-6row {
		@apply h-6row-base;
	}
	.h-7row {
		@apply h-7row-base;
	}
	.h-8row {
		@apply h-8row-base;
	}
	.max-h-element {
		@apply max-h-element-max-base;
	}
	img.card, video.card, img.card-base, video.card-base {
		@apply p-0 space-y-0 space-x-0 object-cover object-center;
	}
	hgroup.display {
		@apply space-y-display;
	}
	hgroup.heading {
		@apply space-y-heading;
	}
	hgroup.title {
		@apply space-y-title;
	}
	.hgroup-display {
		@apply space-y-display;
	}
	.hgroup-heading {
		@apply space-y-heading;
	}
	.hgroup-title {
		@apply space-y-title;
	}

	/* ---------------------------- */
	/* ANCHOR Adaptive Text			*/
	/* ---------------------------- */
	.text-d1 {
		font-size: var(--text-d1a-size) !important;
		line-height: var(--text-d1a-lh) !important;
	}
	.text-d2 {
		font-size: var(--text-d2a-size) !important;
		line-height: var(--text-d2a-lh) !important;
	}
	.text-d3 {
		font-size: var(--text-d3a-size) !important;
		line-height: var(--text-d3a-lh) !important;
	}
	.text-i1 {
		font-size: var(--text-i1a-size) !important;
		line-height: var(--text-i1a-lh) !important;
	}
	h1 {
		@apply text-h1a;
	}
	header nav a.nav, header nav a:link.nav, header nav a:visited {
		@apply text-nava;
	}
	footer nav a.nav, footer nav a:link.nav, footer nav a:visited {
		@apply text-nava-size leading-p2;
	}

	/* --------------------------------------------------------------------	*/
	/* /DESIGNFRAME ADAPTIVE - Do not edit section 							*/
	/* !SECTION BASE (<640px) Screens 										*/
	/* --------------------------------------------------------------------	*/

	/* --------------------------------------------------------------------	*/
	/* SECTION SM (640px+) Screens											*/
	/* DESIGNFRAME ADAPTIVE - Do not edit section 							*/
	/* --------------------------------------------------------------------	*/

	@media (min-width:640px) { /* NOTE SCREEN SM CONFIG: You must manually set the min-width: here to match your --screen-sm value if you changed it from the preset Designframe Preferred Value. */

		header {
			@apply px-shoulder-sm py-header-sm;
		}
		.header-base {
			@apply px-0 py-0;
		}
		.header-grid nav {
			@apply grid-cols-sm gap-x-gutter-sm;
		}
		.header-flex nav {
			@apply gap-x-gutter-sm;
		}
		footer {
			@apply px-shoulder-sm py-section-sm;
		}
		.footer-base {
			@apply px-0 py-0;
		}
		.footer-grid {
			@apply grid-cols-sm gap-gutter-sm;
		}
		.footer-flex nav {
			@apply gap-gutter-sm;
		}
		section {
			@apply px-shoulder-sm py-section-sm;
		}
		.section-grid, .centerframe-grid, .grid-base {
			@apply grid-cols-sm gap-gutter-sm;
		}
		.grid-cols {
			@apply grid-cols-sm;
		}
		.section-flex, .centerframe-flex, .flex-base {
			@apply gap-gutter-sm;
		}
		.mt-gutter {
			@apply mt-gutter-sm;
		}
		.mb-gutter {
			@apply mb-gutter-sm;
		}
		.ml-gutter {
			@apply ml-gutter-sm;
		}
		.mr-gutter {
			@apply mr-gutter-sm;
		}
		.pt-gutter {
			@apply pt-gutter-sm;
		}
		.pb-gutter {
			@apply pb-gutter-sm;
		}
		.pl-gutter {
			@apply pl-gutter-sm;
		}
		.pr-gutter {
			@apply pr-gutter-sm;
		}
		.mt-article {
			@apply mt-article-sm;
		}
		.mb-article {
			@apply mb-article-sm;
		}
		.ml-article {
			@apply ml-article-sm;
		}
		.mr-article {
			@apply mr-article-sm;
		}
		.gap-form {
			@apply gap-form-sm;
		}
		.gap-gutter {
			@apply gap-gutter-sm;
		}
		.gap-gutterx {
			@apply gap-gutterx-sm;
		}
		.gap-x-gutterx {
			@apply gap-x-gutterx-sm;
		}
		.gap-y-gutterx {
			@apply gap-y-gutterx-sm;
		}
		.gap-gutterxx {
			@apply gap-gutterxx-sm;
		}
		.gap-x-gutterxx {
			@apply gap-x-gutterxx-sm;
		}
		.gap-y-gutterxx {
			@apply gap-y-gutterxx-sm;
		}
		.gap-stack {
			@apply gap-stack-sm;
		}
		.gap-y-stack {
			@apply gap-y-stack-sm;
		}
		.gap-x-stack {
			@apply gap-x-stack-sm;
		}
		.space-y-card {
			@apply space-y-card-sm;
		}
		.space-x-card {
			@apply space-x-card-sm;
		}
		.section-grid.gap-2gutter, .centerframe-grid.gap-2gutter, .grid-base.gap-2gutter, .section-flex.gap-2gutter, .centerframe-flex.gap-2gutter, .flex-base.gap-2gutter {
			@apply gap-2gutter-sm;
		}
		.gap-2gutter {
			@apply gap-2gutter-sm;
		}
		.gap-x-gutter {
			@apply gap-x-gutter-sm;
		}
		.gap-y-gutter {
			@apply gap-y-gutter-sm;
		}
		.gap-x-2gutter {
			@apply gap-x-2gutter-sm;
		}
		.gap-y-2gutter {
			@apply gap-y-2gutter-sm;
		}
		.section-grid .block-xs, .centerframe-grid .block-xs, .grid-base .block-xs {
			@apply col-sm-xs;
		}
		.section-grid .block-sm, .centerframe-grid .block-sm, .grid-base .block-sm {
			@apply col-sm-sm;
		}
		.section-grid .block-smx, .centerframe-grid .block-smx, .grid-base .block-smx {
			@apply col-sm-smx;
		}
		.section-grid .block-md, .centerframe-grid .block-md, .grid-base .block-md {
			@apply col-sm-md;
		}
		.section-grid .block-mdx, .centerframe-grid .block-mdx, .grid-base .block-mdx {
			@apply col-sm-mdx;
		}
		.section-grid .block-lg, .centerframe-grid .block-lg, .grid-base .block-lg {
			@apply col-sm-lg;
		}
		.section-grid .block-lgx, .centerframe-grid .block-lgx, .grid-base .block-lgx {
			@apply col-sm-lgx;
		}
		.section-grid .block-xl, .centerframe-grid .block-xl, .grid-base .block-xl {
			@apply col-sm-xl;
		}
		.section-grid .block-xlx, .centerframe-grid .block-xlx, .grid-base .block-xlx {
			@apply col-sm-xlx;
		}
		.section-grid .block-2xl, .centerframe-grid .block-2xl, .grid-base .block-2xl {
			@apply col-sm-2xl;
		}
		.section-grid .block-2xlx, .centerframe-grid .block-2xlx, .grid-base .block-2xlx {
			@apply col-sm-2xlx;
		}
		.section-grid .block-full, .centerframe-grid .block-full, .grid-base .block-full {
			@apply col-span-full;
		}
		.section-flex .block-xs, .centerframe-flex .block-xs, .flex-base .block-xs {
			@apply shrink grow basis-sm-xs;
		}
		.section-flex .block-sm, .centerframe-flex .block-sm, .flex-base .block-sm {
			@apply shrink grow basis-sm-sm;
		}
		.section-flex .block-smx, .centerframe-flex .block-smx, .flex-base .block-smx {
			@apply shrink grow basis-sm-smx;
		}
		.section-flex .block-md, .centerframe-flex .block-md, .flex-base .block-md {
			@apply shrink grow basis-sm-md;
		}
		.section-flex .block-mdx, .centerframe-flex .block-mdx, .flex-base .block-mdx {
			@apply shrink grow basis-sm-mdx;
		}
		.section-flex .block-lg, .centerframe-flex .block-lg, .flex-base .block-lg {
			@apply shrink grow basis-sm-lg;
		}
		.section-flex .block-lgx, .centerframe-flex .block-lgx, .flex-base .block-lgx {
			@apply shrink grow basis-sm-lgx;
		}
		.section-flex .block-xl, .centerframe-flex .block-xl, .flex-base .block-xl {
			@apply shrink grow basis-sm-xl;
		}
		.section-flex .block-xlx, .centerframe-flex .block-xlx, .flex-base .block-xlx {
			@apply shrink grow basis-sm-xlx;
		}
		.section-flex .block-2xl, .centerframe-flex .block-2xl, .flex-base .block-2xl {
			@apply shrink grow basis-sm-2xl;
		}
		.section-flex .block-2xlx, .centerframe-flex .block-2xlx, .flex-base .block-2xlx {
			@apply shrink grow basis-sm-2xlx;
		}
		.section-flex .block-full, .centerframe-flex .block-full, .flex-base .block-full {
			@apply shrink grow basis-full;
		}
		.rounded-card {
			@apply rounded-article-sm;
		}
		.rounded-t-card {
			@apply rounded-t-article-sm;
		}
		.rounded-b-card {
			@apply rounded-b-article-sm;
		}
		.rounded-l-card {
			@apply rounded-l-article-sm;
		}
		.rounded-r-card {
			@apply rounded-r-article-sm;
		}
		.card {
			@apply rounded-article-sm p-article-sm;
		}
		.card-base {
			@apply rounded-article-sm;
		}
		.block-base, .block-xs, .block-sm, .block-smx, .block-md, .block-mdx, .block-lg, .block-lgx, .block-xl, .block-xlx, .block-2xl, .block-2xlx, .block-full {
			@apply space-y-block-sm;
		}
		.block-base.card, .block-xs.card, .block-sm.card, .block-smx.card, .block-md.card, .block-mdx.card, .block-lg.card, .block-lgx.card, .block-xl.card, .block-xlx.card, .block-2xl.card, .block-2xlx.card, .block-full.card {
			@apply space-y-card-sm;
		}
		.stack-y, .block-base.stack-y, .block-xs.stack-y, .block-sm.stack-y, .block-smx.stack-y, .block-md.stack-y, .block-mdx.stack-y, .block-lg.stack-y, .block-lgx.stack-y, .block-xl.stack-y, .block-xlx.stack-y, .block-2xl.stack-y, .block-2xlx.stack-y, .block-full.stack {
			@apply space-y-card-sm;
		}
		.stack-x, .block-base.stack-x, .block-xs.stack-x, .block-sm.stack-x, .block-smx.stack-x, .block-md.stack-x, .block-mdx.stack-x, .block-lg.stack-x, .block-lgx.stack-x, .block-xl.stack-x, .block-xlx.stack-x, .block-2xl.stack-x, .block-2xlx.stack-x, .block-full.stack {
			@apply space-x-card-sm;
		}
		.datarow, .formrow {
			@apply gap-stack-sm;
		}
		.card .datarow, .card-base .datarow,
		.card .formrow, .card-base .formrow   {
			@apply gap-card-sm;
		}
		.stack-row {
			@apply flex flex-row gap-x-stack-sm;
		}
		.stack-row-wrap {
			@apply flex flex-row gap-x-stack-sm gap-y-stack-sm flex-wrap;
		}
		.stack-col {
			@apply flex flex-col gap-stack-sm;
		}
		.stack-form-col {
			@apply flex flex-col gap-form-sm;
		}
		.card .stack-row, .card-base .stack-row {
			@apply flex flex-row gap-x-card-sm;
		}
		.card .stack-row-wrap, .card-base .stack-row-wrap {
			@apply flex flex-row gap-x-card-sm gap-y-stack-sm flex-wrap;
		}
		.card .stack-col, .card-base .stack-col {
			@apply flex flex-col gap-y-card-sm;
		}
		.pl-half-gutter {
			@apply pl-half-gutter-sm;
		}
		.pr-half-gutter {
			@apply pr-half-gutter-sm;
		}
		.ml-half-gutter {
			@apply ml-half-gutter-sm;
		}
		.mr-half-gutter {
			@apply mr-half-gutter-sm;
		}
		.max-w-half-breakpoint-less-gutter {
			max-width: calc((var(--screen-sm) / 2) - var(--spacing-gutter-sm));
		}
		.max-w-half-breakpoint {
			max-width: calc(var(--screen-sm) / 2);
		}
		.max-w-breakpoint {
			@apply max-w-screen-sm;
		}
		.pr-col {
			@apply pr-col-sm;
		}
		.pl-col {
			@apply pl-col-sm;
		}
		.p-header-safeview {
			padding-top: max(var(--pad-header-sm), env(safe-area-inset-top));
			padding-left: max(var(--spacing-shoulder-sm), env(safe-area-inset-left));
			padding-right: max(var(--spacing-shoulder-sm), env(safe-area-inset-right));
			padding-bottom: var(--pad-header-sm);
		}
		.p-section-safeview {
			padding-top: max(var(--pad-section-sm), env(safe-area-inset-top));
			padding-left: max(var(--spacing-shoulder-sm), env(safe-area-inset-left));
			padding-right: max(var(--spacing-shoulder-sm), env(safe-area-inset-right));
			padding-bottom: max(var(--pad-section-sm), env(safe-area-inset-bottom));
		}
		.pt-section-safeview {
			padding-top: max(var(--pad-section-sm), env(safe-area-inset-top));
		}
		.pb-section-safeview {
			padding-bottom: max(var(--pad-section-sm), env(safe-area-inset-bottom));
		}
		.pl-section-safeview {
			padding-left: max(var(--spacing-shoulder-sm), env(safe-area-inset-left));
		}
		.pr-section-safeview {
			padding-right: max(var(--spacing-shoulder-sm), env(safe-area-inset-right));
		}
		.px-section-safeview {
			padding-left: max(var(--spacing-shoulder-sm), env(safe-area-inset-left));
			padding-right: max(var(--spacing-shoulder-sm), env(safe-area-inset-right));
		}
		.py-section-safeview {
			padding-top: max(var(--pad-section-sm), env(safe-area-inset-top));
			padding-bottom: max(var(--pad-section-sm), env(safe-area-inset-bottom));
		}
		.p-footer-safeview {
			padding-top: var(--pad-section-sm);
			padding-left: max(var(--spacing-shoulder-sm), env(safe-area-inset-left));
			padding-right: max(var(--spacing-shoulder-sm), env(safe-area-inset-right));
			padding-bottom: max(var(--pad-section-sm), env(safe-area-inset-bottom));
		}
		.m-header-safeview {
			margin-top: max(var(--pad-header-sm), env(safe-area-inset-top));
			margin-left: max(var(--spacing-shoulder-sm), env(safe-area-inset-left));
			margin-right: max(var(--spacing-shoulder-sm), env(safe-area-inset-right));
			margin-bottom: var(--pad-header-sm);
		}
		.m-section-safeview {
			margin-top: max(var(--pad-section-sm), env(safe-area-inset-top));
			margin-left: max(var(--spacing-shoulder-sm), env(safe-area-inset-left));
			margin-right: max(var(--spacing-shoulder-sm), env(safe-area-inset-right));
			margin-bottom: max(var(--pad-section-sm), env(safe-area-inset-bottom));
		}
		.ml-section-safeview {
			margin-left: max(var(--spacing-shoulder-sm), env(safe-area-inset-left));
		}
		.mr-section-safeview {
			margin-right: max(var(--spacing-shoulder-sm), env(safe-area-inset-right));
		}
		.mt-section-safeview {
			margin-top: max(var(--pad-section-sm), env(safe-area-inset-top));
		}
		.mb-section-safeview {
			margin-bottom: max(var(--pad-section-sm), env(safe-area-inset-bottom));
		}
		.mx-section-safeview {
			margin-left: max(var(--spacing-shoulder-sm), env(safe-area-inset-left));
			margin-right: max(var(--spacing-shoulder-sm), env(safe-area-inset-right));
		}
		.my-section-safeview {
			margin-top: max(var(--pad-section-sm), env(safe-area-inset-top));
			margin-bottom: max(var(--pad-section-sm), env(safe-area-inset-bottom));
		}
		.m-footer-safeview {
			margin-top: var(--pad-section-sm);
			margin-left: max(var(--spacing-shoulder-sm), env(safe-area-inset-left));
			margin-right: max(var(--spacing-shoulder-sm), env(safe-area-inset-right));
			margin-bottom: max(var(--pad-section-sm), env(safe-area-inset-bottom));
		}
		.p-header {
			@apply px-shoulder-sm py-header-sm;
		}
		.px-header {
			@apply px-shoulder-sm;
		}
		.py-header {
			@apply py-header-sm;
		}
		.p-shoulder {
			@apply p-shoulder-sm;
		}
		.pl-shoulder {
			@apply pl-shoulder-sm;
		}
		.pr-shoulder {
			@apply pr-shoulder-sm;
		}
		.p-section {
			@apply p-section-sm;
		}
		.px-section {
			@apply px-section-sm;
		}
		.py-section {
			@apply py-section-sm;
		}
		.pt-section {
			@apply pt-section-sm;
		}
		.pb-section {
			@apply pb-section-sm;
		}
		.pl-section {
			@apply pl-section-sm;
		}
		.pr-section {
			@apply pr-section-sm;
		}
		.p-article {
			@apply p-article-sm;
		}
		.px-article {
			@apply px-article-sm;
		}
		.py-article {
			@apply py-article-sm;
		}
		.p-block {
			@apply p-block-sm;
		}
		.px-block {
			@apply px-block-sm;
		}
		.py-block {
			@apply py-block-sm;
		}
		.p-card {
			@apply p-card-sm;
		}
		.px-card {
			@apply px-card-sm;
		}
		.py-card {
			@apply py-card-sm;
		}
		.p-element {
			@apply p-element-sm;
		}
		.px-element {
			@apply px-element-sm;
		}
		.py-element {
			@apply py-element-sm;
		}
		.pl-element {
			@apply pl-element-sm;
		}
		.pr-element {
			@apply pr-element-sm;
		}
		.pt-element {
			@apply pt-element-sm;
		}
		.pb-element {
			@apply pb-element-sm;
		}
		.p-sub {
			@apply p-sub-sm;
		}
		.pt-sub {
			@apply pt-sub-sm;
		}
		.pb-sub {
			@apply pb-sub-sm;
		}
		.pl-sub {
			@apply pl-sub-sm;
		}
		.pr-sub {
			@apply pr-sub-sm;
		}
		.px-sub {
			@apply px-sub-sm;
		}
		.py-sub {
			@apply py-sub-sm;
		}
		.p-base {
			@apply p-base-sm;
		}
		.px-base {
			@apply px-base-sm;
		}
		.py-base {
			@apply py-base-sm;
		}
		.h-1row {
			@apply h-1row-sm;
		}
		.h-2row {
			@apply h-2row-sm;
		}
		.h-3row {
			@apply h-3row-sm;
		}
		.h-4row {
			@apply h-4row-sm;
		}
		.h-5row {
			@apply h-5row-sm;
		}
		.h-6row {
			@apply h-6row-sm;
		}
		.h-7row {
			@apply h-7row-sm;
		}
		.h-8row {
			@apply h-8row-sm;
		}
		.max-h-element {
			@apply max-h-element-max-sm;
		}
		/* ---------------------------- */
		/* ANCHOR Adaptive Text			*/
		/* ---------------------------- */
		.text-d1 {
			font-size: var(--text-d1a-size) !important;
			line-height: var(--text-d1a-lh) !important;
		}
		.text-d2 {
			font-size: var(--text-d2a-size) !important;
			line-height: var(--text-d2a-lh) !important;
		}
		.text-d3 {
			font-size: var(--text-d3a-size) !important;
			line-height: var(--text-d3a-lh) !important;
		}
		.text-i1 {
			font-size: var(--text-i1a-size) !important;
			line-height: var(--text-i1a-lh) !important;
		}
		h1 {
			@apply text-h1a;
		}
		header nav a.nav, header nav a:link.nav, header nav a:visited {
			@apply text-nava;
		}
		footer nav a.nav, footer nav a:link.nav, footer nav a:visited {
			@apply text-nava-size leading-p2;
		}
	}

	/* --------------------------------------------------------------------	*/
	/* /DESIGNFRAME ADAPTIVE - Do not edit section 							*/
	/* !SECTION SM (640px+) Screens											*/
	/* --------------------------------------------------------------------	*/

	/* --------------------------------------------------------------------	*/
	/* SECTION MD (768px+) Screens 											*/
	/* DESIGNFRAME ADAPTIVE - Do not edit section 							*/
	/* --------------------------------------------------------------------	*/

	@media (min-width:768px) { /* NOTE SCREEN MD CONFIG: You must manually set the min-width: here to match your --screen-md value if you changed it from the preset Designframe Preferred Value. */
		header {
			@apply px-shoulder-md py-header-md;
		}
		.header-base {
			@apply px-0 py-0;
		}
		.header-grid nav {
			@apply grid-cols-md gap-x-gutter-md;
		}
		.header-flex nav {
			@apply gap-x-gutter-md;
		}
		footer {
			@apply px-shoulder-md py-section-md;
		}
		.footer-base {
			@apply px-0 py-0;
		}
		.footer-grid {
			@apply grid-cols-md gap-gutter-md;
		}
		.footer-flex nav {
			@apply gap-gutter-md;
		}
		section {
			@apply px-shoulder-md py-section-md;
		}
		.section-grid, .centerframe-grid, .grid-base {
			@apply grid-cols-md gap-gutter-md;
		}
		.grid-cols {
			@apply grid-cols-md;
		}
		.section-flex, .centerframe-flex, .flex-base {
			@apply gap-gutter-md;
		}
		.mt-gutter {
			@apply mt-gutter-md;
		}
		.mb-gutter {
			@apply mb-gutter-md;
		}
		.ml-gutter {
			@apply ml-gutter-md;
		}
		.mr-gutter {
			@apply mr-gutter-md;
		}
		.pt-gutter {
			@apply pt-gutter-md;
		}
		.pb-gutter {
			@apply pb-gutter-md;
		}
		.pl-gutter {
			@apply pl-gutter-md;
		}
		.pr-gutter {
			@apply pr-gutter-md;
		}
		.mt-article {
			@apply mt-article-md;
		}
		.mb-article {
			@apply mb-article-md;
		}
		.ml-article {
			@apply ml-article-md;
		}
		.mr-article {
			@apply mr-article-md;
		}
		.gap-form {
			@apply gap-form-md;
		}
		.gap-gutter {
			@apply gap-gutter-md;
		}
		.gap-gutterx {
			@apply gap-gutterx-md;
		}
		.gap-x-gutterx {
			@apply gap-x-gutterx-md;
		}
		.gap-y-gutterx {
			@apply gap-y-gutterx-md;
		}
		.gap-gutterxx {
			@apply gap-gutterxx-md;
		}
		.gap-x-gutterxx {
			@apply gap-x-gutterxx-md;
		}
		.gap-y-gutterxx {
			@apply gap-y-gutterxx-md;
		}
		.gap-stack {
			@apply gap-stack-md;
		}
		.gap-y-stack {
			@apply gap-y-stack-md;
		}
		.gap-x-stack {
			@apply gap-x-stack-md;
		}
		.space-y-card {
			@apply space-y-card-md;
		}
		.space-x-card {
			@apply space-x-card-md;
		}
		.section-grid.gap-2gutter, .centerframe-grid.gap-2gutter, .grid-base.gap-2gutter, .section-flex.gap-2gutter, .centerframe-flex.gap-2gutter, .flex-base.gap-2gutter {
			@apply gap-2gutter-md;
		}
		.gap-2gutter {
			@apply gap-2gutter-md;
		}
		.gap-x-gutter {
			@apply gap-x-gutter-md;
		}
		.gap-y-gutter {
			@apply gap-y-gutter-md;
		}
		.gap-x-2gutter {
			@apply gap-x-2gutter-md;
		}
		.gap-y-2gutter {
			@apply gap-y-2gutter-md;
		}
		.section-grid .block-xs, .centerframe-grid .block-xs, .grid-base .block-xs {
			@apply col-md-xs;
		}
		.section-grid .block-sm, .centerframe-grid .block-sm, .grid-base .block-sm {
			@apply col-md-sm;
		}
		.section-grid .block-smx, .centerframe-grid .block-smx, .grid-base .block-smx {
			@apply col-md-smx;
		}
		.section-grid .block-md, .centerframe-grid .block-md, .grid-base .block-md {
			@apply col-md-md;
		}
		.section-grid .block-mdx, .centerframe-grid .block-mdx, .grid-base .block-mdx {
			@apply col-md-mdx;
		}
		.section-grid .block-lg, .centerframe-grid .block-lg, .grid-base .block-lg {
			@apply col-md-lg;
		}
		.section-grid .block-lgx, .centerframe-grid .block-lgx, .grid-base .block-lgx {
			@apply col-md-lgx;
		}
		.section-grid .block-xl, .centerframe-grid .block-xl, .grid-base .block-xl {
			@apply col-md-xl;
		}
		.section-grid .block-xlx, .centerframe-grid .block-xlx, .grid-base .block-xlx {
			@apply col-md-xlx;
		}
		.section-grid .block-2xl, .centerframe-grid .block-2xl, .grid-base .block-2xl {
			@apply col-md-2xl;
		}
		.section-grid .block-2xlx, .centerframe-grid .block-2xlx, .grid-base .block-2xlx {
			@apply col-md-2xlx;
		}
		.section-grid .block-full, .centerframe-grid .block-full, .grid-base .block-full {
			@apply col-span-full;
		}
		.section-flex .block-xs, .centerframe-flex .block-xs, .flex-base .block-xs {
			@apply shrink grow basis-md-xs;
		}
		.section-flex .block-sm, .centerframe-flex .block-sm, .flex-base .block-sm {
			@apply shrink grow basis-md-sm;
		}
		.section-flex .block-smx, .centerframe-flex .block-smx, .flex-base .block-smx {
			@apply shrink grow basis-md-smx;
		}
		.section-flex .block-md, .centerframe-flex .block-md, .flex-base .block-md {
			@apply shrink grow basis-md-md;
		}
		.section-flex .block-mdx, .centerframe-flex .block-mdx, .flex-base .block-mdx {
			@apply shrink grow basis-md-mdx;
		}
		.section-flex .block-lg, .centerframe-flex .block-lg, .flex-base .block-lg {
			@apply shrink grow basis-md-lg;
		}
		.section-flex .block-lgx, .centerframe-flex .block-lgx, .flex-base .block-lgx {
			@apply shrink grow basis-md-lgx;
		}
		.section-flex .block-xl, .centerframe-flex .block-xl, .flex-base .block-xl {
			@apply shrink grow basis-md-xl;
		}
		.section-flex .block-xlx, .centerframe-flex .block-xlx, .flex-base .block-xlx {
			@apply shrink grow basis-md-xlx;
		}
		.section-flex .block-2xl, .centerframe-flex .block-2xl, .flex-base .block-2xl {
			@apply shrink grow basis-md-2xl;
		}
		.section-flex .block-2xlx, .centerframe-flex .block-2xlx, .flex-base .block-2xlx {
			@apply shrink grow basis-md-2xlx;
		}
		.section-flex .block-full, .centerframe-flex .block-full {
			@apply shrink grow basis-full;
		}
		.rounded-card {
			@apply rounded-article-md;
		}
		.rounded-t-card {
			@apply rounded-t-article-md;
		}
		.rounded-b-card {
			@apply rounded-b-article-md;
		}
		.rounded-l-card {
			@apply rounded-l-article-md;
		}
		.rounded-r-card {
			@apply rounded-r-article-md;
		}
		.card {
			@apply rounded-article-md p-article-md;
		}
		.card-base {
			@apply rounded-article-md;
		}
		.block-base, .block-xs, .block-sm, .block-smx, .block-md, .block-mdx, .block-lg, .block-lgx, .block-xl, .block-xlx, .block-2xl, .block-2xlx, .block-full {
			@apply space-y-block-md;
		}

		.block-base.card, .block-xs.card, .block-sm.card, .block-smx.card, .block-md.card, .block-mdx.card, .block-lg.card, .block-lgx.card, .block-xl.card, .block-xlx.card, .block-2xl.card, .block-2xlx.card, .block-full.card {
			@apply space-y-card-md;
		}
		.stack-y, .block-base.stack-y, .block-xs.stack-y, .block-sm.stack-y, .block-smx.stack-y, .block-md.stack-y, .block-mdx.stack-y, .block-lg.stack-y, .block-lgx.stack-y, .block-xl.stack-y, .block-xlx.stack-y, .block-2xl.stack-y, .block-2xlx.stack-y, .block-full.stack {
			@apply space-y-card-md;
		}
		.stack-x, .block-base.stack-x, .block-xs.stack-x, .block-sm.stack-x, .block-smx.stack-x, .block-md.stack-x, .block-mdx.stack-x, .block-lg.stack-x, .block-lgx.stack-x, .block-xl.stack-x, .block-xlx.stack-x, .block-2xl.stack-x, .block-2xlx.stack-x, .block-full.stack {
			@apply space-x-card-md;
		}
		.datarow, .formrow {
			@apply gap-stack-md;
		}
		.card .datarow, .card-base .datarow,
		.card .formrow, .card-base .formrow {
			@apply gap-card-md;
		}
		.stack-row {
			@apply flex flex-row gap-x-stack-md;
		}
		.stack-row-wrap {
			@apply flex flex-row gap-x-stack-md gap-y-stack-md flex-wrap;
		}
		.stack-col {
			@apply flex flex-col gap-stack-md;
		}
		.stack-form-col {
			@apply flex flex-col gap-form-md;
		}
		.card .stack-row, .card-base .stack-row {
			@apply flex flex-row gap-x-card-md;
		}
		.card .stack-row-wrap, .card-base .stack-row-wrap {
			@apply flex flex-row gap-x-card-md gap-y-stack-md flex-wrap;
		}
		.card .stack-col, .card-base .stack-col {
			@apply flex flex-col gap-y-card-md;
		}
		.pl-half-gutter {
			@apply pl-half-gutter-md;
		}
		.pr-half-gutter {
			@apply pr-half-gutter-md;
		}
		.ml-half-gutter {
			@apply ml-half-gutter-md;
		}
		.mr-half-gutter {
			@apply mr-half-gutter-md;
		}
		.max-w-half-breakpoint-less-gutter {
			max-width: calc((var(--screen-md) / 2) - var(--spacing-gutter-md));
		}
		.max-w-half-breakpoint {
			max-width: calc(var(--screen-md) / 2);
		}
		.max-w-breakpoint {
			@apply max-w-screen-md;
		}
		.pr-col {
			@apply pr-col-md;
		}
		.pl-col {
			@apply pl-col-md;
		}
		.p-header-safeview {
			padding-top: max(var(--pad-header-md), env(safe-area-inset-top));
			padding-left: max(var(--spacing-shoulder-md), env(safe-area-inset-left));
			padding-right: max(var(--spacing-shoulder-md), env(safe-area-inset-right));
			padding-bottom: var(--pad-header-md);
		}
		.p-section-safeview {
			padding-top: max(var(--pad-section-md), env(safe-area-inset-top));
			padding-left: max(var(--spacing-shoulder-md), env(safe-area-inset-left));
			padding-right: max(var(--spacing-shoulder-md), env(safe-area-inset-right));
			padding-bottom: max(var(--pad-section-md), env(safe-area-inset-bottom));
		}
		.pt-section-safeview {
			padding-top: max(var(--pad-section-md), env(safe-area-inset-top));
		}
		.pb-section-safeview {
			padding-bottom: max(var(--pad-section-md), env(safe-area-inset-bottom));
		}
		.pl-section-safeview {
			padding-left: max(var(--spacing-shoulder-md), env(safe-area-inset-left));
		}
		.pr-section-safeview {
			padding-right: max(var(--spacing-shoulder-md), env(safe-area-inset-right));
		}
		.px-section-safeview {
			padding-left: max(var(--spacing-shoulder-md), env(safe-area-inset-left));
			padding-right: max(var(--spacing-shoulder-md), env(safe-area-inset-right));
		}
		.py-section-safeview {
			padding-top: max(var(--pad-section-md), env(safe-area-inset-top));
			padding-bottom: max(var(--pad-section-md), env(safe-area-inset-bottom));
		}
		.p-footer-safeview {
			padding-top: var(--pad-section-md);
			padding-left: max(var(--spacing-shoulder-md), env(safe-area-inset-left));
			padding-right: max(var(--spacing-shoulder-md), env(safe-area-inset-right));
			padding-bottom: max(var(--pad-section-md), env(safe-area-inset-bottom));
		}
		.m-header-safeview {
			margin-top: max(var(--pad-header-md), env(safe-area-inset-top));
			margin-left: max(var(--spacing-shoulder-md), env(safe-area-inset-left));
			margin-right: max(var(--spacing-shoulder-md), env(safe-area-inset-right));
			margin-bottom: var(--pad-header-md);
		}
		.m-section-safeview {
			margin-top: max(var(--pad-section-md), env(safe-area-inset-top));
			margin-left: max(var(--spacing-shoulder-md), env(safe-area-inset-left));
			margin-right: max(var(--spacing-shoulder-md), env(safe-area-inset-right));
			margin-bottom: max(var(--pad-section-md), env(safe-area-inset-bottom));
		}
		.ml-section-safeview {
			margin-left: max(var(--spacing-shoulder-md), env(safe-area-inset-left));
		}
		.mr-section-safeview {
			margin-right: max(var(--spacing-shoulder-md), env(safe-area-inset-right));
		}
		.mt-section-safeview {
			margin-top: max(var(--pad-section-md), env(safe-area-inset-top));
		}
		.mb-section-safeview {
			margin-bottom: max(var(--pad-section-md), env(safe-area-inset-bottom));
		}
		.mx-section-safeview {
			margin-left: max(var(--spacing-shoulder-md), env(safe-area-inset-left));
			margin-right: max(var(--spacing-shoulder-md), env(safe-area-inset-right));
		}
		.my-section-safeview {
			margin-top: max(var(--pad-section-md), env(safe-area-inset-top));
			margin-bottom: max(var(--pad-section-md), env(safe-area-inset-bottom));
		}
		.m-footer-safeview {
			margin-top: var(--pad-section-md);
			margin-left: max(var(--spacing-shoulder-md), env(safe-area-inset-left));
			margin-right: max(var(--spacing-shoulder-md), env(safe-area-inset-right));
			margin-bottom: max(var(--pad-section-md), env(safe-area-inset-bottom));
		}
		.p-header {
			@apply px-shoulder-md py-header-md;
		}
		.px-header {
			@apply px-shoulder-md;
		}
		.py-header {
			@apply py-header-md;
		}
		.p-shoulder {
			@apply p-shoulder-md;
		}
		.pl-shoulder {
			@apply pl-shoulder-md;
		}
		.pr-shoulder {
			@apply pr-shoulder-md;
		}
		.p-section {
			@apply p-section-md;
		}
		.px-section {
			@apply px-section-md;
		}
		.py-section {
			@apply py-section-md;
		}
		.pt-section {
			@apply pt-section-md;
		}
		.pb-section {
			@apply pb-section-md;
		}
		.pl-section {
			@apply pl-section-md;
		}
		.pr-section {
			@apply pr-section-md;
		}
		.p-article {
			@apply p-article-md;
		}
		.px-article {
			@apply px-article-md;
		}
		.py-article {
			@apply py-article-md;
		}
		.p-block {
			@apply p-block-md;
		}
		.px-block {
			@apply px-block-md;
		}
		.py-block {
			@apply py-block-md;
		}
		.p-card {
			@apply p-card-md;
		}
		.px-card {
			@apply px-card-md;
		}
		.py-card {
			@apply py-card-md;
		}
		.p-element {
			@apply p-element-md;
		}
		.px-element {
			@apply px-element-md;
		}
		.py-element {
			@apply py-element-md;
		}
		.pl-element {
			@apply pl-element-md;
		}
		.pr-element {
			@apply pr-element-md;
		}
		.pt-element {
			@apply pt-element-md;
		}
		.pb-element {
			@apply pb-element-md;
		}
		.p-sub {
			@apply p-sub-md;
		}
		.pt-sub {
			@apply pt-sub-md;
		}
		.pb-sub {
			@apply pb-sub-md;
		}
		.pl-sub {
			@apply pl-sub-md;
		}
		.pr-sub {
			@apply pr-sub-md;
		}
		.px-sub {
			@apply px-sub-md;
		}
		.py-sub {
			@apply py-sub-md;
		}
		.p-base {
			@apply p-base-md;
		}
		.px-base {
			@apply px-base-md;
		}
		.py-base {
			@apply py-base-md;
		}
		.h-1row {
			@apply h-1row-md;
		}
		.h-2row {
			@apply h-2row-md;
		}
		.h-3row {
			@apply h-3row-md;
		}
		.h-4row {
			@apply h-4row-md;
		}
		.h-5row {
			@apply h-5row-md;
		}
		.h-6row {
			@apply h-6row-md;
		}
		.h-7row {
			@apply h-7row-md;
		}
		.h-8row {
			@apply h-8row-md;
		}
		.max-h-element {
			@apply max-h-element-max-md;
		}
		/* ---------------------------- */
		/* ANCHOR Adaptive Text			*/
		/* ---------------------------- */
		.text-d1 {
			font-size: var(--text-d1a-size) !important;
			line-height: var(--text-d1a-lh) !important;
		}
		.text-d2 {
			font-size: var(--text-d2a-size) !important;
			line-height: var(--text-d2a-lh) !important;
		}
		.text-d3 {
			font-size: var(--text-d3a-size) !important;
			line-height: var(--text-d3a-lh) !important;
		}
		.text-i1 {
			font-size: var(--text-i1a-size) !important;
			line-height: var(--text-i1a-lh) !important;
		}
		h1 {
			@apply text-h1a;
		}
		header nav a.nav, header nav a:link.nav, header nav a:visited {
			@apply text-nava;
		}
		footer nav a.nav, footer nav a:link.nav, footer nav a:visited {
			@apply text-nava-size leading-p2;
		}
	}

	/* --------------------------------------------------------------------	*/
	/* /DESIGNFRAME ADAPTIVE - Do not edit section 							*/
	/* !SECTION MD (768px+) Screens 										*/
	/* --------------------------------------------------------------------	*/

	/* --------------------------------------------------------------------	*/
	/* SECTION LG (960px+) Screens											*/
	/* DESIGNFRAME ADAPTIVE - Do not edit section 							*/
	/* --------------------------------------------------------------------	*/

	@media (min-width:960px) { /* NOTE SCREEN LG CONFIG: You must manually set the min-width: here to match your --screen-lg value if you changed it from the preset Designframe Preferred Value. */
		/* ... */
		header {
			@apply px-shoulder-lg py-header-lg;
		}
		.header-base {
			@apply px-0 py-0;
		}
		.header-grid nav {
			@apply grid-cols-lg gap-x-gutter-lg;
		}
		.header-flex nav {
			@apply gap-x-gutter-lg;
		}
		footer {
			@apply px-shoulder-lg py-section-lg;
		}
		.footer-base {
			@apply px-0 py-0;
		}
		.footer-grid {
			@apply grid-cols-lg gap-gutter-lg;
		}
		.footer-flex nav {
			@apply gap-gutter-lg;
		}
		section {
			@apply px-shoulder-lg py-section-lg;
		}
		.section-grid, .centerframe-grid, .grid-base {
			@apply grid-cols-lg gap-gutter-lg;
		}
		.grid-cols {
			@apply grid-cols-lg;
		}
		.section-flex, .centerframe-flex, .flex-base {
			@apply gap-gutter-lg;
		}
		.mt-gutter {
			@apply mt-gutter-lg;
		}
		.mb-gutter {
			@apply mb-gutter-lg;
		}
		.ml-gutter {
			@apply ml-gutter-lg;
		}
		.mr-gutter {
			@apply mr-gutter-lg;
		}
		.pt-gutter {
			@apply pt-gutter-lg;
		}
		.pb-gutter {
			@apply pb-gutter-lg;
		}
		.pl-gutter {
			@apply pl-gutter-lg;
		}
		.pr-gutter {
			@apply pr-gutter-lg;
		}
		.mt-article {
			@apply mt-article-lg;
		}
		.mb-article {
			@apply mb-article-lg;
		}
		.ml-article {
			@apply ml-article-lg;
		}
		.mr-article {
			@apply mr-article-lg;
		}
		.gap-form {
			@apply gap-form-lg;
		}
		.gap-gutter {
			@apply gap-gutter-lg;
		}
		.gap-gutterx {
			@apply gap-gutterx-lg;
		}
		.gap-x-gutterx {
			@apply gap-x-gutterx-lg;
		}
		.gap-y-gutterx {
			@apply gap-y-gutterx-lg;
		}
		.gap-gutterxx {
			@apply gap-gutterxx-lg;
		}
		.gap-x-gutterxx {
			@apply gap-x-gutterxx-lg;
		}
		.gap-y-gutterxx {
			@apply gap-y-gutterxx-lg;
		}
		.gap-stack {
			@apply gap-stack-lg;
		}
		.gap-y-stack {
			@apply gap-y-stack-lg;
		}
		.gap-x-stack {
			@apply gap-x-stack-lg;
		}
		.space-y-card {
			@apply space-y-card-lg;
		}
		.space-x-card {
			@apply space-x-card-lg;
		}
		.section-grid.gap-2gutter, .centerframe-grid.gap-2gutter, .grid-base.gap-2gutter, .section-flex.gap-2gutter, .centerframe-flex.gap-2gutter, .flex-base.gap-2gutter {
			@apply gap-2gutter-lg;
		}
		.gap-2gutter {
			@apply gap-2gutter-lg;
		}
		.gap-x-gutter {
			@apply gap-x-gutter-lg;
		}
		.gap-y-gutter {
			@apply gap-y-gutter-lg;
		}
		.gap-x-2gutter {
			@apply gap-x-2gutter-lg;
		}
		.gap-y-2gutter {
			@apply gap-y-2gutter-lg;
		}
		.section-grid .block-xs, .centerframe-grid .block-xs, .grid-base .block-xs {
			@apply col-lg-xs;
		}
		.section-grid .block-sm, .centerframe-grid .block-sm, .grid-base .block-sm {
			@apply col-lg-sm;
		}
		.section-grid .block-smx, .centerframe-grid .block-smx, .grid-base .block-smx {
			@apply col-lg-smx;
		}
		.section-grid .block-md, .centerframe-grid .block-md, .grid-base .block-md {
			@apply col-lg-md;
		}
		.section-grid .block-mdx, .centerframe-grid .block-mdx, .grid-base .block-mdx {
			@apply col-lg-mdx;
		}
		.section-grid .block-lg, .centerframe-grid .block-lg, .grid-base .block-lg {
			@apply col-lg-lg;
		}
		.section-grid .block-lgx, .centerframe-grid .block-lgx, .grid-base .block-lgx {
			@apply col-lg-lgx;
		}
		.section-grid .block-xl, .centerframe-grid .block-xl, .grid-base .block-xl {
			@apply col-lg-xl;
		}
		.section-grid .block-xlx, .centerframe-grid .block-xlx, .grid-base .block-xlx {
			@apply col-lg-xlx;
		}
		.section-grid .block-2xl, .centerframe-grid .block-2xl, .grid-base .block-2xl {
			@apply col-lg-2xl;
		}
		.section-grid .block-2xlx, .centerframe-grid .block-2xlx, .grid-base .block-2xlx {
			@apply col-lg-2xlx;
		}
		.section-grid .block-full, .centerframe-grid .block-full, .grid-base .block-full {
			@apply col-span-full;
		}
		.section-flex .block-xs, .centerframe-flex .block-xs, .flex-base .block-xs {
			@apply shrink grow basis-lg-xs;
		}
		.section-flex .block-sm, .centerframe-flex .block-sm, .flex-base .block-sm {
			@apply shrink grow basis-lg-sm;
		}
		.section-flex .block-smx, .centerframe-flex .block-smx, .flex-base .block-smx {
			@apply shrink grow basis-lg-smx;
		}
		.section-flex .block-md, .centerframe-flex .block-md, .flex-base .block-md {
			@apply shrink grow basis-lg-md;
		}
		.section-flex .block-mdx, .centerframe-flex .block-mdx, .flex-base .block-mdx {
			@apply shrink grow basis-lg-mdx;
		}
		.section-flex .block-lg, .centerframe-flex .block-lg, .flex-base .block-lg {
			@apply shrink grow basis-lg-lg;
		}
		.section-flex .block-lgx, .centerframe-flex .block-lgx, .flex-base .block-lgx {
			@apply shrink grow basis-lg-lgx;
		}
		.section-flex .block-xl, .centerframe-flex .block-xl, .flex-base .block-xl {
			@apply shrink grow basis-lg-xl;
		}
		.section-flex .block-xlx, .centerframe-flex .block-xlx, .flex-base .block-xlx {
			@apply shrink grow basis-lg-xlx;
		}
		.section-flex .block-2xl, .centerframe-flex .block-2xl, .flex-base .block-2xl {
			@apply shrink grow basis-lg-2xl;
		}
		.section-flex .block-2xlx, .centerframe-flex .block-2xlx, .flex-base .block-2xlx {
			@apply shrink grow basis-lg-2xlx;
		}
		.section-flex .block-full, .centerframe-flex .block-full {
			@apply shrink grow basis-full;
		}
		.rounded-card {
			@apply rounded-article-lg;
		}
		.rounded-t-card {
			@apply rounded-t-article-lg;
		}
		.rounded-b-card {
			@apply rounded-b-article-lg;
		}
		.rounded-l-card {
			@apply rounded-l-article-lg;
		}
		.rounded-r-card {
			@apply rounded-r-article-lg;
		}
		.card {
			@apply rounded-article-lg p-article-lg;
		}
		.card-base {
			@apply rounded-article-lg;
		}
		.block-base, .block-xs, .block-sm, .block-smx, .block-md, .block-mdx, .block-lg, .block-lgx, .block-xl, .block-xlx, .block-2xl, .block-2xlx, .block-full {
			@apply space-y-block-lg;
		}
		.block-base.card, .block-xs.card, .block-sm.card, .block-smx.card, .block-md.card, .block-mdx.card, .block-lg.card, .block-lgx.card, .block-xl.card, .block-xlx.card, .block-2xl.card, .block-2xlx.card, .block-full.card {
			@apply space-y-card-lg;
		}
		.stack-y, .block-base.stack-y, .block-xs.stack-y, .block-sm.stack-y, .block-smx.stack-y, .block-md.stack-y, .block-mdx.stack-y, .block-lg.stack-y, .block-lgx.stack-y, .block-xl.stack-y, .block-xlx.stack-y, .block-2xl.stack-y, .block-2xlx.stack-y, .block-full.stack {
			@apply space-y-card-lg;
		}
		.stack-x, .block-base.stack-x, .block-xs.stack-x, .block-sm.stack-x, .block-smx.stack-x, .block-md.stack-x, .block-mdx.stack-x, .block-lg.stack-x, .block-lgx.stack-x, .block-xl.stack-x, .block-xlx.stack-x, .block-2xl.stack-x, .block-2xlx.stack-x, .block-full.stack {
			@apply space-x-card-lg;
		}
		.datarow, .formrow {
			@apply gap-stack-lg;
		}
		.card .datarow, .card-base .datarow,
		.card .formrow, .card-base .formrow {
			@apply gap-card-lg;
		}
		.stack-row {
			@apply flex flex-row gap-x-stack-lg;
		}
		.stack-row-wrap {
			@apply flex flex-row gap-x-stack-lg gap-y-stack-lg flex-wrap;
		}
		.stack-col {
			@apply flex flex-col gap-stack-lg;
		}
		.stack-form-col {
			@apply flex flex-col gap-form-lg;
		}
		.card .stack-row, .card-base .stack-row {
			@apply flex flex-row gap-x-card-lg;
		}
		.card .stack-row-wrap, .card-base .stack-row-wrap {
			@apply flex flex-row gap-x-card-lg gap-y-stack-lg flex-wrap;
		}
		.card .stack-col, .card-base .stack-col {
			@apply flex flex-col gap-y-card-lg;
		}
		.pl-half-gutter {
			@apply pl-half-gutter-lg;
		}
		.pr-half-gutter {
			@apply pr-half-gutter-lg;
		}
		.ml-half-gutter {
			@apply ml-half-gutter-lg;
		}
		.mr-half-gutter {
			@apply mr-half-gutter-lg;
		}
		.max-w-half-breakpoint-less-gutter {
			max-width: calc((var(--screen-lg) / 2) - var(--spacing-gutter-lg));
		}
		.max-w-half-breakpoint {
			max-width: calc(var(--screen-lg) / 2);
		}
		.max-w-breakpoint {
			@apply max-w-screen-lg;
		}
		.pr-col {
			@apply pr-col-lg;
		}
		.pl-col {
			@apply pl-col-lg;
		}
		.p-header-safeview {
			padding-top: max(var(--pad-header-lg), env(safe-area-inset-top));
			padding-left: max(var(--spacing-shoulder-lg), env(safe-area-inset-left));
			padding-right: max(var(--spacing-shoulder-lg), env(safe-area-inset-right));
			padding-bottom: var(--pad-header-lg);
		}
		.p-section-safeview {
			padding-top: max(var(--pad-section-lg), env(safe-area-inset-top));
			padding-left: max(var(--spacing-shoulder-lg), env(safe-area-inset-left));
			padding-right: max(var(--spacing-shoulder-lg), env(safe-area-inset-right));
			padding-bottom: max(var(--pad-section-lg), env(safe-area-inset-bottom));
		}
		.pt-section-safeview {
			padding-top: max(var(--pad-section-lg), env(safe-area-inset-top));
		}
		.pb-section-safeview {
			padding-bottom: max(var(--pad-section-lg), env(safe-area-inset-bottom));
		}
		.pl-section-safeview {
			padding-left: max(var(--spacing-shoulder-lg), env(safe-area-inset-left));
		}
		.pr-section-safeview {
			padding-right: max(var(--spacing-shoulder-lg), env(safe-area-inset-right));
		}
		.px-section-safeview {
			padding-left: max(var(--spacing-shoulder-lg), env(safe-area-inset-left));
			padding-right: max(var(--spacing-shoulder-lg), env(safe-area-inset-right));
		}
		.py-section-safeview {
			padding-top: max(var(--pad-section-lg), env(safe-area-inset-top));
			padding-bottom: max(var(--pad-section-lg), env(safe-area-inset-bottom));
		}
		.p-footer-safeview {
			padding-top: var(--pad-section-lg);
			padding-left: max(var(--spacing-shoulder-lg), env(safe-area-inset-left));
			padding-right: max(var(--spacing-shoulder-lg), env(safe-area-inset-right));
			padding-bottom: max(var(--pad-section-lg), env(safe-area-inset-bottom));
		}
		.m-header-safeview {
			margin-top: max(var(--pad-header-lg), env(safe-area-inset-top));
			margin-left: max(var(--spacing-shoulder-lg), env(safe-area-inset-left));
			margin-right: max(var(--spacing-shoulder-lg), env(safe-area-inset-right));
			margin-bottom: var(--pad-header-lg);
		}
		.m-section-safeview {
			margin-top: max(var(--pad-section-lg), env(safe-area-inset-top));
			margin-left: max(var(--spacing-shoulder-lg), env(safe-area-inset-left));
			margin-right: max(var(--spacing-shoulder-lg), env(safe-area-inset-right));
			margin-bottom: max(var(--pad-section-lg), env(safe-area-inset-bottom));
		}
		.ml-section-safeview {
			margin-left: max(var(--spacing-shoulder-lg), env(safe-area-inset-left));
		}
		.mr-section-safeview {
			margin-right: max(var(--spacing-shoulder-lg), env(safe-area-inset-right));
		}
		.mt-section-safeview {
			margin-top: max(var(--pad-section-lg), env(safe-area-inset-top));
		}
		.mb-section-safeview {
			margin-bottom: max(var(--pad-section-lg), env(safe-area-inset-bottom));
		}
		.mx-section-safeview {
			margin-left: max(var(--spacing-shoulder-lg), env(safe-area-inset-left));
			margin-right: max(var(--spacing-shoulder-lg), env(safe-area-inset-right));
		}
		.my-section-safeview {
			margin-top: max(var(--pad-section-lg), env(safe-area-inset-top));
			margin-bottom: max(var(--pad-section-lg), env(safe-area-inset-bottom));
		}
		.m-footer-safeview {
			margin-top: var(--pad-section-lg);
			margin-left: max(var(--spacing-shoulder-lg), env(safe-area-inset-left));
			margin-right: max(var(--spacing-shoulder-lg), env(safe-area-inset-right));
			margin-bottom: max(var(--pad-section-lg), env(safe-area-inset-bottom));
		}
		.p-header {
			@apply px-shoulder-lg py-header-lg;
		}
		.px-header {
			@apply px-shoulder-lg;
		}
		.py-header {
			@apply py-header-lg;
		}
		.p-shoulder {
			@apply p-shoulder-lg;
		}
		.pl-shoulder {
			@apply pl-shoulder-lg;
		}
		.pr-shoulder {
			@apply pr-shoulder-lg;
		}
		.p-section {
			@apply p-section-lg;
		}
		.px-section {
			@apply px-section-lg;
		}
		.py-section {
			@apply py-section-lg;
		}
		.pt-section {
			@apply pt-section-lg;
		}
		.pb-section {
			@apply pb-section-lg;
		}
		.pl-section {
			@apply pl-section-lg;
		}
		.pr-section {
			@apply pr-section-lg;
		}
		.p-article {
			@apply p-article-lg;
		}
		.px-article {
			@apply px-article-lg;
		}
		.py-article {
			@apply py-article-lg;
		}
		.p-block {
			@apply p-block-lg;
		}
		.px-block {
			@apply px-block-lg;
		}
		.py-block {
			@apply py-block-lg;
		}
		.p-card {
			@apply p-card-lg;
		}
		.px-card {
			@apply px-card-lg;
		}
		.py-card {
			@apply py-card-lg;
		}
		.p-element {
			@apply p-element-lg;
		}
		.px-element {
			@apply px-element-lg;
		}
		.py-element {
			@apply py-element-lg;
		}
		.pl-element {
			@apply pl-element-lg;
		}
		.pr-element {
			@apply pr-element-lg;
		}
		.pt-element {
			@apply pt-element-lg;
		}
		.pb-element {
			@apply pb-element-lg;
		}
		.p-sub {
			@apply p-sub-lg;
		}
		.pt-sub {
			@apply pt-sub-lg;
		}
		.pb-sub {
			@apply pb-sub-lg;
		}
		.pl-sub {
			@apply pl-sub-lg;
		}
		.pr-sub {
			@apply pr-sub-lg;
		}
		.px-sub {
			@apply px-sub-lg;
		}
		.py-sub {
			@apply py-sub-lg;
		}
		.p-base {
			@apply p-base-lg;
		}
		.px-base {
			@apply px-base-lg;
		}
		.py-base {
			@apply py-base-lg;
		}
		.h-1row {
			@apply h-1row-lg;
		}
		.h-2row {
			@apply h-2row-lg;
		}
		.h-3row {
			@apply h-3row-lg;
		}
		.h-4row {
			@apply h-4row-lg;
		}
		.h-5row {
			@apply h-5row-lg;
		}
		.h-6row {
			@apply h-6row-lg;
		}
		.h-7row {
			@apply h-7row-lg;
		}
		.h-8row {
			@apply h-8row-lg;
		}
		.max-h-element {
			@apply max-h-element-max-lg;
		}
		/* ---------------------------- */
		/* ANCHOR Adaptive Text			*/
		/* ---------------------------- */
		.text-d1 {
			font-size: var(--text-d1-size) !important;
			line-height: var(--text-d1-lh) !important;
		}
		.text-d2 {
			font-size: var(--text-d2-size) !important;
			line-height: var(--text-d2-lh) !important;
		}
		.text-d3 {
			font-size: var(--text-d3-size) !important;
			line-height: var(--text-d3-lh) !important;
		}
		.text-i1 {
			font-size: var(--text-i1-size) !important;
			line-height: var(--text-i1-lh) !important;
		}
		h1 {
			@apply text-h1-size leading-h1;
		}
		header nav a.nav, header nav a:link.nav, header nav a:visited {
			@apply text-nava
		}
		footer nav a.nav, footer nav a:link.nav, footer nav a:visited {
			@apply text-nav-size leading-p1;
		}
	}

	/* --------------------------------------------------------------------	*/
	/* /DESIGNFRAME ADAPTIVE - Do not edit section 							*/
	/* !SECTION LG (960px+) Screens											*/
	/* --------------------------------------------------------------------	*/

	/* --------------------------------------------------------------------	*/
	/* SECTION XL (1440px+) Screens											*/
	/* DESIGNFRAME ADAPTIVE - Do not edit section 							*/
	/* --------------------------------------------------------------------	*/

	@media (min-width:1440px) { /* NOTE SCREEN XL CONFIG: You must manually set the min-width: here to match your --screen-xl value if you changed it from the preset Designframe Preferred Value. */
		/* ... */
		header {
			@apply px-shoulder-xl py-header-xl;
		}
		.header-base {
			@apply px-0 py-0;
		}
		.header-grid nav {
			@apply grid-cols-xl gap-x-gutter-xl;
		}
		.header-flex nav {
			@apply gap-x-gutter-xl;
		}
		footer {
			@apply px-shoulder-xl py-section-xl;
		}
		.footer-base {
			@apply px-0 py-0;
		}
		.footer-grid {
			@apply grid-cols-xl gap-gutter-xl;
		}
		.footer-flex nav {
			@apply gap-gutter-xl;
		}
		section {
			@apply px-shoulder-xl py-section-xl;
		}
		.section-grid, .centerframe-grid, .grid-base {
			@apply grid-cols-xl gap-gutter-xl;
		}
		.grid-cols {
			@apply grid-cols-xl;
		}
		.section-flex, .centerframe-flex, .flex-base {
			@apply gap-gutter-xl;
		}
		.mt-gutter {
			@apply mt-gutter-xl;
		}
		.mb-gutter {
			@apply mb-gutter-xl;
		}
		.ml-gutter {
			@apply ml-gutter-xl;
		}
		.mr-gutter {
			@apply mr-gutter-xl;
		}
		.pt-gutter {
			@apply pt-gutter-xl;
		}
		.pb-gutter {
			@apply pb-gutter-xl;
		}
		.pl-gutter {
			@apply pl-gutter-xl;
		}
		.pr-gutter {
			@apply pr-gutter-xl;
		}
		.mt-article {
			@apply mt-article-xl;
		}
		.mb-article {
			@apply mb-article-xl;
		}
		.ml-article {
			@apply ml-article-xl;
		}
		.mr-article {
			@apply mr-article-xl;
		}
		.gap-form {
			@apply gap-form-xl;
		}
		.gap-gutter {
			@apply gap-gutter-xl;
		}
		.gap-gutterx {
			@apply gap-gutterx-xl;
		}
		.gap-x-gutterx {
			@apply gap-x-gutterx-xl;
		}
		.gap-y-gutterx {
			@apply gap-y-gutterx-xl;
		}
		.gap-gutterxx {
			@apply gap-gutterxx-xl;
		}
		.gap-x-gutterxx {
			@apply gap-x-gutterxx-xl;
		}
		.gap-y-gutterxx {
			@apply gap-y-gutterxx-xl;
		}
		.gap-stack {
			@apply gap-stack-xl;
		}
		.gap-y-stack {
			@apply gap-y-stack-xl;
		}
		.gap-x-stack {
			@apply gap-x-stack-xl;
		}
		.space-y-card {
			@apply space-y-card-xl;
		}
		.space-x-card {
			@apply space-x-card-xl;
		}
		.section-grid.gap-2gutter, .centerframe-grid.gap-2gutter, .grid-base.gap-2gutter, .section-flex.gap-2gutter, .centerframe-flex.gap-2gutter, .flex-base.gap-2gutter {
			@apply gap-2gutter-xl;
		}
		.gap-2gutter {
			@apply gap-2gutter-xl;
		}
		.gap-x-gutter {
			@apply gap-x-gutter-xl;
		}
		.gap-y-gutter {
			@apply gap-y-gutter-xl;
		}
		.gap-x-2gutter {
			@apply gap-x-2gutter-xl;
		}
		.gap-y-2gutter {
			@apply gap-y-2gutter-xl;
		}
		.section-grid .block-xs, .centerframe-grid .block-xs, .grid-base .block-xs {
			@apply col-xl-xs;
		}
		.section-grid .block-sm, .centerframe-grid .block-sm, .grid-base .block-sm {
			@apply col-xl-sm;
		}
		.section-grid .block-smx, .centerframe-grid .block-smx, .grid-base .block-smx {
			@apply col-xl-smx;
		}
		.section-grid .block-md, .centerframe-grid .block-md, .grid-base .block-md {
			@apply col-xl-md;
		}
		.section-grid .block-mdx, .centerframe-grid .block-mdx, .grid-base .block-mdx {
			@apply col-xl-mdx;
		}
		.section-grid .block-lg, .centerframe-grid .block-lg, .grid-base .block-lg {
			@apply col-xl-lg;
		}
		.section-grid .block-lgx, .centerframe-grid .block-lgx, .grid-base .block-lgx {
			@apply col-xl-lgx;
		}
		.section-grid .block-xl, .centerframe-grid .block-xl, .grid-base .block-xl {
			@apply col-xl-xl;
		}
		.section-grid .block-xlx, .centerframe-grid .block-xlx, .grid-base .block-xlx {
			@apply col-xl-xlx;
		}
		.section-grid .block-2xl, .centerframe-grid .block-2xl, .grid-base .block-2xl {
			@apply col-xl-2xl;
		}
		.section-grid .block-2xlx, .centerframe-grid .block-2xlx, .grid-base .block-2xlx {
			@apply col-xl-2xlx;
		}
		.section-grid .block-full, .centerframe-grid .block-full, .grid-base .block-full {
			@apply col-span-full;
		}
		.section-flex .block-xs, .centerframe-flex .block-xs, .flex-base .block-xs {
			@apply shrink grow basis-xl-xs;
		}
		.section-flex .block-sm, .centerframe-flex .block-sm, .flex-base .block-sm {
			@apply shrink grow basis-xl-sm;
		}
		.section-flex .block-smx, .centerframe-flex .block-smx, .flex-base .block-smx {
			@apply shrink grow basis-xl-smx;
		}
		.section-flex .block-md, .centerframe-flex .block-md, .flex-base .block-md {
			@apply shrink grow basis-xl-md;
		}
		.section-flex .block-mdx, .centerframe-flex .block-mdx, .flex-base .block-mdx {
			@apply shrink grow basis-xl-mdx;
		}
		.section-flex .block-lg, .centerframe-flex .block-lg, .flex-base .block-lg {
			@apply shrink grow basis-xl-lg;
		}
		.section-flex .block-lgx, .centerframe-flex .block-lgx, .flex-base .block-lgx {
			@apply shrink grow basis-xl-lgx;
		}
		.section-flex .block-xl, .centerframe-flex .block-xl, .flex-base .block-xl {
			@apply shrink grow basis-xl-xl;
		}
		.section-flex .block-xlx, .centerframe-flex .block-xlx, .flex-base .block-xlx {
			@apply shrink grow basis-xl-xlx;
		}
		.section-flex .block-2xl, .centerframe-flex .block-2xl, .flex-base .block-2xl {
			@apply shrink grow basis-xl-2xl;
		}
		.section-flex .block-2xlx, .centerframe-flex .block-2xlx, .flex-base .block-2xlx {
			@apply shrink grow basis-xl-2xlx;
		}
		.section-flex .block-full, .centerframe-flex .block-full {
			@apply shrink grow basis-full;
		}
		.rounded-card {
			@apply rounded-article-xl;
		}
		.rounded-t-card {
			@apply rounded-t-article-xl;
		}
		.rounded-b-card {
			@apply rounded-b-article-xl;
		}
		.rounded-l-card {
			@apply rounded-l-article-xl;
		}
		.rounded-r-card {
			@apply rounded-r-article-xl;
		}
		.card {
			@apply rounded-article-xl p-article-xl;
		}
		.card-base {
			@apply rounded-article-xl;
		}
		.block-base, .block-xs, .block-sm, .block-smx, .block-md, .block-mdx, .block-lg, .block-lgx, .block-xl, .block-xlx, .block-2xl, .block-2xlx, .block-full {
			@apply space-y-block-xl;
		}
		.block-base.card, .block-xs.card, .block-sm.card, .block-smx.card, .block-md.card, .block-mdx.card, .block-lg.card, .block-lgx.card, .block-xl.card, .block-xlx.card, .block-2xl.card, .block-2xlx.card, .block-full.card {
			@apply space-y-card-xl;
		}
		.stack-y, .block-base.stack-y, .block-xs.stack-y, .block-sm.stack-y, .block-smx.stack-y, .block-md.stack-y, .block-mdx.stack-y, .block-lg.stack-y, .block-lgx.stack-y, .block-xl.stack-y, .block-xlx.stack-y, .block-2xl.stack-y, .block-2xlx.stack-y, .block-full.stack {
			@apply space-y-card-xl;
		}
		.stack-x, .block-base.stack-x, .block-xs.stack-x, .block-sm.stack-x, .block-smx.stack-x, .block-md.stack-x, .block-mdx.stack-x, .block-lg.stack-x, .block-lgx.stack-x, .block-xl.stack-x, .block-xlx.stack-x, .block-2xl.stack-x, .block-2xlx.stack-x, .block-full.stack {
			@apply space-x-card-xl;
		}
		.datarow, .formrow {
			@apply gap-stack-xl;
		}
		.card .datarow, .card-base .datarow,
		.card .formrow, .card-base .formrow {
			@apply gap-card-xl;
		}
		.stack-row {
			@apply flex flex-row gap-x-stack-xl;
		}
		.stack-row-wrap {
			@apply flex flex-row gap-x-stack-xl gap-y-stack-xl flex-wrap;
		}
		.stack-col {
			@apply flex flex-col gap-stack-xl;
		}
		.stack-form-col {
			@apply flex flex-col gap-form-xl;
		}
		.card .stack-row, .card-base .stack-row {
			@apply flex flex-row gap-x-card-xl;
		}
		.card .stack-row-wrap, .card-base .stack-row-wrap {
			@apply flex flex-row gap-x-card-xl gap-y-stack-xl flex-wrap;
		}
		.card .stack-col, .card-base .stack-col {
			@apply flex flex-col gap-y-card-xl;
		}
		.pl-half-gutter {
			@apply pl-half-gutter-xl;
		}
		.pr-half-gutter {
			@apply pr-half-gutter-xl;
		}
		.ml-half-gutter {
			@apply ml-half-gutter-xl;
		}
		.mr-half-gutter {
			@apply mr-half-gutter-xl;
		}
		.max-w-half-breakpoint-less-gutter {
			max-width: calc((var(--screen-xl) / 2) - var(--spacing-gutter-xl));
		}
		.max-w-half-breakpoint {
			max-width: calc(var(--screen-xl) / 2);
		}
		.max-w-breakpoint {
			@apply max-w-screen-xl;
		}
		.pr-col {
			@apply pr-col-xl;
		}
		.pl-col {
			@apply pl-col-xl;
		}
		.p-header-safeview {
			padding-top: max(var(--pad-header-xl), env(safe-area-inset-top));
			padding-left: max(var(--spacing-shoulder-xl), env(safe-area-inset-left));
			padding-right: max(var(--spacing-shoulder-xl), env(safe-area-inset-right));
			padding-bottom: var(--pad-header-xl);
		}
		.p-section-safeview {
			padding-top: max(var(--pad-section-xl), env(safe-area-inset-top));
			padding-left: max(var(--spacing-shoulder-xl), env(safe-area-inset-left));
			padding-right: max(var(--spacing-shoulder-xl), env(safe-area-inset-right));
			padding-bottom: max(var(--pad-section-xl), env(safe-area-inset-bottom));
		}
		.pt-section-safeview {
			padding-top: max(var(--pad-section-xl), env(safe-area-inset-top));
		}
		.pb-section-safeview {
			padding-bottom: max(var(--pad-section-xl), env(safe-area-inset-bottom));
		}
		.pl-section-safeview {
			padding-left: max(var(--spacing-shoulder-xl), env(safe-area-inset-left));
		}
		.pr-section-safeview {
			padding-right: max(var(--spacing-shoulder-xl), env(safe-area-inset-right));
		}
		.px-section-safeview {
			padding-left: max(var(--spacing-shoulder-xl), env(safe-area-inset-left));
			padding-right: max(var(--spacing-shoulder-xl), env(safe-area-inset-right));
		}
		.py-section-safeview {
			padding-top: max(var(--pad-section-xl), env(safe-area-inset-top));
			padding-bottom: max(var(--pad-section-xl), env(safe-area-inset-bottom));
		}
		.p-footer-safeview {
			padding-top: var(--pad-section-xl);
			padding-left: max(var(--spacing-shoulder-xl), env(safe-area-inset-left));
			padding-right: max(var(--spacing-shoulder-xl), env(safe-area-inset-right));
			padding-bottom: max(var(--pad-section-xl), env(safe-area-inset-bottom));
		}
		.m-header-safeview {
			margin-top: max(var(--pad-header-xl), env(safe-area-inset-top));
			margin-left: max(var(--spacing-shoulder-xl), env(safe-area-inset-left));
			margin-right: max(var(--spacing-shoulder-xl), env(safe-area-inset-right));
			margin-bottom: var(--pad-header-xl);
		}
		.m-section-safeview {
			margin-top: max(var(--pad-section-xl), env(safe-area-inset-top));
			margin-left: max(var(--spacing-shoulder-xl), env(safe-area-inset-left));
			margin-right: max(var(--spacing-shoulder-xl), env(safe-area-inset-right));
			margin-bottom: max(var(--pad-section-xl), env(safe-area-inset-bottom));
		}
		.ml-section-safeview {
			margin-left: max(var(--spacing-shoulder-xl), env(safe-area-inset-left));
		}
		.mr-section-safeview {
			margin-right: max(var(--spacing-shoulder-xl), env(safe-area-inset-right));
		}
		.mt-section-safeview {
			margin-top: max(var(--pad-section-xl), env(safe-area-inset-top));
		}
		.mb-section-safeview {
			margin-bottom: max(var(--pad-section-xl), env(safe-area-inset-bottom));
		}
		.mx-section-safeview {
			margin-left: max(var(--spacing-shoulder-xl), env(safe-area-inset-left));
			margin-right: max(var(--spacing-shoulder-xl), env(safe-area-inset-right));
		}
		.my-section-safeview {
			margin-top: max(var(--pad-section-xl), env(safe-area-inset-top));
			margin-bottom: max(var(--pad-section-xl), env(safe-area-inset-bottom));
		}
		.m-footer-safeview {
			margin-top: var(--pad-section-xl);
			margin-left: max(var(--spacing-shoulder-xl), env(safe-area-inset-left));
			margin-right: max(var(--spacing-shoulder-xl), env(safe-area-inset-right));
			margin-bottom: max(var(--pad-section-xl), env(safe-area-inset-bottom));
		}
		.p-header {
			@apply px-shoulder-xl py-header-xl;
		}
		.px-header {
			@apply px-shoulder-xl;
		}
		.py-header {
			@apply py-header-xl;
		}
		.p-shoulder {
			@apply p-shoulder-xl;
		}
		.pl-shoulder {
			@apply pl-shoulder-xl;
		}
		.pr-shoulder {
			@apply pr-shoulder-xl;
		}
		.p-section {
			@apply p-section-xl;
		}
		.px-section {
			@apply px-section-xl;
		}
		.py-section {
			@apply py-section-xl;
		}
		.pt-section {
			@apply pt-section-xl;
		}
		.pb-section {
			@apply pb-section-xl;
		}
		.pl-section {
			@apply pl-section-xl;
		}
		.pr-section {
			@apply pr-section-xl;
		}
		.p-article {
			@apply p-article-xl;
		}
		.px-article {
			@apply px-article-xl;
		}
		.py-article {
			@apply py-article-xl;
		}
		.p-block {
			@apply p-block-xl;
		}
		.px-block {
			@apply px-block-xl;
		}
		.py-block {
			@apply py-block-xl;
		}
		.p-card {
			@apply p-card-xl;
		}
		.px-card {
			@apply px-card-xl;
		}
		.py-card {
			@apply py-card-xl;
		}
		.p-element {
			@apply p-element-xl;
		}
		.px-element {
			@apply px-element-xl;
		}
		.py-element {
			@apply py-element-xl;
		}
		.pl-element {
			@apply pl-element-xl;
		}
		.pr-element {
			@apply pr-element-xl;
		}
		.pt-element {
			@apply pt-element-xl;
		}
		.pb-element {
			@apply pb-element-xl;
		}
		.p-sub {
			@apply p-sub-xl;
		}
		.pt-sub {
			@apply pt-sub-xl;
		}
		.pb-sub {
			@apply pb-sub-xl;
		}
		.pl-sub {
			@apply pl-sub-xl;
		}
		.pr-sub {
			@apply pr-sub-xl;
		}
		.px-sub {
			@apply px-sub-xl;
		}
		.py-sub {
			@apply py-sub-xl;
		}
		.p-base {
			@apply p-base-xl;
		}
		.px-base {
			@apply px-base-xl;
		}
		.py-base {
			@apply py-base-xl;
		}
		.h-1row {
			@apply h-1row-xl;
		}
		.h-2row {
			@apply h-2row-xl;
		}
		.h-3row {
			@apply h-3row-xl;
		}
		.h-4row {
			@apply h-4row-xl;
		}
		.h-5row {
			@apply h-5row-xl;
		}
		.h-6row {
			@apply h-6row-xl;
		}
		.h-7row {
			@apply h-7row-xl;
		}
		.h-8row {
			@apply h-8row-xl;
		}
		.max-h-element {
			@apply max-h-element-max-xl;
		}
		/* ---------------------------- */
		/* ANCHOR Adaptive Text			*/
		/* ---------------------------- */
		.text-d1 {
			font-size: var(--text-d1-size) !important;
			line-height: var(--text-d1-lh) !important;
		}
		.text-d2 {
			font-size: var(--text-d2-size) !important;
			line-height: var(--text-d2-lh) !important;
		}
		.text-d3 {
			font-size: var(--text-d3-size) !important;
			line-height: var(--text-d3-lh) !important;
		}
		.text-i1 {
			font-size: var(--text-i1-size) !important;
			line-height: var(--text-i1-lh) !important;
		}
		h1 {
			@apply text-h1-size leading-h1;
		}
		header nav a.nav, header nav a:link.nav, header nav a:visited {
			@apply text-nav-size leading-nav;
		}
		footer nav a.nav, footer nav a:link.nav, footer nav a:visited {
			@apply text-nav-size leading-p1;
		}
	}

	/* --------------------------------------------------------------------	*/
	/* /DESIGNFRAME ADAPTIVE - Do not edit section 							*/
	/* !SECTION XL (1440px+) Screens										*/
	/* --------------------------------------------------------------------	*/

	/* --------------------------------------------------------------------	*/
	/* SECTION 2XL (1600px+) Screens										*/
	/* DESIGNFRAME ADAPTIVE - Do not edit section 							*/
	/* --------------------------------------------------------------------	*/

	@media (min-width:1600px) { /* NOTE SCREEN 2XL CONFIG: You must manually set the min-width: here to match your --screen-2xl value if you changed it from the preset Designframe Preferred Value. */
		/* ... */
		header {
			@apply px-shoulder-2xl py-header-2xl;
		}
		.header-base {
			@apply px-0 py-0;
		}
		.header-grid nav {
			@apply grid-cols-2xl gap-x-gutter-2xl;
		}
		.header-flex nav {
			@apply gap-x-gutter-2xl;
		}
		footer {
			@apply px-shoulder-2xl py-section-2xl;
		}
		.footer-base {
			@apply px-0 py-0;
		}
		.footer-grid {
			@apply grid-cols-2xl gap-gutter-2xl;
		}
		.footer-flex nav {
			@apply gap-gutter-2xl;
		}
		section {
			@apply px-shoulder-2xl py-section-2xl;
		}
		.section-grid, .centerframe-grid, .grid-base {
			@apply grid-cols-2xl gap-gutter-2xl;
		}
		.grid-cols {
			@apply grid-cols-2xl;
		}
		.section-flex, .centerframe-flex, .flex-base {
			@apply gap-gutter-2xl;
		}
		.mt-gutter {
			@apply mt-gutter-2xl;
		}
		.mb-gutter {
			@apply mb-gutter-2xl;
		}
		.ml-gutter {
			@apply ml-gutter-2xl;
		}
		.mr-gutter {
			@apply mr-gutter-2xl;
		}
		.pt-gutter {
			@apply pt-gutter-2xl;
		}
		.pb-gutter {
			@apply pb-gutter-2xl;
		}
		.pl-gutter {
			@apply pl-gutter-2xl;
		}
		.pr-gutter {
			@apply pr-gutter-2xl;
		}
		.mt-article {
			@apply mt-article-2xl;
		}
		.mb-article {
			@apply mb-article-2xl;
		}
		.ml-article {
			@apply ml-article-2xl;
		}
		.mr-article {
			@apply mr-article-2xl;
		}
		.gap-form {
			@apply gap-form-2xl;
		}
		.gap-gutter {
			@apply gap-gutter-2xl;
		}
		.gap-gutterx {
			@apply gap-gutterx-2xl;
		}
		.gap-x-gutterx {
			@apply gap-x-gutterx-2xl;
		}
		.gap-y-gutterx {
			@apply gap-y-gutterx-2xl;
		}
		.gap-gutterxx {
			@apply gap-gutterxx-2xl;
		}
		.gap-x-gutterxx {
			@apply gap-x-gutterxx-2xl;
		}
		.gap-y-gutterxx {
			@apply gap-y-gutterxx-2xl;
		}
		.gap-stack {
			@apply gap-stack-2xl;
		}
		.gap-y-stack {
			@apply gap-y-stack-2xl;
		}
		.gap-x-stack {
			@apply gap-x-stack-2xl;
		}
		.space-y-card {
			@apply space-y-card-2xl;
		}
		.space-x-card {
			@apply space-x-card-2xl;
		}
		.section-grid.gap-2gutter, .centerframe-grid.gap-2gutter, .grid-base.gap-2gutter, .section-flex.gap-2gutter, .centerframe-flex.gap-2gutter, .flex-base.gap-2gutter {
			@apply gap-2gutter-2xl;
		}
		.gap-2gutter {
			@apply gap-2gutter-2xl;
		}
		.gap-x-gutter {
			@apply gap-x-gutter-2xl;
		}
		.gap-y-gutter {
			@apply gap-y-gutter-2xl;
		}
		.gap-x-2gutter {
			@apply gap-x-2gutter-2xl;
		}
		.gap-y-2gutter {
			@apply gap-y-2gutter-2xl;
		}
		.section-grid .block-xs, .centerframe-grid .block-xs, .grid-base .block-xs {
			@apply col-2xl-xs;
		}
		.section-grid .block-sm, .centerframe-grid .block-sm, .grid-base .block-sm {
			@apply col-2xl-sm;
		}
		.section-grid .block-smx, .centerframe-grid .block-smx, .grid-base .block-smx {
			@apply col-2xl-smx;
		}
		.section-grid .block-md, .centerframe-grid .block-md, .grid-base .block-md {
			@apply col-2xl-md;
		}
		.section-grid .block-mdx, .centerframe-grid .block-mdx, .grid-base .block-mdx {
			@apply col-2xl-mdx;
		}
		.section-grid .block-lg, .centerframe-grid .block-lg, .grid-base .block-lg {
			@apply col-2xl-lg;
		}
		.section-grid .block-lgx, .centerframe-grid .block-lgx, .grid-base .block-lgx {
			@apply col-2xl-lgx;
		}
		.section-grid .block-xl, .centerframe-grid .block-xl, .grid-base .block-xl {
			@apply col-2xl-xl;
		}
		.section-grid .block-xlx, .centerframe-grid .block-xlx, .grid-base .block-xlx {
			@apply col-2xl-xlx;
		}
		.section-grid .block-2xl, .centerframe-grid .block-2xl, .grid-base .block-2xl {
			@apply col-2xl-2xl;
		}
		.section-grid .block-2xlx, .centerframe-grid .block-2xlx, .grid-base .block-2xlx {
			@apply col-2xl-2xlx;
		}
		.section-grid .block-full, .centerframe-grid .block-full, .grid-base .block-full {
			@apply col-span-full;
		}
		.section-flex .block-xs, .centerframe-flex .block-xs, .flex-base .block-xs {
			@apply shrink grow basis-2xl-xs;
		}
		.section-flex .block-sm, .centerframe-flex .block-sm, .flex-base .block-sm {
			@apply shrink grow basis-2xl-sm;
		}
		.section-flex .block-smx, .centerframe-flex .block-smx, .flex-base .block-smx {
			@apply shrink grow basis-2xl-smx;
		}
		.section-flex .block-md, .centerframe-flex .block-md, .flex-base .block-md {
			@apply shrink grow basis-2xl-md;
		}
		.section-flex .block-mdx, .centerframe-flex .block-mdx, .flex-base .block-mdx {
			@apply shrink grow basis-2xl-mdx;
		}
		.section-flex .block-lg, .centerframe-flex .block-lg, .flex-base .block-lg {
			@apply shrink grow basis-2xl-lg;
		}
		.section-flex .block-lgx, .centerframe-flex .block-lgx, .flex-base .block-lgx {
			@apply shrink grow basis-2xl-lgx;
		}
		.section-flex .block-xl, .centerframe-flex .block-xl, .flex-base .block-xl {
			@apply shrink grow basis-2xl-xl;
		}
		.section-flex .block-xlx, .centerframe-flex .block-xlx, .flex-base .block-xlx {
			@apply shrink grow basis-2xl-xlx;
		}
		.section-flex .block-2xl, .centerframe-flex .block-2xl, .flex-base .block-2xl {
			@apply shrink grow basis-2xl-2xl;
		}
		.section-flex .block-2xlx, .centerframe-flex .block-2xlx, .flex-base .block-2xlx {
			@apply shrink grow basis-2xl-2xlx;
		}
		.section-flex .block-full, .centerframe-flex .block-full {
			@apply shrink grow basis-full;
		}
		.rounded-card {
			@apply rounded-article-2xl;
		}
		.rounded-t-card {
			@apply rounded-t-article-2xl;
		}
		.rounded-b-card {
			@apply rounded-b-article-2xl;
		}
		.rounded-l-card {
			@apply rounded-l-article-2xl;
		}
		.rounded-r-card {
			@apply rounded-r-article-2xl;
		}
		.card {
			@apply rounded-article-2xl p-article-2xl;
		}
		.card-base {
			@apply rounded-article-2xl;
		}
		.block-base, .block-xs, .block-sm, .block-smx, .block-md, .block-mdx, .block-lg, .block-lgx, .block-xl, .block-xlx, .block-2xl, .block-2xlx, .block-full {
			@apply space-y-block-2xl;
		}
		.block-base.card, .block-xs.card, .block-sm.card, .block-smx.card, .block-md.card, .block-mdx.card, .block-lg.card, .block-lgx.card, .block-xl.card, .block-xlx.card, .block-2xl.card, .block-2xlx.card, .block-full.card {
			@apply space-y-card-2xl;
		}
		.stack-y, .block-base.stack-y, .block-xs.stack-y, .block-sm.stack-y, .block-smx.stack-y, .block-md.stack-y, .block-mdx.stack-y, .block-lg.stack-y, .block-lgx.stack-y, .block-xl.stack-y, .block-xlx.stack-y, .block-2xl.stack-y, .block-2xlx.stack-y, .block-full.stack {
			@apply space-y-card-2xl;
		}
		.stack-x, .block-base.stack-x, .block-xs.stack-x, .block-sm.stack-x, .block-smx.stack-x, .block-md.stack-x, .block-mdx.stack-x, .block-lg.stack-x, .block-lgx.stack-x, .block-xl.stack-x, .block-xlx.stack-x, .block-2xl.stack-x, .block-2xlx.stack-x, .block-full.stack {
			@apply space-x-card-2xl;
		}
		.datarow, .formrow {
			@apply gap-stack-2xl;
		}
		.card .datarow, .card-base .datarow,
		.card .formrow, .card-base .formrow {
			@apply gap-card-2xl;
		}
		.stack-row {
			@apply flex flex-row gap-x-stack-2xl;
		}
		.stack-row-wrap {
			@apply flex flex-row gap-x-stack-2xl gap-y-stack-2xl flex-wrap;
		}
		.stack-col {
			@apply flex flex-col gap-stack-2xl;
		}
		.stack-form-col {
			@apply flex flex-col gap-form-2xl;
		}
		.card .stack-row, .card-base .stack-row {
			@apply flex flex-row gap-x-card-2xl;
		}
		.card .stack-row-wrap, .card-base .stack-row-wrap {
			@apply flex flex-row gap-x-card-2xl gap-y-stack-2xl flex-wrap;
		}
		.card .stack-col, .card-base .stack-col {
			@apply flex flex-col gap-y-card-2xl;
		}
		.pl-half-gutter {
			@apply pl-half-gutter-2xl;
		}
		.pr-half-gutter {
			@apply pr-half-gutter-2xl;
		}
		.ml-half-gutter {
			@apply ml-half-gutter-2xl;
		}
		.mr-half-gutter {
			@apply mr-half-gutter-2xl;
		}
		.max-w-half-breakpoint-less-gutter {
			max-width: calc((var(--screen-2xl) / 2) - var(--spacing-gutter-2xl));
		}
		.max-w-half-breakpoint {
			max-width: calc(var(--screen-2xl) / 2);
		}
		.max-w-breakpoint {
			@apply max-w-screen-2xl;
		}
		.pr-col {
			@apply pr-col-2xl;
		}
		.pl-col {
			@apply pl-col-2xl;
		}
		.p-header-safeview {
			padding-top: max(var(--pad-header-2xl), env(safe-area-inset-top));
			padding-left: max(var(--spacing-shoulder-2xl), env(safe-area-inset-left));
			padding-right: max(var(--spacing-shoulder-2xl), env(safe-area-inset-right));
			padding-bottom: var(--pad-header-2xl);
		}
		.p-section-safeview {
			padding-top: max(var(--pad-section-2xl), env(safe-area-inset-top));
			padding-left: max(var(--spacing-shoulder-2xl), env(safe-area-inset-left));
			padding-right: max(var(--spacing-shoulder-2xl), env(safe-area-inset-right));
			padding-bottom: max(var(--pad-section-2xl), env(safe-area-inset-bottom));
		}
		.pt-section-safeview {
			padding-top: max(var(--pad-section-2xl), env(safe-area-inset-top));
		}
		.pb-section-safeview {
			padding-bottom: max(var(--pad-section-2xl), env(safe-area-inset-bottom));
		}
		.pl-section-safeview {
			padding-left: max(var(--spacing-shoulder-2xl), env(safe-area-inset-left));
		}
		.pr-section-safeview {
			padding-right: max(var(--spacing-shoulder-2xl), env(safe-area-inset-right));
		}
		.px-section-safeview {
			padding-left: max(var(--spacing-shoulder-2xl), env(safe-area-inset-left));
			padding-right: max(var(--spacing-shoulder-2xl), env(safe-area-inset-right));
		}
		.py-section-safeview {
			padding-top: max(var(--pad-section-2xl), env(safe-area-inset-top));
			padding-bottom: max(var(--pad-section-2xl), env(safe-area-inset-bottom));
		}
		.p-footer-safeview {
			padding-top: var(--pad-section-2xl);
			padding-left: max(var(--spacing-shoulder-2xl), env(safe-area-inset-left));
			padding-right: max(var(--spacing-shoulder-2xl), env(safe-area-inset-right));
			padding-bottom: max(var(--pad-section-2xl), env(safe-area-inset-bottom));
		}
		.m-header-safeview {
			margin-top: max(var(--pad-header-2xl), env(safe-area-inset-top));
			margin-left: max(var(--spacing-shoulder-2xl), env(safe-area-inset-left));
			margin-right: max(var(--spacing-shoulder-2xl), env(safe-area-inset-right));
			margin-bottom: var(--pad-header-2xl);
		}
		.m-section-safeview {
			margin-top: max(var(--pad-section-2xl), env(safe-area-inset-top));
			margin-left: max(var(--spacing-shoulder-2xl), env(safe-area-inset-left));
			margin-right: max(var(--spacing-shoulder-2xl), env(safe-area-inset-right));
			margin-bottom: max(var(--pad-section-2xl), env(safe-area-inset-bottom));
		}
		.ml-section-safeview {
			margin-left: max(var(--spacing-shoulder-2xl), env(safe-area-inset-left));
		}
		.mr-section-safeview {
			margin-right: max(var(--spacing-shoulder-2xl), env(safe-area-inset-right));
		}
		.mt-section-safeview {
			margin-top: max(var(--pad-section-2xl), env(safe-area-inset-top));
		}
		.mb-section-safeview {
			margin-bottom: max(var(--pad-section-2xl), env(safe-area-inset-bottom));
		}
		.mx-section-safeview {
			margin-left: max(var(--spacing-shoulder-2xl), env(safe-area-inset-left));
			margin-right: max(var(--spacing-shoulder-2xl), env(safe-area-inset-right));
		}
		.my-section-safeview {
			margin-top: max(var(--pad-section-2xl), env(safe-area-inset-top));
			margin-bottom: max(var(--pad-section-2xl), env(safe-area-inset-bottom));
		}
		.m-footer-safeview {
			margin-top: var(--pad-section-2xl);
			margin-left: max(var(--spacing-shoulder-2xl), env(safe-area-inset-left));
			margin-right: max(var(--spacing-shoulder-2xl), env(safe-area-inset-right));
			margin-bottom: max(var(--pad-section-2xl), env(safe-area-inset-bottom));
		}
		.p-header {
			@apply px-shoulder-2xl py-header-2xl;
		}
		.px-header {
			@apply px-shoulder-2xl;
		}
		.py-header {
			@apply py-header-2xl;
		}
		.p-shoulder {
			@apply p-shoulder-2xl;
		}
		.pl-shoulder {
			@apply pl-shoulder-2xl;
		}
		.pr-shoulder {
			@apply pr-shoulder-2xl;
		}
		.p-section {
			@apply p-section-2xl;
		}
		.px-section {
			@apply px-section-2xl;
		}
		.py-section {
			@apply py-section-2xl;
		}
		.pt-section {
			@apply pt-section-2xl;
		}
		.pb-section {
			@apply pb-section-2xl;
		}
		.pl-section {
			@apply pl-section-2xl;
		}
		.pr-section {
			@apply pr-section-2xl;
		}
		.p-article {
			@apply p-article-2xl;
		}
		.px-article {
			@apply px-article-2xl;
		}
		.py-article {
			@apply py-article-2xl;
		}
		.p-block {
			@apply p-block-2xl;
		}
		.px-block {
			@apply px-block-2xl;
		}
		.py-block {
			@apply py-block-2xl;
		}
		.p-card {
			@apply p-card-2xl;
		}
		.px-card {
			@apply px-card-2xl;
		}
		.py-card {
			@apply py-card-2xl;
		}
		.p-element {
			@apply p-element-2xl;
		}
		.px-element {
			@apply px-element-2xl;
		}
		.py-element {
			@apply py-element-2xl;
		}
		.pl-element {
			@apply pl-element-2xl;
		}
		.pr-element {
			@apply pr-element-2xl;
		}
		.pt-element {
			@apply pt-element-2xl;
		}
		.pb-element {
			@apply pb-element-2xl;
		}
		.p-sub {
			@apply p-sub-2xl;
		}
		.pt-sub {
			@apply pt-sub-2xl;
		}
		.pb-sub {
			@apply pb-sub-2xl;
		}
		.pl-sub {
			@apply pl-sub-2xl;
		}
		.pr-sub {
			@apply pr-sub-2xl;
		}
		.px-sub {
			@apply px-sub-2xl;
		}
		.py-sub {
			@apply py-sub-2xl;
		}
		.p-base {
			@apply p-base-2xl;
		}
		.px-base {
			@apply px-base-2xl;
		}
		.py-base {
			@apply py-base-2xl;
		}
		.h-1row {
			@apply h-1row-2xl;
		}
		.h-2row {
			@apply h-2row-2xl;
		}
		.h-3row {
			@apply h-3row-2xl;
		}
		.h-4row {
			@apply h-4row-2xl;
		}
		.h-5row {
			@apply h-5row-2xl;
		}
		.h-6row {
			@apply h-6row-2xl;
		}
		.h-7row {
			@apply h-7row-2xl;
		}
		.h-8row {
			@apply h-8row-2xl;
		}
		.max-h-element {
			@apply max-h-element-max-2xl;
		}
		/* ---------------------------- */
		/* ANCHOR Adaptive Text			*/
		/* ---------------------------- */
		.text-d1 {
			font-size: var(--text-d1-size) !important;
			line-height: var(--text-d1-lh) !important;
		}
		.text-d2 {
			font-size: var(--text-d2-size) !important;
			line-height: var(--text-d2-lh) !important;
		}
		.text-d3 {
			font-size: var(--text-d3-size) !important;
			line-height: var(--text-d3-lh) !important;
		}
		.text-i1 {
			font-size: var(--text-i1-size) !important;
			line-height: var(--text-i1-lh) !important;
		}
		h1 {
			@apply text-h1-size leading-h1;
		}
		header nav a.nav, header nav a:link.nav, header nav a:visited {
			@apply text-nav-size leading-nav;
		}
		footer nav a.nav, footer nav a:link.nav, footer nav a:visited {
			@apply text-nav-size leading-p1;
		}
	}
	/* --------------------------------------------------------------------	*/
	/* /DESIGNFRAME ADAPTIVE - Do not edit section 							*/
	/* !SECTION 2XL (1600px+) Screens										*/
	/* --------------------------------------------------------------------	*/

	/* -------------------------------- */
} /* Do not edit 					*/
/* !LAYER components end 			*/
/* -------------------------------------------------------------------------------------------- */
/* !SECTION DF Adaptives (Screen Media Queries)													*/
/* -------------------------------------------------------------------------------------------- */

/* -------------------------------------------------------------------------------------------- */
/* SECTION CONFIG Theming																		*/
/* -------------------------------------------------------------------------------------------- */
/* NOTE All targets here will inherit auto/adaptive structure from Designframe Core and			*/
/* Adaptives. All styles here are primarily for visual theming and any style can be overridden  */
/* by using inline CSS and Designframe/Tailwind classes in the HTML directly.					*/
/* -------------------------------------------------------------------------------------------- */
/* Designframe layers cascade in the following order (lowest to highest specificity:			*/
/* 	1. CONFIG Fonts, Linked Assets, and Variables is where your specific values are defined.	*/
/* 	2. HTML Element Applications apply Variables to create fallback styles.						*/
/* 	3. DF Core Layout Utilities set up Designframe base Layout utilities/classes.				*/
/* 	4. DF Core Visual Utilities set up Designframe color and visual utilities/classes.			*/
/* 	5. DF Components Structure set up colorless components, integrating Variables.				*/
/* 	5. DF Components Radius & Transitions apply component behaviors, integrating Variables.		*/
/* 	6. DF Components Visuals apply base colors to components, integrating Variables.			*/
/* 	8. DF Theming Variants set up .theme & .theme-* variants, integrating Variables.			*/
/* 	9. DF Adaptives apply layout and other adaptive behaviors across all screen sizes			*/
/* 10. CONFIG Theming is where your specific Global and Theme style modifiers are configured.	*/
/* 11. CONFIG Overrides (Optional) is for multi-instance overrides that would not be inline.	*/
/* 12. CONFIG Custom CSS Classes is for any additional custom styles that would not be inline.	*/
/* -------------------------------------------------------------------------------------------- */
/* Note: DF Documentation Classes are styles for the DF Docs and can be optionally removed.		*/
/* -------------------------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------------------------- */
/* Theme Sets																					*/
/* -------------------------------------------------------------------------------------------- */
/* BASE (All Themes) is a default which overrides the HTML Fallbacks and sets inherited styles. */
/* -------------------------------------------------------------------------------------------- */
/* THEME is your main color set and is used to determine the logical INVERT variations.			*/
/* THEME TRANSPARENT is same as THEME but with transparent background defaults for layering.	*/
/* THEME INVERT is should be defined as the invert/dark mode equivalent of THEME.				*/
/* THEME INVERT TRANSPARENT is same as THEME INVERT but with transparent background defaults.	*/
/* -------------------------------------------------------------------------------------------- */
/* THEME KEY is THEME-based but with Key Color(s) as backgrounds with colors adjusted.			*/
/* THEME KEY GRADIENT is THEME KEY ALT-based but with Key Gradient as backgrounds.				*/
/* -------------------------------------------------------------------------------------------- */
/* THEME ALT is an optional alternative color palette. 											*/
/* THEME ALT TRANSPARENT is same as THEME ALT but with transparent background defaults.			*/
/* THEME ALT INVERT is should be defined as the invert/dark mode equivalent of THEME ALT.		*/
/* THEME ALT INVERT TRANSPARENT is same as THEME ALT INVERT but with transparent backgrounds.	*/
/* -------------------------------------------------------------------------------------------- */
/* THEME KEY ALT is THEME ALT-based but with Key Alt as backgrounds with colors adjusted.		*/
/* THEME KEY ALT GRADIENT is THEME KEY ALT-based but with Key Alt Gradient as backgrounds.		*/
/* -------------------------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------------------------- */
/* How To Use Themes																			*/
/* -------------------------------------------------------------------------------------------- */
/* NOTE Apply theme and theme-* classes only to HEADER, FOOTER, and SECTION Elements, and only	*/
/* to DIV Elements with section-*, centerframe-*, grid-*, and flex-* classes. Avoid nesting a 	*/
/* theme class in another theme class to prevent conflicts and ensure proper cascading.			*/
/* -------------------------------------------------------------------------------------------- */
/* NOTE For specificity and semantic reasons, normal text links should be wrapped in a			*/
/* paragraph element, e.g. "<p><a href="#">text link</a></p>". This is specifically needed to 	*/
/* support reliable gradient applications. However, if a workaround is needed, use inline 		*/
/* Tailwind classes. If there are any issues, try adding bg-none and/or bg-clip-* to clear any 	*/
/* potential gradient conflicts. 																*/
/* -------------------------------------------------------------------------------------------- */
/* LAYER components begin 			*/
@layer components { /* Do not edit	*/
	/* ---------------------------------*/

	/* -------------------------------------------------------------------- */
	/* SECTION Base Styles													*/
	/* -------------------------------------------------------------------- */
	/* These are your stylings without a theme active and sets inheritance.	*/
	/* -------------------------------------------------------------------- */
	/* These are your Base Styles which override the HTML Fallback Styles, define DF (Non-Theme) Base Styles, and are the base inherited styles for all themes. Any differences in .theme and .theme-* styles will override when the that theme is active. All inline style in HTML or more specific Custom Classes at the end of this file will also override any differences. Generally, you should use this for sizing, weights, effects, and animation styles which you want apply consitently across all themes, plus default any properties. */

	/* ---------------------------- */
	/* ANCHOR  Icons Usage			*/
	/* ---------------------------- */
	/* Preferred set up uses Font Awesome. Use text utility to manage text-based icon sizing by using classes such as text-i1. Options are: i1, i2, 13, i4, i5, i6, in. */

	/* ---------------------------- */
	/* ANCHOR  Effects Config		*/
	/* ---------------------------- */
	/* These are defined sets of effects that are applied to most interacte elements by default, and can be optionally added to any element inline and using @apply. */

	.config-focus-set {
		@apply focus-set; /* Set this to focus-set to apply the focus defaults or change/add your chosen properties by applying Tailwind "focus:CLASS" format classes for all theme and non-theme states. */
	}

	.config-transition-set {
		@apply transition-set; /* Set this to transition-set to apply the transition settings defined by your Variables or change/add your chosen properties by applying Tailwind classes for all theme and non-theme states. */
	}

	/* ---------------------------- */
	/* ANCHOR  Key Text Config		*/
	/* ---------------------------- */
	/* Note that these apply to H1 to H6 elements. The logical variant (invert, etc) will be applied by the corresponding theme-* parent. */

	.config-text-key {
		@apply gradient-text-key; /* Set this to text-key or gradient-text-key to apply your chosen key color type (solid vs gradient). */
	}
	.config-text-key-invert {
		@apply gradient-text-key-invert; /* Set this to text-key-invert or gradient-text-key-invert to apply your chosen key invert color type (solid vs gradient). */
	}

	.config-text-key-alt {
		@apply gradient-text-key-alt; /* Set this to text-key-alt or gradient-text-key-alt to apply your chosen key alt color type (solid vs gradient). */
	}
	.config-text-key-alt-invert {
		@apply gradient-text-key-alt-invert; /* Set this to text-key-alt-invert or gradient-text-key-alt-invert to apply your chosen key alt invert color type (solid vs gradient). */
	}

	/* ---------------------------- */
	/* ANCHOR  Base Links Config	*/
	/* ---------------------------- */
	/* Note that these apply to H1 to H6 link elements and P A link elements("A" elements in a "P" element). The logical variants (invert, etc) will be applied by the corresponding theme-* parent. */

	/* CSNOTE All link configs are CS Specific. */

	.config-hlink-base {
		@apply gradient-text-key no-underline; /* Set this to text-key or gradient-text-key (solid vs gradient), and add any additional default global styles. */
	}
	.config-hlink-hover {
		@apply gradient-text-key opacity-gradient-active; /* Set this to text-key or gradient-text-key (solid vs gradient), and add hover plus any additional default global styles. */
	}
	.config-hlink-active {
		@apply gradient-text-key opacity-gradient-active; /* Set this to text-key or gradient-text-key (solid vs gradient), and add any additional default global styles. */
	}

	.config-plink-base {
		@apply gradient-text-key italic no-underline; /* Set this to text-key or gradient-text-key (solid vs gradient), and add any additional default global styles. */
	}
	.config-plink-hover {
		@apply gradient-text-key opacity-gradient-active; /* Set this to text-key or gradient-text-key (solid vs gradient), and add hover plus any additional default global styles. */
	}
	.config-plink-active {
		@apply gradient-text-key opacity-gradient-active; /* Set this to text-key or gradient-text-key (solid vs gradient), and add any additional default global styles. */
	}

	/* ---------------------------- */
	/* ANCHOR  Specific Links		*/
	/* ---------------------------- */
	/* Note that these apply to specific link elements and inherit the Base Links Config above but override any conflicting properties. The logical variants (invert, etc) will be applied by the corresponding theme-* parent. */

& header {
	nav p a, nav p a:link, nav p a:visited, nav p a:focus {
		@apply text-secondary font-bold not-italic;
	}
	nav p a:hover  {
		@apply gradient-text-key opacity-100;
	}
	nav p a:active  {
		@apply text-secondary opacity-gradient-active;
	}
}

& footer {
nav p a, nav p a:link, nav p a:visited, nav p a:focus {
	@apply text-secondary not-italic;
}
nav p a:hover  {
	@apply gradient-text-key opacity-100;
}
nav p a:active  {
	@apply text-secondary opacity-gradient-active;
}
}

/* ---------------------------- */
/* ANCHOR Text & Backgrounds	*/
/* ---------------------------- */
/* These are the Base default backgrounds and text color for BODY and MAIN. Use of a specific .theme and .theme-* will override bg-* colors and text-* colors. MAIN layers over BODY, and HEADER, SECTION, and FOOTER also layer above both BODY and MAIN. These override the @base layer plain HTML Fallbacks and become a new fallback/default. */

body {
	@apply bg-default text-primary;
}
header {
	@apply bg-default text-primary;
}
main {
	@apply bg-default text-primary;
}
footer {
	@apply bg-default text-primary;
}

& footer {
h1, h2, h3, h4, h5, h6 {
	@apply text-primary;
}
}

::placeholder {
	@apply italic;
}

/* ---------------------------- */
/* ANCHOR Mobile Menu			*/
/* ---------------------------- */

#header-nav-menu {
	@apply lg:hidden; /* NOTE: Must include *:hidden for screen breakpoint (sm,md,lg,xl,2xl) to specify when mobile menu should display. */
}
#header-nav-menu	nav a { /* Optionally include px-*, w-*, and text-center/text-* to override @base default structure. */
	@apply shadow-inner-light-y;
}
#header-nav-menu	nav a p { /* Optionally include px-*, w-*, and text-center/text-* to override @base default structure. */
	@apply font-bold no-underline;
}

/* ---------------------------- */
/* ANCHOR  Interactions			*/
/* ---------------------------- */
/* These effects are applied to all targeted elements by default. */

:focus-visible { /* Optional override of @base default :focus-visible */
	@apply outline-key;
}

.chip, .chip-gradient {
	@apply opacity-100;
}
.chip:hover, .chip-gradient:hover {
	@apply opacity-gradient-active;
}

/* ---------------------------- */
/* ANCHOR  Other Elements		*/
/* ---------------------------- */
/* These effects are applied to all targeted elements by default. */

.card, .card-base {
	@apply shadow-light; /* Applies defined --shadow-light to all cards */
}

/* ---------------------------- */
/* ANCHOR Custom Applications	*/
/* ---------------------------- */
/* You can optionally add any base custom applications here which will be inherited across all themes. */

/* CSNOTE All Custom Applications are CS Specific. */

#cs-space-home-welcome-image a i {
	@apply text-invert;
}

/* -------------------------------------------------------------------- */
/* !SECTION SECTION Base Styles											*/
/* -------------------------------------------------------------------- */

/* -------------------------------------------------------------------- */
/* SECTION Theme & Theme Transparent									*/
/* -------------------------------------------------------------------- */
/* Most of the colors styles here are inherited from the DF Visuals, Base, and Global colors. Only edit/add overrides or new styles if needed. */

/* ---------------------------- */
/* ANCHOR Containers			*/
/* ---------------------------- */
/* Use these optional classes to add matching theme styles to BODY and MAIN elements directly, but not implement theming to child elements (which should be implemented by adding the theme class to HEADER, SECTION, and FOOTER elements. Note that HEADER, SECTION, and FOOTER theming will visually layer on top of BODY and MAIN elements. */

.body-theme {
	@apply bg-background;
}

/* CSNOTE gradient-bg or space-specified color/gradient replaces default bg-background for full page background setting. */

.main-theme {
	@apply gradient-bg;
}

.body-theme-transparent, .main-theme-transparent {
	@apply bg-transparent;
}
.body-theme, .main-theme, .body-theme-transparent, .main-theme-transparent {
	@apply text-primary;
}

/* To directly style your header and footer element, you can apply a specific background and/or other styles here which will apply only to the header and footer elements (and child elements, if applicable) when the theme class is present. Alternatively, you can always use inline styles/classes. */

header.theme {
	@apply bg-header-background bg-none text-header-primary; /* If applying a solid color background here while also using a gradient background for the theme, both will be active and layered. If so, bg-none must be added here to clear the gradient so that the solid color background visible. */
}

& header.theme {
nav p a, nav p a:link, nav p a:visited, nav p a:focus {
	@apply text-header-nav-link font-header-nav-weight;
}
nav p a:hover  {
	@apply gradient-text-key opacity-100;
}
nav p a:active  {
	@apply text-secondary opacity-gradient-active;
}
}

footer.theme {
	@apply bg-footer-background bg-none text-footer-primary; /* If applying a solid color background here while also using a gradient background for the theme, both will be active and layered. If so, bg-none must be added here to clear the gradient so that the solid color background visible. */
}

& footer.theme {
nav p a, nav p a:link, nav p a:visited, nav p a:focus {
	@apply text-footer-nav-link font-footer-nav-weight;
}
nav p a:hover  {
	@apply gradient-text-key opacity-100;
}
nav p a:active  {
	@apply text-secondary opacity-gradient-active;
}
}

/* ---------------------------- */
/* ANCHOR Theme Applications	*/
/* ---------------------------- */
/* These styles apply to theme parent elements and nested child elements. Avoid nesting theme classes under another theme class to prevent specificity issues. */
/* Note: The following colors are inherited from the Base "All Themes" Styles - Global:	.theme h1 to h6, .theme-transparent h1 to h6, header.theme h1 to h6, footer.theme h1 to h6. If you apply a solid color to these elements while using a gradient test base style, you may need to also apply bg-none to clear the gradient background and/or add bg-clip-* to fix clipping. */

/* CSNOTE gradient-bg or space-specified color/gradient replaces default bg-background for FAM page background setting. */

.theme {
	@apply gradient-bg text-primary;
}

.theme-transparent {
	@apply bg-transparent text-primary;
}

& .theme, .theme-transparent {
.card, .card-base {
	@apply bg-background;
}
}

/* ---------------------------- */
/* ANCHOR Custom Applications	*/
/* ---------------------------- */
/* You can optionally add any theme-specific custom applications here. */

/* CSNOTE All Custom Applications are CS Specific. */

& .theme, .theme-transparent {

.cs-stack-title h2 span,
.cs-stack-title-filters h2 span,
.cs-stack-title-icons h2 span {
	@apply text-primary;
}

.cs-product-membership hgroup p,
.cs-product-item hgroup p,
.cs-featured-link a hgroup h4, .cs-featured-link hgroup p,
.cs-featured-access a hgroup h4, .cs-featured-access hgroup p,
.cs-featured-support a hgroup h4, .cs-featured-support hgroup p, .cs-featured-support hgroup p i {
	@apply text-invert;
}

}

/* -------------------------------------------------------------------- */
/* !SECTION Theme & Theme Transparent									*/
/* -------------------------------------------------------------------- */

/* -------------------------------------------------------------------- */
/* SECTION Theme Invert & Theme Invert Transparent						*/
/* -------------------------------------------------------------------- */
/* Only edit/add overrides or new styles if needed. DF Component color variants are predefined in DF Variants. */

/* ---------------------------- */
/* ANCHOR Containers			*/
/* ---------------------------- */
/* Use these optional classes to add matching theme styles to BODY and MAIN elements directly, but not implement theming to child elements (which should be implemented by adding the theme class to HEADER, SECTION, and FOOTER elements. Note that HEADER, SECTION, and FOOTER theming will visually layer on top of BODY and MAIN elements. */

.body-theme-invert, .main-theme-invert {
	@apply bg-primary;
}
.body-theme-invert-transparent, .main-theme-invert-transparent {
	@apply bg-transparent;
}
.body-theme-invert, .main-theme-invert, .body-theme-invert-transparent, .main-theme-invert-transparent {
	@apply text-invert;
}

/* To directly style your header and footer element, you can apply a specific background and/or other styles here which will apply only to the header and footer elements (and child elements, if applicable) when the theme class is present. Alternatively, you can always use inline styles/classes. */

header.theme-invert, footer.theme-invert {
	@apply bg-primary;
}

& header.theme-invert, footer.theme-invert {

h1, h2, h3, h4, h5, h6 {
	@apply text-invert;
}

nav p a, nav p a:link, nav p a:visited, nav p a:focus {
	@apply text-secondary;
}

nav p a:hover  {
	@apply gradient-text-key;
}

}

/* ---------------------------- */
/* ANCHOR Theme Applications	*/
/* ---------------------------- */
/* These styles apply to theme parent elements and nested child elements. Avoid nesting theme classes under another theme class to prevent specificity issues. */
/* Note: If you apply a solid color override to gradient elements, you may need to also apply bg-none to clear the gradient background and/or add bg-clip-* to fix clipping. */

.theme-invert {
	@apply bg-primary text-invert;
}

.theme-invert-transparent {
	@apply bg-transparent text-invert;
}

& .theme-invert, .theme-invert-transparent {
h1, h2, h3, h4, h5, h6 {
	@apply config-text-key; /* NOTE Enter config-text-key to keep the same key colors as .theme OR config-text-key-invert for your key-invert colors (which may be a equivalent to a solid color-background or an opposite color to color-key, depending on your set Variables). */
}
p a, p a:link, p a:visited {
	@apply config-text-key; /* NOTE Enter config-text-key to keep the same key colors as .theme OR config-text-key-invert for your key-invert colors (which may be a equivalent to a solid color-background or an opposite color to color-key, depending on your set Variables). */
}
.card, .card-base {
	@apply bg-background;
}
.card p, .card-base p {
	@apply text-primary;
}
}

/* ---------------------------- */
/* ANCHOR Custom Applications	*/
/* ---------------------------- */
/* You can optionally add any theme-specific custom applications here. */

/* CSNOTE All Custom Applications are CS Specific. */

& .theme-invert, .theme-invert-transparent {

.stack-row h2 span {
	@apply text-invert;
}

.cs-product-membership hgroup p, .cs-product-item hgroup p, .cs-featured-link a hgroup h4, .cs-featured-link hgroup p {
	@apply text-invert;
}

}

/* -------------------------------------------------------------------- */
/* !SECTION Theme Invert & Theme Invert Transparent						*/
/* -------------------------------------------------------------------- */

/* TODO Theme Key / Alt / Alt Invert / Key Alt and related variant are still under development. */

/* -------------------------------------------------------------------- */
/* SECTION Theme Key & Theme Key Gradient								*/
/* -------------------------------------------------------------------- */
/* Only edit/add overrides or new styles if needed. DF Component color variants are predefined in DF Variants. */

/* ---------------------------- */
/* ANCHOR Containers			*/
/* ---------------------------- */
/* Use these optional classes to add matching theme styles to BODY and MAIN elements directly, but not implement theming to child elements (which should be implemented by adding the theme class to HEADER, SECTION, and FOOTER elements. Note that HEADER, SECTION, and FOOTER theming will visually layer on top of BODY and MAIN elements. */

.body-theme-key, .main-theme-key {
	@apply bg-key;
}
.body-theme-key, .main-theme-key {
	@apply text-invert;
}
.body-theme-key-gradient, .main-theme-key-gradient {
	@apply gradient-key;
}
.body-theme-key-gradient, .main-theme-key-gradient {
	@apply text-invert;
}

/* To directly style your header and footer element, you can apply a specific background and/or other styles here which will apply only to the header and footer elements (and child elements, if applicable) when the theme class is present. Alternatively, you can always use inline styles/classes. */

header.theme-key, footer.theme-key {
	@apply bg-key;
}
header.theme-key-gradient, footer.theme-key-gradient {
	@apply gradient-key;
}

/* ---------------------------- */
/* ANCHOR Theme Applications	*/
/* ---------------------------- */
/* These styles apply to theme parent elements and nested child elements. Avoid nesting theme classes under another theme class to prevent specificity issues. */
/* Note: If you apply a solid color override to gradient elements, you may need to also apply bg-none to clear the gradient background and/or add bg-clip-* to fix clipping. */

.theme-key {
	@apply bg-key text-invert;
}

.theme-key-gradient {
	@apply gradient-key text-invert;
}

& .theme-key {
h1, h2, h3, h4, h5, h6 {
	@apply config-text-key-invert; /* NOTE Enter config-text-key-invert for your key-invert colors (which may be a equivalent to a solid color-background or an opposite color to color-key, depending on your set Variables) or text-invert or another text-* color which contrasts to your key color. */
}
p a, p a:link, p a:visited {
	@apply config-text-key-invert; /* NOTE Enter config-text-key-invert for your key-invert colors (which may be a equivalent to a solid color-background or an opposite color to color-key, depending on your set Variables) or text-invert or another text-* color which contrasts to your key color. */
}
.card, .card-base {
	@apply bg-background;
}
.card p, .card-base p {
	@apply text-primary;
}
}

& .theme-key-gradient {
h1, h2, h3, h4, h5, h6 {
	@apply config-text-key-invert; /* NOTE Enter config-text-key-invert for your key-invert colors (which may be a equivalent to a solid color-background or an opposite color to color-key, depending on your set Variables) or text-invert or another text-* color which contrasts to your key gradient colors. */
}
p a, p a:link, p a:visited {
	@apply config-text-key-invert; /* NOTE Enter config-text-key-invert for your key-invert colors (which may be a equivalent to a solid color-background or an opposite color to color-key, depending on your set Variables) or text-invert or another text-* color which contrasts to your key gradient colors. */
}
.card, .card-base {
	@apply bg-background;
}
.card p, .card-base p {
	@apply text-primary;
}
}

/* ---------------------------- */
/* ANCHOR Custom Applications	*/
/* ---------------------------- */
/* You can optionally add any theme-specific custom applications here. */

& .theme-key, .theme-key-gradient {

.stack-row h2 span {
	@apply text-primary;
}

}

/* -------------------------------------------------------------------- */
/* !SECTION Theme Key & Theme Key Gradient								*/
/* -------------------------------------------------------------------- */

/* -------------------------------------------------------------------- */
/* SECTION Theme Alt & Theme Alt Transparent							*/
/* -------------------------------------------------------------------- */
/* Only edit/add overrides or new styles if needed. DF Component color variants are predefined in DF Variants. */

/* ---------------------------- */
/* ANCHOR Containers			*/
/* ---------------------------- */
/* Use these optional classes to add matching theme styles to BODY and MAIN elements directly, but not implement theming to child elements (which should be implemented by adding the theme class to HEADER, SECTION, and FOOTER elements. Note that HEADER, SECTION, and FOOTER theming will visually layer on top of BODY and MAIN elements. */

.body-theme-alt, .main-theme-alt {
	@apply bg-background-alt;
}
.body-theme-alt-transparent, .main-theme-alt-transparent {
	@apply bg-transparent;
}
.body-theme-alt, .main-theme-alt, .body-theme-alt-transparent, .main-theme-alt-transparent {
	@apply text-primary-alt;
}

/* To directly style your header and footer element, you can apply a specific background and/or other styles here which will apply only to the header and footer elements (and child elements, if applicable) when the theme class is present. Alternatively, you can always use inline styles/classes. */


header.theme-alt {
	@apply bg-header-background-alt bg-none text-header-primary-alt; /* If applying a solid color background here while also using a gradient background for the theme, both will be active and layered. If so, bg-none must be added here to clear the gradient so that the solid color background visible. */
}

& header.theme-alt {
nav p a, nav p a:link, nav p a:visited, nav p a:focus {
	@apply text-header-nav-link-alt font-header-nav-weight-alt;
}
/* TODO Add ALT HOVER STATES HERE */
}

footer.theme-alt {
	@apply bg-footer-background-alt bg-none text-footer-primary-alt; /* If applying a solid color background here while also using a gradient background for the theme, both will be active and layered. If so, bg-none must be added here to clear the gradient so that the solid color background visible. */
}

& footer.theme-alt {
nav p a, nav p a:link, nav p a:visited, nav p a:focus {
	@apply text-footer-nav-link-alt font-footer-nav-weight-alt;
}
/* TODO Add ALT HOVER STATES HERE */
}

/* ---------------------------- */
/* ANCHOR Theme Applications	*/
/* ---------------------------- */
/* These styles apply to theme parent elements and nested child elements. Avoid nesting theme classes under another theme class to prevent specificity issues. */
/* Note: If you apply a solid color override to gradient elements, you may need to also apply bg-none to clear the gradient background and/or add bg-clip-* to fix clipping. */

.theme-alt {
	@apply bg-background-alt text-primary-alt;
}

.theme-alt-transparent {
	@apply bg-transparent text-primary-alt;
}

.theme-alt, .theme-alt-transparent {
h1, h2, h3, h4, h5, h6 {
	@apply config-text-key-alt;
}
p a, p a:link, p a:visited {
	@apply config-text-key-alt;
}
.card, .card-base {
	@apply bg-background-alt;
}
.card p, .card-base p {
	@apply text-primary-alt;
}
}

/* ---------------------------- */
/* ANCHOR Custom Applications	*/
/* ---------------------------- */
/* You can optionally add any theme-specific custom applications here. */

/* CSNOTE All Custom Applications are CS Specific. */

& .theme-alt, .theme-alt-transparent {

.stack-row h2 span {
	@apply text-primary-alt;
}

}

/* -------------------------------------------------------------------- */
/* !SECTION Theme Alt & Theme Alt Transparent							*/
/* -------------------------------------------------------------------- */

/* -------------------------------------------------------------------- */
/* SECTION Theme Alt Invert & Theme Alt Invert Transparent				*/
/* -------------------------------------------------------------------- */
/* Only edit/add overrides or new styles if needed. DF Component color variants are predefined in DF Variants. */

/* ---------------------------- */
/* ANCHOR Containers			*/
/* ---------------------------- */
/* Use these optional classes to add matching theme styles to BODY and MAIN elements directly, but not implement theming to child elements (which should be implemented by adding the theme class to HEADER, SECTION, and FOOTER elements. Note that HEADER, SECTION, and FOOTER theming will visually layer on top of BODY and MAIN elements. */

.body-theme-alt-invert, .main-theme-alt-invert {
	@apply bg-primary-alt;
}
.body-theme-alt-invert-transparent, .main-theme-alt-invert-transparent {
	@apply bg-transparent;
}
.body-theme-alt-invert, .main-theme-alt-invert, .body-theme-alt-invert-transparent, .main-theme-alt-invert-transparent {
	@apply text-invert-alt;
}

/* To directly style your header and footer element, you can apply a specific background and/or other styles here which will apply only to the header and footer elements (and child elements, if applicable) when the theme class is present. Alternatively, you can always use inline styles/classes. */

header.theme-alt-invert, footer.theme-alt-invert {
	@apply bg-primary-alt;
}

/* ---------------------------- */
/* ANCHOR Theme Applications	*/
/* ---------------------------- */
/* These styles apply to theme parent elements and nested child elements. Avoid nesting theme classes under another theme class to prevent specificity issues. */
/* Note: If you apply a solid color override to gradient elements, you may need to also apply bg-none to clear the gradient background and/or add bg-clip-* to fix clipping. */

.theme-alt-invert {
	@apply bg-primary-alt text-invert-alt;
}

.theme-alt-invert-transparent {
	@apply bg-transparent text-invert-alt;
}

& .theme-alt-invert, .theme-alt-invert-transparent {
h1, h2, h3, h4, h5, h6 {
	@apply config-text-key-alt-invert;
}
p a, p a:link, p a:visited {
	@apply config-text-key-alt-invert;
}
.card, .card-base {
	@apply bg-background-alt;
}
.card p, .card-base p {
	@apply text-primary-alt;
}
}

/* ---------------------------- */
/* ANCHOR Custom Applications	*/
/* ---------------------------- */
/* You can optionally add any theme-specific custom applications here. */

/* CSNOTE All Custom Applications are CS Specific. */

& .theme-alt-invert, .theme-alt-invert-transparent {

.stack-row h2 span {
	@apply text-invert-alt;
}

}

/* -------------------------------------------------------------------- */
/* !SECTION Theme Alt Invert & Theme Alt Invert Transparent				*/
/* -------------------------------------------------------------------- */

/* -------------------------------------------------------------------- */
/* SECTION Theme Key Alt & Theme Key Alt Gradient						*/
/* -------------------------------------------------------------------- */

/* Only edit/add overrides or new styles if needed. DF Component color variants are predefined in DF Variants. */

/* ---------------------------- */
/* ANCHOR Containers			*/
/* ---------------------------- */
/* Use these optional classes to add matching theme styles to BODY and MAIN elements directly, but not implement theming to child elements (which should be implemented by adding the theme class to HEADER, SECTION, and FOOTER elements. Note that HEADER, SECTION, and FOOTER theming will visually layer on top of BODY and MAIN elements. */

.body-theme-key-alt, .main-theme-key-alt {
	@apply bg-key-alt;
}
.body-theme-key-alt, .main-theme-key-alt {
	@apply text-invert-alt;
}
.body-theme-key-alt-gradient, .main-theme-key-alt-gradient {
	@apply gradient-key-alt;
}
.body-theme-key-alt-gradient, .main-theme-key-alt-gradient {
	@apply text-invert-alt;
}

/* To directly style your header and footer element, you can apply a specific background and/or other styles here which will apply only to the header and footer elements (and child elements, if applicable) when the theme class is present. Alternatively, you can always use inline styles/classes. */

header.theme-key-alt, footer.theme-key-alt {
	@apply bg-key-alt;
}
header.theme-key-alt-gradient, footer.theme-key-alt-gradient {
	@apply gradient-key-alt;
}

/* ---------------------------- */
/* ANCHOR Theme Applications	*/
/* ---------------------------- */
/* These styles apply to theme parent elements and nested child elements. Avoid nesting theme classes under another theme class to prevent specificity issues. */
/* Note: If you apply a solid color override to gradient elements, you may need to also apply bg-none to clear the gradient background and/or add bg-clip-* to fix clipping. */

.theme-key-alt {
	@apply bg-key-alt text-invert-alt;
}

.theme-key-alt-gradient {
	@apply gradient-key-alt text-invert-alt;
}

& .theme-key-alt {
h1, h2, h3, h4, h5, h6 {
	@apply config-text-key-alt-invert;
}
p a, p a:link, p a:visited {
	@apply config-text-key-alt-invert;
}
.card, .card-base {
	@apply bg-background-alt;
}
.card p, .card-base p {
	@apply text-primary-alt;
}
}

& .theme-key-alt-gradient {
h1, h2, h3, h4, h5, h6 {
	@apply config-text-key-alt-invert;
}
p a, p a:link, p a:visited {
	@apply config-text-key-alt-invert;
}
.card, .card-base {
	@apply bg-background;
}
.card p, .card-base p {
	@apply text-primary-alt;
}
}

/* ---------------------------- */
/* ANCHOR Custom Applications	*/
/* ---------------------------- */
/* You can optionally add any theme-specific custom applications here. */

/* CSNOTE All Custom Applications are CS Specific. */

& .theme-key-alt, .theme-key-alt-gradient {

.stack-row h2 span {
	@apply text-primary-alt;
}

}


/* -------------------------------------------------------------------- */
/* !SECTION Theme Key & Theme Key Gradient								*/
/* -------------------------------------------------------------------- */

/* -------------------------------- */
} /* Do not edit					*/
/* !LAYER components end			*/
/* -------------------------------------------------------------------------------------------- */
/* !SECTION CONFIG Theming																		*/
/* -------------------------------------------------------------------------------------------- */

/* -------------------------------------------------------------------------------------------- */
/* SECTION CONFIG Overrides (Optional) 															*/
/* -------------------------------------------------------------------------------------------- */
/* LAYER utilities begin 			*/
@layer utilities { /* Do not edit	*/
	/* -------------------------------- */

	/* NOTE: Add any overrides here which target Designframe/Tailwind Classes and Utilities. */

	/* -------------------------------- */
} /* Do not edit 					*/
/* !LAYER utilities end 			*/
/* -------------------------------------------------------------------------------------------- */
/* !SECTION CONFIG Overrides (Optional)															*/
/* -------------------------------------------------------------------------------------------- */

/* -------------------------------------------------------------------------------------------- */
/* SECTION DF Documentation Classes 															*/
/* -------------------------------------------------------------------------------------------- */
/* LAYER utilities begin 			*/
@layer utilities { /* Do not edit	*/
	/* -------------------------------- */

	/* ---------------------------- */
	/* ANCHOR Code Formatting 		*/
	/* ---------------------------- */
	.df-block-code pre code {
		@apply whitespace-pre-wrap block;
	}

	.df-block-code pre {
		position: relative;
		margin-bottom: 32px;
	}
	.df-block-code pre button {
		position: absolute;
		bottom: -32px;
		left: 0;
		min-width: 6rem;
		@apply rounded px-2 py-1 text-xs text-key shadow-sm hover:bg-key hover:text-invert ring-key ring-1;
	}

	/* -------------------------------- */
} /* Do not edit 					*/
/* !LAYER utilities end 			*/
/* -------------------------------------------------------------------------------------------- */
/* !SECTION DF Documentation Classes															*/
/* -------------------------------------------------------------------------------------------- */

/* -------------------------------------------------------------------------------------------- */
/* SECTION CONFIG Custom CSS Classes 															*/
/* -------------------------------------------------------------------------------------------- */
/* LAYER global begin 			*/
/* ---------------------------- */

/* NOTE IMPORTANT CUSTOM CSS RULES */
/* Any Tailwind or Designframe Utility can be implemented under a Custom Class using @apply, and you can always add Custom CSS here, and use inline classes directly. However, there are certain rules to follow: */

/* Keep Adaptive & Layout Control Parent Classes Inline */
/* Designframe Adaptive Classes use CSS Specificity to target and select child classes and elements. This means classes like .centerframe-grid .block-*, and most top-level classes that have 2+ levels of nested targets or dependencies have to be inline to your HTML for the accurate targeting of the nested/child. Depending on your implementation, Tailwind's content/purge processing may not reliably maintain the targeting in deep nesting situations, primarily when using @apply to roll up nesting-dependent classes. This applies to any custom CSS, not just Designframe, as inline class styling is core to Tailwind's approach. */

/* Do Not Custom Classes with @apply/nested Adaptive Layout Classes */
/* Tailwind's Content/Purge processer will exponentially multiply the size of the output.css file when Designframe Adaptive Layout Classes are nested under a Custom Class. Low-level Adaptive Classes are nestable such as .text-* however, check output sizes before deploying. */

/* TL;DR */
/* Keep all structure and layout classes inline, and use Custom Classes here for repeating visual styling and sub-element styling if needed. Specifcally all Designframe grid, flex, block, card, stack, gap, and gradient object (buttons and input) styles should be inline. */

/* -------------------------------------------------------------------------------------------- */
/* Need help? Visit designframe.io for more information.										*/
/* -------------------------------------------------------------------------------------------- */

/* --------------------------------------------------------------------	*/
/* SECTION Commonspace Custom Classes 									*/
/* -------------------------------------------------------------------------------------------- */
/* CS Custom ID Taxonomy																		*/
/*	#cs-space-*				= Space-Unique Unique Container/Component							*/
/*	#cs-space-header-*		= Space-Unique Header Unique Component								*/
/*	#cs-space-PAGENAME-*	= Space-Unique Page-Specific Unique Main Component					*/
/*	#cs-space-footer-*		= Space-Unique Footer Unique Component								*/
/*	#cs-platform-*			= Platform-Unique Unique Container/Component						*/
/*	#cs-platform-header-*	= Platform-Unique Header Unique Component							*/
/*	#cs-platform-PAGENAME-*	= Platform-Unique Page-Specific Unique Main Component				*/
/*	#cs-platform-footer-*	= Platform-Unique Footer Unique Component							*/
/*	#cs-*-*-*-*				= Unique Sub-Component or Item of -PARENT (if any)					*/
/* -------------------------------------------------------------------------------------------- */
/* CS Custom Class Taxonomy																		*/
/*	.cs-*					= Non-Unique Element or Format										*/
/*	.cs-*-*					= Non-Unique Nested Element or Group or Variant						*/
/*	.cs-*-*-*				= Non-Unique Nested Element or Group or Variant						*/
/*	.cs-*-*-*-*				= Non-Unique Group or Variant										*/
/* -------------------------------------------------------------------------------------------- */
/* Prioritize Designframe and Tailwind Classes first, then add custom mix-ins & roll-ups here.	*/
/* -------------------------------------------------------------------------------------------- */
/* CSNOTE All Color cs-* class applications are now in CONFIG Theming.							*/
/* -------------------------------------------------------------------------------------------- */

/* -------------------------------------------- */
/* SECTION Header								*/
/* -------------------------------------------- */

/* LOGO BLOCK LAYOUT & EFFECTS */
#cs-platform-header-logo, #cs-space-header-logo {
	@apply basis-4/5 lg:basis-2/7 justify-center sm:justify-start;
}
#cs-platform-header-logo a, #cs-space-header-logo a {
	@apply gradient-glow-key-cycle-pair gradient-glow-key-cycle-pair-reverse;
} /* NOTE Default is "hover:opacity-90" class only for Spaces, which is currently always enabled automatically. This will be changed to a new option set via Space Admin in the future. for CS pages it is "gradient-glow-key-cycle-pair gradient-glow-key-cycle-pair-reverse" */

#cs-platform-header-logo img, #cs-space-header-logo img {
	@apply h-element-nav-min lg:h-element-nav-max;
}

/* CENTER NAV LAYOUT */
#cs-platform-header-nav, #cs-space-header-nav {
	@apply basis-5/7 items-center justify-center;
}

/* CTA BUTTON LAYOUT */
#cs-platform-header-user-nav,
#cs-space-header-user-nav {
	@apply hidden sm:flex;
}
#cs-platform-header-user-nav,
#cs-space-header-user-nav {
	@apply basis-2/7 items-center justify-end;
}

#cs-platform-header-user-nav .button-primary-gradient,
#cs-platform-header-user-nav .button-secondary-gradient,
#cs-space-header-user-nav .button-primary-gradient,
#cs-space-header-user-nav .button-secondary-gradient {
	@apply min-w-[100px] flex-1;
}

#cs-platform-header-user-nav .button-primary-gradient p,
#cs-platform-header-user-nav .button-secondary-gradient p,
#cs-space-header-user-nav .button-primary-gradient p,
#cs-space-header-user-nav .button-secondary-gradient p {
	@apply whitespace-nowrap;
}

#header-nav-menu.cs-nav-menu {
	@apply w-full h-dvh shadow-inner-light-t;
}

.cs-stack-user-nav-public {
	@apply hidden lg:flex flex-row w-full gap-gutter;
}
.cs-stack-user-nav-public-menu-toggle {
	@apply hidden sm:flex lg:hidden basis-1/5 items-center justify-end;
}
.cs-stack-user-nav-member {
	@apply flex flex-row gap-stack-base xl:gap-stack-xl;
}
nav .cs-stack-user-nav-member a {
	@apply transition-none;
}
nav .cs-stack-user-nav-member a:hover {
	@apply transition-none;
}
.cs-stack-user-nav-member #header-user-nav-menu-toggle {
	@apply hidden sm:flex lg:hidden;
}

/* .cs-stack-user-nav-member #header-user-nav-menu-toggle.active {
	@apply bg-gradient-to-r from-key to-key-end;
}
.cs-stack-user-nav-member #header-user-nav-menu-toggle.active span {
	@apply bg-opacity-gradient-invert;
} */
.cs-stack-user-nav-member #header-user-nav-menu-toggle.active i {
	@apply gradient-text-key;
}
.cs-stack-user-nav-member a i, .cs-footer-brand .cs-stack-link-icons a i {
	@apply text-secondary;
}
.cs-stack-user-nav-member a:hover i,  .cs-footer-brand .cs-stack-link-icons a:hover i {
	@apply gradient-text-key;
}

#cs-profile-banner-info .cs-stack-link-icons a span p i {
	@apply text-invert;
}
#cs-profile-banner-info .cs-stack-link-icons a:hover span p i {
	@apply text-key;
}


.cs-stack-user-nav-member a.avatar-gradient,
#cs-platform-footer-mobile-nav a.avatar-gradient,
#cs-space-footer-mobile-nav a.avatar-gradient,
.cs-stack-user-nav-member a.avatar-gradient:active,
#cs-platform-footer-mobile-nav a.avatar-gradient:active,
#cs-space-footer-mobile-nav a.avatar-gradient:active,
.cs-stack-user-nav-member a.avatar-gradient:visited,
#cs-platform-footer-mobile-nav a.avatar-gradient:visited,
#cs-space-footer-mobile-nav a.avatar-gradient:visited {
	@apply bg-none bg-clip-border shadow-none transition-none;
}
.cs-stack-user-nav-member a.avatar-gradient:hover,
#cs-platform-footer-mobile-nav a.avatar-gradient:hover,
#cs-space-footer-mobile-nav a.avatar-gradient:hover {
	@apply sm:gradient-key sm:bg-clip-border sm:shadow-light transition-none;
}

.cs-stack-user-nav-member a:hover.avatar-gradient,
#cs-platform-footer-mobile-nav a:hover.avatar-gradient,
#cs-space-footer-mobile-nav a:hover.avatar-gradient,
.cs-stack-user-nav-member a.avatar-gradient.active,
#cs-platform-footer-mobile-nav a.avatar-gradient.active,
#cs-space-footer-mobile-nav a.avatar-gradient.active {
	@apply gradient-key bg-clip-border shadow-light transition-none;
}
.cs-stack-user-nav-member a.avatar-gradient img,
#cs-platform-footer-mobile-nav a.avatar-gradient img,
#cs-space-footer-mobile-nav a.avatar-gradient img {
	@apply shadow-light;
}
.cs-stack-user-nav-member a.avatar-gradient:hover img,
#cs-platform-footer-mobile-nav a.avatar-gradient img,
#cs-space-footer-mobile-nav a.avatar-gradient img {
	@apply shadow-none brightness-100;
}

#cs-platform-user-menu,
#cs-space-user-menu {
	@apply
	sm:fixed sm:right-0
	sm:top-offset-header-sm md:top-offset-header-md lg:top-offset-header-lg xl:top-offset-header-base 2xl:top-offset-header-2xl
	sm:-translate-y-[10px] xl:translate-y-offset-element-min 2xl:-translate-y-offset-element-sm
	sm:mt-base-unit xl:mt-sub-base sm:p-border-button sm:rounded-corner
	sm:shadow-light sm:gradient-key
	sm:duration-base sm:origin-top-right
	motion-reduce:transition-none;
}
#cs-platform-user-menu.hide,
#cs-space-user-menu.hide {
	@apply sm:scale-0 sm:duration-base sm:-skew-y-32 sm:opacity-5 sm:blur-light sm:-translate-x-5 sm:-translate-y-5
	motion-reduce:transition-none;
}
#cs-platform-user-menu nav,
#cs-space-user-menu nav {
	@apply flex flex-col bg-background sm:rounded-corner-inset;
}

.cs-dash-spacer {
	@apply hidden lg:grid col-span-12 h-element-base;
}

.cs-platform-dash:not(:has(.cs-pagination)) .centerframe-grid {
	@apply mb-0 sm:mb-element-base-unit;
}
.cs-platform-dash:not(:has(.cs-pagination)) .cs-dash {
	@apply mb-gutter-base xl:mb-gutter-xl;
}

.cs-dash-breadcrumbs nav {
	@apply hidden sm:flex flex-row flex-wrap gap-x-gutter-base;
}
.cs-dash-breadcrumbs nav p a {
	@apply text-primary not-italic font-bold;
}
.cs-dash-breadcrumbs nav p a:hover, .cs-dash-breadcrumbs nav p a.active {
	@apply gradient-text-key;
}
.cs-dash-breadcrumbs nav p i {
	@apply text-nav;
}
.cs-dash-breadcrumbs nav p::after {
	content: "\f054";
	font-family: "Font Awesome 6 Pro";
	font-size: 20px;
	font-weight: 400;
	line-height: 40px;
	-webkit-font-smoothing: antialiased;
	padding-left: var(--spacing-gutter-base);

}
.cs-dash-breadcrumbs nav p:last-child::after {
	content: '';
	display: none;
}

.cs-dash-nav {
	@apply hidden sm:grid col-span-full lg:col-span-2;
}
.cs-dash {
	@apply grid col-span-full lg:col-span-10;
}
.cs-dash-nav nav {
	@apply flex flex-row flex-wrap lg:flex-col lg:flex-nowrap justify-center lg:justify-start h-fit sticky top-2gutter-base;
}
.cs-dash-nav nav a {
	@apply flex flex-row w-fit lg:w-full items-center justify-start px-sub-base
	sm:max-xl:text-p3-size;
}

.cs-dash-nav nav a div {
	@apply flex flex-row items-center justify-start gap-x-sub-base xl:gap-x-element-sm;
}
.cs-dash-nav nav a div span {
	@apply w-element-min flex items-center justify-center;
}
.cs-dash-nav nav a, .cs-dash-nav nav a i {
	@apply bg-none transition-none
	text-secondary;
}
.cs-dash-nav nav a:hover, .cs-dash-nav nav a:hover i {
	@apply gradient-text-key;
}

#cs-platform-user-menu nav a div span,
#cs-space-user-menu nav a div span {
	@apply w-element-min flex items-center justify-center;
}
#cs-platform-user-menu nav a div,
#cs-space-user-menu nav a div {
	@apply flex flex-row items-center justify-start gap-x-sub-base sm:gap-x-element-sm;
}
#cs-platform-user-menu nav a i,
#cs-space-user-menu nav a i {
	@apply text-secondary;
}
#cs-platform-user-menu nav a:hover i,
#cs-space-user-menu nav a:hover i {
	@apply text-invert;
}
#cs-platform-user-menu nav a,
#cs-space-user-menu nav a {
	@apply flex flex-row items-center justify-center sm:justify-start px-element-base text-secondary hover:text-background hover:gradient-key transition-none;
}
#cs-platform-user-menu nav > a:first-child,
#cs-space-user-menu nav > a:first-child {
	@apply sm:rounded-t-corner-inset sm:hover:shadow-inner-light-b;
}
#cs-platform-user-menu nav a:not(:first-child):not(:last-child),
#cs-space-user-menu nav a:not(:first-child):not(:last-child) {
	@apply sm:hover:shadow-inner-light-y;
}
#cs-platform-user-menu nav > a:last-child,
#cs-space-user-menu nav > a:last-child {
	@apply sm:rounded-b-corner-inset sm:hover:shadow-inner-light-t;
}

#cs-space-header.split {
	@apply h-5row-base sm:h-5row-sm md:h-5row-md lg:h-5row-lg xl:h-5row-xl 2xl:h-5row-2xl
	absolute top-0 left-0 w-full bg-transparent;
}
#cs-space-header.split #header-nav-menu-open, #cs-space-header.split .cs-stack-user-nav-member a span p i {
	@apply gradient-text-key;
}
#cs-space-header.split #cs-space-header-nav {
	@apply lg:h-offset-header-lg xl:h-offset-header-xl 2xl:h-offset-header-2xl
	lg:absolute lg:bottom-0 lg:left-0 lg:w-full lg:bg-background;
}

/* -------------------------------------------- */
/* !SECTION Header	 							*/
/* -------------------------------------------- */

/* -------------------------------------------- */
/* SECTION Footer		 						*/
/* -------------------------------------------- */

/* BRAND BLOCK & LOGO LAYOUT & EFFECTS */
.cs-footer-brand {
	@apply col-span-full lg:col-span-5 xl:col-span-4;
}
#cs-platform-footer-logo,
#cs-space-footer-logo {
	@apply justify-center lg:justify-start;
}
#cs-platform-footer-logo a,
#cs-space-footer-logo a {
	@apply gradient-glow-key;
} /* NOTE Default is "hover:opacity-90" class only for Spaces, which is currently always enabled automatically. This will be changed to a new option set via Space Admin in the future. for CS pages it is "gradient-glow-key" */

#cs-platform-footer-logo a img,
#cs-space-footer-logo a img {
	@apply w-full max-h-element-base;
}

/* LOCALIZATION BUTTON STACK LAYOUT & ADAPTIVE */
.cs-footer-localization {
	@apply justify-center lg:justify-start lg:flex-nowrap;
}

.cs-footer-localization button {
	@apply max-w-[400px] whitespace-nowrap;
}

/* COLUMN FILLER/SPACER */
.cs-footer-spacer {
	@apply  block-base hidden lg:grid lg:col-span-1 xl:col-span-2;
}

/* FOOTER LINKS COLUMNS STACK LAYOUT & ADAPTIVE */
#cs-platform-footer-nav-1, #cs-platform-footer-nav-2, #cs-platform-footer-nav-3, #cs-space-footer-nav-1, #cs-space-footer-nav-2, #cs-space-footer-nav-3 {
	@apply col-span-2;
}
.cs-footer-nav-stack h3 {
	@apply leading-element text-center lg:text-left;
}
.cs-footer-nav-stack-links {
	@apply text-center lg:text-left flex flex-col gap-base-unit;
}

/* FOOTER COPYRIGHT BLOCK TEXT & ADAPTIVE */
.cs-footer-copyright p {
	@apply text-secondary text-center lg:text-left;
}

/* -------------------------------------------- */
/* !SECTION Footer 								*/
/* -------------------------------------------- */

/* -------------------------------------------- */
/* SECTION CS Custom Mobile Menu 				*/
/* -------------------------------------------- */

#cs-platform-header .header-flex > nav, #cs-space-header .header-flex > nav {
	@apply justify-center sm:justify-between;
}

#cs-platform-main, #cs-space-main {
	@apply shadow-inner-light-t;
}

#cs-platform-footer, #cs-space-footer {
	@apply sm:shadow-outer-light-t w-full;
}

#cs-platform-footer-mobile-menu-links nav,
#cs-space-footer-mobile-menu-links nav {
	@apply flex flex-col gap-y-base-unit gap-x-stack-2xl justify-center;
}

#cs-platform-footer-mobile-menu-links nav p,
#cs-space-footer-mobile-menu-links nav p {
	@apply flex w-full justify-center;
}

#cs-platform-footer-mobile-menu-links nav a,
#cs-space-footer-mobile-menu-links nav a {
	@apply text-p1-size leading-h3;
}

#cs-platform-footer-mobile-auth nav,
#cs-platform-footer-mobile-auth nav,
#cs-space-footer-mobile-auth nav,
#cs-space-footer-mobile-auth nav {
	@apply flex flex-col gap-stack-base justify-center;
}

#cs-platform-footer-mobile-auth nav a,
#cs-space-footer-mobile-auth nav a {
	@apply w-full;
}

main.cs-main {
	@apply pb-offset-footer-base sm:pb-0;
}
footer.cs-footer {
	@apply fixed bottom-0 left-0 sm:relative flex flex-col justify-end z-250;
}

#cs-platform-footer-mobile-nav,
#cs-space-footer-mobile-nav {
	@apply z-top relative bg-background shadow-outer-light-t w-full;
}

#cs-platform-footer-mobile-nav nav,
#cs-space-footer-mobile-nav nav {
	@apply flex flex-row gap-stack-base py-sub-base justify-between;
}

#cs-platform-footer-mobile-nav nav a,
#cs-space-footer-mobile-nav nav a {
	@apply transition-none w-element-base text-center;
}

#cs-platform-footer-mobile-nav nav a:hover,
#cs-space-footer-mobile-nav nav a:hover {
	@apply gradient-text-key;
}
#cs-platform-footer-mobile-nav nav a.toggle-menu-gradient:hover,
#cs-space-footer-mobile-nav nav a.toggle-menu-gradient:hover {
	@apply text-secondary bg-transparent sm:bg-clip-text sm:gradient-text-key;
}

#cs-platform-footer-mobile-nav nav a i,
#cs-space-footer-mobile-nav a i {
	@apply text-secondary;
}

#cs-platform-footer-mobile-nav nav a:hover i,
#cs-space-footer-mobile-nav a:hover i,
#cs-platform-footer-mobile-nav nav a i.active,
#cs-platform-footer-mobile-nav nav a:hover i.active,
#cs-space-footer-mobile-nav nav a i.active,
#cs-space-footer-mobile-nav nav a:hover i.active {
	@apply gradient-text-key;
}

#footer-frame {
	@apply duration-base-2x sm:duration-0 sm:relative motion-reduce:transition-none;
}

@media (max-width: 639px) {

	footer#cs-platform-footer,
	footer#cs-space-footer {
		@apply bg-transparent;
	}

	#footer-frame {
		@apply fixed w-full bottom-offset-footer-base
		bg-background shadow-outer-light-t motion-reduce:transition-none;
	}

	#footer-frame.hide {
		@apply translate-y-full transition-set shadow-none motion-reduce:transition-none;
	}
	.cs-stack-link-icons {
		@apply pt-sub-base;
	}

	#cs-platform-user-menu,
	#cs-space-user-menu {
		@apply 	fixed bottom-offset-footer-base
		mr-0 py-gutter-lg w-full
		bg-background shadow-outer-light-t
		duration-base-2x motion-reduce:transition-none;
	}
	#cs-platform-user-menu.hide,
	#cs-space-user-menu.hide {
		@apply translate-y-full transition-set shadow-none motion-reduce:transition-none;
	}
	#cs-platform-user-menu nav,
	#cs-space-user-menu nav {
		@apply gap-base-unit;
	}
	#cs-platform-user-menu nav a div,
	#cs-space-user-menu nav a div {
		@apply w-[138px]
	}

	#cs-platform-user-menu nav a,
	#cs-space-user-menu nav a {
		@apply hover:shadow-inner-light-y;
	}
	.cs-dash-card.cs-banner-card.cs-bg-swap {
		@apply !bg-none;
	}
	.cs-dash-card.cs-banner-card.cs-bg-swap .cs-banner-card-shader {
		@apply bg-transparent backdrop-blur-none;
	}

}

/* -------------------------------------------- */
/* !SECTION CS Custom Mobile Menu 				*/
/* -------------------------------------------- */

/* -------------------------------------------- */
/* SECTION Pages / Tab Panes					*/
/* -------------------------------------------- */

#cs-space-home-c1.theme-transparent {
	@apply pb-0;
}

/* -------------------------------------------- */
/* !SECTION Pages / Tab Panes					*/
/* -------------------------------------------- */

/* -------------------------------------------- */
/* SECTION Components 							*/
/* -------------------------------------------- */

/* ---------------------------- */
/* ANCHOR Welcome				*/
/* ---------------------------- */
#cs-space-home-welcome-image {
	@apply bg-cover bg-center content-center text-center aspect-cs-preview xl:aspect-video align-middle;
}

#cs-space-home-welcome-image a {
	@apply h-element-base w-element-base block place-content-center m-auto align-middle my-auto;
}

#cs-space-home-welcome-image a i {
	@apply text-i3 xl:text-i1 opacity-50 hover:opacity-100 config-transition-set;
}
#cs-space-home-welcome-intro {
	@apply content-center;
}
#cs-space-home-welcome-intro hgroup {
	@apply hgroup-heading text-center lg:text-left;
}
#cs-space-home-welcome-intro hgroup h2 {
	@apply text-3xl xl:text-h2;
}
#cs-space-home-welcome-intro hgroup p {
	@apply text-p2 xl:text-p1;
}

/* -------------------------------------------- */
/* !SECTION Components 							*/
/* -------------------------------------------- */

/* -------------------------------------------- */
/* SECTION Card Instances						*/
/* -------------------------------------------- */

/* ---------------------------- */
/* ANCHOR Product Card			*/
/* ---------------------------- */

.cs-product-membership, .cs-product-item {
	@apply bg-cover bg-center h-5row p-0;
}

.cs-dash-card-product.cs-product-membership,
.cs-dash-card-product.cs-product-item {
	@apply h-3row hover:brightness-110 shrink-0;
}
.cs-dash-card-product.cs-product-membership i,
.cs-dash-card-product.cs-product-item i {
	@apply text-invert;
}

.cs-product-membership .shader, .cs-product-item .shader {
	@apply content-end text-center p-card h-full w-full bg-gradient-to-t from-primary-shader to-primary-shader-light sm:to-transparent sm:to-65% md:to-90% lg:to-80% xl:to-65%;
}
.cs-product-membership hgroup, .cs-product-item hgroup {
	@apply hgroup-heading;
}
.cs-product-membership hgroup h4, .cs-product-item hgroup h4 {
	@apply text-invert;
}
.cs-product-membership hgroup p, .cs-product-item hgroup p {
	@apply italic text-invert;
}
.cs-product-membership a, .cs-product-item a {
	@apply w-full;
}

.cs-stack-cards-controls-select .cs-dash-card-product {
	@apply border border-transparent;
}
.cs-stack-cards-controls-select .cs-dash-card-product:hover,
.cs-stack-cards-controls-select .cs-dash-card-product.active {
	@apply border border-key;
}

/* ---------------------------- */
/* ANCHOR Excerpt Card			*/
/* ---------------------------- */
.cs-excerpt, .cs-excerpt-update, .cs-excerpt-benefit {
	@apply flex flex-col;
}

.cs-excerpt a img, .cs-excerpt-update a img, .cs-excerpt-benefit a img {
	@apply aspect-cs-preview hover:opacity-90 p-0 space-y-0 space-x-0 object-cover object-center;
}
.cs-excerpt hgroup, .cs-excerpt-update hgroup, .cs-excerpt-benefit hgroup {
	@apply hgroup-title;
}

.cs-excerpt hgroup p, .cs-excerpt-update hgroup p, .cs-excerpt-benefit hgroup p {
	@apply line-clamp-6;
}

.cs-benefits-post-access {
	@apply w-full;
}

.cs-benefits-post-access .button-primary-gradient {
	@apply w-full;
}

/* ---------------------------- */
/* ANCHOR Updates Article		*/
/* ---------------------------- */
.cs-stack-noaccess hgroup p {
	@apply font-bold;
}
.cs-stack-updates-tools, .cs-stack-benefits-tools {
	@apply lg:min-h-[80px];
}
.cs-updates-article-cover-media a:hover img, .cs-benefits-article-cover-media a:hover img {
	@apply opacity-90;
}
.cs-updates-article-cover-media a img, .cs-benefits-article-cover-media a img {
	@apply aspect-x-golden w-full h-full object-cover object-center;
}
.cs-stack-updates-aside .cs-stack-categories h3, .cs-stack-benefits-aside .cs-stack-categories h3 {
	@apply hidden lg:block;
}

/* ---------------------------- */
/* ANCHOR Post Card				*/
/* ---------------------------- */
.cs-post a img {
	@apply aspect-x-golden w-full h-full object-cover object-center hover:opacity-90;
}
.cs-post.block-full.card-base {
	@apply space-y-0;
}

.cs-stack-post-activity > p {
	@apply w-full sm:w-fit text-center content-center;
}

.cs-stack-post-activity > div {
	@apply w-full sm:w-fit items-center ;
}

.cs-stack-post-activity > div > p {
	@apply text-center;
}
.cs-stack-post-comment-form .avatar-gradient {
	@apply hidden sm:block;
}
.cs-post .form-input-combo input:focus, .cs-post .form-input-combo input:focus-visible {
	@apply outline-none;
}
.cs-stack-comment-body {
	@apply w-full;
}

.cs-post-avatar {
	@apply avatar-gradient-sm sm:avatar-gradient-md;
}
.cs-post hgroup h4 {
	@apply sm:text-h3;
}
.cs-post-intro {
	@apply italic;
}
.cs-stack-post-header > hgroup {
	@apply flex place-content-center;
}
.cs-stack-post-header > .stack-row > hgroup {
	@apply content-center;
}
.cs-stack-tags {
	@apply gap-stack-base;
}
.cs-post.cs-pinned .cs-post-avatar {
	@apply lg:avatar-gradient-sm xl:avatar-gradient-md;
}
.cs-post.cs-pinned hgroup h4 {
	@apply lg:max-xl:text-h4;
}
.cs-post.cs-pinned hgroup p {
	@apply lg:max-xl:text-p4;
}

.cs-stack-post-actions a, .cs-stack-comment-actions a {
	@apply content-center;
}

.cs-comment-avatar {
	@apply avatar-gradient-min sm:avatar-gradient;
}
.cs-stack-comment-header > div {
	@apply items-center;
}
.cs-stack-comment-header > a.button-icon-base-gradient {
	@apply content-center;
}

.cs-product-preview-row {
	@apply flex flex-row gap-x-element-base items-center;
}

.cs-product-preview-row h5 {
	@apply sm:text-h4 ;
}
.cs-product-preview-row p {
	@apply text-p4 sm:text-p3;
}

#cs-product-renewal-pricing > .datarow > p > span {
	@apply font-normal text-p3-size text-secondary italic pl-base-unit;
}
#cs-product-benefits > .datarow > p > span {
	@apply text-secondary italic pl-base-unit;
}
.cs-comment-body {
	@apply my-base-unit;
}
.cs-comment-thread {
	@apply w-full;
}
.cs-stack-comment-header .cs-stack-comment-published p {
	@apply text-p4 sm:text-p3;
}

.cs-stack-comment-header {
	@apply w-full;
}

/* ---------------------------- */
/* ANCHOR Link Card				*/
/* ---------------------------- */
.cs-featured-link {
	@apply bg-cover bg-center content-center text-center hover:opacity-90 h-3row p-0;
}
.cs-featured-link a {
	@apply inline-block w-full h-full place-content-center text-center bg-gradient-to-t from-primary-shader to-primary-shader-light sm:to-transparent;
}
.cs-featured-link a hgroup {
	@apply hgroup-title;
}
.cs-featured-link a hgroup p {
	@apply italic;
}

/* ---------------------------- */
/* ANCHOR Access Card			*/
/* ---------------------------- */
.cs-featured-access {
	@apply bg-cover bg-center content-center text-center hover:opacity-90 h-3row p-0;
}
.cs-featured-access a {
	@apply w-full h-full flex flex-col justify-between bg-gradient-to-t from-primary-shader;
}
.cs-featured-access a hgroup {
	@apply hgroup-title;
}
.cs-featured-access a hgroup p i {
	@apply text-i3 text-invert;
}

/* ---------------------------- */
/* ANCHOR Support Link Card			*/
/* ---------------------------- */
.cs-featured-support {
	@apply bg-cover bg-center content-center text-center hover:opacity-90 h-2row p-0;
}
.cs-featured-support a {
	@apply w-full h-full flex flex-col justify-center items-center shader-gradient-key-heavy;
}
.cs-featured-support a hgroup {
	@apply hgroup-title;
}
.cs-featured-support a hgroup p i {
	@apply text-i3 text-invert;
}

/* -------------------------------------------- */
/* !SECTION Instances							*/
/* -------------------------------------------- */

/* -------------------------------------------- */
/* SECTION Utilities 							*/
/* -------------------------------------------- */

/* ---------------------------- */
/* ANCHOR STACKS				*/
/* ---------------------------- */

.cs-stack-title {
	@apply justify-center sm:justify-between items-baseline;
}
.cs-stack-title h2 {
	@apply text-center sm:text-left;
}
.cs-stack-title > p {
	@apply text-p1 hidden sm:inline;
}
.cs-stack-channel-header-info > div {
	@apply items-center;
}
.cs-stack-channel-header-info h2 {
	@apply text-center;
}
.cs-stack-channel-header-info p {
	@apply italic;
}
.cs-stack-access {
	@apply justify-between items-center;
}
.cs-stack-access div {
	@apply items-center;
}
.cs-stack-access p {
	@apply text-invert italic min-h-element-min content-center text-center w-full;
}
.cs-stack-access-split {
	@apply justify-center xl:justify-between items-center;
}
.cs-stack-access-split div {
	@apply items-center;
}
.cs-stack-access-split p {
	@apply text-invert italic min-h-element-min content-center text-center;
}
.cs-stack-access-split > p {
	@apply hidden xl:block;
}

.cs-stack-activity {
	@apply flex flex-col gap-y-stack-sm;
}

.cs-stack-title-filters {
	@apply justify-center sm:justify-between items-baseline;
}
.cs-stack-title-filters > h2 {
	@apply text-center sm:text-left;
}
.cs-stack-title-filters > div {
	@apply w-full sm:w-fit;
}
.cs-stack-title-icons {
	@apply justify-center sm:justify-between items-baseline;
}
.cs-stack-title-icons > h2 {
	@apply text-center sm:text-left;
}
.cs-stack-title-icons > div {
	@apply w-full sm:w-fit;
}
.cs-stack-filters {
	@apply justify-center stack-row-base gap-gutter;
}
#cs-profile-create-main .cs-stack-form-buttons-set, .cs-stack-space-create-cs-profile .cs-stack-form-buttons-set {
	@apply flex-wrap xl:flex-nowrap;
}
.cs-stack-heading {
	@apply gap-heading flex flex-col;
}
.cs-stack-heading .stack-row {
	@apply flex-wrap sm:flex-nowrap items-baseline;
}
.cs-stack-heading h3 {
	@apply text-primary hidden sm:block;
}

.cs-stack-form-guided, .cs-panel {
	@apply flex flex-col gap-gutter-base;
}

.cs-stack-form-guided .cs-panel button.icon-dropdown-gradient-alt p, .cs-footer-localization button.icon-dropdown-gradient-alt p  {
	@apply text-p3-size;
}

.cs-stack-form-guided .formrow.title > a {
	@apply order-1 mb-gutter-base lg:order-2 lg:mb-0 ;
}
.cs-stack-form-guided .formrow.title > div {
	@apply order-2 lg:order-2;
}
.cs-stack-form-guided .formrow {
	@apply gap-x-gutter-base gap-y-base-unit flex-wrap lg:flex-nowrap;
}
.cs-stack-form-guided .formrow.title {
	@apply items-center;
}

.cs-stack-form-guided .formrow.title > div:first-child {
	@apply flex flex-col sm:flex-row w-full justify-between items-start sm:items-baseline lg:items-center gap-x-gutter-base;
}
.cs-stack-form-guided .formrow > :last-child {
	@apply w-full lg:w-2/5;
}
.cs-stack-form-guided .formrow.subtitle {
	@apply mt-element-base;
}
.cs-stack-form-guided .formrow.subtitle > :last-child p {
	@apply text-right lg:text-left;
}
.cs-stack-form-guided .formrow > div:last-child {
	@apply flex min-h-element-minxx items-start lg:items-center;
}
.cs-stack-form-guided .formrow > div.cs-formrow-textarea-helper {
	@apply lg:min-h-elementc-md h-full;
}
.cs-stack-form-guided .formrow > .cs-formrow-helper-split {
	@apply flex flex-row gap-x-stack-base gap-y-sub-base lg:flex-col mb-base-unit lg:mb-0;
}
.cs-stack-form-guided .formrow > .cs-formrow-helper-start {
	@apply self-start;
}
.cs-stack-form-guided .formrow > .cs-formrow-helper-end {
	@apply self-end;
}
.cs-stack-form-guided .formrow.media {
	@apply mt-element-base;
}
.cs-form-card-stack {
	@apply w-full;
}
.cs-stack-form-guided .cs-stack-plans {
	@apply md:flex-row;
}
.cs-panel-title {
	@apply flex flex-row gap-x-sub-base;
}
.cs-panel-title h3 {
	@apply leading-element-base;
}
.cs-panel-heading {
	@apply flex flex-col gap-y-heading;
}
.cs-panel-content {
	@apply flex flex-col gap-y-card-base;
}
.cs-panel-content label span {
	@apply text-secondary italic;
}

.cs-panel-toggle {
	@apply rotate-180 config-transition-set;
}
.cs-panel-toggle.active {
	@apply rotate-0 config-transition-set;
}
/* .cs-panel-content.hide {
	@apply hidden;
} */
.cs-panel-content {
	transition-duration: 1000ms;
	transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
	transform-origin: top left;

}

.cs-panel-content.hide {
	transition-duration: 1000ms;
	transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
	transform: scale(0) translateY(-10%) ;
	opacity: 0;
	transform-origin: top left ;
	visibility: collapse;
	position: absolute;
}
.cs-panel, .cs-stack-form-guided, #cs-platform-create-pro-theming-c1, #cs-platform-create-pro-theming-c1 > div, #cs-platform-create-pro-theming-c1 > div > div  {
	@apply config-transition-set;
}
.cs-panel-content > * {
	transition-duration: 1000ms;
	transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
	opacity: 1;
}
.cs-panel-content.hide > * {
	transition-duration: 1000ms;
	transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
	transform: scale(0) rotate(180deg);
	opacity: 0;
	filter: blur(16px);
}

.cs-plan-card, .cs-feature-card {
	@apply p-border-button bg-transparent ring-1 ring-key ring-inset w-full;
}
.cs-stack-plan-card, .cs-stack-feature-card {
	@apply flex flex-col gap-y-article-base text-center w-full h-full p-article-2xl items-center;
}

.cs-stack-feature-card > a {
	@apply w-full;
}

.cs-plan-card.gradient, .cs-feature-card.gradient {
	@apply gradient-key ring-transparent ring-0;
}
.cs-plan-card.gradient .cs-stack-plan-card,
.cs-feature-card.gradient .cs-stack-feature-card {
	@apply bg-invert bg-opacity-gradient-invert;
}
.cs-plan-card .cs-stack-plan-card > hgroup > p,
.cs-plan-card .cs-plan-pricing-details p {
	@apply text-p4;
}
.cs-plan-card .cs-stack-plan-card > hgroup > p > span,
.cs-plan-card .cs-plan-pricing-details p span {
	@apply italic text-p4 font-normal;
}
.cs-plan-card .cs-stack-plan-card h5, .cs-plan-card .cs-stack-plan-card h6 {
	@apply text-primary;
}
.cs-plan-card .cs-plan-intro {
	@apply flex flex-col items-center;
}
.cs-plan-card .cs-plan-intro > i,
.cs-feature-card .cs-feature-title > i {
	@apply text-h2 gradient-text-key;
}
.cs-plan-card .cs-plan-pricing h3 span {
	@apply text-h6-size;
}
.cs-plan-card .cs-plan-rates,
.cs-plan-card .cs-plan-features {
	@apply flex flex-col gap-y-element-base;
}
.cs-plan-card .cs-plan-rates p,
.cs-plan-card .cs-plan-pricing-details {
	@apply font-bold;
}
.cs-plan-card .cs-plan-features {
	@apply w-fit;
}
.cs-plan-card .cs-plan-features ul {
	@apply text-p4 text-left;
}

.cs-form-card-stack .form-stack-radio-options {
	@apply flex-col md:flex-row;
}

.cs-form-card-stack label {
	@apply w-full;
}
.cs-form-card-stack .cs-form-card-stack-title {
	@apply gradient-text-key font-bold text-p2;
}
.cs-form-card-stack label span {
	@apply flex flex-col items-center;
}
.cs-form-card-stack label p {
	@apply text-center;
}
.cs-form-card-stack label > div i {
	@apply gradient-text-key text-h3;
}
.cs-stack-form-inputs.cs-stack-datarows {
	@apply gap-y-form-base flex-col;
}
.cs-stack-form-inputs .datarow > p:first-child {
	@apply font-bold w-1/3;
}
.cs-stack-form-inputs .datarow > p {
	@apply line-clamp-1;
}
.cs-stack-form-inputs .datarow.cs-data-item-text-long > p {
	@apply line-clamp-none items-start;
}
.cs-stack-form-inputs.cs-stack-form-input-quarters {
	@apply w-full flex-wrap lg:flex-nowrap gap-y-article-base;
}
.cs-stack-form-inputs.cs-stack-form-input-quarters div {
	@apply w-full;
}
.cs-stack-form-inputs.stack-col {
	@apply justify-between text-left;
}
.cs-form-color-picker, .cs-form-fieldset-card-row {
	@apply flex flex-row gap-x-0 items-end;
}
.cs-form-color-picker div.form-input.air, .cs-form-fieldset-card-row fieldset {
	@apply w-full;
}
.cs-form-radio-set-block.air {
	@apply flex flex-col w-full;
}
.cs-form-radio-set-block.air .cs-label {
	@apply text-p4 sm:text-p3 text-key pb-base-unit;
}
.cs-form-fieldset-card-row .form-radio-set.card-block .form-radio-option {
	@apply py-[5px] justify-center;
}
.cs-form-radio-set-block .form-stack-radio-options label span p {
	@apply sm:text-p3-size;
}
#cs-theming-headerfooter-content .cs-form-radio-set-block .form-stack-radio-options label {
	@apply px-sub-base sm:px-element-base;
}
.cs-form-radio-set-stack, .cs-form-radio-set-stack label {
	@apply w-full;
}
#cs-theming-headerfooter-content .cs-form-radio-set-stack label > div {
	@apply p-0 rounded-t-none;
}
#cs-theming-headerfooter-content .cs-form-radio-set-stack .form-radio-option {
	@apply flex flex-col cursor-pointer;
}
#cs-theming-headerfooter-content .cs-form-radio-set-stack .form-radio-option > div > span {
	@apply flex flex-col w-full h-full items-center justify-center;
}
#cs-theming-headerfooter-content .cs-form-radio-set-stack .form-radio-option > img {
	@apply rounded-t-corner-inset rounded-b-none pb-border-button;
}
#cs-theming-headerfooter-content .cs-form-radio-set-stack .form-radio-option > div > span > p {
	@apply text-p4 sm:text-p3 gradient-text-key not-italic px-card-base py-base-unit;
}
#cs-theming-headerfooter-content .cs-form-radio-set-stack .form-radio-option:hover > div > span > p {
	@apply text-invert;
}
#cs-theming-headerfooter-content .cs-form-radio-set-stack .form-radio-option input:checked > p {
	@apply text-invert;
}
#cs-theming-headerfooter-content .cs-form-radio-set-stack .form-radio-set.card-stack.secondary label:has(input[type="radio"]:checked) :first-child p {
	@apply text-invert;
}

.cs-color-picker-block {
	@apply w-element-base h-col-base gradient-key rounded-r-corner shadow-outer-light-t z-10;
}
.cs-color-picker-block:hover {
	@apply p-border-button;
}
.cs-color-picker-swatch {
	@apply flex w-full h-full rounded-r-corner-inset;
}

.cs-form-card-stack.form-radio-set.card-stack.gradient.secondary .form-stack-radio-options label > div {
	@apply gap-y-element-base justify-start p-gutter-base;
}

.cs-dash-card .formrow.title {
	@apply flex-wrap sm:flex-nowrap gap-y-heading;
}
.cs-dash-card .formrow.title :first-child {
	@apply w-fit sm:whitespace-nowrap;
}
.modal-block .formrow.title p,
.cs-dash-card .formrow.title p {
	@apply text-left sm:text-right;
}
.cs-dash-card .button-secondary-gradient span,
.cs-dash-card button.secondary-gradient span
{
	@apply bg-opacity-100;
}

.cs-dash-card .button.gradient.secondary > span,
.cs-dash-card .cs-profile-edit .icon-dropdown-gradient-alt.secondary > span {
	@apply bg-opacity-100;
}

.cs-dash-card .button.gradient.secondary:hover > span,
.cs-dash-card .button.gradient.secondary.active > span,
.cs-dash-card .cs-profile-edit .icon-dropdown-gradient-alt.secondary:hover > span {
	@apply bg-opacity-gradient-invert;
}

.cs-dash-card .button.gradient.secondary:hover > span p,
.cs-dash-card .button.gradient.secondary:hover > span i,
.cs-dash-card .button.gradient.secondary.active > span p,
.cs-dash-card .button.gradient.secondary.active > span i {
	@apply
	gradient-text-key;
}
.cs-dash-card .form-input.air input,
.cs-dash-card .form-textarea.air textarea {
	@apply border-b border-b-key;
}

.cs-dash-card .form-input.gradient > span,
.cs-dash-card .form-input-file.gradient > span {
	@apply bg-invert;
}

.cs-dash-card .form-input.gradient label,
.cs-dash-card .form-input-file.gradient label {
	@apply gradient-text-key;
}

.cs-dash-card .form-input.gradient input:hover,
.cs-dash-card .form-input-file.gradient input:hover {
	@apply
	gradient-key-opacity-invert;
}

.cs-dash-card.card-base {
	@apply flex flex-row p-0;
}
.cs-dash-card.card-base > div {
	@apply flex w-full;
}
.cs-dash-card.card-base .cs-stack-profile-preview {
	@apply w-full items-center justify-center;
}
.cs-dash-card.card-base .cs-stack-form-buttons-set {
	@apply flex-col;
}
.cs-dash-card.card-base .cs-profile-preview {
	@apply hidden md:flex relative;
}
.cs-dash-card.card-base .cs-profile-preview > video {
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	position: absolute;
	object-fit: cover;
	object-position: center;
	border-radius: inherit;
}
.cs-dash-card.card-base .cs-stack-profile-preview .form-input-media > video {
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	position: absolute;
	object-fit: cover;
	object-position: center;
}
.cs-stack-avatar-data .avatar {
	@apply mr-element-base;
}
.cs-stack-avatar-data hgroup > :first-child {
	@apply mb-base-unit;
}
.cs-dash-card .cs-stack-avatar-data hgroup > p:last-child {
	@apply gradient-text-key italic;
}

.cs-stack-avatar-data.inactive hgroup > h4 {
	@apply text-secondary bg-none;
}

.cs-stack-avatar-data.inactive img {
	@apply saturate-0;
}

.cs-stack-avatar-data.inactive > hgroup > p:last-child {
	@apply !text-secondary bg-none;
}
.cs-stack-avatar-data.inactive .avatar {
	@apply bg-secondary bg-none;
}

.formrow.split > div.form-input,
.formrow.split > div.form-input-file {
	@apply w-full;
}
.cs-datarow-leaderboard .data-count {
	@apply ml-sub-base;
}
.cs-dash-card .stack-avatar + .data-count {
	@apply italic ml-element-base;
}
.cs-stack-controlrows > .formrow,
.cs-dash-card .formrow {
	@apply flex-wrap sm:flex-nowrap;
}
.cs-stack-controlrows > .alert.callout {
	@apply mt-0;
}
.cs-stack-controlrows > .formrow > p:first-child {
	@apply flex w-full sm:w-1/5 text-p2 gradient-text-key;
}
.cs-dash-card .cs-banner-card-avatar {
	@apply flex w-full sm:w-1/5 aspect-square;
}

.cs-dash-card:has(.cs-dash-card-header-banner) {
	@apply p-0 space-y-0;
}
.cs-dash-card-space,
.cs-dash-card-collection {
	@apply h-fit;
}
.cs-dash-card-space .datarow.p-card,
.cs-dash-card-collection .datarow.p-card {
	@apply flex-col md:flex-row gap-y-stack-base;
}

.cs-dash-card-space .datarow.p-card > :first-child {
	@apply w-full md:w-1/3;
}
.cs-dash-card-space .datarow.p-card > div:nth-child(2) {
	@apply w-full md:w-fit;
}
.cs-dash-card-space .datarow.p-card > :last-child {
	@apply w-full md:w-3/10;
}

.cs-dash-card-space .cs-stack-activity {
	@apply flex-row flex-wrap md:flex-col;
}
.cs-dash-card-space .cs-stack-activity a {
	@apply gradient-text-key w-1/2 md:w-full;
}

.cs-dash-card-space .cs-stack-product-data hgroup > p:last-child {
	@apply italic text-secondary;
}

.cs-dash-card-collection .datarow.p-card > :first-child {
	@apply w-[118px];
}
.cs-dash-card-collection .datarow.p-card > div:nth-child(2) {
	@apply w-fit;
}
.cs-dash-card-collection .datarow.p-card > :last-child {
	@apply w-full md:w-3/10;
}

.cs-banner-card-avatar > img {
	@apply w-full h-full flex aspect-square object-cover sm:shadow-base;
	border-radius: inherit;
}
.cs-dash-card .formrow > .cs-banner-card-info {
	@apply flex flex-1;
}
.cs-dash-card .formrow > .cs-banner-card-info h3 {
	@apply text-invert bg-none leading-h2 xl:text-h2;
}
.cs-dash-card .formrow > .cs-banner-card-info h4 {
	@apply text-invert bg-none sm:text-h5 xl:text-h4;
}
.cs-dash-card .formrow > .cs-banner-card-info p {
	@apply text-invert bg-none sm:text-p4 xl:text-p3;
}
.cs-dash-card .formrow > .cs-banner-card-controls {
	@apply flex w-full sm:w-2/5 xl:w-3/10;
}
.cs-dash-card.cs-banner-card .cs-banner-card-info-stats {
	@apply flex flex-col;
}
.cs-dash-card.cs-banner-card .cs-banner-card-info-stats-status,
.cs-dash-card.cs-banner-card .cs-banner-card-info-stats-ranked {
	@apply flex flex-col w-full;
}
.cs-dash-card.cs-banner-card.cs-bg-swap .formrow > .cs-banner-card-info .cs-banner-card-info-stats-status p:first-child {
	@apply gradient-text-key sm:text-invert sm:bg-none;
}
.cs-dash-card.cs-banner-card.cs-bg-swap .formrow > .cs-banner-card-info .cs-banner-card-info-stats-status p:last-child {
	@apply text-key italic sm:text-invert;
}

.cs-dash-card.cs-banner-card.cs-bg-swap .formrow > .cs-banner-card-info h3 {
	@apply gradient-text-key sm:text-invert sm:bg-none leading-h3 xl:leading-h2;
}
.cs-dash-card.cs-banner-card.cs-bg-swap .formrow > .cs-banner-card-info h4 {
	@apply gradient-text-key sm:text-invert sm:bg-none;
}
.cs-dash-card.cs-banner-card.cs-bg-swap .formrow > .cs-banner-card-info p {
	@apply text-primary sm:text-invert sm:bg-none;
}
.cs-dash-card .formrow > .cs-banner-card-controls .button.secondary {
	@apply ring-invert hover:gradient-key-opacity-invert hover:ring-key transition-none;
}
.cs-dash-card .formrow > .cs-banner-card-controls .button.secondary:hover p,
.cs-dash-card .formrow > .cs-banner-card-controls .button.secondary:hover i {
	@apply text-invert;
}

.cs-dash-card.cs-banner-card.cs-bg-swap .formrow > .cs-banner-card-controls .button.secondary.gradient {
	@apply sm:ring-1 sm:ring-invert hover:gradient-key-opacity-invert hover:ring-key transition-none hover:text-invert;
}
.cs-dash-card.cs-banner-card.cs-bg-swap .formrow > .cs-banner-card-controls .button.secondary.gradient::before {
	@apply sm:bg-none;
}

.modal-block.card .button.secondary.gradient span {
	@apply bg-key-gradient-invert;
}
.modal-block.card .button.secondary.gradient:hover span {
	@apply bg-opacity-gradient-invert;
}
.modal-block.card .button.secondary.gradient:hover span p,
.modal-block.card .button.secondary.gradient:hover span i {
	@apply gradient-text-key;
}
.cs-dash-card.cs-banner-card.cs-bg-swap .formrow > .cs-banner-card-controls .button.secondary.gradient span {
	@apply sm:bg-transparent;
}
.cs-dash-card.cs-banner-card.cs-bg-swap .formrow > .cs-banner-card-controls .button.secondary.gradient span p,
.cs-dash-card.cs-banner-card.cs-bg-swap .formrow > .cs-banner-card-controls .button.secondary.gradient span i {
	@apply sm:text-invert sm:bg-none;
}

.cs-dash-card.cs-banner-card.cs-bg-swap .formrow > .cs-banner-card-controls .button.secondary.gradient:hover span p,
.cs-dash-card.cs-banner-card.cs-bg-swap .formrow > .cs-banner-card-controls .button.secondary.gradient:hover span i {
	@apply gradient-text-key sm:text-invert sm:bg-none;
}

.cs-dash-card.cs-product-card {
	@apply flex-col sm:flex-row;
}
.cs-dash-card.cs-product-card .cs-product-card-info {
	@apply justify-center w-full order-2 sm:order-1;
}
.cs-dash-card.cs-product-card .cs-product-card-info h4 {
	@apply text-secondary bg-none;
}
.cs-dash-card.cs-product-card .cs-product-card-display {
	@apply w-full order-1 sm:order-2;
}

.cs-dash-card.cs-product-card .cs-product-card-display .cs-product-card-shader {
	@apply justify-center items-center bg-primary-shader-light backdrop-blur-lg;
}
.cs-dash-card .cs-product-card-media {
	@apply w-full aspect-square;
}
.cs-dash-card .cs-product-card-media > img {
	@apply w-full h-full flex aspect-square object-cover sm:shadow-base;
	border-radius: inherit;
}
.cs-dash-card.cs-product-card .cs-product-card-info .cs-product-card-description > p:first-child {
	@apply line-clamp-5 xl:line-clamp-12;
}
.cs-dash-card.cs-product-card .cs-product-card-info .cs-product-card-authored {
	@apply flex flex-col gap-y-base-unit;
}
.cs-dash-card.cs-product-card .cs-product-card-info .cs-product-card-authored > p a {
	@apply not-italic;
}
.cs-dash-card.cs-product-card .cs-product-card-info .cs-product-card-authored > p {
	@apply text-secondary;
}
.cs-product-card-controls {
	@apply flex-wrap sm:flex-nowrap gap-y-stack-base;
}


.cs-stack-controlrows > .formrow > .data-item:first-child {
	@apply flex-1;
}
.cs-stack-controlrows > .formrow.split > div:last-child p,
.cs-stack-controlrows > .formrow > p:nth-child(2) {
	@apply flex-1 text-p4 italic text-secondary text-left;
}
.cs-stack-controlrows > .formrow.split > :first-child {
	@apply flex w-full sm:w-1/2;
}
.cs-stack-controlrows > .formrow.split > :last-child {
	@apply flex w-full sm:w-1/2;
}
.cs-stack-controlrows > .formrow > :last-child {
	@apply flex w-full sm:w-2/5 xl:w-3/10;
}
.cs-dash-card > .cs-stack-controlrows > .formrow > .data-item > .stack-avatar.md {
	@apply -space-x-offset-element-md sm:-space-x-offset-element-md;
}
.cs-dash-card > .cs-stack-controlrows > .formrow > .data-item > .stack-avatar.md > .avatar.md {
	@apply w-elementc-sm h-elementc-sm sm:w-elementc-md sm:h-elementc-md;
}


.data-item > .stack-avatar + .block-element {
	@apply italic;
}

.cs-stack-form-guided .formrow > div:last-child p {
	@apply text-p4 italic text-secondary w-full text-center lg:text-left;
}
.cs-stack-form-guided .formrow.media > div:last-child {
	@apply self-center;
}

.cs-stack-form-guided .formrow div.cs-formrow-helper-none {
	@apply hidden lg:flex;
}

.cs-stack-form-buttons p {
	@apply text-key pb-base-unit;
}
.cs-stack-form-buttons-set {
	@apply w-full flex flex-row gap-y-form-2xl gap-x-gutter-base;
}
#cs-product-activate-main form.stack-col > div {
	@apply pt-element-base;
}
#cs-product-activate-main > .cs-stack-title-preview > h4 > span {
	@apply italic text-primary;
}
#cs-product-activate-main a.button-primary-gradient,
#cs-product-activate-main button.primary-gradient,
#cs-product-activate-main a.button-secondary-gradient,
#cs-product-activate-main button.secondary-gradient {
	@apply w-full;
}
.cs-checkout-review-product hgroup.title > p > span {
	@apply font-bold;
}
.cs-checkout-review-product .form-radio-option > span:first-child {
	@apply w-9/20 sm:w-1/3;
}
.cs-checkout-review-product .form-radio-option > span:last-child {
	@apply flex flex-row flex-wrap;
}
.cs-checkout-review-product .form-radio-option > span p i {
	@apply ml-base-unit;
}

.cs-checkout-review-product .form-stack-radio-options label span:first-child p {
	@apply text-p3-size;
}

.cs-profile-preview {
	@apply gradient-key-opacity-invert;
}

.cs-profile-preview.card {
	@apply p-0;
}


.cs-stack-profile-preview {
	@apply place-items-center p-article-2xl;
}
.cs-stack-profile-preview.shader {
	@apply bg-primary-shader-light backdrop-blur-lg;
}
#cs-product-activate-main p a i {
	@apply config-text-key;
}
.cs-profile-preview, .cs-profile-preview h1 {
	@apply text-white text-center;
}
.cs-profile-preview .active {
	@apply gradient-text-key;
}
.cs-stack-title-preview {
	@apply flex flex-col gap-y-element-base;
}

.cs-stack-channel-tools {
	@apply lg:min-h-row-sm;
}
.cs-stack-form-media {
	@apply w-full text-center;
}
.cs-stack-form-inputs {
	@apply w-full flex;
}
.cs-stack-form-inputs.stack-row > div {
	@apply w-full;
}
.cs-stack-form-media-item .cs-label {
	@apply gradient-text-key mt-element-base text-p4 sm:text-p3;
}
.cs-stack-form-media-item .cs-helper {
	@apply text-secondary italic text-p4 sm:text-p3;
}
.cs-helper {
	@apply text-secondary italic;
}
.cs-stack-form-media-item {
	@apply w-full place-items-center flex flex-col;
}
.cs-stack-subtitle-published {
	@apply justify-between;
}
.cs-stack-form-link-icons {
	@apply flex flex-col;
}
.cs-stack-form-link-icons > p {
	@apply text-key pb-base-unit;
}

.cs-stack-form-link-icons a.button-icon-round-gradient-alt p, .cs-stack-form-link-icons button.icon-round-gradient-alt p {
	@apply text-secondary;
}

.cs-stack-form-link-icons a.button-icon-round-gradient-alt:hover p, .cs-stack-form-link-icons button.icon-round-gradient-alt:hover p, .cs-stack-form-link-icons a.button-icon-round-gradient-alt:active p, .cs-stack-form-link-icons button.icon-round-gradient-alt:active p, .cs-stack-form-link-icons a.button-icon-round-gradient-alt.active p {
	@apply text-key;
}
div.cs-stack-categories {
	@apply gap-stack-base;
}

div.cs-stack-categories > h3 {
	@apply mt-sub-base lg:mt-0;
}

div.cs-stack-pinned > h3 {
	@apply mt-sub-base lg:mt-0;
}

div.cs-stack-category-links {
	@apply gap-sub-base w-full justify-center lg:justify-normal;
}

div.cs-stack-category-links a > span {
	@apply gap-element-base;
}

div.cs-stack-category-links p.cs-count {
	@apply italic;
}

div.cs-stack-tags {
	@apply justify-center lg:justify-normal;
}

div.cs-stack-trending > h3 {
	@apply hidden lg:block;
}

.cs-stack-categories a.button-secondary-gradient.squared {
	@apply w-fit lg:w-full;
}

.cs-stack-categories a.button-secondary-gradient.squared p {
	@apply text-secondary transition-none;
}
.cs-stack-categories a.button-secondary-gradient.squared:hover p, .cs-stack-categories a.button-secondary-gradient.squared:active p {
	@apply gradient-text-key transition-none;
}
.cs-stack-categories a.button-secondary-gradient.squared {
	@apply gradient-key-transparent hover:gradient-key active:gradient-key transition-none;
}
.cs-stack-categories a.button-secondary-gradient.squared span {
	@apply bg-transparent transition-none;
}
.cs-stack-categories a.button-secondary-gradient.squared:hover span, .cs-stack-categories a.button-secondary-gradient.squared:active span {
	@apply bg-key-gradient-invert bg-opacity-gradient-invert transition-none;
}

/* STACK GAPS/FILLERS/SPACERS */

.cs-stack-excerpts {
	@apply col-span-full gap-x-gutter gap-y-gutterxx;
}
.cs-stack-fill {
	@apply grow content-end;
}
.cs-stack-fill a.button-secondary-gradient {
	@apply w-full;
}

.stack-avatar, .stack-avatar-min {
	@apply z-to-b;
}

#cs-profile-nav a {
	@apply basis-base-sm lg:w-full;
}

#cs-dash-card-nav-collection .stack-row,
#cs-dash-card-nav-benefits .stack-row,
#cs-dash-card-nav-spaces .stack-row {
	@apply flex-wrap md:flex-nowrap gap-y-sub-base;
}

#cs-dash-card-nav-collection a,
#cs-dash-card-nav-benefits a,
#cs-dash-card-nav-spaces a {
	@apply basis-full sm:basis-base-xs;
}

#cs-dash-card-nav-benefits .button.secondary,
#cs-dash-card-nav-spaces .button.secondary {
	@apply bg-transparent transition-none;
}

#cs-dash-card-nav-benefits .button.secondary::before,
#cs-dash-card-nav-spaces .button.secondary::before {
	@apply opacity-0 transition-none;
}

#cs-dash-card-nav-benefits .button.secondary:hover::before,
#cs-dash-card-nav-spaces .button.secondary:hover::before {
	@apply opacity-100 transition-none;
}

#cs-dash-card-nav-benefits .button.secondary.active span,
#cs-dash-card-nav-spaces .button.secondary.active span {
	@apply bg-transparent transition-none;
}

#cs-dash-card-nav-benefits .button.secondary.active span p,
#cs-dash-card-nav-spaces .button.secondary.active span p {
	@apply bg-none text-invert font-bold transition-none;
}

#cs-dash-card-nav-collection .button {
	@apply bg-none ring-0;
}
#cs-dash-card-nav-collection .button.active span,
#cs-dash-card-nav-collection .button:hover span {
	@apply bg-opacity-50 bg-invert;
}
#cs-dash-card-nav-collection .button:hover span p {
	@apply bg-none text-invert;
}
#cs-dash-card-nav-collection .button.active span p {
	@apply bg-none text-invert font-bold;
}

/* TODO REMOVE #cs-profile-nav duplicates below after cs-nav-tabs added */
#cs-profile-nav a,
.cs-nav-tabs a {
	@apply shrink grow basis-base-sm lg:w-full gradient-key-transparent transition-none;
}
#cs-profile-nav a:hover, #cs-profile-nav a:active, #cs-profile-nav a.active,
.cs-nav-tabs a:hover, .cs-nav-tabs a:active, .cs-nav-tabs a.active  {
	@apply gradient-key bg-opacity-100;
}
#cs-profile-nav a p,
.cs-nav-tabs a p {
	@apply transition-none;
}
#cs-profile-nav a:hover p,
.cs-nav-tabs a:hover p {
	@apply gradient-text-key transition-none;
}
#cs-profile-nav a:active p, #cs-profile-nav a.active p,
.cs-nav-tabs a:active p, .cs-nav-tabs a.active p {
	@apply text-invert;
}
#cs-profile-nav a span,
.cs-nav-tabs a span {
	@apply bg-transparent transition-none;
}
#cs-profile-nav a:hover span,
.cs-nav-tabs a:hover span {
	@apply bg-key-gradient-invert bg-opacity-gradient-invert;
}
#cs-profile-nav a:active span, #cs-profile-nav a.active span,
.cs-nav-tabs a:active span, .cs-nav-tabs a.active span {
	@apply bg-key-gradient-invert bg-opacity-0;
}

.cs-title-icon-attribute {
	@apply items-baseline;
}

.cs-title-icon-attribute p i {
	@apply pr-sub-base;
}

.cs-top8-avatar {
	@apply avatar-gradient-sm sm:avatar-gradient-md shadow-light;
}
#cs-top8 h2 {
	@apply text-center sm:text-left;
}
.cs-stack-top8 {
	@apply justify-center sm:justify-normal w-full;
}
.cs-top8-item {
	@apply items-center shrink sm:grow basis-base-sm;
}
.cs-stack-top8-info {
	@apply gap-stack-min;
}

.cs-stack-top8-stats {
	@apply gap-x-stack-base;
}

.cs-stack-top8-stats p {
	@apply w-elementc-md;
}

.cs-stack-top8-stats p i {
	@apply pr-sub-base;
}

#cs-mod-profile-spotify-playlist iframe {
	@apply rounded-card shadow-light;
}

#cs-profile-c1 {
	@apply shadow-inner-light-t;
}
#cs-profile-banner .centerframe-grid {
	@apply items-center;
}

#cs-profile-banner-main.block-xl {
	@apply lg:max-xl:col-span-9;
}
#cs-profile-banner-actions.block-md {
	@apply lg:max-xl:col-span-3;
}

#cs-profile-banner-main > .stack-row {
	@apply gap-gutter;
}

#cs-profile-banner-avatar {
	@apply content-center text-center;
}
.cs-profile-avatar {
	@apply avatar-gradient-lg sm:avatar-gradient-xl shadow-base;
}

#cs-profile-banner-info {
	@apply w-full text-invert gap-stack-base text-center lg:text-left;
}

#cs-profile-banner-info .cs-stack-link-icons {
	@apply gap-stack-min xl:max-2xl:gap-element-base 2xl:gap-element-2xl justify-center lg:justify-start;
}

.cs-centerframe-split {
	@apply w-full flex flex-col sm:flex-row sm:pr-0 items-center;
}
.cs-centerframe-split-left {
	@apply w-full flex flex-col items-start sm:py-0 sm:pl-0;
}
.cs-centerframe-split-left > div {
	@apply w-full flex flex-col items-end;
}
.cs-centerframe-split-right {
	@apply w-full;
}
.cs-centerframe-split-right div img {
	@apply h-3row-base sm:h-full sm:aspect-x-golden w-full object-cover object-center sm:rounded-l-corner-max;
}

.cs-centerframe-split-lg {
	@apply w-full flex flex-col lg:flex-row lg:pr-0 items-center;
}
.cs-centerframe-split-left-lg {
	@apply w-full flex flex-col items-start lg:py-0 lg:pl-0;
}
.cs-centerframe-split-left-lg > div {
	@apply w-full flex flex-col items-end;
}
.cs-centerframe-split-right-lg {
	@apply w-full;
}
.cs-centerframe-split-right-lg div img {
	@apply h-3row-base lg:h-full lg:aspect-x-golden w-full object-cover object-center lg:rounded-l-corner-max;
}

#cs-mod-profile-featured-links article {
	@apply flex-1;
}

.cs-stack-create-account .form-input label {
	@apply whitespace-nowrap line-clamp-1;
}
.cs-stack-create-account {
	@apply w-full;
}
.cs-stack-input-helper {
	@apply flex flex-col gap-y-sub-base;
}
.cs-stack-input-helper p.cs-helper {
	@apply text-p4 text-center;
}
.cs-stack-auth-form {
	@apply w-full;
}
.cs-stack-auth-form > hgroup > p {
	@apply text-p1;
}
.cs-stack-auth-form > form {
	@apply w-full;
}
.cs-stack-auth-form > p {
	@apply text-p2 italic;
}

.cs-stack-contact-form {
	@apply w-full;
}
.cs-stack-contact-form .stack-row {
	@apply flex-wrap sm:flex-nowrap gap-y-stack-base w-full;
}
.cs-stack-contact-form .stack-row .form-input {
	@apply w-full;
}
.cs-stack-contact-form > hgroup > p {
	@apply text-p1;
}
.cs-stack-contact-form > form {
	@apply w-full;
}
.cs-stack-contact-form > p {
	@apply text-p2 italic;
}

#cs-space-checkout-c1 .cs-centerframe-split-lg {
	@apply items-start;
}
#cs-space-checkout-c1 .cs-centerframe-split-right-lg {
	@apply lg:sticky lg:top-0;
}

#cs-platform-create-start-c1 .button-primary-gradient {
	@apply w-full;
}

.cs-stack-checkout-form {
	@apply w-full;
}

.cs-stack-checkout-form p a i {
	@apply gradient-text-key;
}
.cs-checkout-terms {
	@apply w-full;
}
.cs-checkout-terms fieldset {
	@apply flex flex-col gap-y-stack-min;
}
.cs-checkout-form-promo {
	@apply w-full;
}
.cs-checkout-terms .stack-button-info {
	@apply mt-base-unit;
}

.cs-checkout-terms .stack-button-info > p {
	@apply text-center;
}

.cs-checkout-terms .form-input-checkbox > div > p {
	@apply text-p4-size leading-p4;
}

.cs-checkout-terms .stack-button-info > p > span {
	@apply gradient-text-key pr-sub-base;
}
.cs-checkout-product-hero {
	@apply lg:pl-half-gutter;
}
.cs-checkout-product-hero img {
	@apply sm:max-h-element;
}
.cs-checkout-total {
	@apply items-baseline flex flex-col gap-y-base-unit;
}
.cs-checkout-total-upfront {
	@apply items-baseline flex flex-row gap-x-stack-min;
}
.cs-checkout-total-upfront h2 {
	@apply text-primary;
}
.cs-checkout-total-upfront p {
	@apply text-p2;
}
.cs-checkout-total-renewal p {
	@apply italic;
}
.cs-profile-username {
	@apply text-p1;
}
.cs-stack-checkout-product-info {
	@apply w-full flex flex-col gap-y-stack-min;
}
.cs-stack-checkout-product-info > div.stack-row p:first-child {
	@apply text-p2 gradient-text-key w-9/20 sm:w-1/3;
}
.cs-stack-checkout-product-info > div.stack-row p:last-child {
	@apply text-p2 text-left;
}

.cs-stack-profile-displayname-pronouns {
	@apply items-baseline justify-center lg:justify-start;
}

.cs-stack-profile-displayname-pronouns > h1 {
	@apply text-invert;
}

.cs-stack-profile-displayname-pronouns > p {
	@apply text-p2 italic;
}

.cs-stack-element-preview-form-input div {
	@apply w-full;
}
.cs-stack-element-preview {
	@apply flex flex-col w-full;
}
.cs-element-preview-label {
	@apply flex flex-row;
}

.cs-profile-bio {
	@apply italic;
}
.cs-banner-card {
	@apply p-0 bg-cover bg-center
}
.cs-banner-card-shader {
	@apply bg-primary-shader-light backdrop-blur-lg;
}
.cs-banner-card .cs-banner-card-shader > .formrow {
	@apply gap-y-card-base;
}
.cs-stack-profile-stats {
	@apply justify-center lg:justify-start;
}

.cs-stack-profile-stats-item > :first-child {
	@apply text-p1;
}

.cs-post-form .form-textarea {
	@apply border-0 rounded-field p-sub;
}

.cs-post-form .form-textarea textarea {
	@apply w-full border-0 p-0;
}

.cs-post-form .form-textarea label {
	@apply sr-only;
}

div.cs-stack-comment-hearts.stack-row {
	@apply items-center gap-x-stack-min 2xl:gap-element-2xl gradient-text-key;
}

#cs-space-product-item-c2.theme-transparent {
	@apply bg-invert shadow-inner-light-y;
}

#cs-space-product-item-c2 .datarow a i {
	@apply gradient-text-key;
}
#cs-space-product-item-c2 .datarow {
	@apply flex-wrap sm:flex-nowrap sm:items-start;
}
#cs-space-product-item-c2 .datarow.title {
	@apply sm:items-baseline;
}
#cs-space-product-item-c2 .datarow.title h3 {
	@apply w-full;
}
#cs-space-product-item-c2 .datarow.title p {
	@apply text-left sm:text-right italic;
}
#cs-space-product-item-c2 .datarow > :first-child {
	@apply w-full sm:w-2/5;
}
#cs-space-product-item-c2 .datarow > p:first-child {
	@apply font-bold;
}
#cs-space-product-item-c2 .datarow > :last-child {
	@apply w-full;
}
#cs-product-benefits, #cs-product-traits,
#cs-product-technicals {
	@apply w-full;
}
#cs-product-technicals .datarow.title {
	@apply w-full basis-full;
}
#cs-product-technicals .datarow {
	@apply basis-full sm:shrink sm:grow sm:basis-base-sm;
}
#cs-product-technicals .datarow :first-child {
	@apply w-full;
}
.cs-product-pricing-details p.cs-product-renewal-summary-cost {
	@apply text-primary italic;
}
.cs-product-pricing-details > div > h4 > i {
	@apply min-w-element-base text-center;
}
#cs-platform-create-start-c1 aside {
	@apply hidden lg:block;
}

/* ---------------------------- */
/* ANCHOR Product Page			*/
/* ---------------------------- */
#cs-product-cover-media {
	@apply shadow-light;
}
#cs-product-cover-media img {
	@apply w-full h-full object-cover object-center;
}
#cs-stack-product-actions a {
	@apply w-full;
}

#cs-product-info {
	@apply content-center;
}

.cs-product-info-listing {
	@apply italic;
}

.cs-product-pricing-details > div {
	@apply w-full;
}
.cs-product-pricing-details h2 span {
	@apply text-h6-size;
}
.cs-product-pricing-details h4 {
	@apply leading-h2;
}
.cs-product-pricing-details h4 i {
	@apply pr-sub-base;
}
.cs-product-pricing-details p {
	@apply leading-h2 text-secondary italic;
}

/* ---------------------------- */
/* ANCHOR DATAROWS				*/
/* ---------------------------- */

.cs-stack-follows {
	@apply flex flex-row flex-wrap sm:flex-col w-full gap-stack-base justify-center;
}

.cs-datarow-follow {
	@apply flex-wrap sm:flex-nowrap basis-base-sm sm:w-full;
}
.cs-datarow-follow .igroup h4 {
	@apply line-clamp-1;
}
.cs-datarow-follow > .datarow {
	@apply flex-wrap sm:flex-nowrap;
}

.cs-datarow-search-result > .datarow,
.cs-datarow-leaderboard > .datarow {
	@apply flex-wrap lg:flex-nowrap;
}

.cs-datarow-search-result > .datarow > .data-item:first-child {
	@apply w-full lg:w-1/3 justify-start;
}

.cs-datarow-search-result > .datarow > .data-item:last-child {
	@apply w-full justify-start;
}

.cs-datarow-follow > .datarow > div,
.cs-datarow-leaderboard > .datarow > div {
	@apply w-full lg:w-1/3 justify-center;
}

.cs-datarow-follow > .datarow > .data-item:first-child,
.cs-datarow-leaderboard > .datarow > .data-item:first-child {
	@apply lg:justify-start;
}

.cs-datarow-follow > .datarow > .data-item:last-child,
.cs-datarow-leaderboard > .datarow > .data-item:last-child {
	@apply lg:justify-end;
}
.cs-datarow-search-result > .datarow > .data-item:last-child .button {
	@apply w-full;
}
/* TODO Swap to data-item.* format */

.cs-datarow-search-result div,
.cs-datarow-follow div,
.cs-datarow-leaderboard div {
	@apply items-center;
}
.cs-datarow-search-result .igroup,
.cs-datarow-follow .igroup,
.cs-datarow-leaderboard .igroup {
	@apply gap-sub-base;
}

.cs-mod-profile-community-attributes {
	@apply gap-y-stack-base sm:gap-y-stack-min;
}

.cs-datarow-attribute {
	@apply flex-wrap sm:flex-nowrap gap-y-base-unit;
}

.cs-datarow-attribute h5 {
	@apply text-primary;
}
.cs-datarow-attribute .data-item:first-child {
	@apply shrink grow basis-base-sm;
}

.cs-datarow-attribute .data-item:last-child {
	@apply w-full;
}

.cs-datarow-search-result .igroup i,
.cs-datarow-leaderboard i.fa-crown,
.cs-datarow-leaderboard i.fa-caret-up {
	@apply gradient-text-key;
}
.cs-datarow-leaderboard i.fa-caret-down { @apply text-secondary;}

.cs-post-form form div.form-textarea {
	@apply ring-transparent p-0;
}

.cs-post-form-avatar {
	@apply avatar-gradient sm:avatar-gradient-md;
}

.cs-hero-content {
	@apply flex col-span-full;
}

/* ---------------------------- */
/* ANCHOR Icon Related			*/
/* ---------------------------- */


i.fa-badge-check::after {
	content: '';
	position: absolute;
	top: 15%;
	right: 15%;
	bottom: 15%;
	left: 15%;
	background: rgb(var(--color-background));
	z-index: -1;
	border-radius: 100%;
}

i.fa-circle-xmark::before {
	position: absolute;
	z-index: 1;
	top: 0;
	left: 0;
}

i.fa-circle-xmark::after {
	content: '';
	position: absolute;
	top: 15%;
	right: 15%;
	bottom: 15%;
	left: 15%;
	background: rgb(var(--color-background));
	z-index: 0;
	border-radius: 100%;
}

p i.fa-regular {
	line-height: inherit;
}

/* ---------------------------- */
/* ANCHOR Pagination			*/
/* ---------------------------- */

.cs-pagination {
	@apply justify-center text-center justify-self-center;
}

/* ---------------------------- */
/* ANCHOR Mobile & Adaptive		*/
/* ---------------------------- */
/* Reusable Mobile Modifiers	*/
/* For use across multiple instances and elements. */

/* ICON LINK SET - ADAPTIVE ALIGNMENT */
.cs-stack-link-icons {
	@apply justify-center lg:justify-start;
}

/* VIEW ALL MOBILE LINK */
.cs-mobile-link-viewall {
	@apply text-p1 sm:hidden col-span-full text-center;
}

/* FOOTER TEXT - ADAPTIVE ALIGNMENT */
.cs-footer-brand-text {
	@apply text-center lg:text-left;
}

#page-content-loading-overlay div.modal-block {
	@apply text-center p-article-base animate-fade-half;
}

#page-content-loading-overlay i {
	@apply text-h1 gradient-text-key animate-spin-slow;
}

/* ---------------------------- */
/* ANCHOR Draggables			*/
/* ---------------------------- */
div.cs-stack-form-drag-cards {
	@apply w-full flex flex-col;
}

div.cs-stack-form-drag-links {
	@apply w-full flex flex-col gap-y-card-base;
}

.cs-form-drag-link {
	@apply p-0 rounded-corner relative text-primary shadow-light;
}

.cs-form-drag-link::before {
	@apply absolute top-0 left-0
	w-full h-full rounded-corner
	bg-gradient-to-r from-key to-key-end
	config-transition-set;
	content: '';
	z-index: 0;
}
.cs-form-drag-link.active,
.cs-form-drag-link:hover {
	@apply p-border-button;
}
.cs-form-drag-card {
	@apply w-full flex flex-col;
}
.cs-form-drag-card .cs-controls-draggable p {
	@apply cursor-grab text-secondary h-element-base flex items-center hover:gradient-text-key;
}
.cs-form-drag-card.active .cs-controls-draggable p {
	@apply cursor-grabbing;
}

.cs-form-drag-card > .cs-stack-controlrows {
	@apply flex flex-row flex-wrap sm:flex-nowrap
	items-center justify-between
	w-full h-full;
}
.cs-form-drag-card .cs-form-drag-card-title {
	@apply w-full items-center gap-x-gutter-base flex flex-col sm:flex-row;
}
.cs-form-drag-card .cs-benefit-card-title,
.cs-form-drag-card .cs-access-card-title,
.cs-form-drag-card .cs-question-card-title,
.cs-form-drag-card .cs-channel-card-title {
	@apply w-full justify-between sm:justify-start;
}
.cs-form-drag-card .cs-benefit-card-title p,
.cs-form-drag-card .cs-access-card-title p,
.cs-form-drag-card .cs-question-card-title p,
.cs-form-drag-card .cs-channel-card-title p {
	@apply leading-h4 sm:hidden lg:block;
}

.cs-stack-icons-attributes {
	@apply flex flex-row gap-x-form-2xl w-fit;
}

.cs-form-drag-card .cs-stack-icons-attributes p {
	@apply text-secondary;
}

.cs-stack-icons-attributes p {
	@apply w-element-min text-center;
}

.cs-form-drag-card .cs-access-card-controls
.cs-form-drag-card .cs-question-card-controls
.cs-form-drag-card .cs-channel-card-controls {
	@apply w-fit sm:w-full justify-end;
}

.cs-stack-form-drag-link {
	@apply flex flex-row flex-wrap sm:flex-nowrap
	items-center
	w-full h-full
	gap-form-sm
	rounded-corner-inset
	p-[17px]
	bg-key-gradient-invert
	bg-opacity-gradient-invert
	relative;
}
.cs-form-drag-link.active .cs-stack-form-drag-link,
.cs-form-drag-link:hover .cs-stack-form-drag-link  {
	@apply p-card-base;
}
.cs-form-drag-link:hover .cs-stack-form-drag-link  {
	@apply cursor-grab;
}
.cs-form-drag-link.active .cs-stack-form-drag-link {
	@apply cursor-grabbing;
}
.cs-form-drag-link .form-input input,
.cs-form-drag-link button.form-input-dropdown span p {
	@apply text-p3-size;
}
.cs-form-drag-link i {
	@apply gradient-text-key;
}

.cs-stack-form-drag-link > div,
.cs-stack-form-drag-link > div > .form-input-dropdown {
	@apply w-full;
}
.cs-form-drag-card div.cs-controls-draggable,
.cs-form-drag-link div.cs-controls-draggable {
	@apply flex flex-row w-full sm:w-fit justify-between items-center;
}
.cs-controls-draggable-buttons {
	@apply flex flex-row sm:hidden;
}
.cs-controls-draggable-buttons > button {
	@apply w-element-minxx text-center;
}
.cs-stack-form-question-edit.hide,
.cs-stack-form-channel-edit.hide {
	@apply hidden;
}

/* --------------------------------------- */
/* CSNOTE ANT FORM OVERRIDES			   */
/* --------------------------------------- */
/* NOTE REQUIRED IF ANT FORM IS USED	   */
/* --------------------------------------- */
.ant-form-item-explain {
	@apply hidden;
}

/* --------------------------------------- */
/* CSNOTE DEMO STATE TOGGLES			   */
/* --------------------------------------- */
/* NOTE NOT FOR PRODUCTION - DEMO USE ONLY */
/* --------------------------------------- */
& BODY.demo-auth-member {

.cs-stack-user-nav-public,
.cs-stack-user-nav-public-menu-toggle,
#header-nav-menu > NAV > :nth-last-child(2),
#header-nav-menu > NAV > :last-child,
#cs-platform-footer-mobile-auth,
#cs-platform-footer-mobile-nav > nav > :nth-child(6),
#cs-space-footer-mobile-auth,
#cs-space-footer-mobile-nav > nav > :nth-child(6)
{ @apply !hidden;}

}

& BODY.demo-auth-public {

.cs-stack-user-nav-member,
#cs-platform-footer-mobile-nav > nav > :nth-child(2),
#cs-platform-footer-mobile-nav > nav > :nth-child(3),
#cs-platform-footer-mobile-nav > nav > :nth-child(4),
#cs-space-footer-mobile-nav > nav > :nth-child(2),
#cs-space-footer-mobile-nav > nav > :nth-child(3),
#cs-space-footer-mobile-nav > nav > :nth-child(4)
{@apply !hidden;}

}

/* -------------------------------------------- */
/* !SECTION Utilities	 						*/
/* -------------------------------------------- */

/* ------------------------------------------------	*/
/* SECTION Theming Special Variable Applications	*/
/* ------------------------------------------------ */
/* CSJS Theming Backgrounds and Banners Image Variables applied here. */

SECTION.theme-bg-image {
	background-size: cover;
	background-position: var(--cs-theme-bg-image-position);
	background-repeat: no-repeat;
	background-image: var(--cs-theme-bg-image);
}

SECTION.theme-alt-bg-image {
	background-size: cover;
	background-position: var(--cs-theme-alt-bg-image-position);
	background-repeat: no-repeat;
	background-image: var(--cs-theme-alt-bg-image);
}

#cs-space-banner-hero {
	background-size: cover;
	background-position: var(--cs-space-banner-hero-bg-image-position);
	background-repeat: no-repeat;
	background-image: var(--cs-space-banner-hero-bg-image);
}

#cs-space-banner-updates {
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	background-image: var(--cs-space-banner-updates-image);
}
#cs-space-banner-benefits {
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	background-image: var(--cs-space-banner-benefits-image);
}
#cs-space-banner-members {
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	background-image: var(--cs-space-banner-members-image);
}
#cs-space-banner-access {
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	background-image: var(--cs-space-banner-access-image);
}
#cs-space-banner-channels {
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	background-image: var(--cs-space-banner-channels-image);
}
#cs-space-banner-info {
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	background-image: var(--cs-space-banner-info-image);
}

#cs-space-banner-hero {
	position: relative;
}

#cs-space-banner-hero .cs-hero-frame {
	height: 100%;
	justify-items: var(--cs-space-banner-hero-fg-content-justify);
	align-items: var(--cs-space-banner-hero-fg-content-align);
	position: relative;
	z-index: 1;
}

#cs-hero-bg-video {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 0;
	object-fit: cover;
	object-position: var(--cs-space-banner-hero-bg-video-position);
}
.cs-main {
	@apply relative;
}

.cs-main > SECTION {
	@apply relative;
}

.cs-main > SECTION > #cs-theme-bg-video {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 0;
	object-fit: cover;
	object-position: var(--cs-theme-bg-video-position);
}
.cs-main > SECTION > #cs-theme-alt-bg-video {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 0;
	object-fit: cover;
	object-position: var(--cs-theme-alt-bg-video-position);
}

.cs-main > SECTION > .centerframe-grid {
	position: relative;
	z-index: 1;
}

.cs-main > SECTION.theme-transparent > #cs-theme-bg-video,
.cs-main > SECTION.theme-transparent > #cs-theme-alt-bg-video,
.cs-main > SECTION.theme-alt-transparent  > #cs-theme-bg-video,
.cs-main > SECTION.theme-alt-transparent  > #cs-theme-alt-bg-video {
	@apply hidden;
}


/* ------------------------------------------------	*/
/* !SECTION Theming Special Variable Applications	*/
/* ------------------------------------------------ */

/* --------------------------------------------------------------------	*/
/* !SECTION Commonspace Custom Classes 									*/
/* --------------------------------------------------------------------	*/

/* -------------------------------- */
/* !LAYER global end 				*/
/* -------------------------------------------------------------------------------------------- */
/* !SECTION CONFIG Custom CSS Classes 															*/
/* -------------------------------------------------------------------------------------------- */