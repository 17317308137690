$gradient-color-from: #fb03b9; // primary gradient from color
$gradient-color-to: #3883ff; // primary gradient to color
$body-text-color-reg: #0c0c0c;
$body-text-color-light: #888888;
$body-text-color-lightest: #ffffff;
$body-text-color-disable: #e7e7e7;

//quill text editor css customization
.quill {
	width: 100%; /* Fallback for other browsers */
	//width: -webkit-fill-available; /* WebKit-specific value */
	background-color: white;
	border: 0 solid transparent;

	.ql-container {
		min-height: 150px;
	}
	.ql-editor {
		word-break: break-word; /* Ensures long strings wrap within the container */
	}

	//
	//.ql-toolbar {
	//	border-top-left-radius: 32px;
	//	border-top-right-radius: 32px;
	//}
}

.disabled-input {
	background: #d7d7d7 !important; /* Light gray background */
	color: #6c6c6c; /* Gray text */
	//border: 1px solid #dcdcdc; /* Light gray border */
	cursor: not-allowed; /* Not-allowed cursor */
}

.gradient-color-bg {
	background: linear-gradient(to right, $gradient-color-from, $gradient-color-to) border-box;
}

.gradient-color-bg-hover {
	background: linear-gradient(to right, $gradient-color-to, $gradient-color-from) border-box;
}

.gradient-color-border-no-bg{
	background: linear-gradient(#f8e9fa, #f4ecfc) padding-box,
		linear-gradient(to right, $gradient-color-from, $gradient-color-to) border-box !important;
}

.gradient-color-border {
	background: linear-gradient(white, white) padding-box,
		linear-gradient(to right, $gradient-color-from, $gradient-color-to) border-box ;
}

.gradient-color-txt {
	background: linear-gradient(to right, $gradient-color-from, $gradient-color-to);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	text-decoration: none;
}

.gradient-border {
	@extend .gradient-color-txt;
	position: relative;
	margin: 0 auto;

	&:after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		@extend .gradient-color-border;
		border: 1px solid transparent;
		border-radius: 32px;
		background: inherit;
		background-origin: border-box;
		background-clip: border-box;
		-webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0) ;
		-webkit-mask-composite: xor;
		mask-composite: exclude;
		-webkit-mask-repeat: no-repeat;
	}
}

.gradient-border-not-rounded {
	@extend .gradient-color-txt;
	position: relative;
	margin: 0 auto;

	&:after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		@extend .gradient-color-border;
		border: 1px solid transparent;
		border-radius: 4px;
		background: inherit;
		background-origin: border-box;
		background-clip: border-box;
		-webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0) ;
		-webkit-mask-composite: xor;
		mask-composite: exclude;
		-webkit-mask-repeat: no-repeat;
	}
}

/*
Antd css override
*/
.ant-form .ant-form-item .ant-form-item-explain-error {
  display: none !important;
}

//overide margin bottom of ant design form item
.ant-form-item {
  margin-bottom: 0;
}

//overide height of ant design button
.ant-btn {
  height: unset;
}

//overide ant design status error
.ant-picker-status-error {
	border: none !important;
	background: none !important;
}

//overide styles of ant design upload dragger
.ant-upload-wrapper .ant-upload-drag {
  border: unset;
  background: none;


  .ant-upload {
    padding: unset;
  }
}

.cs-post-form {
  .ant-upload-wrapper .ant-upload-drag {
    border: none;
  }
}

#settings-birthdate{
    width: 68% !important;
}

#cscxl-datepicker{
    .ant-picker .ant-picker-input{
        padding-right: 5px;
    }
    .ant-picker .ant-picker-input:hover .ant-picker-clear{
        background-color: transparent;
        padding-right: 30px;
    }
    .ant-picker-suffix{
        padding-right: 8px;
        color:black;
    }
    .ant-picker-input > input::placeholder {
        color: black;
        font-style: normal;
    }
    .ant-picker-input > input {
        color: black;
        font-style: normal;
        padding-left: 9px;
    }

}

#cscxl-datepicker.form-input{
    width: 100%;
    padding: 4.8px;
}

#cscxl-datepicker.form-input{
   label{
    padding-left: 11px;
   }
}

/*
End antd css
*/

/*
Header css
*/
#user-menu-toggle {
  .videoPreview video {
    object-fit: cover;
    border-radius: 9999px;
  }
}

//Dropdown menu for the header profile
.dropdown-menu-end {
  margin-left: -80px;
}
/*
End of header css
*/

/*
Footer css
*/

// Footer video profile icon
#cs-space-footer-mobile-nav {
  .videoPreview video {
    object-fit: cover;
    border-radius: 9999px;
  }
}

// Basic footer user profile icon
#cs-platform-footer-mobile-nav {
  .videoPreview video {
    object-fit: cover;
    border-radius: 9999px;
  }
}

/*
End of footer css
*/

/*
UserView
*/
#cs-profile-banner-main {
  .videoPreview video {
    object-fit: cover;
    border-radius: 9999px;
  }
}

//UserView Spaces Tab css
.profile-spaces {
	border-radius: 32px;
	padding: 32px;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: top;
	position: relative;

	.items-center {
		@media screen and (max-width: 1400px) {
			margin-top: 7px !important;
		}

		@media screen and (max-width: 1300px) {
			margin-top: 6px !important;
		}

		@media screen and (max-width: 1200px) {
			margin-top: 5px !important;
		}

		@media screen and (max-width: 1100px) {
			margin-top: 4px !important;
		}

		@media screen and (max-width: 1000px) {
			margin-top: 3px !important;
		}
	}

	.profile-spaces-content {
		position: relative;
		z-index: 2;

		.img-spaces {
			width: 296px;
			height: 296px;
			box-shadow: 0px 0px 10px 0px rgba(12, 12, 12, 0.5);
			border-radius: 32px;
			object-fit: cover;
			object-position: center;
			@media screen and (max-width: 960px) {
				width: 100% !important;
			}
		}

		.body-text-reg {
			font-size: 17px;
		}

		.header-small-title {
			font-size: 18px;
		}

		.body-txtitalic--small {
			font-size: 18px;
		}

		.fa-solid {
			font-size: 24px;
			@media screen and (max-width: 1400px) {
				font-size: 20px !important;
			}
			@media screen and (max-width: 1200px) {
				font-size: 19px !important;
			}
			@media screen and (max-width: 1000px) {
				font-size: 17px !important;
			}
		}

		.body-text--small {
			font-size: 18px;
		}

	}

	.bg-overlay {
		border-radius: 32px;
	}

	.polygon-spaces {
		list-style: none;
		padding: 0px;
		margin: 0px;

		li:first-child {
			margin-left: 0px;
		}

		li {
			display: inline-block;
			margin-left: -40px; // 30px

			@media screen and (max-width: 1400px) {
				margin-left: -25px;
			}

			@media screen and (max-width: 1200px) {
				margin-left: -20px;
			}

			@media screen and (max-width: 1000px) {
				margin-left: -15px;
			}

			a.square {
				position: relative;
			}

			a.hex {
				margin-top: 4px;
				width: 80px;
				height: 80px;
				display: inline-block;
				background: linear-gradient(to right, #fb03b9, #3883ff) border-box;
				clip-path: polygon(21% 1%, 79% 1%, 100% 46%, 79% 99%, 21% 99%, 0% 46%);
				position: relative;

				img {
					width: 80px;
					height: 80px;
					display: block;
					position: absolute;
					top: 1px;
					left: 1px;
					clip-path: polygon(21% 1%, 79% 1%, 100% 46%, 79% 99%, 21% 99%, 0% 46%);
				}
			}
		}
	}
	.body-txtitalic--smallest {
		font-size: 16px !important;
	}
}


/*
End of UserView Css
*/


/*
Space
*/
#cs-space-main {
	.videoPreview video {
		object-fit: cover;
	}
	.youtubeVideoPreview iframe{
		border-radius: 24px;
	}
	.cs-product-membership {
		.videoPreview video {
		  object-fit: cover;
		  border-radius: 24px;
		}
	}
	.cs-excerpt-update {
		.videoPreview video {
		  aspect-ratio: 432 / 280;
		  object-fit: cover;
		  border-radius: 24px;
		}
	}
	#cs-space-links-latest {
		.videoPreview video {
		  aspect-ratio: 432 / 280;
		  object-fit: cover;
		  border-radius: 24px;
		}
	}
}
#cs-space-home-welcome-image {
  .videoPreview video {
    border-radius: 24px;
    object-fit: cover;
  }
}
/*
End of Space css
*/


/* UploadDraggerV2 css */
.circle a video {
	border-radius: 9999px !important;
	height: 100% !important;
	object-fit: cover !important;
}
.video a video {
	border-radius: 8px !important;
	object-fit: cover !important;
}
.square a video {
	border-radius: 8px !important;
	object-fit: cover !important;
}
.aspect-cs-preview a video {
	border-radius: 8px !important;
	object-fit: cover !important;
}
/* End of UploadDraggerV2 css */

/*
Temporary adjust split header parent container into h-3row
To adjust the split header and not overlaping in the main layout
*/
.important-h-3row {
	@apply h-3row-base sm:h-3row-sm md:h-3row-md lg:h-3row-lg xl:h-3row-xl 2xl:h-3row-2xl
	absolute top-0 left-0 w-full bg-transparent;
}

/*
Create / Edit profile aside layout css
*/
#cs-profile-create-aside {
	// css for profile preview video
	.video-profile-background {
		height: 100%;
		width: 100%;
		object-fit: cover;
		border-radius: 24px;
	}
	.video-profile-background-container {
		height: 100%;
		width: 100%;

		video {
			height: 100%;
			width: 100%;
			object-fit: cover;
			border-radius: 9999px;
		}

		img {
			height: 100%;
			width: 100%;
			object-fit: cover;
			border-radius: 9999px;
		}
	}
}

/*
Dashboard css
*/
#csxl-profile-dashboard {
	// Profile Edit Css

	#cscxl-manage-followers {
		.dashboard-wrapper {
			background-color: #fff;
			border-radius: 8px;
			padding: 32px;

			.ant-btn-default {
				box-shadow: unset;
				color: unset;
			}

			.header-medium {
				font-size: 36px;
				line-height: 2.5rem;
			}

			.follows-card-row {
				list-style: none;
				margin: 0;
				padding: 0;
			}

			.nav-pills .nav-item .nav-link {
				align-items: center;
				border: 0;
				display: flex;
				//font-family: notoSansRegular;
				font-size: 18px;
				height: 40px;
				justify-content: center;
			}

			li{
				position: relative;
				list-style-type: none;
				border-top-width: 0px;
				text-align: center;
				margin-top: 0px !important;
			}

			.nav-pills {
				list-style: none;
				padding: 0;

				.nav-item:first-child {
					padding-left: 0;
				}
				.nav-item:last-child {
					padding-right: 0;
				}
				.nav-item {
					@media screen and (max-width: 960px) {
						display: flex;
					}
					.nav-link {
						background: linear-gradient(to right, #fb03b9, #3883ff);
						-webkit-background-clip: text;
						-webkit-text-fill-color: transparent;
						text-decoration: none;
						border: 0;
						height: 40px;
						font-size: 18px;
						display: flex;
						justify-content: center;
						align-items: center;
					}
					.nav-link.active,
					.nav-pills .show>.nav-link {
						background: linear-gradient(to right, #fb03b9, #3883ff) border-box;
						border-radius: 8px;
						color: #ffffff;
						-webkit-text-fill-color: #ffffff;
					}
				}
			}
		}
	}

	.dashboard-profileno-background {
		.follows-card-row {
			list-style: none;
			padding: 0px;
			margin: 0px;

			li {
				display: inline-block;
				height: 48px;
				width: 48px;
				text-align: center;
				a {
					font-size: 36px;
					color: $body-text-color-light;
				}

				a.active {
					@extend .gradient-color-txt;
				}
			}
		}

		.nav-link {
			width: 322px;
			@media screen and (max-width: 1400px) {
				width: 270px;
			}

			@media screen and (max-width: 1300px) {
				width: 260px;
			}

			@media screen and (max-width: 1200px) {
				width: 220px;
			}

			@media screen and (max-width: 1000px) {
				width: 180px;
			}
		}

		.row-view {
			.row-view-wrapper {
				border-radius: 32px;
				background-color: #ffffff;
				padding: 15px 25px;
			}

			.rounded-circle {
				@extend .gradient-color-border;
				border: 1px solid transparent;
				width: 40px;
				height: 40px;
				object-fit: cover;
				border-radius: 50%;

				@media screen and (max-width: 1000px) {
					width: 38px;
					height: 38px;
				}
			}

			.fa-light {
				@media screen and (max-width: 1000px) {
					font-size: 14px !important;
				}
			}

			.body-text--smallest {
				font-size: 16px;
			}

			.header-small {
				font-size: 24px;
			}
			i {
				padding: 0 2%;
				font-size: 18px;
			}
			label {
				padding: 0 6% 0 0;
			}
			.btn-row-view {
				width: 80px;
				height: 48px;
				border-radius: 32px;
				background: none;
				border: 1px solid $body-text-color-light;
				color: $body-text-color-light;
				display: flex;
				align-items: center;
				justify-content: center;
				z-index: 2;
				text-decoration: none;

				i {
					font-size: 20px;
				}
			}
		}

		.card-view {
			.card-wrapper {
				padding: 20px;
				margin: 6% 0;
				border-radius: 32px;
				background-repeat: no-repeat;
				background-size: cover;
				background-position: top;
				position: relative;

				.rounded-circle {
					border: 4px solid rgba(12, 12, 12, 0.1);
					width: 80px;
					height: 80px;
					margin: 0 auto;
					position: relative;
					z-index: 2;
					object-fit: cover;
					border-radius: 50%;
				}

				h6 {
					font-family: din2014Bold;
					font-size: 20px;
					color: $body-text-color-lightest;
					position: relative;
					z-index: 2;
				}

				i {
					padding: 0 2%;
					font-size: 18px;
					color: $body-text-color-lightest;
					z-index: 2;
				}

				label {
					padding: 0 8% 0 0;
					color: $body-text-color-lightest;
					z-index: 2;
				}

				.btn-card-view {
					width: 80px;
					height: 48px;
					border-radius: 32px;
					background: none;
					border: 1px solid $body-text-color-lightest;
					color: $body-text-color-lightest;
					display: flex;
					align-items: center;
					justify-content: center;
					z-index: 2;
					text-decoration: none;

					i {
						font-size: 20px;
						z-index: 2;
					}
				}

				.bg-overlay {
					border-radius: 32px;
				}
			}
		}
		.dashboard-wrapper {
			.col-items {
				.nav-pills {
					z-index: 2;
					position: relative;

					.nav-link {
						color: $body-text-color-lightest;
						-webkit-text-fill-color: $body-text-color-lightest;
					}

					.nav-item {

						.nav-link.active,
						.nav-pills .show>.nav-link {
							background: rgba(255, 255, 255, 0.2);
						}
					}
				}
			}
		}
	}
	/* End of dashboard-profileno-background */
	.btn {
		position: relative;
		color: $body-text-color-lightest;
		border-style: none;
		border-radius: 50px;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;

		&:hover {
			border: none;
		}

		span {
			display: inline-block;
		}
	}
	.btn-md {
		height: 40px;
		font-size: 16px;
		font-family: din2014Bold;

		i {
			font-size: 18px;
			font-style: normal;
			font-weight: 300;
			line-height: 40px;
		}
		// @media screen and (max-width: 480px) {
		// 	font-size: 11px;
		// }
	}
	.btn-secondary {
		@extend .gradient-color-txt;
		transition: opacity 0.2s linear;
		padding: 7px;
		text-align: center;

		&:hover {
			@extend .gradient-color-bg-hover;
			-webkit-text-fill-color: #fff !important;
		}

		&:before {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			width: 100%;
			height: 100%;
			@extend .gradient-color-border;
			border: 1px solid transparent;
			border-radius: 50px;
			background: inherit;
			background-origin: border-box;
			background-clip: border-box;
			-webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
			-webkit-mask-composite: xor;
			mask-composite: exclude;
			-webkit-mask-repeat: no-repeat;


		}

		i {
			//background: #fb03b9;
			background: linear-gradient(to right, #FB03B9, #3883FF);
			-webkit-background-clip: text;
			-webkit-text-fill-color: inherit;
			padding-left: 6px;
			padding-right: 6px;
		}
	}

	.profile-card-dashboard {
		background-image: url('../../src/assets/images/profile_card_bg_no_radius.png');
		background-repeat: no-repeat;
		background-size: cover;
		background-position: left;
		width: 100%;
		fill: var(--shaders-angled-100-cool-10, linear-gradient(225deg, rgba(56, 131, 255, 0.10) 0%, rgba(251, 3, 185, 0.10) 100%));
		height: 696px;
		text-align: center;
		display: flex;
		flex-direction: column;
		justify-content: center;
		position: relative;

		//@media screen and (max-width: 1200px) {
		//	width: 450px !important;
		//}
		//
		//@media screen and (max-width: 1000px) {
		//	width: 392px !important;
		//}

		.z-index-ontop {
			position: relative;
			z-index: 0;
		}
		.video-profile-background-container {
			background: hsla(0, 0%, 100%, .2);
			border: 4px solid hsla(0, 0%, 5%, .1);
			border-radius: 50%;
			display: flex;
			height: 184px;
			width: 184px;
			margin: 0 auto;
			position: relative;
			overflow: hidden;

			.video-profile-avatar {
				position: absolute;
				width: 100%;
				height: 100%;
				object-fit: cover;
				left: 0;
				top: 0;
			}
		}

		img {
			border-radius: 50%;
			background: rgba(255, 255, 255, 0.2);
			border: 4px solid rgba(12, 12, 12, 0.1);
			width: 184px;
			height: 184px;
			margin: 0 auto;
			display: flex;
			object-fit: cover;
			object-position: center;
		}

		.body-text--reg,
		.header-large,
		p {
			color: $body-text-color-lightest;
			-webkit-text-fill-color: $body-text-color-lightest;
			word-wrap: break-word;
			/* All browsers since IE 5.5+ */
			overflow-wrap: break-word;
			/* Renamed property in CSS3 draft spec */
		}

		.list-socials {
			li {

				i {
					// color: $body-text-color-lightest;
					font-size: 36px;
				}

				.isDisabled {
					color: gray !important;
					cursor: not-allowed;
					opacity: 0.5;
					text-decoration: none;
				}
			}
		}
	}
}
/*
End of #csxl-profile-dashboard
*/


#cscxl-wallet-dashboard {
	.payment-row-view {
		@extend .row-view;
		display: block;

		.card-ico {
			font-size: 48px;
			@extend .gradient-color-txt;
			padding-right: 6%;
		}
		.exp {
			color: $body-text-color-light;
		}
		.user-img-temp {
			width: 48px;
			height: 48px;
			display: flex;
			justify-content: center;
			align-items: center;
			@extend .gradient-border;
			margin: 0 10px 0 0;

			i {
				font-size: 22px;
			}
		}
		.header-small {
			font-size: 24px;
		}
		.body-text--smallest{
			font-size: 16px;
			color: $body-text-color-lightest;
		}
		.btn-row-view {
			width: 80px;
			height: 48px;
			border-radius: 32px;
			background: none;
			border: 1px solid $body-text-color-light;
			color: $body-text-color-light;
			display: flex;
			align-items: center;
			justify-content: center;
			z-index: 2;
			text-decoration: none;

			i {
				font-size: 20px;
			}
		}
	}
}

#cscxl-dashboard {
	.dashboard-wrapper {
		border-radius: 8px;
		background-color: #ffffff;
		padding: 32px;
	}
	.btn-secondary {
		background: linear-gradient(to right, #fb03b9, #3883ff);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		text-decoration: none;

		&:before {
			background: linear-gradient(white, white) padding-box, linear-gradient(to right, #fb03b9, #3883ff) border-box;
		}
	}
	.nav-pills {
		list-style: none;
		padding: 0;

		.nav-item:first-child {
			padding-left: 0;
		}
		.nav-item:last-child {
			padding-right: 0;
		}
		.nav-item {
			@media screen and (max-width: 960px) {
				display: flex;
			}
			.nav-link {
				background: linear-gradient(to right, #fb03b9, #3883ff);
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
				text-decoration: none;
				border: 0;
				height: 40px;
				font-size: 18px;
				display: flex;
				justify-content: center;
				align-items: center;
			}
			.nav-link.active,
			.nav-pills .show>.nav-link {
				background: linear-gradient(to right, #fb03b9, #3883ff) border-box;
				border-radius: 8px;
				color: #ffffff;
				-webkit-text-fill-color: #ffffff;
			}
		}
	}
	.btn {
		position: relative;
		color: #ffffff;
		border-style: none;
		border-radius: 50px;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;

		&.btn-md {
			font-weight: bold;
			height: 40px;
			font-size: 16px;

			&:hover {
				background: linear-gradient(to right, #3883ff, #fb03b9) border-box;
				-webkit-text-fill-color: #fff !important;
			}
			&:before {
				content: '';
				position: absolute;
				top: 0;
				right: 0;
				width: 100%;
				height: 100%;
				background: linear-gradient(white, white) padding-box, linear-gradient(to right, #fb03b9, #3883ff) border-box ;
				border: 1px solid transparent;
				border-radius: 50px;
				background-origin: border-box;
				background-clip: border-box;
				-webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
				-webkit-mask-composite: xor;
				mask-composite: exclude;
				-webkit-mask-repeat: no-repeat;
			}
			i {
				font-size: 18px;
				font-style: normal;
				font-weight: 300;
				line-height: 40px;
			}
			&.btn-warning {
				background-color: transparent !important;
				background: none;
				border: 1px solid $body-text-color-light;
				border-color: $body-text-color-light;
				-webkit-text-fill-color: $body-text-color-light !important;

				&:hover,
				&:active {
					background-color: transparent !important;
					background: none;
					border: 1px solid $body-text-color-light;
					border-color: $body-text-color-light;
					-webkit-text-fill-color: $body-text-color-light !important;
				}
			}
		}
		&.btn-secondary {
			transition: opacity 0.2s linear;
			padding: 7px;
			text-align: center;

			&:hover {
				background: linear-gradient(to right, #3883ff, #fb03b9) border-box;
				-webkit-text-fill-color: #fff !important;
			}
			&:before {
				content: '';
				position: absolute;
				top: 0;
				right: 0;
				width: 100%;
				height: 100%;
				border: 1px solid transparent;
				border-radius: 50px;
				background: inherit;
				background-origin: border-box;
				background-clip: border-box;
				-webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
				-webkit-mask-composite: xor;
				mask-composite: exclude;
				-webkit-mask-repeat: no-repeat;
			}
			i {
				background: linear-gradient(to right, #FB03B9, #3883FF);
				-webkit-background-clip: text;
				-webkit-text-fill-color: inherit;
				padding-left: 6px;
				padding-right: 6px;
			}
		}
		&.btn-tertiary {
			border: 1px solid $body-text-color-lightest;
			color: $body-text-color-lightest;
			display: flex;
			align-items: center;
			justify-content: center;
			z-index: 2;
			padding: 5px;

			&:hover,
			&:active,
			&:focus {
				border: 1px solid $body-text-color-lightest;
				color: $body-text-color-lightest !important;
				background: none;
			}
		}
		@media (min-width: 960px) {
			.lg\:hidden {
				display: none;
			}
		}
	}

	&.spaces-content-container, &.collections-content-container {
		.gradient-color-txt {
			background: linear-gradient(to right, #fb03b9, #3883ff);
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
			text-decoration: none;
		}

		.spaces-container {
			margin-top: 3%;
			border-radius: 8px;
			background-color: #ffffff;

			.spaces-header {
				padding: 24px;
				border-top-left-radius: 8px;
				border-top-right-radius: 8px;
				background-repeat: no-repeat;
				background-size: cover;
				background-position: top;
				position: relative;

				h5 {
					font-size: 24px;
					color: #ffffff;
					margin: 0;
					z-index: 2;
					position: relative;
					font-weight: bold;
					--tw-gradient-from: none;
				}
				i {
					color: #ffffff;
					font-size: 18px;
					z-index: 2;
					position: relative;
				}
				label {
					font-size: 16px;
					z-index: 2;
					position: relative;
					color: #ffffff;
				}
				.bg-overlay {
					width: 100%;
					height: 100%;
					background-color: rgba(12, 12, 12, 0.2);
					position: absolute;
					top: 0;
					right: 0;
					z-index: 1;
					-webkit-backdrop-filter: blur(12px);
					backdrop-filter: blur(12px);
					border-top-left-radius: 8px;
					border-top-right-radius: 8px;
				}
			}

			.spaces-content {
				padding: 25px;

				p {
					margin: 0;
				}
				.body-txtitalic--smallest {
					color: #888888;
				}
				.spaces-menu {
					list-style: none;
					padding: 0;
					margin: 0;

					li:first-child {
						padding-top: 0;
					}
					li {
						padding-top: 15px;

						a {
							text-decoration: none;

							i {
								width: 35px;
								font-size: 18px;

							}
						}
					}
				}
				.expired {
					color: #888888 !important;
					-webkit-text-fill-color: #888888 !important;
				}
				.header-title {
					background: linear-gradient(to right, #fb03b9, #3883ff);
					-webkit-background-clip: text;
					-webkit-text-fill-color: transparent;
					text-decoration: none;
					font-size: 20px;
				}
				.body-text--smallest {
					color: #0c0c0c;
					word-wrap: break-word;
					font-size: 16px;
					line-height: 1.5rem;
					margin: 0;
				}
				.body-txtitalic--smallest {
					overflow-wrap: break-word;
					font-size: 14px;
					line-height: 1.25rem;
					margin: 0;
					color: #888888;
					font-style: italic;
				}
				.img-thumb, .img-thumb-circle, .img-thumb-square, .img-thumb-expired {
					width: 84px;
					height: 84px;
					border: 1px solid transparent;
					object-fit: cover;
					object-position: center;
				}
				.img-thumb-square {
					background: linear-gradient(white, white) padding-box, linear-gradient(to right, #fb03b9, #3883ff) border-box;
					border-radius: 50%;
				}
				.polygon-membership {
					list-style: none;
					padding: 0;
					margin: 0;

					li {
						display: inline-block;
						margin-left: -30px;

						&:first-child {
							margin-left: 0;
						}

						a.hex {
							background: linear-gradient(to right, #fb03b9, #3883ff) border-box;
							margin-top: 4px;
							width: 80px;
							height: 80px;
							display: inline-block;
							position: relative;
							border-radius: 50%;

							img {
								width: 80px;
								height: 80px;
								display: block;
								position: absolute;
								top: 1px;
								left: 1px;
								object-fit: cover;
								border-radius: 50%;
							}
						}
					}
				}
				@media (min-width: 768px) {
					.md\:flex {
						display: flex;

						a.ms-4 {
							margin-left: 16px;
						}
					}
				}
			}
		}

		.space-manage-bg {
			padding: 32px;
			border-radius: 32px;
			transition: border-radius 2s ease-in;
			background-repeat: no-repeat;
			background-size: cover;
			background-position: top;
			position: relative;

			&.open {
				border-radius: 32px 32px 0 0;
				transition: border-radius 0s ease-in;
			}
			.space-manage-content {
				z-index: 10;
			}
			.btn-tertiary.focus {
				background-repeat: no-repeat;
				background-size: cover;
				background-position: top;
				position: relative;
				@extend .gradient-color-bg;
			}

			img,
			h3,
			h5 {
				z-index: 2;
				position: relative;
			}

			img {
				object-fit: cover;
				object-position: center;
				width: 240px;
				height: 240px;
				display: inline-block;
				@extend .gradient-color-border;
				border: 1px solid transparent;
				border-radius: 8px;
			}

			h3 {
				font-size: 36px;
				color: $body-text-color-lightest;
			}

			h5 {
				color: $body-text-color-lightest;
				font-size: 20px;
			}

			.header-title {
				color: $body-text-color-lightest;
				-webkit-text-fill-color: $body-text-color-lightest;
			}

			.body-text--smallest,
			.body-txtitalic--smallest {
				color: $body-text-color-lightest;
			}

			p {
				margin-bottom: 4px;
			}

			.bg-overlay {
				border-radius: 32px;
			}

		}
	}
}


/*
Explicit Dropdown override
*/
#cscxl-explicit-dropdown{
	display: unset;

    ul{
        margin-top: 0px !important;
        border-radius: 8px;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        width: calc(100% - 32px);
        margin-left: 16px;
        margin-right: 16px;
        padding: 0px;
        overflow: hidden;
    }
    li{
        position: relative;
        list-style-type: none;
        border-top-width: 0px;
        text-align: center;
        margin-top: 0px !important;
    }
    li:hover{
        background-image: linear-gradient(to right, var(--tw-gradient-stops));
        --tw-gradient-from: rgb(var(--color-key) / 1) var(--tw-gradient-from-position);
        --tw-gradient-to: rgb(var(--color-key) / 0) var(--tw-gradient-to-position);
        --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
        --tw-gradient-to: rgb(var(--color-key-end) / 1) var(--tw-gradient-to-position);
        --tw-text-opacity: 1;
        color: rgb(var(--color-invert) / var(--tw-text-opacity));
    }

    //.icon-dropdown-gradient-alt.secondary span, .button-dropdown-gradient-alt.secondary span {
    //    --tw-bg-opacity: 0.9;
    //}

}

/*
Country Select Dropdown Override
*/

#cscxl-country-select, #cscxl-state-dropdown{
    select{
        margin-top: 0px;
    }
}
#cscxl-city-select{
    select{
        margin-top: 0px;
    }
}


/*
Start Customize Pro Space
*/
	.cscxl-color-holder {
		max-width: 58px;
		height: 38px;
		margin: 8px 16px;
		width: 100%;
		border: 1px solid #e7e7e7;
		cursor: pointer;
		position: relative;

	}

    .cs-color-picker-block{
        background-image: none;
    }
/*
End Customize Pro Space
*/
#cscxl-font-picker{
    div[id^=font-picker] {
        width: 100%;
        background: transparent;
    }
    div[id^=font-picker] .dropdown-icon {
        display: none;
    }


    div[id^=font-picker] ul{
        background: #f5edfc;
    }
}


#font-picker-titleFont, #font-picker-bodyFont{
    div[id^=font-picker] {
        width: 100%;
    }
    div[id^=font-picker].expanded ul {
        width: 100%;
    }

    .dropdown-button{
        background: transparent;
     }
}


/*
Hide antd file attachment name
*/
.ant-upload-list-item {
	display: none !important;
}

/*
Hide antd labels
*/
.ant-form-item-label {
    display: none;
}


/*
 Membership cs-checkout-product-hero video css
*/
.cs-checkout-product-hero {
	video {
		aspect-ratio: 1.1618 / 1;
		object-fit: cover;
		border-top-left-radius: 24px;
		border-bottom-left-radius: 24px;
	}
}
/*
 Mebership product video css
*/
#cs-product-cover-media {
	video {
		object-fit: cover;
		border-radius: 24px;
	}
}

#memberProfileForm {
	.form-input input {
		margin: unset;
	}
}

/*
 Edit profile preview video css
*/
#cscxl-profile-edit-video-bg {
	video {
		object-fit: cover;
		// @apply rounded-r-card
	}
}

/*
 Create membership profile preview video css
*/
#cscxl-create-member-profile {
	video {
		object-fit: cover;
		// @apply rounded-card
	}

}

/*
 Override react modal overlay
*/
//.ReactModal__Overlay {
//	z-index: 888;
//}

//.ReactModal__Overlay{
//    position: absolute !important;
//    z-index: 800;
//}

/*
 CSS for space dashboard members and updates
*/
.row-view {
	.row-view-wrapper {
		border-radius: 32px;
		background-color: #ffffff;
		padding: 15px 25px;
	}

	.rounded-circle {
		@extend .gradient-color-border;
		border: 1px solid transparent;
		width: 40px;
		height: 40px;
		object-fit: cover;
		border-radius: 50%;

		@media screen and (max-width: 1000px) {
			width: 38px;
			height: 38px;
		}
	}

	.fa-light {
		@media screen and (max-width: 1000px) {
			font-size: 14px !important;
		}
	}

	.body-text--smallest {
		font-size: 16px;
	}

	.header-small {
		font-size: 24px;
	}
	i {
		padding: 0 2%;
		font-size: 18px;
	}
	label {
		padding: 0 6% 0 0;
	}
	.btn-row-view {
		width: 80px;
		height: 48px;
		border-radius: 32px;
		background: none;
		border: 1px solid $body-text-color-light;
		color: $body-text-color-light;
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 2;
		text-decoration: none;

		i {
			font-size: 20px;
		}
	}
}

.table-header {
	a {
		text-decoration: none;
		color: $body-text-color-light !important;

		i {
			font-size: 14px !important;
		}
	}
}

.manage-updates{
	.thumb-rectangle{
		width: 80px;
		height: 48px;
		border-radius: 8px;
		object-fit: cover;

		video {
			border-radius: 8px;
		}
	}

	.text-editor-area {
		.ql-container {
			min-height: 304px !important;
		}
	}
}

/*
 End CSS for space dashboard members and updates
*/

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
	display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

/* Remove input and text area focus highlights */
input:focus,
input.form-control:focus {
	outline: none !important;
	outline-width: 0 !important;
	box-shadow: none;
	-moz-box-shadow: none;
	-webkit-box-shadow: none;
}

textarea:focus,
textarea.form-control:focus {
	outline: none !important;
	outline-width: 0 !important;
	box-shadow: none;
	-moz-box-shadow: none;
	-webkit-box-shadow: none;
}
/* End of remove input and text area focus highlights */

/* Manage Members */
.manage-members{
	.btn {
		position: relative;
		color: #ffffff;
		border-style: none;
		border-radius: 50px;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;

		&.btn-md {
			font-weight: bold;
			height: 40px;
			font-size: 16px;

			&:hover {
				background: linear-gradient(to right, #3883ff, #fb03b9) border-box;
				-webkit-text-fill-color: #fff !important;
			}
			&:before {
				content: '';
				position: absolute;
				top: 0;
				right: 0;
				width: 100%;
				height: 100%;
				background: linear-gradient(white, white) padding-box, linear-gradient(to right, #fb03b9, #3883ff) border-box ;
				border: 1px solid transparent;
				border-radius: 50px;
				background-origin: border-box;
				background-clip: border-box;
				-webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
				-webkit-mask-composite: xor;
				mask-composite: exclude;
				-webkit-mask-repeat: no-repeat;
			}
			i {
				font-size: 18px;
				font-style: normal;
				font-weight: 300;
				line-height: 40px;
			}
			&.btn-warning {
				background-color: transparent !important;
				background: none;
				border: 1px solid $body-text-color-light;
				border-color: $body-text-color-light;
				-webkit-text-fill-color: $body-text-color-light !important;

				&:hover,
				&:active {
					background-color: transparent !important;
					background: none;
					border: 1px solid $body-text-color-light;
					border-color: $body-text-color-light;
					-webkit-text-fill-color: $body-text-color-light !important;
				}
			}
		}
		&.btn-secondary {
			transition: opacity 0.2s linear;
			padding: 7px;
			text-align: center;

			&:hover {
				background: linear-gradient(to right, #3883ff, #fb03b9) border-box;
				-webkit-text-fill-color: #fff !important;
			}
			&:before {
				content: '';
				position: absolute;
				top: 0;
				right: 0;
				width: 100%;
				height: 100%;
				border: 1px solid transparent;
				border-radius: 50px;
				background: inherit;
				background-origin: border-box;
				background-clip: border-box;
				-webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
				-webkit-mask-composite: xor;
				mask-composite: exclude;
				-webkit-mask-repeat: no-repeat;
			}
			i {
				background: linear-gradient(to right, #FB03B9, #3883FF);
				-webkit-background-clip: text;
				-webkit-text-fill-color: inherit;
				padding-left: 6px;
				padding-right: 6px;
			}
		}
		&.btn-tertiary {
			border: 1px solid $body-text-color-lightest;
			color: $body-text-color-lightest;
			display: flex;
			align-items: center;
			justify-content: center;
			z-index: 2;
			padding: 5px;

			&:hover,
			&:active,
			&:focus {
				border: 1px solid $body-text-color-lightest;
				color: $body-text-color-lightest !important;
				background: none;
			}
		}
		@media (min-width: 960px) {
			.lg\:hidden {
				display: none;
			}
		}
	}
	.list-socials {
		list-style: none;
		padding: 0px;
		margin: 0;
		-webkit-text-fill-color: gray !important;
		opacity: 0.5;

		li {
			display: inline-block;
			padding: 0px 10px;

			i {
				font-size: 36px;
			}
			.isDisabled {
				color: gray !important;
				cursor: not-allowed;
				opacity: 0.5;
				text-decoration: none;
			}
		}
	}

    .manage-members-header{
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        position: relative;
        border-top-left-radius: 24px;
        border-top-right-radius: 24px;
        padding: 32px;

        .ms-4 {
            overflow-wrap: break-word;
        }

        .manage-members-profile{
            width: 188px;
            height: 188px;
            border-radius: 50%;
            border: 4px solid rgba(12, 12, 12, 0.1);
            object-fit: cover;
        }

        .list-socials{
            a{
                color: #FFFFFF;
            }
        }

        .bg-overlay {
            border-top-left-radius: 32px;
            border-top-right-radius: 32px;
        }

        .ico-p{
            i{
                width: 30px;
            }
        }
    }

    .manage-members-container{
        padding: 32px;

        .manage-members-card{
            width: 150px;
            height: 150px;
            box-shadow: 0px 0px 10px 0px rgba(12, 12, 12, 0.5);
            border-radius: 32px;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            position: relative;

            .bg-overlay {
                backdrop-filter: none !important;
                border-radius: 32px;
            }
        }
    }
}
/* End Manage Members */


/*Dashboard profile class css*/
.dashboard-profile {
	border-radius: 24px;
	// background-color: $body-text-color-lightest;

	&.no-background {
		background-color: transparent;
	}

	.card-btn-wrapper {
		//padding: 25px 12px 0px 30px;

		.btn-md {
			i {
				font-size: 20px;
			}
		}
	}

	p {
		//@media screen and (max-width: 1400px) {
		//	font-size: 14px !important;
		//}
		//
		//@media screen and (max-width: 1000px) {
		//	font-size: 12px !important;
		//}
		//@media screen and (max-width: 400px) {
		//	font-size: 12px !important;
		//}
	}


	.input-border-bottom {

		input {
			font-size: 14px !important;
			// @media screen and (max-width: 1400px) {
			// 	font-size: 14px !important;
			// }

			// @media screen and (max-width: 1200px) {
			// 	font-size: 13px !important;
			// }

			// @media screen and (max-width: 1000px) {
			// 	font-size: 12px !important;
			// }
		}

		span {
			@media screen and (max-width: 1400px) {
				font-size: 10px !important;
			}

			@media screen and (max-width: 1000px) {
				font-size: 9px !important;
			}
		}

		label {
			// @media screen and (max-width: 1400px) {
			// 	font-size: 12px !important;
			// }

			// @media screen and (max-width: 1000px) {
			// 	font-size: 10px !important;
			// }
		}
	}

	.upload-circ {
		width: 80px;
		height: 80px;

		.ant-upload-drag-container {
			display: inline-grid !important;
			height: 100%;
			width: 100%;

			.videoPreview {
				height: 158% !important;
			}
		}
	}

	.upload-rec {
		width: 145px;
		height: 80px;

		@media screen and (max-width: 1400px) {
			width: 130px !important;
			height: 70px !important;
		}


		@media screen and (max-width: 1200px) {
			width: 110px !important;
			height: 70px !important;
		}

		@media screen and (max-width: 1000px) {
			width: 110px !important;
			height: 70px !important;
		}
	}

	.list-socials {
		list-style: none;
		padding: 0px;
		-webkit-text-fill-color: gray !important;
		opacity: 1;

		li {
			display: inline-block;
			padding: 0px 8px;
			height: 40px;
			width: 40px;
			display: inline-flex;
			align-items: center;

			i {
				font-size: 20px;

				@media screen and (max-width: 1400px) {
					font-size: 20px !important;
				}

				// @media screen and (max-width: 1200px) {
				// 	font-size: 13px !important;
				// }

				// @media screen and (max-width: 1000px) {
				// 	font-size: 14px !important;
				// }
			}
		}
	}

	.profile-card-dashboard {
		//background-image: url('../../../src/assets/images/profile_card_bg_no_radius.png');
		background-repeat: no-repeat;
		background-size: cover;
		background-position: left;
		width: 100%;
		//fill: var(--shaders-angled-100-cool-10, linear-gradient(225deg, rgba(56, 131, 255, 0.10) 0%, rgba(251, 3, 185, 0.10) 100%));
		height: 696px;
		text-align: center;
		display: flex;
		flex-direction: column;
		justify-content: center;
		position: relative;

		//@media screen and (max-width: 1200px) {
		//	width: 450px !important;
		//}
		//
		//@media screen and (max-width: 1000px) {
		//	width: 392px !important;
		//}

		img {
			border-radius: 50%;
			background: rgba(255, 255, 255, 0.2);
			border: 4px solid rgba(12, 12, 12, 0.1);
			width: 184px;
			height: 184px;
			margin: 0 auto;
			display: flex;
			object-fit: cover;
			object-position: center;
		}

		.body-text--reg,
		.header-large,
		p {
			color: $body-text-color-lightest;
			-webkit-text-fill-color: $body-text-color-lightest;
			word-wrap: break-word;
			/* All browsers since IE 5.5+ */
			overflow-wrap: break-word;
			/* Renamed property in CSS3 draft spec */
		}

		.list-socials {
			li {

				i {
					// color: $body-text-color-lightest;
					font-size: 36px;
				}

				.isDisabled {
					color: gray !important;
					cursor: not-allowed;
					opacity: 0.5;
					text-decoration: none;
				}
			}
		}
	}

	.profile-card-item {
		background-repeat: no-repeat;
		background-size: cover;
		background-position: top;
		height: 100%;
		border-top-right-radius: 24px;
		border-bottom-right-radius: 24px;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;

		img {
			width: 496px;
			height: 496px;
			position: relative;
			z-index: 2;
			border-radius: 15px;
			object-fit: cover;
		}

		.bg-overlay {
			border-top-right-radius: 32px;
			border-bottom-right-radius: 32px;
		}
	}
}
/*End Dashboard profile class css*/


.dialog-box-secondary {
	@extend .gradient-color-border;
	border: 1px solid transparent;
	//background-color: rgba(251, 3, 185, 1);
	border-radius: 32px;
	display: flex;

	.content {
		background: rgba(251, 3, 185, 1);
		width: 100%;
		height: 100%;
		border-radius: 32px;
		align-self: stretch;
		padding: 25px;
	}
}

/* Social list Styles for space settings */
.space-profile-url {
	.list-socials {
		padding: 0px;
		margin-top: 20px;
		list-style: none;
		display: flex;
		justify-content: space-evenly;

		li {
			display: inline-block;

			// a {
			i {
				font-size: 36px;
			}

			.isDisabled {
				color: gray !important;
				cursor: not-allowed;
				opacity: 0.5;
				text-decoration: none;
			}
		}
	}
}
/* End social list Styles for space settings */

.gradient-border-mask {
	position: relative;
}

.gradient-border-mask::before {
	content: "";
	position: absolute;
	inset: 0;
	border-radius: inherit;
	border: 5px solid transparent;
	background: linear-gradient(to right, $gradient-color-from, $gradient-color-to) border-box;
	mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
	mask-composite: exclude;
}

.remove-benefit-file {
	float: right;
	margin: auto -8px auto auto;
	font-size: 22px;
	color: indianred;
	cursor: pointer;
}